<script>
  import { _ } from 'svelte-i18n';
  import { get } from 'svelte/store';
  import { onMount } from 'svelte';
  import { push } from 'svelte-spa-router';
  import { ApplicationStep, AGENT_ACTIONS, appReport, FailedReason, EventStatus, EventType, USER_ACTIONS } from '../../js/appReport';
  import {
    booking,
    CustomErrorModal,
    headPassenger,    
    infants,
    integratedCatalogue,
    isGetBagAllowanceServiceCalled,
    passengerBagCounts,
    setErrorModal,    
    wizardPosition,
  } from '../../js/stores';
  import endTransaction from '../../js/endTransaction';
  import {     
    numberOfBagsSelected,
    getPieceConceptExcessNumberOfBags,
    excessPieceApproxCharges,
    excessPieceCurrency,
    setAcceptedToPay
  } from '../../js/stores/bags';
  import { ErrorModals } from '../../js/const/errorModals';
  import flightdeck from '../../js/services/flightdeck';
  import flightdeckConsts from '../../js/services/flightdeck/const';
  import logger from '../../js/logger';
  import { numberOfBags } from '../../js/numberOfBags';
  import { AllowanceType, WizardPosition } from '../../js/const';
  import BaggageAllowance from '../components/modal/BaggageAllowance.svelte';
  import PieceAllowanceExceeded from '../components/modal/PieceAllowanceExceeded.svelte';
  import Content from '../components/Content.svelte';
  import Footer from '../components/Footer/index.svelte';
  import Header from '../components/Header/index.svelte';
  import Loading from '../components/Loading.svelte';
  import NumberOfBags from '../components/NumberOfBags/index.svelte';
  import OtherNumberOfBags from '../components/modal/OtherNumberOfBags.svelte';
  import switchboardClient from '../../js/services/switchboard';

  wizardPosition.set(WizardPosition.PASSENGERS);

  const avgBags = numberOfBags.getPreEmptiveBaggage();
  
  let isLoading = true;
  let isPieceExcessRateRetrieved = false;
  let isPcBaggage = null;
  let showBaggageAllowanceModal = false;
  let showOtherNumberOfBagsModal = false;
  let showPieceAllowanceExceededModal = false;
  let selectedNumberOfBags = 0;
  let approximateExcessCharges = 0;
  let currency = 'AED'; 
  let excessNumberOfBags = 0;
  let declinedPaymentModal;

  /** Store the user's selection.  */
  function recordBagInput(numberOfBags) {
    logger.info(`User selects number of bags: ${numberOfBags}.`);

    passengerBagCounts.setPassengerBagCount(
      $headPassenger.passengerID,
      numberOfBags,
    );

    excessNumberOfBags = getPieceConceptExcessNumberOfBags(selectedNumberOfBags);
    
    isLoading = true;    

    // we can come back to this screen "Number of bags" from Next screen "Dangerous Good". we will not call
    // acceptBaggage from here, since user may come back and change the number of bags they want to check in
    advanceToNextScreen();
  }

  function getExcessBaggageRateForPiece(_excessNumberOfBags) {
    if (_excessNumberOfBags > 0) {    
      isLoading = true;
      const { passengers } = get(booking);
      const customerPrimeId = passengers[0].passengerID; 
      const segment = passengers[0].segments[0];
      let marketingCarrier = segment.marketingAirlineCode;
      let departureDate = segment.departureDateTime;
      let boardPoint = segment.departureCode;
      let offPoint = segment.arrivalCode;       
      
      switchboardClient.excessBaggageRate(marketingCarrier, departureDate, boardPoint, offPoint, customerPrimeId, _excessNumberOfBags)
        .then((response) => {
          if (response) {
            let ebr = response?.data?.excessBaggageRate;
            let excessBaggageRateHasErrors = ebr && ((ebr?.errorMessages && ebr?.errorMessages?.length > 0) || !ebr?.isGetSuccessful)
            if (excessBaggageRateHasErrors) {
              logger.error('Error getting excess baggage rate information');
              ebr?.errorMessages?.forEach((error) => {
                logger.error(error);
              });
              flightdeck.excessBaggageRateFailed();
              setErrorModal(ErrorModals.EXCESS_BAGGAGE_RATE_ERROR);
              return;
            }
            approximateExcessCharges = ebr?.excessCharges;
            excessNumberOfBags = _excessNumberOfBags;
            currency = ebr?.currency;
            isLoading = false;
            isPieceExcessRateRetrieved = true;
            showPieceAllowanceExceededModal = true;
            excessPieceApproxCharges.set(ebr?.excessCharges);
            excessPieceCurrency.set(ebr?.currency);
          }
        },(error) => {
          isLoading = false;
          flightdeck.excessBaggageRateFailed();
          setErrorModal(ErrorModals.EXCESS_BAGGAGE_RATE_ERROR);
          return;
        });
    } else {
      push('/dangerous-goods');
    }
  }



  /** Advance to the next screen. */
  function advanceToNextScreen() {    
    // check if it is piece concept, then call ExcessBaggageRate
    if (!isPcBaggage) { 
      // now that the passenger can come back from Dangerous goods screen to this screen,
      // we need to check if integratedCatalogue has already been called
      let catalogue = get(integratedCatalogue);
      let isCatalogueEmpty = false;
      if (catalogue && Object.keys(catalogue).length === 0) {
        isCatalogueEmpty = true;
      } 

      if (isCatalogueEmpty) {
        let currentBooking = get(booking);
        let bookingReference;
        
        if (currentBooking.bagGroupOwnerPassengerID) {
          logger.info(`This booking has a bag group owner with passengerID ${currentBooking.bagGroupOwnerPassengerID}`)
          currentBooking.passengers.forEach(p => {
            if (p.passengerID === currentBooking.bagGroupOwnerPassengerID) {
              logger.info(`Booking Reference of bag group owner is: ${p.bookingReference}`)
              bookingReference = p.bookingReference;
            }
          });
        } else {
          logger.info(`This booking does not have a bag group owner. hence using ${currentBooking?.bookingReference}`)
          bookingReference = currentBooking?.bookingReference;
        }

        // fetch integrated catalogue before going to dangerous goods
        switchboardClient.integratedCatalogue(bookingReference)
        .then((response) => {
          if (response) {
            const errorMessages = response.data?.integratedCatalogue?.errorMessages;
            
            if (errorMessages && errorMessages?.length > 0) {
              logger.error('Errors in Integrated catalogue service. Excess baggage calculation may be incorrect.');
              errorMessages.forEach((error) => {
                logger.error(error);
              });
              // we do not want to show an alert or exit on errors from integrated catalogue
              // since it deals with excess baggage only.
            }
            integratedCatalogue.set(response?.data?.integratedCatalogue);
          }
          isLoading = false;
          push('/dangerous-goods');
        });
      } else {
        isLoading = false;
        push('/dangerous-goods');
      }
    } else {
      isLoading = false;
      push('/dangerous-goods');
    }
  }

  /** Handle user input of number of bags. */
  function showOtherNumberOfBagsHandler(event) {
    showOtherNumberOfBagsModal = false;
    
    selectedNumberOfBags = event.detail.numberOfBags;

    if (selectedNumberOfBags === 0) {
      logger.info(`User selects number of bags: 0. Going to dangerous goods screen.`);
      passengerBagCounts.setPassengerBagCount($headPassenger.passengerID, 0);
      push('/dangerous-goods');
      return;
    }

    numberOfBagsSelected.set(selectedNumberOfBags);

    excessNumberOfBags = getPieceConceptExcessNumberOfBags(selectedNumberOfBags);
    // we need to consider return to bag drop case, where passenger may have previously checked in bags
    if (isPcBaggage && excessNumberOfBags > 0) {
      logger.info('Selected number of bags has exceeded piece allowance.');
      getExcessBaggageRateForPiece(excessNumberOfBags);
    } else {
      // If it is weight market, call record Bag Input OR 
      // If it is piece market but there is no Excess then also call record Bag Input
      recordBagInput(selectedNumberOfBags);
    }
  }

  function cabinBaggageAllowanceHandler() {
    showBaggageAllowanceModal = true;
  }

  onMount(() => {
    appReport.updateStepStart(ApplicationStep.ACCEPT_BAGGAGE_GROUP);

    // when the number of bags screen is loaded we would show the loading/inProgress animations
    // because we are fetching baggage allowances on mount, so until
    // we finish fetching baggage allowance and update the store, we want to keep 
    // showing the loading/inProgress animations
    isLoading = true;
    isPcBaggage = booking.getBaggageUnit() === 'pc';
    showBaggageAllowanceModal = false;
    showOtherNumberOfBagsModal = false;

    declinedPaymentModal = new CustomErrorModal(
      ErrorModals.EXCESS_BAGGAGE_COST_DECLINED,
    );

    declinedPaymentModal.setFlightdeckHandler(
      flightdeckConsts.TransactionStatuses.Cancelled,
      handleFlightDeckPaymentDeclinedCancelled,
    );

    declinedPaymentModal.setEndHandler(
      handleFlightDeckPaymentDeclinedCancelled,
    );

    declinedPaymentModal.setFlightdeckHandler(
      flightdeckConsts.TransactionStatuses.Overridden,
      handleFlightDeckPaymentDeclinedOverride,
    );

    declinedPaymentModal.setOverrideHandler(
      handleFlightDeckPaymentDeclinedOverride,
    );

    let bagAllowanceServiceCalled = get(isGetBagAllowanceServiceCalled);
    let isbagAllowanceServiceCalledEmpty = false;
    if (bagAllowanceServiceCalled && Object.keys(bagAllowanceServiceCalled).length === 0) {
      isbagAllowanceServiceCalledEmpty = true;
    } 
    logger.info('calling getBagAllowance() Service');
    getBaggageAllowance();
  });

  function handleFlightDeckPaymentDeclinedOverride() {
    // Report a failure, with step finished
    appReport.updateStepWithAction(
      ApplicationStep.EXCESS_BAGGAGE_EXTRA_PIECES_PAYMENT,
      EventType.STEP_FINISH,
      EventStatus.SUCCESS,
      AGENT_ACTIONS.PAYMENT_DECLINED_TRANSACTION_ENDED_FROM_FLIGHTDECK,
    );
    
    recordBagInput(selectedNumberOfBags);
    return true;
  }

  function handleFlightDeckPaymentDeclinedCancelled() {
    // Report a failure, with step finished
    appReport.updateStepWithAction(
      ApplicationStep.EXCESS_BAGGAGE_EXTRA_PIECES_PAYMENT,
      EventType.STEP_FINISH,
      EventStatus.SUCCESS,
      AGENT_ACTIONS.PAYMENT_DECLINED_TRANSACTION_ENDED_FROM_FLIGHTDECK,
    );

    endTransaction();
    return true;
  }
  
  function getBaggageAllowance() {
    appReport.updateStepStart(ApplicationStep.GET_BAGGAGE_ALLOWANCE);
    let passengerDetailsList = [];
    let thisBooking = get(booking);
    (thisBooking.passengers || []).concat(get(infants)).forEach((passenger) => {
      let passengerDetails = {};
      let segmentIndex = 0;
      passenger.segments.forEach((segment) => {
        if (segmentIndex === 0) {
          passengerDetails = {
            surname: passenger.lastName,
            customerPrimeId: passenger.passengerID,
            marketingCarrier: segment.airlineCode,
            flightNumber: segment.flightNumber,
            departureDate: thisBooking?.departureDate,
            boardPoint: segment.departureCode,
            productPrimeId: segment.passengerDID
          }
          segmentIndex = segmentIndex + 1;
        }
      });
      passengerDetailsList.push(passengerDetails);
    });
    switchboardClient.getBagAllowance(passengerDetailsList).then(
      (response) => {                  
        const errorMessages = response.data?.bagAllowance?.errorMessages;
        if (errorMessages && errorMessages?.length > 0) {
          logger.error('Errors in GetBagAllowance service.');
          errorMessages.forEach((error) => {
            logger.error(error);
          });
          isLoading = false;
          flightdeck.bagAllowanceRetrievalFailed();
          setErrorModal(ErrorModals.BAG_ALLOWANCE_ERROR);
          return;
        }
        isGetBagAllowanceServiceCalled.set(true);
        get(booking).passengers.forEach((passenger) => {
          updateBaggageInBooking(response, passenger);
        });
        get(infants).forEach((passenger) => {
          updateBaggageInBooking(response, passenger);
        });
        
        isLoading = false;
        appReport.updateStepSuccess(ApplicationStep.GET_BAGGAGE_ALLOWANCE);
      },
      (error) => {
        appReport.updateStepFailed(
          ApplicationStep.GET_BAGGAGE_ALLOWANCE,
          FailedReason.SWITCHBOARD_CALL_FAILED
        );
      },
    );    
  }
  
  function updateBaggageInBooking(response, passenger) {
    // passengerBagDetails contains the Array<BagDetails> for one passenger at a time
    // it may have one or more rows
    let passengerBagDetails =
      response.data.bagAllowance.baggageDetailsList.filter(
        (x) => x.passengerId === passenger.passengerID,
      );
    if (passengerBagDetails && passengerBagDetails.length > 0) {
      // if baggage for this passenger is null, initialise it
      if (!passenger.baggage) {
        passenger.baggage = [];
      } else {
        // Remove any ticketed baggage for this passenger that was added from CPR_Identification
        // Amadeus recommends to use Ticketed baggage allowance from GetBaggageAllowance service.
        passenger.baggage = passenger.baggage.filter(
          (bag) => bag.type !== AllowanceType.VCR,
        );
      }
      passengerBagDetails.forEach((bag) => {
        passenger.baggage.push({
          unit: bag.unitQualifier,
          allowance: bag.number ? bag.number : bag.weight,
          type: AllowanceType.VCR,
          pieceWeight: bag.weight
        });
      });
    }
  }

  function handleBaggageAllowanceExceededPCAccept() {
    showPieceAllowanceExceededModal = false;
    setAcceptedToPay(true);
    recordBagInput(selectedNumberOfBags);
  }

  function handleBaggageAllowanceExceededPCDecline() {
    showPieceAllowanceExceededModal = false;

    declinedPaymentModal.open();
    
    flightdeck.excessBaggagePaymentFailed(
      excessNumberOfBags,
      approximateExcessCharges,
      currency,
    );
  }
</script>

<Header />

{#if isLoading === false }

  {#if isPieceExcessRateRetrieved === true }
    <PieceAllowanceExceeded
      excessNumberOfBags={excessNumberOfBags}
      currency={currency}
      bind:showModal={showPieceAllowanceExceededModal}
      approximateExcessCharges={approximateExcessCharges}
      passengerAcceptHandler={handleBaggageAllowanceExceededPCAccept}
      passengerDeclineHandler={handleBaggageAllowanceExceededPCDecline}
    />
  {/if}

  <BaggageAllowance bind:showModal={showBaggageAllowanceModal} />
  <OtherNumberOfBags
    bind:showModal={showOtherNumberOfBagsModal}
    on:numberOfBagsSelection={showOtherNumberOfBagsHandler}
  />
  <Content>
    <span slot="heading">{$_('numberOfBags.heading')}</span>
    <div slot="main" style="margin-top: 200px;">
      <NumberOfBags
        {avgBags}
        {cabinBaggageAllowanceHandler}
        {showOtherNumberOfBagsHandler}
        bind:showBaggageAllowanceModal
        bind:showOtherNumberOfBagsModal
      />
    </div>  
    </Content>
    
{:else}      
  <Loading heading={$_('app.pleaseWait')} />
{/if}

<Footer />
import { get } from 'svelte/store';
import { booking } from './stores/booking';
import { AVERAGE_PASSENGER_BAGS_PER_DESTINATION } from './const';

// eslint-disable-next-line import/prefer-default-export
export const numberOfBags = {
  getPreEmptiveBaggage() {
    const { destinationCode } = get(booking);
    const totalBags = Math.round(
      AVERAGE_PASSENGER_BAGS_PER_DESTINATION[destinationCode] *
        booking.passengerCount(),
    );
    return totalBags > 1 ? totalBags : 2;
  },
};

<script>
  import { _, locale } from 'svelte-i18n';
  import logger from '../../../js/logger';

  import Button from '../Button.svelte';
  import ModalBase from '../ModalBase.svelte';

  export let showModal = false;

  let triggerClose;

  /** Creates a function that will set locale and close. */
  function createClickHandler(newLocale) {
    return () => {
      logger.info(`Language selected: ${newLocale}.`);
      locale.set(newLocale);
      triggerClose();
    };
  }

  const LANGUAGES = [
    {
      greeting: 'مرحباً',
      language: '‏‏العربية',
      code: 'ar',
      forceArabic: true,
    },
    {
      greeting: 'Hello!',
      language: 'English',
      code: 'en',
      forceArabic: false,
    },
    {
      greeting: 'Bonjour!',
      language: 'French',
      code: 'fr',
      forceArabic: false,
    },
    {
      greeting: 'Hola!',
      language: 'Spanish',
      code: 'es',
      forceArabic: false,
    },
  ];
</script>

<ModalBase
  heading="Choose Your Language"
  class="notArabic"
  bind:showModal
  bind:triggerClose
>
  <div class="flex flex-wrap justify-between mt-18 -mx-2" dir="ltr">
    {#each LANGUAGES as language, i}
      <div class="mb-14 px-2 w-1/2">
        <Button
          class="leading-none w-full"
          isArabic={language.forceArabic}
          on:click={createClickHandler(`${language.code}`)}
        >
          {language.greeting}
          <br />
          {language.language}
        </Button>
      </div>
    {/each}
  </div>
</ModalBase>

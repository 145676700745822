import endTransaction from '../endTransaction';

import alert from '../../svg/warning.svg';
import alertBag from '../../svg/alertBag.svg';
import alertBagMovement from '../../svg/alertBagMovement.svg';
import alertEmail from '../../svg/message.svg';
import alertPrint from '../../svg/printer.svg';

const overrideDefault = {
  heading: 'Passenger Assistance',
  endDescription: 'Pressing End will terminate the transaction.',
  overrideDescription: 'Pressing Override will resume the transaction.',
  overrideHandler: () => {},
  endHandler: (reason = null) => {
    endTransaction(reason);
  },
};

export const ErrorModals = {
  ADD_BAGGAGE_INFORMATION_ERROR: {
    heading: 'modal.bagTagIssuance.heading', // using bag tags texts since add baggage information is used for the similar purposes
    textLine1: 'modal.bagTagIssuance.textLine1',
    textLine2: 'modal.generic.textLine1',
    icon: alertBag,
    iconClass: 'text-current',
  },
  ADULT_OR_INFANT_PASSPORT_SCAN_FAILED: {
    heading: 'modal.assistanceRequired.heading',
    icon: alert,
    override: {
      ...overrideDefault,
      flightdeckMessage: 'Adult / Infant Passport Scan Override.',
    },
  },
  ASSISTANCE_REQUIRED_ADC_CHECKS_FAILED: {
    heading: 'modal.assistanceRequired.heading',
    icon: alert,
    timeout: 30, // seconds
    timeoutEnds: true,
    reason: 'TIMATIC_CHECKS_FAILED'
  },
  ASSISTANCE_REQUIRED: {
    heading: 'modal.assistanceRequired.heading',
    icon: alert,
    timeout: 30, // seconds
    timeoutEnds: true,
    reason: null
  },
  BAG_DIMENSION_ERROR: {
    heading: 'modal.bagDimension.heading',
    textLine1: 'modal.bagDimension.textLine1',
    textLine2: 'modal.bagDimension.textLine2',
    icon: alertBag,
    iconClass: 'text-current',
    override: {
      ...overrideDefault,
      flightdeckMessage: 'Bag Dimension Error',
    },
  },
  BAG_TAG_ACTIVATION_ERROR: {
    heading: 'modal.bagTagActivation.heading',
    textLine1: 'modal.bagTagActivation.textLine1',
    textLine2: 'modal.generic.textLine1',
    icon: alertBag,
    iconClass: 'text-current',
  },
  BAG_TAG_DETECTION_ERROR: {
    heading: 'modal.bagTagDetection.heading',
    textLine1: 'modal.bagTagDetection.textLine1',
    textLine2: 'modal.generic.textLine1',
    icon: alertBag,
    iconClass: 'text-current',
  },
  BAG_TAG_ISSUANCE_ERROR: {
    heading: 'modal.bagTagIssuance.heading',
    textLine1: 'modal.bagTagIssuance.textLine1',
    textLine2: 'modal.generic.textLine1',
    icon: alertBag,
    iconClass: 'text-current',
  },  
  BAG_TAG_PRINT_ERROR: {
    heading: 'modal.bagTagPrint.heading',
    textLine1: 'modal.generic.textLine1',
    icon: alert,
  },
  BAG_ALLOWANCE_ERROR: {
    heading: 'modal.bagAllowance.heading',
    textLine1: 'modal.bagAllowance.textLine1',
    textLine2: 'modal.generic.textLine1',
    icon: alertBag,
    iconClass: 'text-current',
    timeout: 8, // seconds
    timeoutEnds: true,
  },
  BAG_ACCEPTANCE_ERROR: {
    heading: 'modal.bagAcceptance.heading',
    textLine1: 'modal.bagAcceptance.textLine1',
    textLine2: 'modal.generic.textLine1',
    icon: alertBag,
    iconClass: 'text-current',
    timeout: 8, // seconds
    timeoutEnds: true,
  },
  BAG_WEIGHT_ERROR: {
    heading: 'modal.bagWeight.heading',
    textLine1: 'modal.bagWeight.textLine1',
    textLine2: 'modal.generic.textLine1',
    icon: alertBag,
    iconClass: 'text-current',
    timeout: 30, // seconds
    timeoutEnds: true,
  },
  BAGGAGE_TOO_MANY_BAGS: {
    heading: 'modal.tooManyBags.heading',
    textLine1: 'modal.tooManyBags.textLine1',
    textLine2: 'modal.generic.textLine1',
    icon: alertBag,
    iconClass: 'text-current'
  },
  CONVEYOR_NOT_CONVEYABLE: {
    heading: 'modal.conveyorNotConveyable.heading',
    textLine1: 'modal.conveyorNotConveyable.textLine1',
    textLine2: 'modal.conveyorNotConveyable.textLine2',
    icon: alertBag,
    iconClass: 'text-current',
    override: overrideDefault,
  },
  CONVEYOR_INTERFERENCE_USER: {
    heading: 'modal.conveyorInterferenceUser.heading',
    textLine1: 'modal.conveyorInterferenceUser.textLine1',
    textLine1Class: 'font-bold text-3xl text-arabianSunset',
    icon: alertBagMovement,
    iconClass: 'text-current',
    override: overrideDefault,
    image: 'selfServiceConveyorInterferenceUser',
  },
  CONVEYOR_RECOVERY: {
    heading: 'modal.conveyorRecovery.heading',
    textLine1: 'modal.conveyorRecovery.textLine1',
    icon: alertBag,
    iconClass: 'text-current',
  },
  CONVEYOR_BAGGAGE_RESTLESS: {
    heading: 'modal.conveyorBaggageRestless.heading',
    textLine1: 'modal.conveyorBaggageRestless.textLine1',
    icon: alertBag,
    iconClass: 'text-current',
  },
  CHECKIN_FAILURE: {
    heading: 'modal.checkInFailure.heading',
    textLine1: 'modal.checkInFailure.textLine1',
    icon: alert,
    timeout: 30, // seconds
    timeoutEnds: true,
  },
  CONVEYOR_NOT_READABLE: {
    heading: 'modal.conveyorNotReadable.heading',
    textLine1: 'modal.conveyorNotReadable.iconList1',
    textLine1Class: 'checkbox',
    textLine2: 'modal.conveyorNotReadable.iconList2',
    textLine2Class: 'checkbox',
    textLine3: 'modal.conveyorNotReadable.iconList3',
    textLine3Class: 'checkbox',
    textLine4: 'modal.conveyorNotReadable.iconList4',
    textLine4Class: 'checkbox',
    icon: alertBag,
    iconClass: 'text-current',
    override: overrideDefault,
    video: 'selfServiceConveyorNotReadable',
  },
  CONVEYOR_NOT_READABLE_FINAL_ATTEMPT: {
    heading: 'modal.conveyorNotReadableFinalAttempt.heading',
    textLine1: 'modal.conveyorNotReadableFinalAttempt.textLine1',
    icon: alertBag,
    iconClass: 'text-current',
    override: overrideDefault,
  },
  CONVEYOR_MULTI_BAG_TAG: {
    heading: 'modal.conveyorMultiBagTag.heading',
    textLine1: 'modal.conveyorMultiBagTag.textLine1',
    icon: alertBag,
    iconClass: 'text-current',
    closable: true,
  },
  CONVEYOR_NO_WEIGHT_DATA_RECEIVED: {
    heading: 'modal.conveyorNoWeightDataReceived.heading',
    textLine1: 'modal.conveyorNoWeightDataReceived.textLine1',
    icon: alertBag,
    iconClass: 'text-current',
    timeout: 30, // seconds
    timeoutEnds: true,
  },
  DGR_DECLINED: {
    heading: 'modal.assistanceRequired.heading',
    icon: alert,
    override: {
      ...overrideDefault,
      flightdeckMessage: 'DGR Override.',
    },
  },
  EMAIL_FORMAT_ERROR: {
    heading: 'modal.emailformat.heading',
    textLine1: 'modal.emailformat.textLine1',
    icon: alert,
    closable: true,
  },
  EMERGENCY_CONTACT: {
    heading: 'modal.assistanceRequired.heading',
    textLine1: 'modal.emergencyContact.textLine1',
    icon: alert,
  },
  EXCESS_BAGGAGE_RATE_ERROR: {
    heading: 'modal.excessBaggageRateError.heading',
    textLine1: 'modal.excessBaggageRateError.textLine1',
    textLine2: 'modal.generic.textLine1',
    icon: alert,
  },
  EXCESS_PIECE_AGENT_OVERRIDE_FAILED: {
    heading: 'modal.excessPieceAgentOverride.heading',
    textLine1: 'modal.excessPieceAgentOverride.textLine1',
    icon: alertBag,
    iconClass: 'text-current',
  },
  EXCESS_BAGGAGE_COST_DECLINED: {
    heading: 'modal.excessBaggageCostDeclined.heading',
    textLine1: 'modal.excessBaggageCostDeclined.textLine1', 
    icon: alert,
    override: {
      heading: 'Passenger Assistance',
      endDescription:
        'Pressing End will allow any baggage receipts to be printed,\nand will terminate the transaction.',
      overrideDescription:
        'Pressing Override will allow the passenger to re-attempt payment ',
      flightdeckMessage: 'Excess Baggage Extra Pieces Payment Cancelled/Declined/Failed Override.',
    },
  },
  EXCESS_BAGGAGE_PAYMENT_FAILED: {
    heading: 'modal.paymentFailAssistance.heading',
    textLine1: 'modal.paymentFailAssistance.textLine1',
    icon: alert,
    override: {
      heading: 'Passenger Assistance',
      endDescription:
        'Pressing End will allow any baggage receipts to be printed,\nand will terminate the transaction.',
      overrideDescription:
        'Pressing Override will allow the passenger to re-attempt payment ',
      flightdeckMessage: 'Excess Baggage Payment Cancelled/Failed Override.',
    },
  },
  FATAL_CONVEYOR: {
    heading: 'modal.fatalConveyor.heading',
    textLine1: 'modal.fatalConveyor.textline1',
    icon: alertBag,
    iconClass: 'text-current',
    timeout: 30, // seconds
    timeoutEnds: true,
  },
  GENERAL_FAILURE: {
    heading: 'modal.generalFailure.heading',
    textLine1: 'modal.generalFailure.textLine1',
    textLine2: 'modal.generalFailure.textLine2',
    icon: alert,
    timeout: 30, // seconds
    timeoutEnds: true,
    reason: null
  },
  HELP_ASSISTANCE_REQUIRED: {
    heading: 'modal.assistanceRequired.heading',
    icon: alert,
    override: {
      ...overrideDefault,
      flightdeckMessage: 'Assistance Required Override.',
    },
  },
  OPTED_OUT_OF_BAG_UPGRADE: {
    heading: 'modal.excessBaggagePaymentCancelled.heading',
    textLine1: 'modal.excessBaggagePaymentCancelled.textLine1',
    textLine2: 'modal.generic.textLine1',
    icon: alertBag,
    iconClass: 'text-current',
    timeout: 30, // seconds
    timeoutEnds: true,
    override: overrideDefault,
  },
  PASSPORT_EXPIRED: {
    heading: 'modal.passportExpired.heading',
    textLine1: 'modal.passportExpired.textLine1',
    icon: alert,
    timeout: 8, // seconds
    closable: true,
  },
  PASSPORT_NOT_IN_BOOKING: {
    heading: 'modal.passportNotInBooking.heading',
    textLine1: 'modal.passportNotInBooking.textLine1',
    icon: alert,
    timeout: 8, // seconds
    closable: true,
  },
  PASSPORT_NOT_PROMPTED_USER: {
    heading: 'modal.passportNotPromptedUser.heading',
    textLine1: 'modal.passportNotPromptedUser.textLine1',
    textLine2: 'modal.passportNotPromptedUser.textLine2',
    icon: alert,
    timeout: 8, // seconds
    closable: true,
  },
  PASSPORT_PARSE_ERROR: {
    heading: 'modal.passportParseError.heading',
    textLine1: 'modal.passportParseError.textLine1',
    icon: alert,
    timeout: 8, // seconds
    closable: true,
  },
  PAYMENT_TRANSACTION_ERROR: {
    heading: 'modal.paymentTransaction.heading',
    textLine1: 'modal.paymentTransaction.textLine1',
    textLine2: 'modal.generic.textLine1',
    icon: alert,
  },
  PCR_TEST_NOT_RECORDED: {
    heading: 'modal.pcrTestNotRecorded.heading',
    textLine1: 'modal.generic.textLine1',
    icon: alert,
    timeout: 30, // seconds
    timeoutEnds: true,
  },
  PLUSGRADE_CONFIRMATION_FAILURE: {
    heading: 'modal.paymentFailAssistance.heading',
    textLine1: 'modal.paymentFailAssistance.textLine1',
    icon: alert,
    override: {
      heading: 'Passenger Assistance',
      endDescription:
        'Pressing End will allow any baggage receipts to be printed,\nand will terminate the transaction.',
      flightdeckMessage: 'Plusgrade Failed Override.',
    },
  },
  PUT_BAG_BACK_ON_SCALE: {
    heading: 'modal.putBagBackOnScale.heading',
    textLine1: 'modal.putBagBackOnScale.textLine1',
    textLine2: 'app.or',
    textLine2Class: 'font-bold',
    textLine3: 'modal.putBagBackOnScale.textLine3',
    icon: alertBag,
    iconClass: 'text-current',
  },
  RECEIPT_PRINT_ERROR: {
    heading: 'modal.receiptPrint.heading',
    textLine1: 'modal.receiptPrint.textLine1',
    textLine2: 'modal.generic.textLine1',
    icon: alertPrint,
    iconClass: 'text-current',
  },
  RECEIPT_EMAIL_ERROR: {
    heading: 'modal.receiptEmail.heading',
    textLine1: 'modal.receiptEmail.textLine1',
    textLine2: 'modal.receiptEmail.textLine2',
    closable: true,
    icon: alertEmail,
    iconClass: 'text-current',
  },
  REQUEST_PRINT_FAILURE: {
    heading: 'modal.requestReprintFailure.heading',
    textLine1: 'modal.requestReprintFailure.textLine1',
    icon: alert,
    timeout: 15, // seconds
    timeoutEnds: true,
  },
  SEAT_PAYMENT_FAILED: {
    heading: 'modal.paymentFailAssistance.heading',
    textLine1: 'modal.paymentFailAssistance.textLine1',
    icon: alert,
    override: {
      heading: 'Passenger Assistance',
      endDescription:
        'Pressing End will allow any baggage receipts to be printed,\nand will terminate the transaction.',
      flightdeckMessage: 'Seat Payment Failed Override.',
    },
  },
  TIMATIC_UNSUCCESSFUL_SINGLE_PASSENGER: {
    heading: 'modal.timatic.heading',
    textLine1: 'modal.timatic.textLine1',
    textLine2: 'modal.generic.textLine1',
    icon: alert,
    override: {
      ...overrideDefault,
      flightdeckMessage: 'Unsuccessful Timatic Checks.',
    },
  },
  TIMATIC_UNSUCCESSFUL_MULTIPLE_PASSENGERS: {
    heading: 'modal.timatic.heading',
    textLine1: 'modal.timatic.textLine1',
    textLine2: 'modal.generic.textLine1',
    icon: alert,
    override: {
      ...overrideDefault,
      endDescription:
        'Pressing End will terminate the transaction ' +
        'for all selected passengers.',
      overrideDescription:
        'Pressing Override will resume the transaction ' +
        'for all selected passengers.',
      flightdeckMessage: 'Unsuccessful Timatic Checks.',
    },
  },
  TIMEOUT: {
    heading: 'modal.timeout.heading',
    textLine1: 'modal.timeout.textLine1',
    icon: alert,
    timeout: 30, // seconds
    timeoutEnds: true,
  },
  TIMEOUT_RECOVERY_FAIL: {
    heading: 'modal.timeoutRecoveryFail.heading',
    textLine1: 'modal.timeoutRecoveryFail.textLine1',
    textLine2: 'modal.generic.textLine1',
    icon: alert,
    override: {
      ...overrideDefault,
      flightdeckMessage: 'Timeout recovery failed.',
    },
  },
  TUB_REQUIRED: {
    heading: 'modal.tubRequired.heading',
    textLine1: 'modal.tubRequired.textLine1',
    textLine2: 'modal.tubRequired.textLine2',
    icon: alertBag,
    iconClass: 'text-current',
  },
  UNABLE_TO_SCAN: {
    heading: 'modal.unableToScan.heading',
    textLine1: 'modal.generic.textLine1',
    icon: alert,
  },
};

Object.freeze(ErrorModals);

<script>
  import { _ } from 'svelte-i18n';
  import { createEventDispatcher, onMount } from 'svelte';

  import { rightToLeft } from '../../../js/stores';
  import { Priority } from '../../../js/const';
  import { VoiceIntent } from '../../../js/services/voicerec/voicerec';

  import Button from '../Button.svelte';
  import KeypadButton from '../KeypadButton.svelte';
  import ModalBase from '../ModalBase.svelte';

  export let showModal = false;

  const dispatch = createEventDispatcher();

  const clearIndex = 10;
  const minimumNumberOfBags = 0;
  const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'clear'];

  let currentButton = null;
  let numberOfBags = '';

  function resetBags() {
    numberOfBags = '';
  }

  function updateDisplayedBags(value) {
    if (String(numberOfBags).length <= 1) {
      numberOfBags += value;
    }
  }

  /** English to Arabic digits. */
  String.prototype.toArabic = function () {
    return this.replace(/\d/g, (digit) => '٠١٢٣٤٥٦٧٨٩'[digit]);
  };

  onMount(() => {
    resetBags();
  });
</script>

<ModalBase
  heading={$_('otherNumberOfBags.heading')}
  priority={Priority.LOW}
  bind:showModal
>
  <div class="mb-24 mt-8 mx-auto w-88">
    <div
      class="text-left border border-current
      font-AltisMedium mb-4 mx-2 h-18 px-4 rounded text-5xl"
    >
      {numberOfBags}
    </div>

    <div class="flex flex-wrap">
      {#each numbers as number, i}
        <KeypadButton
          kind={i === clearIndex ? 'wide' : ''}
          intent={VoiceIntent.NUMBER_OF_BAGS}
          on:click={() => {
            currentButton = i;
            if (i === clearIndex) {
              resetBags();
            } else {
              updateDisplayedBags(number);
            }
          }}
        >
          {$_(`${i === clearIndex ? 'app.' : 'app.no'}` + number)}
        </KeypadButton>
      {/each}
    </div>
  </div>

  <div dir="rtl" class="flex mx-4">
    <Button
      on:click={() => {
        dispatch('numberOfBagsSelection', {
          numberOfBags: Number(numberOfBags),
        });
      }}
    >
      {$_('app.done')}
    </Button>
  </div>
</ModalBase>

<script>
  import { _ } from 'svelte-i18n';

  import info from '../../../svg/info.svg';

  export let paneHeight = null;
</script>

<div class="otherAirlineMessage" style="height: {paneHeight}rem;">
  <div class="flex h-full items-center px-8">
    <div class="bg-informative-100 p-12 rounded-md w-full">
      <div class="mb-3 mx-auto text-informative-200 w-21">
        {@html info}
      </div>

      <div class="leading-normal text-1.625 text-center">
        <p class="font-AltisMedium mb-1">
          {$_('seatMap.otherAirlineHeading')}
        </p>
        <p>{$_('seatMap.otherAirlineTextLine1')}</p>
      </div>
    </div>
  </div>
</div>

/* Application constants. */
export { FlightDeckMessages } from './const/flightdeckMessages';
export { AVERAGE_PASSENGER_BAGS_PER_DESTINATION } from './const/preEmptiveBaggage';
export {
  BagLocations,
  CussStatusCodes,
  SBDBelts,
  SBDStates,
} from './const/cussSBD';

/** Define Z-index consts. */
export const Priority = Object.freeze({
  LOW: {
    'z-index': 'z-5',
  },
  NORMAL: {
    'z-index': 'z-10',
  },
  HIGH: {
    'z-index': 'z-20',
  },
  HIGHEST: {
    'z-index': 'z-50',
  },
});

/** Define Footer Wizard positions. */
export const WizardPosition = Object.freeze({
  PASSENGERS: 0,
  SECURITY: 1,
  BAGS: 2,
  FINISH: 3,
});

/** Number of items in the footer Wizard. */
export const WizardSize = Object.keys(WizardPosition).length;

/** Maximum bags allowed per passenger. */
export const MaximumBagsPerPassenger = 5;

/** Max weight of an individual bag. */
export const BagWeightMax = 32; // kilograms

/** Heavy weight of an individual bag. */
export const BagWeightHeavy = 23; // kilograms

/** Max dimensions of an individual bag. */
export const BagLengthMax = 95; // centimetres
export const BagWidthMax = 77; // centimetres
export const BagHeightMax = 50; // centimetres
export const BagWeightWarningPercent = 80;

/**
 * If another weight is not received after this duration,
 * bag is considered as settled
 */
export const BagSettledTimeout = 4000; // in milliseconds

/** The amount of minutes before boarding closes. */
export const BoardingClosingTime = 20; // minutes

export const HOURS_PER_DAY = 24;

export const MINUTES_PER_HOUR = 60; // minutes

export const SECONDS_PER_MINUTE = 60; // seconds

/** Milliseconds per second. */
export const MILLISECONDS_PER_SECOND = 1000; // milliseconds

export const MILLISECONDS_PER_DAY =
  HOURS_PER_DAY *
  MINUTES_PER_HOUR *
  SECONDS_PER_MINUTE *
  MILLISECONDS_PER_SECOND;

/** Timeout warning modal. */
export const TimeoutWarningShort = 45; // seconds
export const TimeoutWarning = 60; // seconds
export const TimeoutWarningDouble = TimeoutWarning * 2; // seconds
export const TimeoutWarningExtended = 300; // seconds

/** Timeout to end transaction when there is no transaction started in Home screen. */
export const TRANSACTION_STARTED_TIMEOUT = 60; // seconds

/**
 * Timeout to end transaction when there is no weight data received
 * from scale device in Place-Bag screen.
 */
export const WEIGHT_DATA_NOT_RECEIVED_TIMEOUT = 180; // seconds

/** Timeout for Flight Deck Heartbeat. */
export const FlightdeckHeartbeatTimer = 110; // seconds

/** Weight unit when used in non-internationalised contexts. */
export const WeightUnit = 'kg';
export const PieceUnit = 'pc';
export const PieceUnitP = 'p';

/** Modes for application flow. */
export const ApplicationFlow = Object.freeze({
  SELF_SERVICE: 0,
  PORTER_BAG_DROP: 1,
  HYBRID: 2,
});

/** Ways that the bag drop transaction can be initiated by an end user. */
export const TransactionInitiator = Object.freeze({
  PNR: 'PNR',
  BOARDING_PASS: 'Boarding Pass',
  PASSPORT: 'Passport',
  BIOMETRIC: 'Biometric scan',
});

/** Timeout for advancing screens in PDB demo. */
export const DEMO_TIMEOUT = 30; // 30 seconds

/** Timeout for go to Home Screen. */
export const GO_TO_HOME_TIMEOUT = 60; // Unit = seconds
export const SCREEN_IDLE_TIMEOUT = 60; // Unit = seconds

/** Round up overweight bags to the nearest increment. */
export const WeightPaymentThreshold = 5; // kg

export const WEIGHT_UNITS = 'kg';

/** Country codes that speak arabic. */
export const ArabicCountry = [
  'EG', // Egypt
  'EGY',
  'LB', // Lebanon
  'LBN',
  'IQ', // Iraq
  'IRQ',
  'JO', // Jordan
  'JOR',
  'MA', // Morocco
  'MAR',
  'PS', // Palestine
  'PSE',
];

/** ICAO country codes for GCC country. */
export const GccCountry = [
  'AE', // UAE
  'ARE',
  'BH', // Bahrain
  'BHR',
  'KW', // Kuwait
  'KWT',
  'OM', // Oman
  'OMN',
  'QA', // Qatar
  'QAT',
  'SA', // Saudi Arabia
  'SAU',
];

/** Countries that speak Hindi. */
export const HindiCountries = [
  'IN', // India
  'IND',
  'PK', // Pakistan
  'PAK',
];

export const AllowanceType = Object.freeze({
  VCR: 'VCR', // Allowance
  EMD: 'EMD', // Extra allowance
  FF: 'FF', // Frequent Flyer
  PGCU: 'PGCU', // Plusgrade
});

export const OPERATOR_CODE = 'EY';

export const OVERRIDE_SECRET = 'aaaaaaaa';

export const HARDWARE_CHECK_TIME = 10000; // 10 seconds

/** EYSS12345 is development Kiosk Name to check flight deck alerts. */
export const DEV_KIOSK_NAME = 'EYSS12345';

export const bagDropLocation = 'AUH';

export const BAGGAGE_SUBCODE = '0CU';
export const SEAT_SUBCODE = '0B5';

/** Svelte component events. */
export const ComponentEvents = Object.freeze({
  TIMEOUT_CONTINUE: 'continue',
});

export const DEFAULT_LOCAL_CURRENCY = 'AED';

export const SwitchboardSuccessRegex = /^success$/i;

export const QueryStringParam = Object.freeze({
  BASE_STATION: 'base',
  FEATURES: 'features',
  FLOW: 'flow',
  ISSUING_DEVICE: 'device',
  LOCAL_CURRENCY: 'localCurrency',
});

/**
 * From eithad.com/en-au/book/fare-choices
 * Access via utils.js mapFareCode().
 */
export const FARE_CODE_MAP = Object.freeze({
  'First class': ['A', 'F', 'O', 'P', 'R'],
  Business: ['C', 'D', 'I', 'J', 'W', 'X', 'Z'],
  Economy: ['B', 'E', 'H', 'K', 'L', 'M', 'N', 'Q', 'S', 'T', 'U', 'V', 'Y'],
});

/** Valid feature flags. */
export const FeatureFlags = Object.freeze({
  CAMERA: 'camera',
  DARK: 'dark',
  EMAIL: 'email',
  EXCESS_BAGGAGE: 'excessBaggage',
  FIT_TO_FLY: 'fitToFly',
  GLOBAL_TIMEOUT: 'globalTimeout',
  MULTI_SECTOR: 'multiSector',
  PCR_TEST: 'pcrTest',
  PLUSGRADE: 'plusgrade',
  SEAT_PAYMENT: 'seatPayment',
});

/** Location of the Kiosk (Airport code). */
export const DEFAULT_BASE_STATION = 'AUH';

/** Default issuing device. */
export const DEFAULT_ISSUING_DEVICE = 'BDX';

/** Reasons for ending transaction */
export const END_TRANSACTION_REASON = {
  TIMATIC_CHECKS_FAILED: 'TIMATIC_CHECKS_FAILED',
  SCREEN_IDLE_TIMEOUT_OCCURRED: 'SCREEN_IDLE_TIMEOUT_OCCURRED',
};

/** Reasons for showing Assistance Required */
export const ASSISTANCE_REQUIRED_REASONS = {
  TIMATIC_CHECKS_FAILED: 'TIMATIC_CHECKS_FAILED',
};

/** amadeus checkout form status to set as VERIFIED */
export const STATUS_VERIFIED = 'VERIFIED';

/** amadeus checkout form use for payload as reference owner */
export const REFERENCE_OWNER = '1A';

/** amadeus checkout form use for payload as reference type */
export const REFERENCE_TYPE = 'PassengerNamedRecord';

/** amadeus checkout form use for payload as default merchant code */
export const MERCHANT_CODE = 'EY';

/** amadeus checkout form use for payload as template id */
export const TEMPLATE_ID = 'CHECKOUTPAGE-PNRINTEGRATED-BAGDROP';

/** amadeus checkout form use for payload as language */
export const LANGUAGE = 'en';

/** amadeus checkout form use for payload as session timeout */
export const SESSION_TIMEOUT = 50000;

/** amadeus checkout form use for endpoint */
export const AMADEUS_CHECKOUT_ENDPOINT = 'payment/checkout-forms';

/** amadeus token checkout form use for endpoint */
export const AMADEUS_TOKEN_ENDPOINT = 'security/oauth2/token';

/** amadeus checkout STATUS SUCCESSFUL */
export const PAYMENT_STATUS_OK = 'OK';

/** amadeus checkout STATUS NOT EXIST*/
export const PAYMENT_STATUS_NOT_EXIST = 'KO';
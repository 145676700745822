/**
 * The API exposed by the UI Application to the CUSS Applet.
 *
 * Functions here are called by the CUSS Applet to pass CUSS messages into
 * the UI Application.
 *
 * For calls by the UI Application into the CUSS Applet,
 * see cuss-components.js.
 */
import { get } from 'svelte/store';
import { locale } from 'svelte-i18n';
import { location, push } from 'svelte-spa-router';
import mrzParse from 'mrz';

import { ApplicationStep, appReport } from './appReport';
import AgentAuth from './agentAuth';
import {
  appTransferDataContainingPassportMRZ,
  applicationMadeActiveThroughTransfer,
  appIsActive,
  appletRunning,
  bagInjected,
  bioMrz,
  boardingPass,
  booking,
  bookingRetrieved,
  currentBag,
  envLevel,
  headPassenger,
  mrz,
  passengerMrzManager,
  passportNumbers,
  persistentSingleApplicationMode,
  setCurrentBagBagTagID,
  setCurrentBagDimensions,
  setCurrentBagWeight,
  setErrorModal,
  startUpSuccessful,
  transactionHasStarted,
  userInitiationMethod,
} from './stores';
import { coerceMRZ } from './utils/mrz';
import { compareArrays, getDateYYMMDD, getKeyByValue } from './utils';
import { ErrorModals } from './const/errorModals';
import events from './events';
import { getEnvLevel } from './cuss-components';
import { lastBagLocation, sbdState } from './stores/sbdState';
import logger from './logger';
import { CussStatusCodes, CUSSAppStates, SBDStates } from './const/cussSBD';
import { ArabicCountry, GccCountry, TransactionInitiator } from './const';
import flightdeck from './services/flightdeck';
import { processScaleEvent } from './stores/bags';

/**
 * Check a MRZ against the one used to init the transaction.
 *
 * Only useful when get(transactionHasStarted) is true.
 *
 * @param {string[]} mrzArray - The raw mrzArray from CUSS.
 * @returns {boolean} - The result of the check.
 */
function checkMRZMatchesInitial(mrzArray) {
  const headPassengerMrz =
    passengerMrzManager.getPassengerMrz(get(headPassenger)) || [];
  return compareArrays(headPassengerMrz, mrzArray);
}

/**
 * Check a Boarding Pass against the one used to init the transaction.
 *
 * Only useful when get(transactionHasStarted) is true.
 *
 * @param {string} rawString - The raw barcode string from CUSS.
 * @returns {boolean} - The result of the check.
 */
function checkBPMatchesInitial(rawString) {
  return rawString === get(boardingPass);
}

export default {
  /**
   * Print a string using logLevel.
   *
   * @param {string} message
   */
  log(message) {
    logger.info('Message from CUSS Applet.', message);
  },

  /** Receive notification that the applet is running. */
  appletRunning() {
    appletRunning.set(true);
  },

  /** Receive notification that the applet has started. */
  startUpSuccessful() {
    envLevel.set(getEnvLevel());
    startUpSuccessful.set(true);
  },

  /**
   * Receive weight from a scale.
   *
   * @param {string} weight
   */
  bagWeight(weight) {
    logger.info('Received bag weight (grams):', weight);
    const allowedLocations = [
      '/porter-place-passenger-bag-hybrid',
      '/porter-place-passenger-bag',
      '/place-passenger-bag',
    ];
    

    // do not accept weight, if the excess payment has already been taken or if the bag tag is already activated
    // since some times when bag is still on parking belt, passenger places the other bag.
    const accepting =
       ((!(get(currentBag).bagTagActivated || get(currentBag).isPaymentSuccess))
       &&
       (allowedLocations.includes(get(location)) || 
        get(sbdState) === SBDStates.WAITING));

    if (accepting) {
      logger.info('The application is accepting bag weight.');
      setCurrentBagWeight(Number(weight) / 1000);
      events.emitWeightDataReceived();
    } else {
      logger.info(`Not accepting bag weight. 
       isPaymentSuccess is ${get(currentBag).isPaymentSuccess}
       current location is: ${allowedLocations.includes(get(location))}
       current SBD state is: ${get(sbdState)}`);
    }
  },

  /**
   * Bag dimensions.
   *
   * @param {string} height
   * @param {string} length
   * @param {string} width
   */
  bagDimensions(length, width, height) {
    logger.info(
      `Received dimensions. ` +
        `length: ${length}; ` +
        `width: ${width}; ` +
        `height: ${height}.`,
    );
    const allowedLocations = [
      '/porter-place-passenger-bag-hybrid',
      '/porter-place-passenger-bag',
      '/place-passenger-bag',
      '/dispatching-bag'
    ];
    const accepting = allowedLocations.includes(get(location));
    if (accepting) {
      logger.info('The application is accepting bag dimensions.');
      setCurrentBagDimensions(length, width, height);
    }
  },

  /**
   * Bag Tag
   *
   * @param {object} bagTagIDs
   */
  bagBagTag(...bagTagIDs) {
    logger.info(
      `Received bag tag ids: ${
        bagTagIDs && Array.isArray(bagTagIDs)
          ? bagTagIDs.join(', ')
          : bagTagIDs
      }.`,
      `, bagTagIDs.length: ${bagTagIDs && bagTagIDs.length}.`,
    );
    const allowedLocations = ['/place-passenger-bag', '/dispatching-bag'];
    const accepting = allowedLocations.includes(get(location));
    if (accepting) {
      if (bagTagIDs.length === 1) {
        logger.info('The application is accepting bag tag id.');
        setCurrentBagBagTagID(bagTagIDs[0]);
      } else if (bagTagIDs.length > 1) {
        events.emitMultiBagTag();
      }
    }
  },

  /**
   * Receive scale's events from CUSS platform.
   *
   * @param {string} statusCode
   */
  scaleEvent(statusCode) {
    logger.info(`Received scale's event ${statusCode}.`);
    const accepting = [
      '/porter-checking-your-bag-hybrid',
      '/porter-checking-your-bag',
      '/porter-place-passenger-bag-hybrid',
      '/porter-place-passenger-bag',
      '/place-passenger-bag',
    ].includes(get(location));

    if (accepting) {
      processScaleEvent(statusCode);
    }
  },

  /** Bag injected into the BHS, received BAGGAGE_DELIVERED. */
  bagInjected() {
    logger.info('Received notice that bag injected into BHS.');
    bagInjected.set(true);
  },

  /**
   * Receive a passport MRZ from CUSS.
   *
   * The contents of `arguments` should be strings for each MRZ line.
   */
  passportMRZ() {
    const originalMrzArray = Array.prototype.slice.call(arguments);
    logger.info(
      `Received MRZ: \
        ${
          originalMrzArray && Array.isArray(originalMrzArray)
            ? originalMrzArray.join(',')
            : ''
        }.`,
    );

    if (
      !originalMrzArray ||
      (originalMrzArray &&
        Array.isArray(originalMrzArray) &&
        originalMrzArray.some((line) => !line))
    ) {
      logger.info('Received empty or partially empty MRZ.');
      return;
    }

    const mrzArray = coerceMRZ(originalMrzArray);

    let hasParseFailed = false;
    let hasExpiredPassport = false;
    let mrzParseResults = null;

    try {
      mrzParseResults = mrzParse.parse(mrzArray);
      hasExpiredPassport =
        mrzParseResults.fields.expirationDate <= getDateYYMMDD();
      hasParseFailed = !mrzParseResults.valid;
      if (hasParseFailed) {
        logger.warn(
          'MRZ parse failed.' +
            (mrzParseResults.details || [])
              .filter((detail) => !detail.valid)
              .map((detail) => ` '${detail.label}': ${detail.error}.`)
              .join(''),
        );
      }
    } catch (error) {
      hasParseFailed = true;
      logger.warn(`MRZ parse failed, error: Error: ${error.message}`);
    }

    if (hasExpiredPassport) {
      logger.warn('MRZ expired.');
      setErrorModal(ErrorModals.PASSPORT_EXPIRED);
      return;
    }

    if (hasParseFailed) {
      setErrorModal(ErrorModals.PASSPORT_PARSE_ERROR);
      return;
    }

    if (get(transactionHasStarted)) {
      events.emitDocumentScan(checkMRZMatchesInitial(mrzArray));
    }

    const { nationality, documentNumber } = mrzParseResults.fields;

    if (
      GccCountry.includes(nationality) ||
      ArabicCountry.includes(nationality)
    ) {
      logger.info(`${nationality} detected. Setting locale to 'ar'.`);
      locale.set('ar');
    }

    passportNumbers.insert(documentNumber);

    if (get(bookingRetrieved)) {
      events.emitMRZScan(
        booking.isMatchToPassengerDocS(documentNumber),
        mrzArray,
        documentNumber,
      );
    }

    if (!get(transactionHasStarted)) {
      userInitiationMethod.set(TransactionInitiator.PASSPORT);
      mrz.set(mrzArray);
      appReport.updateTransactionStart()

      // This change is just for self_service and retrofit flow
      // If biometrics sends us a MRZ to look up a booking with, 
      // log it differently to as if a passenger manually scans their PP to scan the transaction
      if(get(appTransferDataContainingPassportMRZ) === true)
      {
        appReport.updateStepSuccess(ApplicationStep.BIOMETRIC_PASSPORT_SCAN);
      }
      else
      {
        appReport.updateStepSuccess(ApplicationStep.PASSPORT_SCAN);
      }
    }
  },

  /** Receive application-transfer-data from CUSS. */
  appTransferData(data) {
    logger.debug(`appTransferData - Received data: ${data}`);

    applicationMadeActiveThroughTransfer.set(true)

    if (!data) {
      logger.warn('Received invalid/empty app-transfer-data.');
      return;
    }

    let parsedTransferData = null;
    try {
      parsedTransferData = JSON.parse(data);
    } catch (error) {
      logger.warn(
        'Failed parsing app-transfer-data as a JSON structure.',
        error?.message,
      );
      return;
    }

    if (
      parsedTransferData &&
      parsedTransferData.track1 &&
      parsedTransferData.track2 &&
      parsedTransferData.track1.startsWith('P')
    ) {
      appTransferDataContainingPassportMRZ.set(true);
      this.passportMRZ(parsedTransferData.track1, parsedTransferData.track2);
    } else {
      logger.warn(`app-transfer-data has unexpected JSON structure.`);
    }
  },

  /**
   * Receive a biometric MRZ.
   *
   * @param {string} mrzString - A pipe delimited string representing the MRZ.
   */
  biometricMRZ(mrzString) {
    logger.info('Received biometric MRZ:', mrzString);

    const currentRoute = get(location);
    const accepting = currentRoute === '/' && !get(transactionHasStarted);
    if (!accepting) {
      return;
    }

    userInitiationMethod.set(TransactionInitiator.BIOMETRIC);
    bioMrz.set(mrzString);
    appReport.updateTransactionStart()
    appReport.updateStepSuccess(ApplicationStep.PASSPORT_SCAN);
  },

  /**
   * Receive a barcode from CUSS.
   *
   * @param {string} rawString
   */
  barcode(rawString) {
    logger.info('Received Barcode:', rawString);
    if (get(transactionHasStarted)) {
      events.emitDocumentScan(checkBPMatchesInitial(rawString));
    }
    const agentAuth = new AgentAuth(rawString);
    if (agentAuth.authenticated) {
      events.emitOverrideScan(agentAuth.epr);
    }
  },

  /**
   * Receive a conveyor listener event from CUSS.
   *
   * @param {string} belt
   * @param {string} statusCode
   */
  conveyorEvent(belt, statusCode) {
    events.emitConveyorEvent(belt, statusCode);
  },

  /**
   * Handle BAGGAGE_PRESENT.
   *
   * @param {string} bagLocation - One of BagLocations from const.js
   *
   * TODO: Check if we should remove this function
   * and obtain belt's BAGGAGE_PRESENT through conveyorEvent
   * and move lastBagLocation's logic to sbdState.js.
   */
  bagPresent(bagLocation) {
    lastBagLocation.set(bagLocation);
    events.emitConveyorEvent(bagLocation, CussStatusCodes.BAGGAGE_PRESENT);
  },

  /**
   * Receive notification from CUSS that application is activated.
   *
   * @param {boolean} isPSAM
   */
  appActivated(isPSAM) {
    logger.log(`CUSS emitted app activated. isPSAM=${isPSAM}.`);
    persistentSingleApplicationMode.set(isPSAM === true);
    appIsActive.set(true);
  },

  /**
   * Receive notification from Applet about the current CUSS application state.
   *
   * @param {number} eventCode - CUSS event code specifying the current CUSS application state.
   */
  notifyCUSSAppState(eventCode) {
    if (eventCode === CUSSAppStates.ACTIVE) {
      return;
    }

    const eventCodeStr = getKeyByValue(CUSSAppStates, eventCode);
    const cussAppStateLog = `CUSSAppState: ${eventCode}/${eventCodeStr}`;

    if (eventCode === CUSSAppStates.AVAILABLE) {
      // Business rule EYSS-3427: If we are on HaveANiceFlight screen let that component route back to home and ignore the navigation below 
      let isOnHaveANiceFlightScreen = get(location) === '/have-a-nice-flight'

      if(isOnHaveANiceFlightScreen)
      {
        logger.log(`Available received on have a nice flight screen. ${cussAppStateLog}.`);
        return;
      }

      logger.log(`Go to Home screen. ${cussAppStateLog}.`);
      push('/');
    } else {
      logger.log(
        'Application is initializing or CUSS components are not ready' +
          ' or there is a hard error with a CUSS component.' +
          ' Go to Unavailable screen.' +
          ` ${cussAppStateLog}.`,
      );
      push('/unavailable');
    }
  },

  /**
   * Receive Applet's notification that there is a hard error with a certain device.
   *
   * @param {string} deviceName
   * @param {string} statusCode
   */
  notifyHardError(deviceName, statusCode) {
    const msg =
      `There is a hard error with device ${deviceName}.` +
      ` Device statusCode: ${statusCode}.` +
      ' The application is offline pending resolution of this issue.';
    logger.error(msg);
    flightdeck.deviceHardError(msg);
  },

  /**
   * Handle CUSS session timeout.
   */
  cussSessionTimeout() {
    logger.warn('CUSS emitted session timeout.');
    push('/');
  },
};

<script>
  import { onElementDestroy } from '../../js/actions';
  import { pnr, userInitiationMethod } from '../../js/stores';
  import { TransactionInitiator } from '../../js/const';
</script>

<div class="flex items-center mt-8 w-14/15 mx-auto">
  <div class="w-1/5">
    <label class="block text-base text-gray-500 font-bold text-right mb-1 mb-0 pr-4" for="pnr">
      Boarding Pass PNR
    </label>
  </div>
  <div class="w-12/15">
    <input
      class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-500 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
      name="pnr"
      type="text"
      use:onElementDestroy={ (val) => {if (val) { userInitiationMethod.set(TransactionInitiator.PNR); pnr.set(val);}} }
      >
  </div>
</div>

import { push } from 'svelte-spa-router';
import { ErrorModals } from './const/errorModals';
import { setErrorModal } from './stores';
import flightdeck from './services/flightdeck';
import logger from './logger';

/**
 * Handler for the error event.
 *
 * @returns {boolean}
 */
function onerror() {
  const error = arguments[arguments.length - 1];
  handleGeneralFailure(error);
  return true;
}

/**
 * Search Booking Validation Check
 * @param {string} error - A description of the error
 * @param {string} reason - The reason for the error sent by the caller
 */
export function handleGeneralFailure(error, reason = null) {
  logger.warn(error);
  flightdeck.generalFailure();
  ErrorModals.GENERAL_FAILURE.reason = reason;
  setErrorModal(ErrorModals.GENERAL_FAILURE);
}

export default () => {
  window.onerror = onerror;
};

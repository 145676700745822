<script>
  import { _ } from 'svelte-i18n';
  import { onDestroy, onMount } from 'svelte';

  import { timeoutBlocked } from '../../js/stores';

  import warning from '../../svg/warning.svg';
  import ModalError from './ModalError.svelte';

  export let expectedResolution = null;
  export let resolution = null;

  onMount(() => {
    timeoutBlocked.set(true);
  });

  onDestroy(() => {
    timeoutBlocked.set(false);
  });
</script>

<ModalError
  heading="Configuration Error"
  textLine1={`System resolution incorrect. ${resolution} should be ${expectedResolution}.`}
  textLine2="Technical support required."
  icon={warning}
/>

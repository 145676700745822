<script>
  import { VoiceIntent } from '../../js/services/voicerec/voicerec';

  import Button, { ButtonKinds } from '../components/Button.svelte';
</script>

<style>
  .box {
    @apply h-20 w-20;
  }
</style>

<div class="px-10 py-8">
  <div class="flex flex-wrap -mx-10 mb-6">
    <div class="w-full px-10">
      <h1 class="font-AltisMedium mb-4">Styleguide</h1>
    </div>
  </div>

  <div class="flex flex-wrap -mx-10 mb-12">
    <div class="w-full px-10">
      <h3 class="italic mb-4">Colour</h3>
    </div>

    <div class="mb-6">
      <div class="flex w-full px-10">
        <div class="bg-backgroundGrey box" />
        <div class="bg-gold-100 box" />
        <div class="bg-midnightSky box" />
      </div>
    </div>

    <div class="mb-6">
      <div class="w-full px-10">
        <p class="mb-2">Brand Colours</p>
      </div>
      <div class="flex w-full px-10">
        <div class="bg-arabicDates box" />
        <div class="bg-desertYellow box" />
        <div class="bg-falconGrey box" />
        <div class="bg-hyperlink box" />
        <div class="bg-wellnessBlue box" />
        <div class="text-center">
          <div class="bg-saffron box" />
          <p class="text-sm">Error</p>
        </div>
        <div class="text-center">
          <div class="bg-palmGreen box" />
          <p class="text-sm">Success</p>
        </div>
        <div class="text-center">
          <div class="bg-arabianSunset box" />
          <p class="text-sm">Warning</p>
        </div>
      </div>
    </div>

    <div class="mb-6">
      <div class="w-full px-10">
        <p class="mb-2">Information</p>
      </div>
      <div class="flex w-full px-10">
        <div class="bg-informative-100 box" />
        <div class="bg-informative-200 box" />
      </div>
    </div>

    <div class="mb-6">
      <div class="w-full px-10">
        <p class="mb-2">Charcoals</p>
      </div>
      <div class="flex w-full px-10">
        <div class="bg-charcoal-100 box" />
        <div class="bg-charcoal-80 box" />
        <div class="bg-charcoal-60 box" />
        <div class="bg-charcoal-40 box" />
        <div class="bg-charcoal-20 box" />
        <div class="bg-charcoal-10 box" />
      </div>
    </div>

    <div class="mb-6">
      <div class="w-full px-10">
        <p class="mb-2">Tiers</p>
      </div>
      <div class="flex w-full px-10">
        <div class="bg-tierBronze box" />
        <div class="bg-tierGold box" />
        <div class="bg-tierKhaas box" />
        <div class="bg-tierPlatinum box" />
        <div class="bg-tierSilver box" />
      </div>
    </div>

    <div class="bg-backgroundGrey py-4 w-full">
      <div class="w-full px-10">
        <p class="mb-2 text-white">Dark Mode</p>
      </div>
      <div class="flex w-full px-10">
        <div class="text-center">
          <div class="bg-error-dark-100 box" />
          <p class="text-sm text-white">Error</p>
        </div>
        <div class="bg-error-dark-200 box" />
        <div class="bg-hyperlink-dark-200 box" />
        <div class="text-center">
          <div class="bg-informative-dark-100 box" />
          <p class="text-sm text-white">Information</p>
        </div>
        <div class="bg-informative-dark-200 box" />
        <div class="text-center">
          <div class="bg-success-dark-100 box" />
          <p class="text-sm text-white">Success</p>
        </div>
        <div class="bg-success-dark-200 box" />
        <div class="text-center">
          <div class="bg-warning-dark-100 box" />
          <p class="text-sm text-white">Warning</p>
        </div>
        <div class="bg-warning-dark-200 box" />
      </div>
    </div>
  </div>

  <div class="flex flex-wrap -mx-10 mb-12">
    <div class="w-full px-10">
      <h3 class="italic mb-4">Typography</h3>
    </div>

    <div class="w-1/2 px-10">
      <div class="mb-8">
        <div class="mb-4">
          <h1>Heading 1</h1>
          <p>AltisHeadline 44.8px</p>
        </div>

        <div class="mb-4">
          <h2>Heading 2</h2>
          <p>AltisHeadline 40px</p>
        </div>

        <div class="mb-4">
          <h3>Heading 3</h3>
          <p>AltisMedium 22px</p>
        </div>

        <h4 class="mb-4">Heading 4 (not in use)</h4>
        <h5 class="mb-4">Heading 5 (not is use)</h5>
        <h6 class="">Heading 6 (not in use)</h6>
      </div>

      <div class="mb-8">
        <div class="mb-4">
          <p>Paragraph</p>
          <p>Altis 22px</p>
        </div>
        <p class="mb-4 italic">Paragraph Italic</p>
        <p class="font-bold">Paragraph Bold</p>
      </div>
    </div>

    <div class="w-1/2 px-10">
      <div class="mb-8">
        <p class="font-Altis mb-4">font-Altis</p>
        <p class="font-Altis italic mb-4">font-Altis italic</p>
        <p class="font-Altis font-bold">font-Altis bold</p>
      </div>

      <div class="mb-8">
        <p class="font-AltisHeadline">font-AltisHeadline</p>
      </div>

      <div class="mb-8">
        <p class="font-AltisMedium mb-4">font-AltisMedium</p>
        <p class="font-AltisMedium italic">font-AltisMedium italic</p>
      </div>

      <div class="mb-8">
        <p class="font-Arabic mb-4">font-Arabic</p>
      </div>

      <div class="flex items-center mt-1">
        <input id="styleguide-radio-a" type="radio" />
        <label class="mb-0" for="styleguide-radio-a">Radio</label>
      </div>

      <div class="flex items-center mt-1">
        <input id="styleguide-radio-b" type="radio" checked />
        <label class="mb-0" for="styleguide-radio-b">Radio checked</label>
      </div>

      <div class="flex items-center mt-1">
        <input id="styleguide-radio-c" type="radio" disabled />
        <label class="mb-0" for="styleguide-radio-c">Radio disabled</label>
      </div>

      <div class="flex items-center mt-1">
        <input id="styleguide-radio-d" type="radio" checked disabled />
        <label class="mb-0" for="styleguide-radio-d">
          Radio checked & disabled
        </label>
      </div>

      <div class="flex items-center mt-1">
        <input id="styleguide-checkbox-a" type="checkbox" />
        <label class="mb-0" for="styleguide-checkbox-a"> Checkbox </label>
      </div>

      <div class="flex items-center mt-1">
        <input id="styleguide-checkbox-b" type="checkbox" checked />
        <label class="mb-0" for="styleguide-checkbox-b">
          Checkbox checked
        </label>
      </div>
    </div>
  </div>

  <div class="flex flex-wrap -mx-10 mb-12">
    <div class="w-full px-10">
      <h3 class="italic mb-4">Buttons</h3>
    </div>

    <div class="w-full px-10 mb-4">
      <Button intent={VoiceIntent.NEXT} size="micro" on:click={() => {}}>
        Primary micro
      </Button>
    </div>

    <div class="w-full px-10 mb-4">
      <Button intent={VoiceIntent.NEXT} size="tiny" on:click={() => {}}>
        Primary tiny
      </Button>
    </div>

    <div class="w-full px-10 mb-4">
      <Button intent={VoiceIntent.NEXT} size="small" on:click={() => {}}>
        Primary small
      </Button>
    </div>

    <div class="w-full px-10 mb-4">
      <Button intent={VoiceIntent.NEXT} size="large" on:click={() => {}}>
        Primary large
      </Button>
    </div>

    <div class="w-full px-10 mb-4">
      <Button intent={VoiceIntent.NEXT} size="huge" on:click={() => {}}>
        Primary huge
      </Button>
    </div>

    <div class="w-full px-10 mb-4">
      <Button intent={VoiceIntent.NEXT} isFull on:click={() => {}}>
        Primary full
      </Button>
    </div>

    <div class="w-full px-10 mb-4">
      <Button intent={VoiceIntent.NEXT} on:click={() => {}}>Primary</Button>
    </div>

    <div class="w-full px-10 mb-4">
      <Button disabled intent={VoiceIntent.NEXT} on:click={() => {}}
        >Primary disabled</Button
      >
    </div>

    <div class="w-full px-10 mb-4">
      <Button
        intent={VoiceIntent.NEXT}
        kind={ButtonKinds.SECONDARY}
        on:click={() => {}}
      >
        Secondary
      </Button>
    </div>

    <div class="w-full px-10 mb-4">
      <Button
        disabled
        intent={VoiceIntent.NEXT}
        kind={ButtonKinds.SECONDARY}
        on:click={() => {}}
      >
        Secondary disabled
      </Button>
    </div>

    <div class="w-full px-10 mb-4">
      <Button
        intent={VoiceIntent.NEXT}
        kind={ButtonKinds.TERTIARY}
        on:click={() => {}}
      >
        Tertiary
      </Button>
    </div>

    <div class="w-full px-10 mb-4">
      <Button
        disabled
        intent={VoiceIntent.NEXT}
        kind={ButtonKinds.TERTIARY}
        on:click={() => {}}
      >
        Tertiary disabled
      </Button>
    </div>
  </div>
</div>

// eslint-disable-next-line import/prefer-default-export
export const AVERAGE_PASSENGER_BAGS_PER_DESTINATION = Object.freeze({
  AMD: 1.9,
  AMM: 1.2,
  AMS: 1.2,
  ATH: 1.2,
  BAH: 1.2,
  BCN: 1.2,
  BEG: 1.1,
  BEY: 1.4,
  BKK: 1.0,
  BLR: 1.4,
  BNE: 1.2,
  BOM: 1.5,
  BRU: 1.3,
  CAI: 1.4,
  CCJ: 1.8,
  CCU: 1.8,
  CDG: 1.1,
  CGK: 1.7,
  CMB: 1.1,
  CMN: 1.4,
  COK: 1.5,
  CTU: 1.0,
  DEL: 1.5,
  DME: 1.0,
  DMM: 1.1,
  DUB: 1.3,
  DUS: 1.1,
  FCO: 1.1,
  FRA: 1.1,
  GVA: 1.1,
  GYD: 1.0,
  HKG: 1.1,
  HKT: 1.0,
  HYD: 1.7,
  IAD: 1.8,
  ICN: 1.1,
  ISB: 1.9,
  IST: 1.1,
  JED: 1.0,
  JFK: 1.8,
  JNB: 1.5,
  KHI: 1.8,
  KRT: 2.0,
  KTM: 1.6,
  KUL: 1.3,
  KWI: 1.1,
  LAX: 1.8,
  LHE: 1.9,
  LHR: 1.4,
  LOS: 1.8,
  MAA: 1.6,
  MAD: 1.2,
  MAN: 1.3,
  MCT: 1.0,
  MED: 1.0,
  MEL: 1.2,
  MLE: 0.9,
  MNL: 1.7,
  MSQ: 1.1,
  MUC: 1.1,
  MXP: 1.2,
  NBO: 1.5,
  NRT: 1.1,
  ORD: 1.9,
  PEK: 1.0,
  PVG: 1.0,
  RBA: 1.6,
  RUH: 1.0,
  SEZ: 1.1,
  SIN: 1.1,
  SYD: 1.3,
  TRV: 1.6,
  TSE: 1.0,
  YYZ: 2.1,
  ZRH: 1.1,
});

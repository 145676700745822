/* Store for the receipt data */
/* eslint-disable import/prefer-default-export */

import { get } from 'svelte/store';
import { resettable } from './extensions/resettable';
import logger from '../logger';

export const receipt = {
  ...resettable([]),
  ...{
    /* Add a bag to the receipt. */
    add(passengerID, weight, checkedTime, tagNumber, tagNumberPrefix) {
      this.set([
        ...get(this),
        {
          passengerID,
          weight,
          checkedTime,
          tagNumber,
          tagNumberPrefix
        },
      ]);
      logger.info(
        `Added bag receipt item: ` +
          `passengerID: ${passengerID}; ` +
          `weight: ${weight}; ` +
          `checkedTime: ${
            checkedTime &&
            checkedTime instanceof Date &&
            checkedTime.toISOString()
          }; ` +
          `tagNumber: ${tagNumber}. ` +
          `Baggage receipt now contains ${this.injectedBagCount()} item(s).`,
      );
    },

    /* Total weight of injected bags. */
    totalWeight() {
      return get(this).reduce(
        (runningTotal, bag) => runningTotal + bag.weight,
        0,
      );
    },

    /* Bag Tag Numbers that have been used on injected bags. */
    usedBagTagNumbers() {
      return get(this).map((bag) => {

        // for all logic and comparision purposes we will use 6 digit tagNumber
        let tagNumber = bag.tagNumber;
        if (tagNumber) {
          if (bag.tagNumber.length > 6 && bag.tagNumber.length === 10) {
            tagNumber = bag.tagNumber.substring(4,10);
          } else if (bag.tagNumber.length > 0 && bag.tagNumber.length === 6) {
            tagNumber = bag.tagNumber;
          }
        }
        return tagNumber;
      });
    },

    /* Number of bags that have been injected. */
    injectedBagCount() {
      return get(this).length;
    },

    haveBagsBeenInjected() {
      return this.injectedBagCount() > 0;
    },
  },
};

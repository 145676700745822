<script>
  import { _ } from 'svelte-i18n';

  import { CustomErrorModal, errorModal } from '../../../js/stores';
  import { ErrorModals } from '../../../js/const/errorModals';
  import {
    VoiceIntent,
    voiceRecognition
  } from '../../../js/services/voicerec/voicerec';

  import Language from '../../../svg/language.svg';
  import LanguageSelect from '../modal/LanguageSelect.svelte';
  import NavButton from './NavButton.svelte';

  let showLanguageSelect = false;
  function languageHandler() {
    showLanguageSelect = true;
  }
</script>

<LanguageSelect bind:showModal={showLanguageSelect} />

<NavButton
  icon={Language}
  intent={VoiceIntent.LANGUAGE}
  key={'I'}
  text={'Language'}
  on:click={languageHandler} />

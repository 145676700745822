<script>
  import { _ } from 'svelte-i18n';
  import { get } from 'svelte/store';
  import { onMount } from 'svelte';

  import {
    booking,
    featureFlags,
    headPassenger,
    plusgradeAncillaryOfferManager,
  } from '../../js/stores';
  import { FeatureFlags } from '../../js/const';
  import { VoiceIntent } from '../../js/services/voicerec/voicerec';

  import BoardingPass from './modal/BoardingPass.svelte';
  import Button, { ButtonKinds } from './Button.svelte';
  import LoadingAnimation from './LoadingAnimation.svelte';
  import TripExtrasBanner from './TripExtrasBanner.svelte';

  export let buttonLeftDisabled = null;
  export let buttonLeftHandler = () => {};
  export let buttonLeftIcon = '';
  export let buttonLeftIconSize = '';
  export let buttonLeftIntent = VoiceIntent.CANCEL;
  export let buttonLeftKind = null;
  export let buttonLeftSize = '';
  export let buttonLeftText = '';
  export let buttonRightDisabled = null;
  export let buttonRightHandler = () => {};
  export let buttonRightIcon = '';
  export let buttonRightIconSize = '';
  export let buttonRightIntent = VoiceIntent.NEXT;
  export let buttonRightKind = null;
  export let buttonRightSize = '';
  export let buttonRightText = '';
  export let disclaimer = '';
  export let hasTripExtrasBanner = false;
  export let isButtonRightActive = true;

  let hasPlusgrade = false;
  let isLoading = false;
  let isPlusgradeEligible = false;
  let showPrintBoardingPass = false;

  $: footerSecondaryProps = {
    ...$$restProps,
    class: ['relative', $$restProps.class].filter(Boolean).join(' '),
  };

  onMount(() => {
    hasPlusgrade =
      hasTripExtrasBanner && featureFlags.isEnabled(FeatureFlags.PLUSGRADE);
  });

  /**
   * FooterSecondary sits above a footer and is a reusuable design pattern.
   *
   * @example <caption>Example usage.</caption>
   * <FooterSecondary
   *   class="mb-4"
   *   disclaimer={$_('myDisclaimer')}
   *   {isButtonRightActive}
   *   buttonLeftDisabled={myBoolean}
   *   buttonLeftHandler={myFunction}
   *   buttonLeftIcon={ChevronRight}
   *   buttonLeftIconSize="large"
   *   buttonLeftIntent={VoiceIntent.YES}
   *   buttonLeftKind={ButtonKinds.SECONDARY}
   *   buttonLeftSize="huge"
   *   buttonLeftText={$_('myLeftText')}
   *   buttonRightDisabled={myBoolean}
   *   buttonRightHandler={myFunction}
   *   buttonRightIcon={ChevronRight}
   *   buttonRightIconSize="large"
   *   buttonRightIntent={VoiceIntent.YES}
   *   buttonRightKind={ButtonKinds.SECONDARY}
   *   buttonRightSize="huge"
   *   buttonRightText={$_('myRightText')}
   *   hasTripExtrasBanner />
   *
   * @type {Boolean} isButtonRightActive. - Dynamically turn button off or on.
   * @type {Boolean} buttonLeftDisabled.
   * @type {Function} buttonLeftHandler.
   * @type {String} buttonLeftIcon.
   * @type {String} buttonLeftIconSize. - Size of the icon.
   * @type {String} buttonLeftIntent.
   * @type {String} buttonLeftKind.
   * @type {String} buttonLeftSize.
   * @type {String} buttonLeftText.
   * @type {Boolean} buttonRightDisabled.
   * @type {Function} buttonRightHandler.
   * @type {String} buttonRightIcon.
   * @type {String} buttonRightIconSize. - Size of the icon.
   * @type {String} buttonRightIntent.
   * @type {String} buttonRightKind.
   * @type {String} buttonRightSize.
   * @type {String} buttonRightText.
   * @type {Boolean} hasTripExtrasBanner. - is the trip extras banner shown?
   */
</script>

<BoardingPass bind:showModal={showPrintBoardingPass} />

<div {...footerSecondaryProps}>
  <div class="fixed bottom-0 left-0 right-0 mb-24 ml-10 mr-10">
    {#if hasPlusgrade}
      {#if isLoading}
        <div class="mb-20 ml-20 mr-20">
          <LoadingAnimation />
        </div>
      {:else if isPlusgradeEligible}
        <div class="mb-18">
          <TripExtrasBanner />
        </div>
      {/if}
    {/if}

    <div
      class="flex {buttonLeftText ? 'justify-between' : 'justify-end'} mb-6"
    >
      {#if buttonLeftText}
        <Button
          disabled={buttonLeftDisabled}
          kind={buttonLeftKind}
          intent={buttonLeftIntent}
          size={buttonLeftSize}
          on:click={buttonLeftHandler}
        >
          {buttonLeftText}
        </Button>
      {:else if buttonLeftIcon}
        <Button
          disabled={buttonLeftDisabled}
          icon={buttonLeftIcon}
          iconSize={buttonLeftIconSize}
          intent={buttonLeftIntent}
          size={buttonLeftSize}
          on:click={buttonLeftHandler}
        />
      {/if}

      {#if isButtonRightActive}
        {#if buttonRightText}
          <Button
            disabled={buttonRightDisabled}
            kind={buttonRightKind}
            intent={buttonRightIntent}
            size={buttonRightSize}
            on:click={buttonRightHandler}
          >
            {buttonRightText}
          </Button>
        {:else if buttonRightIcon}
          <Button
            disabled={buttonRightDisabled}
            icon={buttonRightIcon}
            iconSize={buttonRightIconSize}
            intent={buttonRightIntent}
            kind={isPlusgradeEligible
              ? ButtonKinds.SECONDARY
              : ButtonKinds.PRIMARY}
            size={buttonRightSize}
            on:click={buttonRightHandler}
          />
        {/if}
      {/if}
    </div>

    {#if disclaimer}
      <div class="mb-6 text-center">
        <p class="text-0.8">{disclaimer}</p>
      </div>
    {/if}
  </div>
</div>

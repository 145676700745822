import { graphql } from 'msw';

/** Booking. */
import activateBagTagOptimal from '../src/js/data/mocks/activateBagTagOptimal/activateBagTagOptimal.json';
import addEmergencyContact from '../src/js/data/mocks/addEmergencyContact/addEmergencyContact.json';
import ancillaries from '../src/js/data/mocks/ancillaries/ancillaries.json';
import checkInPassenger from '../src/js/data/mocks/checkInPassenger/checkInPassenger.json';
import issueBagTagsOptimal from '../src/js/data/mocks/issueBagTagsOptimal/issueBagTagsOptimal.json';
import rePrintBP from '../src/js/data/mocks/rePrintBP/single.json';
// import rePrintBPInfant from '../src/js/data/mocks/rePrintBP/single-infant.json';
import sendEmailReservation from '../src/js/data/mocks/sendEmailReservation/sendEmailReservation.json';
import timaticCheck from '../src/js/data/mocks/timaticCheck/timaticCheck.json';
import timaticOverride from '../src/js/data/mocks/timaticOverride/timaticOverride.json';
import updateEmailAddress from '../src/js/data/mocks/updateEmailAddress/updateEmailAddress.json';

/** Booking single. */
import searchBookingSingle from '../src/js/data/mocks/searchBooking/single.json';
// import searchBookingSingleCheckedIn from '../src/js/data/mocks/searchBooking/single-checked-in.json';
// import searchBookingSingleInfant from '../src/js/data/mocks/searchBooking/single-infant.json';
// import searchBookingSingleMultiSector from '../src/js/data/mocks/searchBooking/single-multi-sector.json';

/**
 * MRZ for Single checked in.
 * 
P<EGYACKERMAN<<MIKASA<<<<<<<<<<<<<<<<<<<<<<<
0115179792EGY9902107F2402103<<<<<<<<<<<<<<02
 */

/** Booking multi. */
// import searchBookingMulti from '../src/js/data/mocks/searchBooking/multi.json';
// import searchBookingMultiInfant from '../src/js/data/mocks/searchBooking/multi-infant.json';

/** Payment */
import paymentTransactionSeatUpgradeApproved from '../src/js/data/mocks/paymentTransaction/seat-upgrade-approved.json';
// import paymentTransactionSeatUpgradeDeclined from '../src/js/data/mocks/paymentTransaction/seat-upgrade-declined.json';
// import paymentTransactionSeatUpgradeError from '../src/js/data/mocks/paymentTransaction/seat-upgrade-error.json';

/** Plusgrade. */
import requestofferBusiness from '../src/js/data/mocks/requestOffer/business.json';
// import requestofferExtraSeat from '../src/js/data/mocks/requestOffer/extra-seat.json';

/** SeatMap. */
// import a320J from '../src/js/data/mocks/seatMap/320-business.json'; // equipment 320
// import a320Y from '../src/js/data/mocks/seatMap/320-economy.json';
// import a321Y from '../src/js/data/mocks/seatMap/321-economy.json';
// import a380A from '../src/js/data/mocks/seatMap/388-first.json';
// import a380J from '../src/js/data/mocks/seatMap/388-business.json';
// import a380Y from '../src/js/data/mocks/seatMap/388-economy.json';
// import b777A from '../src/js/data/mocks/seatMap/77w-first.json';
import b781Y from '../src/js/data/mocks/seatMap/781-economy.json';
// import b787J from '../src/js/data/mocks/seatMap/789-business.json';
// import b787Y from '../src/js/data/mocks/seatMap/789-economy.json';
import selectChangeSeat from '../src/js/data/mocks/selectChangeSeat/selectChangeSeat.json';

// eslint-disable-next-line import/prefer-default-export
export const handlers = [
  graphql.mutation('searchBooking', (req, res, ctx) =>
    res(ctx.data(searchBookingSingle)),
  ),

  graphql.query('requestOffer', (req, res, ctx) =>
    res(ctx.data(requestofferBusiness)),
  ),

  graphql.mutation('ancillaries', (req, res, ctx) =>
    res(ctx.data(ancillaries)),
  ),

  graphql.mutation('timaticCheck', (req, res, ctx) =>
    res(ctx.data(timaticCheck)),
  ),

  graphql.mutation('timaticOverride', (req, res, ctx) =>
    res(ctx.data(timaticOverride)),
  ),

  graphql.mutation('addEmergencyContact', (req, res, ctx) =>
    res(ctx.data(addEmergencyContact)),
  ),

  graphql.mutation('checkInPassenger', (req, res, ctx) =>
    res(ctx.data(checkInPassenger)),
  ),

  graphql.query('seatMap', (req, res, ctx) => res(ctx.data(b781Y))),

  graphql.mutation('selectChangeSeat', (req, res, ctx) =>
    res(ctx.data(selectChangeSeat)),
  ),

  graphql.query('paymentTransaction', (req, res, ctx) =>
    res(ctx.data(paymentTransactionSeatUpgradeApproved)),
  ),

  graphql.mutation('issueBagTagsOptimal', (req, res, ctx) =>
    res(ctx.data(issueBagTagsOptimal)),
  ),

  graphql.mutation('activateBagTagOptimal', (req, res, ctx) =>
    res(ctx.data(activateBagTagOptimal)),
  ),

  graphql.mutation('rePrintBP', (req, res, ctx) => res(ctx.data(rePrintBP))),

  graphql.mutation('updateEmailAddress', (req, res, ctx) =>
    res(ctx.data(updateEmailAddress)),
  ),

  graphql.mutation('sendEmailReservation', (req, res, ctx) =>
    res(ctx.data(sendEmailReservation)),
  ),
];

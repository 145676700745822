<script>
  import { _ } from 'svelte-i18n';

  import { Priority } from '../../../js/const';
  import {
    VoiceIntent,
    voiceRecognition,
  } from '../../../js/services/voicerec/voicerec';

  import BaggageAllowance from '../BaggageAllowance/index.svelte';
  import Button from '../Button.svelte';
  import ModalBase from '../ModalBase.svelte';
  import X from '../Icons/X.svelte';

  export let showModal = null;

  let triggerClose;

  function closeHandler() {
    triggerClose();
  }
</script>

<ModalBase
  heading={$_('baggageAllowance.heading')}
  priority={Priority.LOW}
  bind:showModal
  bind:triggerClose
>
  <BaggageAllowance />

  <div class="flex justify-end mt-auto">
    <Button on:click={closeHandler} icon={X} intent={VoiceIntent.CLOSE} />
  </div>
</ModalBase>

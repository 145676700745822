import { applicationFlow } from '../js/stores';
import { ApplicationFlow } from './const';
import { get } from 'svelte/store';
import { push } from 'svelte-spa-router';
import logger from '../js/logger';

// this function will go to the new Have a nice flight screen for embross flows
export function handleTransactionEnd() {
    const flow = get(applicationFlow);      
    if (flow === ApplicationFlow.SELF_SERVICE || flow === ApplicationFlow.HYBRID) {
      push('/have-a-nice-flight');    
    } else {
      logger.info('Going to home screen');
      push('/');
    }
}

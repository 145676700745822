/** Disable the right click menu. */

/**
 * Disable the right click menu.
 *
 * Does not apply when running on localhost (eg. local dev environment).
 */
export default function disableContextMenu() {
  if (window.location.hostname !== 'localhost') {
    window.oncontextmenu = () => false;
  }
}

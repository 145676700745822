<script>
  import { _ } from 'svelte-i18n';
  import { createEventDispatcher, onMount } from 'svelte';
  import { push } from 'svelte-spa-router';

  import endTransaction from '../../js/endTransaction';
  import flightdeck from '../../js/services/flightdeck';
  import logger from '../../js/logger';
  import { secondsToMilliseconds } from '../../js/utils';

  import Button from './Button.svelte';
  import ModalError from './ModalError.svelte';
  import X from './Icons/X.svelte';

  import warning from '../../svg/warning.svg';

  export let showModal = null;
  export let timeoutWarning = null;

  const dispatch = createEventDispatcher();

  /** Handle timeout. */
  function handleTimeout() {
    logger.info(`Passenger has timed out.`);
    flightdeck.passengerEndedFromTimeout();
    showModal = false;
    push('/');
  }

  /** Handle End button. */
  function handleEnd() {
    logger.info(`Passenger has ended transaction from Timeout.`);
    showModal = false;
    endTransaction();
  }

  onMount(() => {
    const timeout = setTimeout(() => {
      handleTimeout();
    }, secondsToMilliseconds(timeoutWarning));

    return () => {
      clearTimeout(timeout);
    };
  });

</script>

<ModalError
  heading={$_('modal.timeout.heading')}
  textLine1={$_('modal.timeout.textLine1')}
  textLine2={$_('modal.timeout.textLine2')}
  icon={warning}
>
  <div slot="modalButtons">
    <div class="flex justify-between">
      <Button icon={X} on:click={handleEnd} />

      <Button
        on:click={() => {
          dispatch('TIMEOUT_CONTINUE');
        }}
      >
        {$_('app.continue')}
      </Button>
    </div>
  </div>
</ModalError>

<script>
  import { onDestroy } from 'svelte';
  import { get } from 'svelte/store';
  import { onElementDestroy } from '../../js/actions.js';
  import {
    currentBag,
    setCurrentBagWeight,
    updateCurrentBag
  } from '../../js/stores.js';

  export let bagNumber;

  let { length, width, height, weight, bagTagID } = get(currentBag);
  let settled = false;

  function isDisabled(fieldName) {
    const bag = get(currentBag);
    return Boolean(bag[fieldName]);
  }

  const unsubscribe = currentBag.subscribe((bag) => {
    length = bag.length;
    width = bag.width;
    height = bag.height;
    weight = bag.weight;
    bagTagID = bag.bagTagID;
    settled = bag.settled;
  });

  onDestroy(unsubscribe);
</script>

<div
  class="flex items-center my-4 w-14/15 mx-auto"
  use:onElementDestroy={() => {
    setCurrentBagWeight(weight);
    updateCurrentBag({ ...get(currentBag), length, width, height, bagTagID });
  }}
>
  <div class="w-2/15">
    <label class="block text-gray-500 font-bold text-right pr-4" for="bagNumber"
      >Bag #{bagNumber}<br /><small>{settled ? '(settled)' : ''}</small>
    </label>
  </div>

  <div class="flex justify-between w-13/15 mx-auto">
    <div class="w-24">
      <label
        class="block uppercase text-right tracking-wide text-gray-500 text-xs font-bold mb-2"
        for="length"
      >
        Length
      </label>
      <input
        disabled={isDisabled('length')}
        class="bg-gray-200 appearance-none text-right border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-500 leading-tight focus:outline-none disabled:bg-white focus:bg-white focus:border-blue-500"
        id="length"
        bind:value={length}
      />
    </div>
    <div class="w-24">
      <label
        class="block uppercase text-right tracking-wide text-gray-500 text-xs font-bold mb-2"
        for="width"
      >
        Width
      </label>
      <input
        disabled={isDisabled('width')}
        class="bg-gray-200 appearance-none text-right border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-500 leading-tight focus:outline-none disabled:bg-white focus:bg-white focus:border-blue-500"
        id="width"
        bind:value={width}
      />
    </div>
    <div class="w-24">
      <label
        class="block uppercase text-right tracking-wide text-gray-500 text-xs font-bold mb-2"
        for="height"
      >
        Height
      </label>
      <input
        disabled={isDisabled('height')}
        class="bg-gray-200 appearance-none text-right border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-500 leading-tight focus:outline-none disabled:bg-white focus:bg-white focus:border-blue-500"
        id="height"
        bind:value={height}
      />
    </div>
    <div class="w-24">
      <label
        class="block uppercase text-right tracking-wide text-gray-500 text-xs font-bold mb-2"
        for="weight"
      >
        Weight
      </label>
      <input
        disabled={isDisabled('weight')}
        class="bg-gray-200 appearance-none text-right border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-500 leading-tight focus:outline-none disabled:bg-white focus:bg-white focus:border-blue-500"
        id="weight"
        bind:value={weight}
      />
    </div>
    <div>
      <label
        class="block uppercase text-right tracking-wide text-gray-500 text-xs font-bold mb-2"
        for="bagId"
      >
        Bag ID
      </label>
      <input
        disabled={isDisabled('bagTagID')}
        class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-500 leading-tight focus:outline-none disabled:bg-white focus:bg-white focus:border-blue-500"
        id="bagId"
        bind:value={bagTagID}
      />
    </div>
  </div>
</div>

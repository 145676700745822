<script>
  import logger from '../../js/logger';
  import { Priority } from '../../js/const';
  import { rightToLeft } from '../../js/stores';

  import HotkeyContext from './HotkeyContext.svelte';

  export let heading = '';
  export let icon = '';
  export let iconClass = '';
  export let priority = Priority.NORMAL;
  export let showModal = false;
  export let textLine1 = '';
  export let textLine1Class = '';
  export let textLine2 = '';
  export let textLine2Class = '';
  export let textLine3 = '';
  export let textLine3Class = '';
  export const triggerClose = () => {
    showModal = false;
  };

  $: {
    if (showModal) {
      logger.info(`ModalBase opened: ${heading}`);
    }
  }

  $: modalBaseProps = {
    ...$$restProps,
    class: [`modal ${priority['z-index']}`, $$restProps.class]
      .filter(Boolean)
      .join(' '),
  };

  /**
   * ModalBase component.
   *
   * This will become the generic implemention of a Modal.
   *
   * @example <caption>Example usage.</caption>
   * <ModalBase
   *   class="mb-12"
   *   heading={$_('myHeading.heading')}
   *   icon={myIcon}
   *   iconClass="text-palmGreen"
   *   priority={Priority.NORMAL}
   *   textLine1={$_('myModal.textLine1')}
   *   textLine1Class="font-bold"
   *   textLine2={$_('myModal.textLine2')}
   *   textLine2Class="font-bold"
   *   textLine3={$_('myModal.textLine3')}
   *   textLine3Class="font-bold"
   *   bind:showModal
   *   bind:triggerClose>
   *   <div></div>
   * </ModalBase>
   *
   * @type {String} heading.
   * @type {String} icon.
   * @type {String} iconClass.
   * @type {String} priority.
   * @type {String} textLine1.
   * @type {String} textLine1Class. - Additional CSS classes.
   * @type {String} textLine2.
   * @type {String} textLine2Class.
   * @type {String} textLine3.
   * @type {String} textLine3Class.
   * @type {Boolean} showModal.
   */
</script>

{#if showModal}
  <div {...modalBaseProps} dir={$rightToLeft ? 'rtl' : 'ltr'}>
    <div class="modalOverlay" />

    <div class="modalContent bg-white text-charcoal-100 {priority['z-index']}">
      <div class="mb-10 px-6">
        {#if heading}
          <h1 class="modalHeading">
            {@html heading}
          </h1>
        {/if}

        {#if icon}
          <div class="modalIcon {iconClass}">
            {@html icon}
          </div>
        {/if}

        {#if textLine1}
          <p class="modalParagraph {textLine1Class}">
            {@html textLine1}
          </p>
        {/if}

        {#if textLine2}
          <p class="modalParagraph {textLine2Class}">
            {@html textLine2}
          </p>
        {/if}

        {#if textLine3}
          <p class="modalParagraph {textLine3Class}">
            {@html textLine3}
          </p>
        {/if}
      </div>

      <HotkeyContext>
        <slot />
      </HotkeyContext>
    </div>
  </div>
{/if}

/* Reset the application. */
import { locale } from 'svelte-i18n';
import logger from './logger';
import events from './events';
import { resetAllResettableStores } from './stores/extensions/resettable';

/** Clear the application state. */
export default function flushState() {
  logger.info('Flushing application state.');
  locale.set('en');
  resetAllResettableStores();
  events.resetEventListeners();
}

<script>
  import Exits from './Exits.svelte';
  import Row from './Row.svelte';
  import RowFacilities from './RowFacilities.svelte';

  export let cabinHeight = null;
  export let cabinWidth = null;
  export let isOpen = null;
  export let passenger = null;
  export let passengerSeats = null;
  export let previewSeat = null;
  export let seatMapManager = null;
  export let seatSpace = null;
  export let seatWidth = null;
  export let selectedSeat = null;
  export let spacerWidth = null;

  $: rows = seatMapManager ? seatMapManager.getRows() : [];
</script>

<style>
  .cabin {
    @apply mx-auto pb-11;
  }
</style>

<div
  class="cabin"
  bind:offsetHeight={cabinHeight}
  style="width: {cabinWidth}rem;"
>
  {#each rows as row}
    {#if seatMapManager ? seatMapManager.isExitRow(row) : null}
      <Exits />
    {/if}

    {#if seatMapManager ? seatMapManager.isFrontFacility(row) : null}
      <RowFacilities {row} {seatMapManager} />
    {/if}

    <Row
      bind:isOpen
      {passenger}
      {passengerSeats}
      {previewSeat}
      {row}
      {seatMapManager}
      {seatSpace}
      {seatWidth}
      {selectedSeat}
      {spacerWidth}
      on:selectSeat
      on:scrollToSeat
    />

    {#if seatMapManager ? seatMapManager.isRearFacility(row) : null}
      <RowFacilities {row} {seatMapManager} />
    {/if}
  {/each}
</div>

<script>
  let showModal = false;

  const toggle_codes = [
    192, // The ~ key
    223, // The ~ key, alternate keyboard layout.
  ];

  function handleKeydown(event) {
    if (toggle_codes.includes(event.keyCode)) {
      showModal = !showModal;
    }
    if (
      event.keyCode === 27 // The Esc key
    ) {
      showModal = false;
    }
  }
</script>

<svelte:window on:keydown={handleKeydown} />

{#if showModal}
  <div class="modal text-charcoal-100 z-50">
    <div
      class="modalOverlay"
      on:click={() => {
        showModal = false;
      }}
    />

    <slot name="modal" />
  </div>
{/if}

/* The currently displayed error modal. */
import cloneDeep from 'lodash/cloneDeep';
import { get } from 'svelte/store';
import { ErrorModals } from '../const/errorModals';
import endTransaction from '../endTransaction';
import { resettable } from './extensions/resettable';
import { getKeyByValue } from '../utils';
import logger from '../logger';
import { appReport, EventType, EventStatus } from '../appReport';

/* The currently displayed modal. */
export const errorModal = resettable(null);

/**
 * Return a string providing a descriptive name for the error modal.
 *
 * Value subject to change, non-canonical.
 * Either uses the key from ErrorModals or derives a value from the i18n key.
 *
 * Custom modals that share a heading will have the same descriptive name.
 *
 * @param {object} sourceErrorModal - The error modal from which to derive
 *                                    a descriptive name. If not provided
 *                                    then the currently open error modal
 *                                    is used.
 * @returns {string} A descriptive name for the error modal.
 *                  If sourceErrorModal is not provided and there is not a
 *                  currently open modal, then an empty string is returned.
 */
export function getErrorModalDescriptiveName(sourceErrorModal = null) {
  const modal = sourceErrorModal || get(errorModal) || {};

  /**
   * Generate a descriptive name from the heading key in the dictionary.
   *
   * @param {object} modal - The modal object.
   * @returns {string}
   */
  function customModalName(modal) {
    const [, heading] = (modal.heading || '').split('.');
    return heading ? `Custom modal: '${heading}'.` : '';
  }

  return getKeyByValue(ErrorModals, modal) || customModalName(modal);
}

/**
 * Set the current error modal.
 *
 * Ensures errorModal first changes to null before changing
 * to a different value if there is already an error modal displayed.
 * This is necessary because GeneralErrorModal performs clean-up of an
 * active modal when the value of errorModal changes to null.
 *
 * @param {object} newErrorModal - The new error modal object.
 */
export function setErrorModal(newErrorModal) {
  const currentErrorModal = get(errorModal);
  if (currentErrorModal && newErrorModal) {
    if (currentErrorModal == newErrorModal) {
      logger.warn(
        'Blocked reopening of an error modal. ' +
          `'${getErrorModalDescriptiveName(
            currentErrorModal,
          )}' is already open.`,
      );
      return;
    }
    logger.warn(
      'An error modal is being opened when there is already one open. ' +
        `Old modal: ${getErrorModalDescriptiveName(currentErrorModal)}; ` +
        `new modal: ${getErrorModalDescriptiveName(newErrorModal)}.`,
    );
    errorModal.set(null);
  }
  errorModal.set(newErrorModal);

  appReport.addEvent(
    EventType.ERROR_MODAL_DISPLAYED,
    EventStatus.FAILED,
    null,
    getErrorModalDescriptiveName(),
  );
}

/**
 * Allows error modal customisation on the fly.
 *
 * Useful for when the override/end functions of the override modal
 * need the context of the caller.
 */
export class CustomErrorModal {
  /**
   * Set a base errorModal.
   *
   * @param {object} base - A base error modal, eg. from const/errorModals.js.
   * @param {object} overrides - An object to override the base error modal.
   */
  constructor(base = {}, overrides = {}) {
    this.errorModal = cloneDeep(base);
    this.errorModal.flightdeckHandlers = {};
    this.errorModal = { ...this.errorModal, ...overrides };
    this.errorModal.reason = null;
  }

  /**
   * Sets a field on the nested 'override' object.
   *
   * @param {string} key - The key of the field.
   * @param {any} value - The value of the field.
   */
  setOverrideField(key, value) {
    if (!('override' in this.errorModal)) {
      this.errorModal.override = {};
    }

    this.errorModal.override[key] = value;
  }

  /**
   * Sets the override handler for the override modal.
   *
   * This is the handler that is executed when the agent presses on the
   * 'OVERRIDE' button in the override modal.
   *
   * @param {Function} func - The function to set as the override handler.
   */
  setOverrideHandler(func) {
    this.setOverrideField('overrideHandler', func);
  }

  /**
   * Sets the end handler for the override modal.
   *
   * This is the handler that is executed when the agent presses on the
   * 'END' button in the override modal.
   *
   * @param {Function} func - The function to set as the end handler.
   */
  setEndHandler(func) {
    this.setOverrideField('endHandler', func);
  }

  /**
   * Set a flightdeck action handler to affect this error modal.
   *
   * @param {string} flightdeckStatus - Member of TransactionStatuses.
   * @param {Function} func - The function to call for the status.
   */

  setFlightdeckHandler(flightdeckStatus, func) {
    this.errorModal.flightdeckHandlers[flightdeckStatus] = func;
  }

  /**
   * Open the error modal.
   *
   *  @param {string} reason - The reason of the error
   */
  open(reason = null) {
    this.errorModal.reason = reason;
    setErrorModal(this.errorModal);
  }
}

<script>
  import { _ } from 'svelte-i18n';
  import Content from '../components/Content.svelte';
  import Header from '../components/Header/index.svelte';
</script>

<Header hasHomeButton={false} />

<Content>
  <span slot="heading">{$_('unavailable.heading')}</span>
  <span slot="text">
    {$_('unavailable.message')}
  </span>
</Content>
<script>
  import { _ } from 'svelte-i18n';
  import Router from 'svelte-spa-router';

  import { FeatureFlags } from '../js/const';
  import { featureFlags, isArabicLocale, rightToLeft } from '../js/stores';
  import disableContextMenu from '../js/disableContextMenu';
  import { kioskHeartbeat, registerKiosk } from '../js/services/flightdeck';
  import initI18n from '../js/i18n';
  import initOnError from '../js/global-error-handler';
  import initRemoteLogging from '../js/logger/remote';
  import { notifyForRunningApplet } from '../js/hardwareCheck';
  import routes from '../js/routes';
  import toolkit from '../js/toolkit';

  import Applet from './components/Applet.svelte';
  import DebugModal from './components/DebugModal.svelte';
  import GeneralErrorModal from './components/GeneralErrorModal.svelte';
  import HotkeyListener from './components/HotkeyListener.svelte';
  import Resolution from './components/Resolution.svelte';
  import Timeout from './components/Timeout.svelte';

  notifyForRunningApplet();
  initI18n();
  registerKiosk();
  kioskHeartbeat();
  initOnError();
  disableContextMenu();
  initRemoteLogging();

  window.toolkit = toolkit;

  if (featureFlags.isEnabled(FeatureFlags.DARK)) {
    document.body.className += 'dark';
  }

</script>

<style>
  :global(html) {
    font-size: 21.275px;
  }

  :global(body) {
    @apply select-none;
  }

  #app {
    @apply block
      min-h-screen
      overflow-hidden
      relative;
  }

</style>

<Timeout />
<DebugModal />
<GeneralErrorModal />
<HotkeyListener />
<Resolution />
<Applet />

<div
  id="app"
  class="bg-white text-charcoal-100"
  class:arabic={$isArabicLocale}
  dir={$rightToLeft ? 'rtl' : 'ltr'}
>
  <Router {routes} />
</div>

<script>
  import { _ } from 'svelte-i18n';
  import { VoiceIntent } from '../../../js/services/voicerec/voicerec';

  import FooterSecondary from '../FooterSecondary.svelte';

  export let advanceToNextScreen = () => {};
  export let isOpen = null;
</script>

<FooterSecondary
  buttonRightDisabled={isOpen}
  buttonRightHandler={advanceToNextScreen}
  buttonRightIntent={VoiceIntent.CONFIRM}
  buttonRightText={$_('app.finish')}
/>

<script>
  export let cabinWidth = null;
  export let seatSpace = null;
  export let seatWidth = null;
  export let hasScrollbar = false;
  export let seatMapManager = null;
  export let spacerWidth = null;

  $: columnGroups = seatMapManager
    ? seatMapManager.getGroupsForColumnHeadings()
    : [];
</script>

<style>
  .columnHeadings {
    @apply pt-2;
  }

  .group {
    @apply flex
      justify-around
      pb-2
      pt-1;
  }

  .heading {
    @apply font-AltisMedium
      text-center
      text-xl;
  }

  .rowSpacer:last-child {
    @apply hidden;
  }

  .hasScrollbar {
    @apply pr-10;
  }
</style>

<div class="columnHeadings" class:hasScrollbar>
  <div class="flex justify-center mx-auto" style="width: {cabinWidth}rem;">
    {#each columnGroups as columnGroup}
      <div
        class="group"
        style="margin-left: -{seatSpace / 2}rem; margin-right: -{seatSpace / 2}rem;">
        {#each columnGroup as columnName}
          <div class="heading" style="width: {seatWidth + seatSpace}rem;">
            {columnName}
          </div>
        {/each}
      </div>
      <div class="rowSpacer" style="width: {spacerWidth}rem;" />
    {/each}
  </div>
</div>

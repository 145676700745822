<script>
  import Beach from '../../svg/beach.svg';
  import Holiday from '../../svg/holiday.svg';
  import Honeymoon from '../../svg/honeymoon.svg';

  $: loadingAnimationProps = {
    ...$$restProps,
    class: ['loader', $$restProps.class].filter(Boolean).join(' '),
  };

  /**
   * Loading Animation component.
   *
   * @example <caption>Example usage.</caption>
   * <div class="w-1/2">
   *   <LoadingAnimation class="mb-4" />
   * </div>
   *
   * @type {String} class.
   */
</script>

<style>
  .loader {
    @apply flex;
  }

  .icon {
    @apply opacity-0 w-1/3;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }

  .icon--1 {
    animation-name: blink1;
  }

  .icon--2 {
    animation-name: blink2;
  }

  .icon--3 {
    animation-name: blink3;
  }

  @keyframes blink1 {
    0%,
    24% {
      opacity: 1;
    }
    25%,
    49% {
      opacity: 0;
    }
    50%,
    74% {
      opacity: 0;
    }
    75%,
    100% {
      opacity: 0;
    }
  }

  @keyframes blink2 {
    0%,
    24% {
      opacity: 0;
    }
    25%,
    49% {
      opacity: 1;
    }
    50%,
    74% {
      opacity: 0;
    }
    75%,
    100% {
      opacity: 0;
    }
  }

  @keyframes blink3 {
    0%,
    24% {
      opacity: 0;
    }
    25%,
    49% {
      opacity: 0;
    }
    50%,
    74% {
      opacity: 1;
    }
    75%,
    100% {
      opacity: 0;
    }
  }
</style>

<div {...loadingAnimationProps}>
  <div class="w-2/12" />
  <div class="icon icon--1">
    {@html Holiday}
  </div>
  <div class="w-3/12" />
  <div class="icon icon--2">
    {@html Honeymoon}
  </div>
  <div class="w-3/12" />
  <div class="icon icon--3">
    {@html Beach}
  </div>
  <div class="w-2/12" />
</div>

<script>
  import NavAssistance from './NavAssistance.svelte';
  import NavHome from './NavHome.svelte';
  import NavLanguage from './NavLanguage.svelte';

  export let hasHomeButton = true;

  export let immediateTransactionEnd = false;
</script>

<nav class="flex items-center justify-end w-full">
  <ul class="flex mt-7 -mx-3">
    <li class="px-3">
      <NavLanguage />
    </li>
    {#if hasHomeButton}
      <li class="px-3">
        <NavHome {immediateTransactionEnd} />
      </li>
    {/if}
    <li class="px-3">
      <NavAssistance />
    </li>
  </ul>
</nav>

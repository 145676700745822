<script>
  import { _, locale } from 'svelte-i18n';
  import { createEventDispatcher } from 'svelte';
  import { get } from 'svelte/store';

  import { hotkey } from '../../../js/actions';
  import { rightToLeft } from '../../../js/stores';
  import { currencyFormat } from '../../../js/utils';

  import Button from '../Button.svelte';

  export let closeMenuHandler = () => {};
  export let passenger = null;
  export let seat = null;

  const dispatch = createEventDispatcher();

  /** Handle the Confirm button. */
  function selectSeatHandler() {
    if (seat.isPaid()) {
      dispatch('openPaymentModal');
    } else {
      dispatch('updateSelectedSeat', {
        passenger,
        seat: String(seat),
      });
    }
  }
</script>

<div class="flex {$rightToLeft ? 'flex-row-reverse' : ''} items-end">
  <button
    class="{$rightToLeft ? 'mr-7' : 'mx-7'} text-gold-100 uppercase underline"
    role="button"
    use:hotkey
    on:click={closeMenuHandler}
  >
    {$_('seatMap.goBack')}
  </button>

  <div class="flex flex-col">
    {#if seat.isPaid()}
      <p class="font-AltisMedium mb-1 text-2xl">
        {currencyFormat($locale, seat.getCurrencyCode(), seat.getPrice())}
      </p>
    {/if}

    <Button class="px-8" size="small" on:click={selectSeatHandler}>
      {$_('seatMap.confirmSeat')}
    </Button>
  </div>
</div>

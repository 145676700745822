/* eslint-disable max-classes-per-file */
import { get } from 'svelte/store';
import { locale } from 'svelte-i18n';

import { booking, pnr } from './booking';
import { currencyFormat, stripLeadingZeroes, timeoutPromise } from '../utils';
import { ErrorModals } from '../const/errorModals';
import { headPassenger } from './headPassenger';
import logger from '../logger';
import { resettable } from './extensions/resettable';
import switchboardClient from '../services/switchboard';

import plusgradeDummyData from '../data/mocks/requestOffer/business.json';

const requestOffer = resettable({});

const RETRIEVAL_TIMEOUT = 20000; // Milliseconds.

export const ProductNames = {
  A380_BUSINESS: 'A380_BUSINESS',
  BUSINESS: 'BUSINESS',
  EXTRA_SEAT: 'EXTRA_SEAT',
  REGIONAL_BUSINESS: 'REGIONAL_BUSINESS',
};

export const CabinUpgradeProducts = [
  ProductNames.A380_BUSINESS,
  ProductNames.BUSINESS,
  ProductNames.REGIONAL_BUSINESS,
];

const ResponseStatuses = Object.freeze({
  ELIGIBLE: 'ELIGIBLE',
});

const ProductTypes = Object.freeze({
  BID: 'BID',
});

/**
 * Encapsulates the Switchboard Product object.
 */
class Product {
  /**
   * Sets the switchboard product object.
   *
   * @param {object} product - Switchboard Product object.
   */
  constructor(product) {
    this.product = product;
  }

  /**
   * Returns the currency of this product.
   *
   * @returns {string}
   */
  getCurrencyCode() {
    return this.product.offerCurrencyCode || '';
  }

  /**
   * Returns the cost of this product.
   *
   * @returns {string}
   */
  getPrice() {
    return String(Math.round(this.product.instantUpgrade.amount)) || '';
  }

  /**
   * Is the product a cabin upgrade?
   *
   * @returns {boolean}
   */
  isCabinUpgrade() {
    return CabinUpgradeProducts.includes(this.product.productName);
  }

  /**
   * Is the product an extra seat?
   *
   * @returns {boolean}
   */
  isExtraSeat() {
    return (this.product.productName || []).includes(ProductNames.EXTRA_SEAT);
  }
}

/**
 * Used for accessing the seat map.
 */
class PlusgradeAncillaryOfferManager {
  constructor() {
    this.retrievalPromise = null;
  }

  /**
   * Gets the matching flight from booking.
   *
   * @param {string} flightNumber - The flightNumber.
   * @returns {object}
   */
  getFlight(flightNumber = null) {
    if (flightNumber === null) {
      flightNumber = stripLeadingZeroes(get(booking).flightNumber);
    }

    return (
      (get(requestOffer).flights || []).find(
        (flight) => flight.flightNumber === flightNumber,
      ) || {}
    );
  }

  /**
   * Gets the offer url.
   *
   * @returns {string} - String containing the offer url.
   */
  getOfferUrl() {
    return get(requestOffer).offerUrl || '';
  }

  /**
   * Gets the products for the offer.
   *
   * @param {string} flightNumber The flight number.
   * @returns {object[]} Array containing offers available for the flight.
   */
  getProducts(flightNumber = null) {
    return (
      (this.getFlight(flightNumber).products || []).map(
        (product) => new Product(product),
      ) || []
    );
  }

  /**
   * Gets the matching products of the param.
   *
   * @param {string} productNames Names of the products.
   * @returns {object[]} Array containing products that match the param.
   */
  getMatchingProducts(productNames) {
    if (!Array.isArray(productNames)) {
      productNames = [productNames];
    }

    return (
      this.getProducts().filter(
        (item) =>
          productNames.includes(item.product.productName) &&
          item.product.instantUpgrade.eligible,
      ) || []
    );
  }

  /**
   * Return the first matching product for the param.
   *
   * @param {string|string[]} productNames A name or array of names of products.
   * @returns {object} The first matching product, or undefined.
   */
  getMatchingProduct(productNames) {
    return this.getMatchingProducts(productNames)[0];
  }

  /**
   * Return the products price.
   *
   * @param {string|string[]} productNames name of product or array of names
   * @returns {string} String containing the price.
   */
  getProductPrice(productNames) {
    const product = this.getMatchingProduct(productNames);
    return product
      ? currencyFormat(
          get(locale),
          product.getCurrencyCode(),
          product.getPrice(),
        )
      : '';
  }

  /**
   * Return the currency code.
   *
   * @param {string|string[]} productNames name of product or array of names
   * @returns {string} String containing the currenct code.
   */
  getProductCurrencyCode(productNames) {
    const product = this.getMatchingProduct(productNames);
    return product ? product.getCurrencyCode() : '';
  }

  getRetrievalPromise() {
    
  }

  /**
   * Is there a product that exists?
   *
   * @param {string} productNames Names of the products.
   * @returns {boolean}
   */
  hasMatchingProducts(productNames) {
    return this.getMatchingProducts(productNames).length !== 0;
  }

  /**
   * Is there a passenger eligible for plusgrade?
   *
   * @returns {boolean}
   */
  isEligibleForCabinUpgrade() {
    const offer = get(requestOffer);
    const responseStatusEligible =
      offer.responseStatus === ResponseStatuses.ELIGIBLE;
    const hasOfferUrl = Boolean(offer.offerUrl);
    const hasAtLeastOneBusinessUpgrade = (offer.flights || []).some((flight) =>
      (flight.products || []).some(
        (product) =>
          product.eligible &&
          CabinUpgradeProducts.includes(product.productName) &&
          product.instantUpgrade.eligible,
      ),
    );
    const isEligible =
      responseStatusEligible && hasOfferUrl && hasAtLeastOneBusinessUpgrade;

    logger.info(
      `Plusgrade eligibility check (${isEligible}). ` +
        `responseStatusEligible: ${responseStatusEligible}; ` +
        `hasOfferUrl: ${hasOfferUrl}; ` +
        `hasAtLeastOneBusinessUpgrade: ${hasAtLeastOneBusinessUpgrade}.`,
    );

    return isEligible;
  }

  /**
   * Retrieves a booking update and stores it.
   *
   * @returns {promise} A promise that resolves when the retrieval is complete.
   */
  performBookingUpdate() {
    logger.info(`Request bookingSearch.`);

    return switchboardClient
      .bookingSearch({ pnr: get(pnr) })
      .then((response) => {
        logger.info(
          `Received bookingSearch response: `,
          response && response.data && response.data.searchBooking,
        );
        booking.updateBookingObject(response.data.searchBooking);
      })
      .catch((error) => {
        logger.warn(`Failed bookingSearch Error: ${error.message}`);
        ErrorModals.ASSISTANCE_REQUIRED;
      });
  }

  /**
   * Retrieves the offer of the current booking and stores it.
   */
  retrieveOffer() {
    const passenger = get(headPassenger);
    logger.info(`Request requestOffer.`);

    plusgradeAncillaryOfferManager.retrievalPromise = switchboardClient
      .requestOffer(passenger.lastName, get(pnr))
      .then((response) => {
        logger.info(
          'Received requestOffer response: ',
          response && response.data && response.data.requestOffer,
        );
        requestOffer.set(response.data.requestOffer);
      })
      .catch((error) => {
        logger.warn(
          `Failed requestOffer. Error: ${error.message}. ` +
            'Plusgrade upgrades will be unavailable for this transaction.',
        );
      });
  }

  /**
   * Retrieves a dummy offer and stores it.
   */
  retrieveDummyOffer() {
    const dummyData = plusgradeDummyData;
    logger.info(dummyData && dummyData.data && dummyData.data.requestOffer);
    requestOffer.set(dummyData.data.requestOffer);
    this.retrievalPromise = Promise.resolve();
  }

  /**
   * Resets the store.
   */
  reset() {
    requestOffer.reset();
    this.retrievalPromise = null;
  }
}

export const plusgradeAncillaryOfferManager =
  new PlusgradeAncillaryOfferManager();

import { derived, get } from 'svelte/store';
import { push } from 'svelte-spa-router';

import {
  BagLocations,
  BagWeightMax,
  CussStatusCodes as ConveyorStatusCodes,
  SBDBelts,
  SBDStates,
  AllowanceType,
  ApplicationFlow,
  CussStatusCodes
} from '../const';
import { applicationFlow, transactionId, isFinalBag } from '../stores';
import { booking } from '../stores/booking';
import { currentPassenger } from '../stores/bagTags';
import { agentHasOverridden, currentBag, currentBagIndex, isBagsPaymentSuccessful, resetCurrentBag } from './bags';
import { CustomErrorModal, errorModal, setErrorModal } from './errorModal';
import { ErrorModals } from '../const/errorModals';
import endTransaction from '../endTransaction';
import events from '../events';
import flightdeck from '../services/flightdeck';
import flightdeckConsts from '../services/flightdeck/const';
import { getCussSBD } from '../cuss-components';
import { getKeyByValue, sleep } from '../utils';
import logger from '../logger';
import { resettable } from './extensions/resettable';
import switchboardClient from '../services/switchboard';
import { baseStationCode } from './config';

/** Map an error status code to an error state. */
const StatusToErrorState = Object.freeze({
  [ConveyorStatusCodes.BAGGAGE_INTERFERENCE_USER]: SBDStates.INTERFERENCE_USER,
  [ConveyorStatusCodes.BAGGAGE_RESTLESS]: SBDStates.BAGGAGE_RESTLESS,
});

/** Represents handlers used to process belts' query results. */
class QueryHandlers {
  /**
   * @param {object.<String, Function>} beltToFuncMap - Contains functions to be
   * run if the bag is present at a certain belt.
   */
  constructor(beltToFuncMap) {
    this.beltToFuncMap = beltToFuncMap;
  }

  /**
   * Query status of all belt components.
   * @returns {object[]} - Array of {belt, statusCode}.
   */
  static queryBeltStatus() {
    return Object.keys(SBDBelts).map((beltKey) => {
      const belt = SBDBelts[beltKey];
      try {
        const cussSBD = getCussSBD();
        cussSBD.setBelt(belt);
        const statusCode = cussSBD.callDirective('query');
        return { belt, statusCode };
      } catch (error) {
        logger.warn(
          `Exception occurred in calling directive 'query'. Error: ${error && error.message}.`,
        );
        return { belt, statusCode: ConveyorStatusCodes.EXCEPTION };
      }
    });
  }

  /** Handle certain error status codes in the provided query results. */
  static handleError(queryResults) {
    try {
      if (get(applicationFlow) === ApplicationFlow.HYBRID) {
        return false;
      }

      logger.debug(
        `handleError - queryResults: ${JSON.stringify(queryResults)}.`,
      );

      const errorStatusFound = queryResults.find(
        (result) => StatusToErrorState[result.statusCode] !== undefined,
      );

      logger.debug(`handleError - errorStatusFound: ${JSON.stringify(errorStatusFound)}.`);

      if (errorStatusFound !== undefined) {
        logger.info(`errorStatusFound is not undefined`);
        const errorState = StatusToErrorState[errorStatusFound.statusCode];
        transitionTo_ErrorState(
          errorStatusFound.belt,
          errorStatusFound.statusCode,
          errorState,
        );

        logger.info('returning true from handleError(queryResults)');
        return true;
      }

      logger.info('returning false from handleError(queryResults)');
      return false;
    } catch (e) {
      logger.info('Exception occurred in handleError(queryResults). Details: ', e);
    }
  }

  /** Handle bag absent from every belts. */
  static handleBagAbsent(queryResults) {
    // commented for new device
    // if (get(applicationFlow) === ApplicationFlow.HYBRID) {
    //   return false;
    // }

    const isBagAbsentOnEveryBelt = queryResults.every(
      (result) => result.statusCode === ConveyorStatusCodes.BAGGAGE_ABSENT,
    );

    // come here 
    if (isBagAbsentOnEveryBelt) {
      // if the bag is not present on any belt, trying recovery flow
      transitionTo_RECOVERY();
      return true;
      // check which is the current belt at the moment (Insertion / verification / Parking)    
      //if (get(lastBagLocation) === BagLocations.VERIFICATION) {
    }

    return false;
  }

  /** Handle bag present at one of the belts. */
  handleBagPresent(queryResults) {
    try {
      let bagPresentFound = null;
      
      let countOfBeltsWithBag = queryResults.filter((result) =>
        [ConveyorStatusCodes.BAGGAGE_PRESENT, ConveyorStatusCodes.BAGGAGE_FULL].includes(result.statusCode),
      ).length;

      logger.info(`countOfBeltsWithBag is: ${countOfBeltsWithBag}`);

      if (countOfBeltsWithBag > 1) {
        logger.info(`Reversing the queryResults of belt`);
        // if we have more than one bag on belt, we will get the belt with bag which is nearer to BHS
        bagPresentFound = queryResults.reverse().find((result) => [ConveyorStatusCodes.BAGGAGE_PRESENT,ConveyorStatusCodes.BAGGAGE_FULL].includes(result.statusCode));
      } else {
        logger.info(`Taking the normal order of queryResults of belt`);
        // if we have no bag or only one bag on the belt, we will get the bag present for belt in order order
        bagPresentFound = queryResults.find((result) => [ConveyorStatusCodes.BAGGAGE_PRESENT,ConveyorStatusCodes.BAGGAGE_FULL].includes(result.statusCode));
      }

      logger.info(`inside handleBagPresent. bagPresentFound is: ${JSON.stringify(bagPresentFound)}`);

      if (bagPresentFound !== undefined) {
        const bagAtBelt = bagPresentFound.belt;
        
        logger.info(`this.beltToFuncMap is : ${this.beltToFuncMap}`);
       
        const bagAtBeltFunc = this.beltToFuncMap[bagAtBelt];
        
        logger.info('bagAtBeltFunc is: ', bagAtBeltFunc);

        if (typeof(bagAtBeltFunc) === 'function' && bagAtBeltFunc !== undefined) {
          logger.info('calling bagAtBeltFunc()');
          bagAtBeltFunc();
        } else {
          logger.info('in else of if (typeof(bagAtBeltFunc)');
          displayErrorModal(`Found bag at unexpected belt '${bagAtBelt}'`);
        }
        return true;
      }

      return false;
    } catch (e) {
      logger.info('exception occurred in handleBagPresent(queryResults). Details: ', e);
    }
  }

  /**
   * Query all belt components and create handlers used to process
   * query results.
   * @returns {function[]} - Handlers used to process query results.
   */
  getHandlers() {
    try {
      const queryResults = QueryHandlers.queryBeltStatus();
      logger.info(
        `Query belt status - results: ${JSON.stringify(queryResults)}.`,
      );
      return [
        () => QueryHandlers.handleError(queryResults),
        () => this.handleBagPresent(queryResults),
        () => QueryHandlers.handleBagAbsent(queryResults),
      ];
    } catch(e) {
      logger.info('exception occurred in getHandlers(). details: ', e);
    }
  }
}

/** Hold current CUSS-SBD state */
export const sbdState = resettable(SBDStates.READY);
export const sbdPausedPendingExcessBaggage = {
  ...resettable(false),
  ...{
    /**
     * Pause the SBD for excess weight scenarios.
     * @param {string} reason - The reason for pausing, will be logged.
     */

    pause(reason) {
      logger.info(`Pausing the SBD for excess baggage. Reason: ${reason}.`);
      this.set(true);
    },

    /**
     * Unpause the SBD for excess weight scenarios.
     * @param {string} reason - The reason for unpausing, will be logged.
     */
    unpause(reason) {
      logger.info(`Unpausing the SBD. Reason: ${reason}.`);
      this.set(false);
    },
  },
};

/** Location the bag was previously seen. */
export const lastBagLocation = resettable(BagLocations.UNKNOWN);

//export const beltDirective = resettable({});

let serviceRetryCount = 0;
let bagTagRetryCount = 0;
let lastError = null;
let hasTriedOfferBag = false;
let readyToInjectToTagActivatedInProgress = false;
let tagActivatedToInjectedInProgress = false;

const BAG_TAG_WAIT = 3000;
const BAG_TAG_RETRY_MAXIMUM = 3;

const Directives = Object.freeze({
  DISABLE: 'disable',
  ENABLE: 'enable',
  FORWARD: 'forward',
  OFFER: 'offer',
  PROCESS: 'process',
  BACKWARD: 'backward',
});

/** Delay prior to calling forward() on belts. */
const FORWARD_DELAY = 300; // 300 miliseconds

/**
 * Expose transition_BEFORE_WAITING as readyToReceive().
 *
 * Call when the asking the passenger to place their bag.
 */
export const readyToReceive = transition_BEFORE__WAITING;

/**
 * Handle incorrect bag tag case.
 * @param {Function} flightDeckFunc - This function will be called to notify
 * flight deck.
 */
export function handleIncorrectBagTag(flightDeckFunc) {
  logger.warn(
    'Incorrect bag-tag detected. Use logic similar to missing-bag-tag for this case.',
  );
  transitionTo_NOT_CONVEYABLE_NO_BAG_TAG(null, null, flightDeckFunc);
}

/**
 * Handle the case in which a bag fails applicaton's dimension checks.
 * @param {Function} flightDeckFunc - This function will be called to notify
 * flight deck.
 */
export function handleFailedDimensionChecks(flightDeckFunc) {
  handleDimensionError(null, null, flightDeckFunc);
}

/**
 * Handle CUSS and application's dimension-related errors
 * and other similar CUSS errors.
 * @param {string} belt - The belt triggering the error.
 * Use null if the error was not triggered by a belt component.
 * @param {number} statusCode - The status code of the belt.
 * Use null if the error was not triggered by a belt component.
 * @param {Function} flightDeckFunc - This function will be called to notify
 * flight deck.
 */
function handleDimensionError(belt, statusCode, flightDeckFunc) {

  // we do not want to check Dimensions in hybrid flow
  if (get(applicationFlow) === ApplicationFlow.HYBRID) {
    return;
  }

   const beforeTransitionFunc = () => {
    const bagDimensionErrorModal = new CustomErrorModal(
      ErrorModals.BAG_DIMENSION_ERROR,
    );
    bagDimensionErrorModal.setOverrideHandler(transitionTo_BEFORE);
    bagDimensionErrorModal.open();
    const reason =
      belt != null && statusCode != null
        ? errorReason(belt, statusCode)
        : 'A bag dimension error was detected (not triggered by a belt component)';
    logDisplayingErrorModal(reason, 'BAG_DIMENSION_ERROR');
    flightDeckFunc();
  };

  transitionTo_USER_RECOVERABLE_ERROR(belt, statusCode, beforeTransitionFunc);
}

/* Convenience logger for transitions. */
function transitionLog(transition) {
  logger.info(`Transition: ${transition}`);
}

const StatusToErrorModal = Object.freeze({
  [ConveyorStatusCodes.BAGGAGE_INTERFERENCE_USER]: {
    modal: ErrorModals.CONVEYOR_INTERFERENCE_USER,
    modalName: 'INTERFERENCE_USER',
  },
  [ConveyorStatusCodes.BAGGAGE_RESTLESS]: {
    modal: ErrorModals.CONVEYOR_BAGGAGE_RESTLESS,
    modalName: 'BAGGAGE_RESTLESS',
  },
  [ConveyorStatusCodes.BAGGAGE_NOT_CONVEYABLE]: {
    modal: ErrorModals.CONVEYOR_NOT_CONVEYABLE,
    modalName: 'CONVEYOR_NOT_CONVEYABLE',
  },
  [ConveyorStatusCodes.BAGGAGE_WEIGHT_OUT_OF_RANGE]: {
    modal: ErrorModals.BAG_WEIGHT_ERROR,
    modalName: 'BAG_WEIGHT_ERROR',
  },
  [ConveyorStatusCodes.BAGGAGE_TOO_MANY_BAGS]: {
    modal: ErrorModals.BAGGAGE_TOO_MANY_BAGS,
    modalName: 'BAGGAGE_TOO_MANY_BAGS',
  }
});

const fatalErrorModal = {
  modal: ErrorModals.FATAL_CONVEYOR,
  modalName: 'FATAL_CONVEYOR',
};

const conveyorRecoveryModal = {
  modal: ErrorModals.CONVEYOR_RECOVERY,
  modalName: 'CONVEYOR_RECOVERY',
};

const conveyorMultiBagTag = {
  modal: ErrorModals.CONVEYOR_MULTI_BAG_TAG,
  modalName: 'CONVEYOR_MULTI_BAG_TAG',
};

function getErrorModal(statusCode) {
  if (get(applicationFlow) === ApplicationFlow.HYBRID) {
    return;
  }
  
  const modal = StatusToErrorModal[statusCode];
  return modal !== undefined ? modal : fatalErrorModal;
}

function displayErrorModalForStatusCode(belt, statusCode) {
  // we do not want to show error modals in hybrid flow
  if (get(applicationFlow) === ApplicationFlow.HYBRID) {
    return;
  }

  const modal = getErrorModal(statusCode);
  displayErrorModal(errorReason(belt, statusCode), modal);
}

function logDisplayingErrorModal(reason, modalName) {
  const currentSBDState = get(sbdState);

  logger.warn(
    `${reason}. Displaying error modal (${modalName}).` +
      ` currentSBDState: '${currentSBDState}'.`,
  );
}

function displayErrorModal(reason, modalValue = fatalErrorModal) {
  if (get(applicationFlow) === ApplicationFlow.HYBRID) {
    return;
  }
  
  logDisplayingErrorModal(reason, modalValue.modalName);
  setErrorModal(modalValue.modal);
}

function errorReason(belt, statusCode, directive = null) {
  const scDetails = statusCodeDetails(statusCode);
  return directive === null
    ? `Conveyor (${belt}) gave statusCode: ${scDetails}`
    : `Directive (${belt}.${directive}) gave statusCode ${scDetails}`;
}

/**
 * Determine if an error modal is currently displayed or not.
 *
 * Transitions may be blocked if an error modal is displayed.
 */
function noErrorDisplayed() {
  return get(errorModal) === null;
}

function statusCodeDetails(statusCode) {
  if (statusCode === null) {
    return null;
  }

  const statusCodeMsg = getKeyByValue(ConveyorStatusCodes, statusCode);
  return `${statusCode}/${statusCodeMsg}`;
}

function withSBDState(currentSBDState, msg) {
  return `currentSBDState: '${currentSBDState}'. ${msg}`;
}

/**
 * Wraps cussSBD.callDirective().
 *
 * Logs the status code.
 *
 * @param {string} belt - Member of SBDBelts.
 * @param {string} directive - The CUSS directive.
 * @param {number[]|number} acceptedCodes - Array or sole accepted statuscode.
 * @param {Function} errorHandler - Called when executing directive ends in failure.
 * @returns {boolean} - Indicates if executing directive ends in success.
 */
function callDirective(
  belt,
  directive,
  acceptedCodes = null,
  errorHandler = null,
) {
  try {

    // beltDirective.set({
    //   belt: belt,
    //   directive: directive
    // });

    acceptedCodes = acceptedCodes || [ConveyorStatusCodes.OK];
    if (!Array.isArray(acceptedCodes)) {
      acceptedCodes = [acceptedCodes];
    }
    const cussSBD = getCussSBD();
    cussSBD.setBelt(belt);
    const statusCode = cussSBD.callDirective(directive);
    logger.debug(
      `Directive '${directive}' called on belt '${belt}'.` +
        ` Result statusCode: ${statusCodeDetails(statusCode)}.`,
    );

    if (!acceptedCodes.includes(statusCode)) {
      let isErrorEventHandled = false;
      if (errorHandler !== null) {
        isErrorEventHandled = errorHandler(belt, statusCode, directive);
      }

      // only call this runDefaultDirectiveError if its not HYBRID Flow
      if (!isErrorEventHandled && get(applicationFlow) !== ApplicationFlow.HYBRID) {
        runDefaultDirectiveErrorHandler(belt, statusCode, directive);
      }

      return false;
    }
    return true;
  } catch (e) {
    // handling exception - if running on debug console since no CUSS SBD is available
    return true;
  }
}

/**
 * Run the handlers one by one.
 * If a handler returns true, stop running the remaining handlers.
 *
 * @param {Function[]} handlers - Handlers to be run.
 * @param  {...any} handlerParams - Params to be passed on to the handlers.
 * @returns {boolean} - Indicates if one of the handlers has returned true.
 */
function runHandlers(handlers, ...handlerParams) {
  for (let i = 0; i < handlers.length; i++) {
    const result = handlers[i](...handlerParams);
    if (result) {
      return true;
    }
  }

  return false;
}

events.onMultiBagTag(() => {
  if (get(applicationFlow) === ApplicationFlow.HYBRID) {
    return;
  }

  const errorModalFunc = () => 
    displayErrorModal('Multiple bag tags detected', conveyorMultiBagTag);
  transitionTo_USER_RECOVERABLE_ERROR(null, null, errorModalFunc);
}, true);

events.onConveyorError(({ belt, statusCode }) => {
  if (get(applicationFlow) === ApplicationFlow.HYBRID) {
    return;
  }

  displayErrorModal(errorReason(belt, statusCode));
  flightdeck.fatalConveyorError();
}, true);

events.onConveyorEvent(({ belt, statusCode }) => {
  if (get(applicationFlow) === ApplicationFlow.HYBRID) {
    return;
  }  
  
  handleConveyorEvent(belt, statusCode);
}, true);

/** Handle CUSS Baggage_Weight_out_of_range . */
function handleEvent_BaggageTooManyBags(belt, statusCode) {
  if (get(applicationFlow) === ApplicationFlow.HYBRID) {
    return false; // tells that the event is not handled
  }
    
  if (CussStatusCodes.BAGGAGE_TOO_MANY_BAGS === statusCode) {    
    setErrorModal(ErrorModals.BAGGAGE_TOO_MANY_BAGS);
    flightdeck.tooManyBags(get(currentBagIndex));
    return true;
  }

  return false;
}


/** Handle CUSS Baggage_Weight_out_of_range . */
function handleEvent_BaggageWeightOutOfRange(belt, statusCode) {  
  if (get(applicationFlow) === ApplicationFlow.HYBRID) {
    return false; // tells that the event is not handled
  }
    
  if (CussStatusCodes.BAGGAGE_WEIGHT_OUT_OF_RANGE === statusCode) {
    const errorDescription =
      'A baggage weight out of range error has been received.' +
      ` (${statusCodeDetails(statusCode)})`;
    logger.info(errorDescription);
    setErrorModal(ErrorModals.BAG_WEIGHT_ERROR);
    return true;
  }

  return false;
}

/** Handle belts' events. */
function handleConveyorEvent(belt, statusCode) {
  // i think we need to add bag_weight_error handler here
  const eventHandlers = [
    handleEvent_BaggageTooManyBags,
    handleEvent_BaggageWeightOutOfRange,
    handleEvent_InUserRecoverableErrorState,
    handleEvent_InInterferenceUserState,
    handleEvent_InRecoveryState,
    handleEvent_InBaggageRestlessState,
    handleEvent_BaggageNotConveyable,
    handleEvent_BaggageInterferenceUser,
    handleEvent_BaggageRestless,
    handleEvent_DimensionError,
  ];

  const isHandled = runHandlers(eventHandlers, belt, statusCode);
  if (isHandled) {
    return;
  }

  const nonErrorStatusCodes = [
    ConveyorStatusCodes.BAGGAGE_FULL,
    ConveyorStatusCodes.BAGGAGE_PRESENT,
    ConveyorStatusCodes.BAGGAGE_ABSENT,
    ConveyorStatusCodes.BAGGAGE_ACCEPTED,
    ConveyorStatusCodes.BAGGAGE_DELIVERED,
    ConveyorStatusCodes.BAGGAGE_TRANSPORT_BUSY,
    ConveyorStatusCodes.OK,
  ];

  if (nonErrorStatusCodes.includes(statusCode)) {
    logger.warn(
      `Received ${statusCodeDetails(statusCode)} from ${belt}.` +
        ' This event does not require further processing.',
    );
    return;
  }

  // we do not want to emit conveyor errors in hybrid flow
  if (get(applicationFlow) === ApplicationFlow.HYBRID) {
    return;
  } 

  events.emitConveyorError(belt, statusCode);
}

/**
 * Run default handling logic when a result of a directive call
 * has been unhandled.
 */
function runDefaultDirectiveErrorHandler(belt, statusCode, directive) {
  logger.info(    'Use handleConveyorEvent logic for' +
      ` result ${statusCodeDetails(statusCode)} of '${belt}.${directive}'.`,
  );
  handleConveyorEvent(belt, statusCode);
}

/** Handle BAGGAGE_NOT_CONVEYABLE event. */
function handleEvent_BaggageNotConveyable(belt, statusCode) {
  if (
    belt === SBDBelts.INSERTION &&
    statusCode === ConveyorStatusCodes.BAGGAGE_NOT_CONVEYABLE
  ) {
    logger.warn(
      'Event handling not required for InsertionBelt BAGGAGE_NOT_CONVEYABLE. ' +
        'Caller of InsertionBelt.forward() will handle instead.',
    );
    return true;
  }

  return false;
}

function handleFlightDeckCancelled(flightdeckAction) {
  endTransaction();
  return true;
}

function handleFlightDeckOverride(flightdeckAction) {
  transitionTo_BEFORE();
  return true;
}

function transitionTo_NOT_CONVEYABLE_PENDING_BAG_TAG(belt, statusCode) {
  transitionLog('To_NOT_CONVEYABLE_PENDING_BAG_TAG');

  let noBagTagTimeoutId = null;

  const unsubscribeCurrentBag = currentBag.subscribe(($currentBag) => {
    // we do not want to check Dimensions in hybrid flow
    if (get(applicationFlow) === ApplicationFlow.HYBRID) {
      return;
    }

    if ($currentBag.bagTagID) {
      clearTimeout(noBagTagTimeoutId);
      transitionTo_NOT_CONVEYABLE_HAVE_BAG_TAG(belt, statusCode);
    }
  });

  noBagTagTimeoutId = setTimeout(() => {
    unsubscribeCurrentBag();

    if (get(currentBag).bagTagID) {
      logger.debug(
        'noBagTag timer has expired but current bag has bagTagID.' +
          ' Do not transition to NOT_CONVEYABLE_NO_BAG_TAG.',
      );
      return;
    }
    transitionTo_NOT_CONVEYABLE_NO_BAG_TAG(belt, statusCode);
  }, BAG_TAG_WAIT);
}

function transitionTo_NOT_CONVEYABLE_HAVE_BAG_TAG(belt, statusCode) {
  // we do not want to check Dimensions in hybrid flow
  if (get(applicationFlow) === ApplicationFlow.HYBRID) {
    return;
  }
  
  transitionLog('To_NOT_CONVEYABLE_HAVE_BAG_TAG');

  const beforeTransitionFunc = () => {
    // we do not want to check Dimensions in hybrid flow
    if (get(applicationFlow) === ApplicationFlow.HYBRID) {
      return;
    }

    displayErrorModal(
      errorReason(belt, statusCode),
      getErrorModal(ConveyorStatusCodes.BAGGAGE_NOT_CONVEYABLE),
    );
    flightdeck.bagNotConveyable(get(currentBag), get(currentBagIndex));
  };

  // we do not want to check Dimensions in hybrid flow
  if (get(applicationFlow) === ApplicationFlow.HYBRID) {
    return;
  }

  transitionTo_USER_RECOVERABLE_ERROR(belt, statusCode, beforeTransitionFunc);
}

/**
 * @param {string} belt - The belt triggering this transition.
 * Use null if the transition is not triggered by a belt component.
 * @param {number} statusCode - The status code of the belt.
 * Use null if the transition is not triggered by a belt component.
 * @param {Function} flightDeckFunc - The function to notify flight deck of the error.
 * Use null if the default not-readable notification should be used.
 */
function transitionTo_NOT_CONVEYABLE_NO_BAG_TAG(
  belt,
  statusCode,
  flightDeckFunc = null,
) {
  transitionLog('To_NOT_CONVEYABLE_NO_BAG_TAG');

  let conveyorNotReadableErrorModal;

  bagTagRetryCount++;

  logger.info(
    `bagTagRetryCount: ${bagTagRetryCount}/${BAG_TAG_RETRY_MAXIMUM}`,
  );

  conveyorNotReadableErrorModal = new CustomErrorModal(
    ErrorModals.CONVEYOR_NOT_READABLE,
  );

  if (flightDeckFunc !== null) {
    flightDeckFunc();
  } else {
    flightdeck.conveyorNotReadable();
  }
  
  logger.warn(
    'Displaying error modal CONVEYOR_NOT_READABLE.' +
      ` currentSBDState: '${get(sbdState)}'.`,
  );
  conveyorNotReadableErrorModal.open();
  transitionTo_USER_RECOVERABLE_ERROR(belt, statusCode);
}

/** Handle events when current state is USER_RECOVERABLE_ERROR. */
function handleEvent_InUserRecoverableErrorState(belt, statusCode) {
  const currentSBDState = get(sbdState);

  if (currentSBDState === SBDStates.USER_RECOVERABLE_ERROR) {
    if (
      hasTriedOfferBag &&
      belt === SBDBelts.INSERTION &&
      statusCode === ConveyorStatusCodes.BAGGAGE_ABSENT
    ) {
      logger.info(
        withSBDState(
          currentSBDState,
          `Process ${statusCodeDetails(statusCode)} received from ${belt}.`,
        ),
      );
      transitionTo_BEFORE();
      return true;
    }

    if (
      belt === SBDBelts.INSERTION &&
      statusCode === ConveyorStatusCodes.BAGGAGE_PRESENT
    ) {
      logger.info(
        withSBDState(
          currentSBDState,
          `Process ${statusCodeDetails(statusCode)} received from ${belt}.` +
            ' Try to offer bag.',
        ),
      );
      offerBag();

      return true;
    }
  }

  return false;
}

/**
 * Transition from BEFORE to WAITING.
 *
 * - Calls enable() on the InsertionBelt.
 * - Immediately transitions to WAITING.
 */
function transition_BEFORE__WAITING() {
  transitionLog('BEFORE__WAITING');
  resetState();

  // for hybrid flow we need to add a delay of 1 before calling insertion enable
  if (get(applicationFlow) === ApplicationFlow.HYBRID) {
      setTimeout(() => {
        if (callDirective(SBDBelts.INSERTION, Directives.ENABLE, [
          ConveyorStatusCodes.BAGGAGE_ABSENT,
          ConveyorStatusCodes.BAGGAGE_PRESENT,
        ])
      ) {
        sbdState.set(SBDStates.WAITING);
      }
    }, 500)
  } else {  
    if (callDirective(SBDBelts.INSERTION, Directives.ENABLE, [
        ConveyorStatusCodes.BAGGAGE_ABSENT,
        ConveyorStatusCodes.BAGGAGE_PRESENT,
      ])
    ) {
      sbdState.set(SBDStates.WAITING);
    }
  }
}

/** Whether the prerequisite for HAVE_BAG_TAG has been confirmed. */
const confirmed_HAVE_WEIGHT = derived(
  [sbdPausedPendingExcessBaggage, currentBag],
  ([$sbdPausedPendingExcessBaggage, $currentBag]) => ({
    bag: $currentBag,
    isPaused: $sbdPausedPendingExcessBaggage,
  }),
);

/**
 * Listen for weight to be settled (ie. final weight received)
 *
 * When weight is settled transition from WAITING to HAVE_WEIGHT
 * if we are currently in the WAITING state.
 */
confirmed_HAVE_WEIGHT.subscribe(({ bag, isPaused }) => {
  if (
    get(sbdState) === SBDStates.WAITING &&
    bag.settled &&
    bag.weight &&
    !isPaused
  ) {
    transition_WAITING__HAVE_WEIGHT();
  }
});

/**
 * Transition from WAITING to HAVE_WEIGHT.
 *
 * - Call disable() on the InsertionBelt.
 * - Check BAGGAGE_PRESENT upon disable().
 * - Transition immediately to HAVE_BAG_TAG.
 */
function transition_WAITING__HAVE_WEIGHT() {
  transitionLog('WAITING__HAVE_WEIGHT');
  if (
    callDirective(
      SBDBelts.INSERTION,
      Directives.DISABLE,
      ConveyorStatusCodes.BAGGAGE_PRESENT,
    )
  ) {
    sbdState.set(SBDStates.HAVE_WEIGHT);
    transition_HAVE_WEIGHT__HAVE_BAG_TAG();
  }
}

/**
 * Transition from HAVE_WEIGHT to HAVE_BAG_TAG.
 *
 * - Call forward() on Insertion belt (after delay).
 * - Transition occurs following confirmed_HAVE_BAG_TAG.
 */
function transition_HAVE_WEIGHT__HAVE_BAG_TAG() {
  transitionLog('HAVE_WEIGHT__HAVE_BAG_TAG');
  sleep(FORWARD_DELAY).then(() => {
    const forwardErrorHandler = (belt, statusCode, directive) => {
      if (
        [
          ConveyorStatusCodes.BAGGAGE_NOT_CONVEYABLE,
          ConveyorStatusCodes.CANCELLED,
          ConveyorStatusCodes.TIMEOUT,
        ].includes(statusCode)
      )  {
        logger.warn(
          'Use NOT_CONVEYABLE_PENDING_BAG_TAG logic for' +
            ` result ${statusCodeDetails(
              statusCode,
            )} of '${belt}.${directive}'.`,
        );
        transitionTo_NOT_CONVEYABLE_PENDING_BAG_TAG(belt, statusCode);
        return true;
      }

      return false;
    };

    if (
      callDirective(
        SBDBelts.INSERTION,
        Directives.FORWARD,
        ConveyorStatusCodes.OK,
        forwardErrorHandler,
      )
    ) {
      logger.info('Transition pending bag tag detection.');
    }
  });
}

/**
 * Transition to USER_RECOVERABLE_ERROR state.
 * @param {string} belt - The belt triggering this transition.
 * Use null if the transition is not triggered by a belt component.
 * @param {number} statusCode - The status code of the belt.
 * Use null if the transition is not triggered by a belt component.
 * @param {Function} beforeTransitionFunc - The function to be run before
 * state's transition (e.g. displaying suitable error modal).
 * Use null if running a function is not needed.
 */
function transitionTo_USER_RECOVERABLE_ERROR(
  belt = null,
  statusCode = null,
  beforeTransitionFunc = null,
) {
  const currentSBDState = get(sbdState);

  if (currentSBDState === SBDStates.USER_RECOVERABLE_ERROR) {
    logger.info(
      withSBDState(
        currentSBDState,
        `Do not transition to '${SBDStates.USER_RECOVERABLE_ERROR}'` +
          ` because currentSBDState is already '${SBDStates.USER_RECOVERABLE_ERROR}'.` +
          ` belt: ${belt}, statusCode: ${statusCodeDetails(statusCode)}.`,
      ),
    );
    return;
  }

  transitionLog('To_USER_RECOVERABLE_ERROR');
  if (belt !== null && statusCode !== null) {
    lastError = { belt, statusCode };
  }

  if (beforeTransitionFunc !== null) {
    beforeTransitionFunc();
  }

  sbdState.set(SBDStates.USER_RECOVERABLE_ERROR);
  tryReturnBag();
}

/** Try to return bag. */
function tryReturnBag() {
  logger.info('Try to return bag.');

  const returnBagHandlers = new QueryHandlers({
    [SBDBelts.INSERTION]: offerBag,
    [SBDBelts.VERIFICATION]: tryMoveBagToInsertionBelt,
  });

  tryRecoveryFlow(returnBagHandlers);
}

/** Transition to BEFORE state. */
function transitionTo_BEFORE() {
  transitionLog('To_BEFORE');
  errorModal.reset();
  resetState();
  resetCurrentBag();
  sbdState.set(SBDStates.BEFORE);
  push('/place-passenger-bag');
}

/** Whether the prerequisite for HAVE_BAG_TAG has been confirmed. */
const confirmed_HAVE_BAG_TAG = derived(
  [lastBagLocation, currentBag],
  ([$lastBagLocation, $currentBag]) =>
    $lastBagLocation === BagLocations.VERIFICATION &&
    noErrorDisplayed() &&
    $currentBag.bagTagID,
);

/** Wait for HAVE_BAG_TAG prerequisite and enter HAVE_BAG_TAG. */
confirmed_HAVE_BAG_TAG.subscribe((isConfirmed) => {
  if (isConfirmed) {
    logger.info('Confirmed we have bag tag.');
    sbdState.set(SBDStates.HAVE_BAG_TAG);
    transition_HAVE_BAG_TAG__HAVE_DIMENSIONS();
  }
});

/**
 * Transition from HAVE_BAG_TAG to HAVE_DIMENIONS
 *
 * - Calls process() on the VerificationBelt.
 * - Transition occurs following confirmed_HAVE_DIMENSIONS.
 */
function transition_HAVE_BAG_TAG__HAVE_DIMENSIONS() {
  transitionLog('HAVE_BAG_TAG__HAVE_DIMENSIONS');
  if (
    callDirective(
      SBDBelts.VERIFICATION,
      Directives.PROCESS,
      ConveyorStatusCodes.BAGGAGE_PRESENT,
    )
  ) {
    logger.info('Transition pending bag dimensions.');
  }
}

/** Whether the prerequisite for HAVE_DIMENSIONS has been confirmed. */
const confirmed_HAVE_DIMENSIONS = derived(
  [lastBagLocation, currentBag],
  ([$lastBagLocation, $currentBag]) => {

    const isReadyForInjectionWithPaymentFlowRule =
      currentBag.isReadyForInjection(true);

    if ($lastBagLocation) {
      logger.info(
        'confirming dimensions:' +
          ` $lastBagLocation: ${$lastBagLocation};` +
          ` length: ${$currentBag && $currentBag.length};` +
          ` width: ${$currentBag && $currentBag.width};` +
          ` height: ${$currentBag && $currentBag.height};` +
          ' isReadyForInjectionWithPaymentFlowRule:' +
          ` ${isReadyForInjectionWithPaymentFlowRule}.`,
      );
    } else {
      logger.info('inside  else of if ($lastBagLocation)')
    }

    return (
      $lastBagLocation === BagLocations.VERIFICATION &&       
      noErrorDisplayed() &&
      isReadyForInjectionWithPaymentFlowRule
    );
  },
);

/** Wait for HAVE_DIMENSIONS prerequisite and enter HAVE_DIMENSIONS. */
confirmed_HAVE_DIMENSIONS.subscribe((isConfirmed) => {
  if (isConfirmed) {
    logger.info('Confirmed we have dimensions.');
    sbdState.set(SBDStates.HAVE_DIMENSIONS);
    transition_HAVE_DIMENSIONS__READY_TO_INJECT();
  } else {
    logger.info('Inside Confirmed is false.');
  }
});

/**
 * Transition from HAVE_DIMENIONS to READY_TO_INJECT.
 *
 * - Calls forward() on VerificationBelt (after delay).
 * - If statuCode is not OK
 *   - Display fatal error modal and end transaction.
 * - Immediately transition to READY_TO_INJECT.
 */
function transition_HAVE_DIMENSIONS__READY_TO_INJECT() {
  transitionLog('HAVE_DIMENSIONS__READY_TO_INJECT');
  const cussSBD = getCussSBD();
  
  cussSBD.setBelt(SBDBelts.VERIFICATION);

  sleep(FORWARD_DELAY).then(() => {
    if (
      callDirective(
        SBDBelts.VERIFICATION,
        Directives.FORWARD,
        ConveyorStatusCodes.OK,
      )
    ) {
      sbdState.set(SBDStates.READY_TO_INJECT);
    }
  });
}

/** Wait for bag on ParkingBelt and transition to READY_TO_INJECT. */
lastBagLocation.subscribe((bagLocation) => {
  if (
    get(sbdState) === SBDStates.READY_TO_INJECT &&
    noErrorDisplayed() &&
    bagLocation === BagLocations.PARKING
  ) {
    logger.info('Calling transition_READY_TO_INJECT__TAG_ACTIVATED method.');
    transition_READY_TO_INJECT__TAG_ACTIVATED();
  }
});

export function getTotalWeightAllowed() {
  let totalWeightAllowed = 0;
  if (booking.totalAllowanceByType(AllowanceType.EMD)) {
    totalWeightAllowed =
      totalWeightAllowed + booking.totalAllowanceByType(AllowanceType.EMD);
  }

  if (booking.totalAllowanceByType(AllowanceType.VCR)) {
    totalWeightAllowed =
      totalWeightAllowed + booking.totalAllowanceByType(AllowanceType.VCR);
  }

  if (booking.totalAllowanceByType(AllowanceType.PGCU)) {
    totalWeightAllowed =
      totalWeightAllowed + booking.totalAllowanceByType(AllowanceType.PGCU);
  }

  if (booking.totalAllowanceByType(AllowanceType.FF)) {
    totalWeightAllowed =
      totalWeightAllowed + booking.totalAllowanceByType(AllowanceType.FF);
  }

  return totalWeightAllowed;
}

/**
 * Returns all product details for the booking.
 *
 * @param {*} segments
 * @returns
 */
export function getAllProductDetails() {
  let productDetailsList = [];
  const currentBooking = get(booking);

  currentBooking.passengers.forEach((passenger) => {
    passenger.segments.forEach((segment) => {
      let productDetails = {
        productFlightDetails: {
          marketingCarrier: segment.airlineCode,
          flightNumber: segment.flightNumber,
          departureDate: segment.departureDateTime,
          boardPoint: segment.departureCode,
          offPoint: segment.arrivalCode,
        },
        productPrimeId: segment.passengerDID,
      };
      productDetailsList.push(productDetails);
    });
  });

  return productDetailsList;
}

function getCurrentBagTag() {
  const bag = get(currentBag);    
  logger.info('Getting bag tag Number from bag object');
  let tagNumber;
  if (bag && bag.bagTagID) {    
    if (bag.bagTagID.length > 6 && bag.bagTagID.length === 10) {
      tagNumber = bag.bagTagID.substring(4,10);
    } else if (bag.bagTagID.length > 0 && bag.bagTagID.length === 6) {
      tagNumber = bag.bagTagID;
    }    
  }
  logger.info(`bag tag Number is: ${tagNumber}`);
  return tagNumber;
}


function handleBagTagNotFound() {
  logger.info('Handling Bag tag not found. Calling transitionTo_NOT_CONVEYABLE_NO_BAG_TAG');
  transitionTo_NOT_CONVEYABLE_NO_BAG_TAG(
    null, 
    null, 
    () => flightdeck.incorrectBagTagNumber(get(currentBag), get(currentBagIndex))
  );
}
/**
 * Transition from READY_TO_INJECT to TAG_ACTIVATED.
 * - Activate bag tag.
 * - Run necessary logic when tag activation completes.
 */
export function transition_READY_TO_INJECT__TAG_ACTIVATED() {
  if (readyToInjectToTagActivatedInProgress) {
    logger.info(
      'There is already an in-progress transition' +
        ' READY_TO_INJECT__TAG_ACTIVATED.' +
        ' No need to start the second one.',
    );
    return;
  }

  transitionLog('READY_TO_INJECT__TAG_ACTIVATED');
  readyToInjectToTagActivatedInProgress = true;

  logger.info('Bag on Parking Belt. Request activateBagTag.');
  
  let tagNumber = getCurrentBagTag();

  if (!tagNumber) {
    logger.info('Bag Tag Number not found. Calling handleBagTagNotFound');
    handleBagTagNotFound();
    return;
  }

  const totalWeight = getTotalWeightAllowed();
  const productsList = getAllProductDetails();
  const currentBagWeight = currentBag.getBagWeight();      
  const bagTagObject = currentPassenger.getBagTagObject(tagNumber); 
  // This bag tag activation is called , when we are in SELF_SERVICE/EMBROSS flow
  // at this point the bag is on the 3rd belt and payment (if required) is already
  // taken so we can activate bag tag straight away
  const activateBagTag = true;

  // check if bagTagObject has values
  if (!bagTagObject || // is undefined
      Object.keys(bagTagObject).length === 0) { // is empty object
    logger.info('Bag tag object is empty. Calling handleBagTagNotFound')
    handleBagTagNotFound();
    return;
  }  

  currentBag
    .activate(totalWeight, productsList, currentBagWeight, bagTagObject, activateBagTag)
    .then((isTagActivated) => {
      if (isTagActivated == 'retry') {
        serviceRetryCount++;        
        if (serviceRetryCount < 3) {
          readyToInjectToTagActivatedInProgress = false; 
          transition_READY_TO_INJECT__TAG_ACTIVATED();
          return;
        } else {
          logger.info('Maximum retry attempts reached. setting isTagActivated to false to let the failure flow continue');
          isTagActivated = 'false';
        }
    }
      
      if (get(currentBag).isAllowableExtra === true) {
        logger.info('inside sbdState... currentBag.activate isAllowableExtra is true');
        const bookingSearchQuery = {};
        bookingSearchQuery.pnr = get(booking).bookingReference;
        switchboardClient
          .bookingSearch(bookingSearchQuery)
          .then((response) => {                  
            switchboardClient.overrideExcessBaggage({
              calculationId: response?.data?.searchBooking?.excessBaggageId,
              baseStation: baseStationCode,
              transactionId: get(transactionId)
            }).then((overrideExcessResponse) => {
              const errorMessages = overrideExcessResponse?.data?.overrideExcessBaggage?.errorMessages
              if (errorMessages && errorMessages?.length) {
                logger.error('Errors received from overrideExcessBaggage api.');
                errorMessages.forEach((error) => {
                  logger.error(error);
                });
              }
            }).catch((error) => {
              flightdeck.overrideExcessBagPieceFailed();
              setErrorModal(ErrorModals.EXCESS_PIECE_AGENT_OVERRIDE_FAILED);
              return;
            });
          }).catch((error) => {
            flightdeck.searchBookingAPIForXCI();
            setErrorModal(ErrorModals.EXCESS_PIECE_AGENT_OVERRIDE_FAILED);
            return;
          });
      }
      const currentSBDState = get(sbdState);
      if (currentSBDState !== SBDStates.READY_TO_INJECT) {
        logger.info(
          withSBDState(
            currentSBDState,
            'Tag activation has completed.' +
              ` sbdState is no longer '${SBDStates.READY_TO_INJECT}'.` +
              ' Do not run remaining logic of' +
              ' transition READY_TO_INJECT__TAG_ACTIVATED.',
          ),
        );
        return;
      }

      runTagActivationCompleted(isTagActivated == 'true');
    })
    .finally(() => {
      readyToInjectToTagActivatedInProgress = false;
    });
}

/**
 * - If activating bag tag succeeds, immediately go to TAG_ACTIVATED state,
 *   then transition to INJECTED.
 * - If activating fails, display tag activation error.
 */
export function runTagActivationCompleted(isTagActivated) {
  if (!isTagActivated) {
    flightdeck.activateBagTagFailed();
    setErrorModal(ErrorModals.BAG_TAG_ACTIVATION_ERROR);
    return;
  }

  logger.info('Tag activated successfully. Proceed to injection.');
  sbdState.set(SBDStates.TAG_ACTIVATED);
  readyToInjectToTagActivatedInProgress = false;
  transition_TAG_ACTIVATED__INJECTED();
}

/**
 * Transition from TAG_ACTIVATED to INJECTED.
 * - Call forward() on ParkingBelt following delay.
 * - If statusCode is not BAGGAGE_ACCEPTED
 *   - Display fatal error and end transaction.
 * - Proceed immediately to INJECTED.
 */
export function transition_TAG_ACTIVATED__INJECTED() {
  if (tagActivatedToInjectedInProgress) {
    logger.info(
      'There is already an in-progress transition' +
        ' TAG_ACTIVATED__INJECTED.' +
        ' No need to start the second one.',
    );
    return;
  }

  transitionLog('TAG_ACTIVATED__INJECTED');
  tagActivatedToInjectedInProgress = true;
  sleep(FORWARD_DELAY)
    .then(() => {
      const currentSBDState = get(sbdState);
      if (currentSBDState !== SBDStates.TAG_ACTIVATED) {
        logger.info(
          withSBDState(
            currentSBDState,
            ` sbdState is no longer '${SBDStates.TAG_ACTIVATED}'.` +
              ' Do not run remaining logic of' +
              ' transition TAG_ACTIVATED__INJECTED.',
          ),
        );
        return;
      }
      
      if (
        callDirective(
          SBDBelts.PARKING,
          Directives.FORWARD,
          ConveyorStatusCodes.BAGGAGE_ACCEPTED,
        )
      ) {
        sbdState.set(SBDStates.INJECTED);
        bagTagRetryCount = 0;
        logger.info('Bag injected, setting bagTagRetryCount to 0');

        // This is printing bag tags one after the other
        // logger.info('Now marking bagInjected to true');
        // bagInjected.set(true);
      }
    })
    .finally(() => {
      tagActivatedToInjectedInProgress = false;
    });
}

/**
 * Handle a belt event when sbdState is in a specific errorState.
 */
function handleEvent_InErrorState(
  belt,
  statusCode,
  errorState,
  alllowedLastErrorStatusCode,
) {
  const currentSBDState = get(sbdState);
  if (currentSBDState !== errorState) {
    return false;
  }

  const goodStatusCodes = [
    ConveyorStatusCodes.BAGGAGE_FULL,
    ConveyorStatusCodes.BAGGAGE_PRESENT,
    ConveyorStatusCodes.BAGGAGE_ABSENT,
    ConveyorStatusCodes.OK,
  ];

  if (lastError === null) {
    logger.error(`lastError is null when state is '${currentSBDState}'.`);
    return false;
  }

  logger.debug(
    `handleEvent_InErrorState (specific state: '${errorState}') -` +
      ` lastError: ${JSON.stringify(lastError)}` +
      ' , alllowedLastErrorStatusCode:' +
      ` ${statusCodeDetails(alllowedLastErrorStatusCode)}`,
  );

  if (
    lastError.statusCode === alllowedLastErrorStatusCode &&
    belt === lastError.belt &&
    goodStatusCodes.includes(statusCode)
  ) {
    // 1 - 02/09/2024 - come here
    logger.debug(
      `handleEvent_InErrorState (specific state: '${errorState}') -` +
        ` Received ${statusCodeDetails(statusCode)} from '${belt}'.` +
        ` Transition to '${SBDStates.RECOVERY}' state.`,
    );

    // the interference may have been caused due to pax placing 2nd bag, while first bag is still on verification / parking belt
    // check if there is a bag on verification belt or parking belt 
    // if both verification belt and parking belt return absent, continue with transitionTo_RECOVERY();
    // if any of the two belts also return BAGGAGE_PRESENT then work accordingly 
    transitionTo_RECOVERY();
    logger.info('After transition to recovery method, returning true');
    return true;
  }

  logger.info('Returning false from handleEvent_InErrorState method');
  return false;
}

/** Handle events when current state is INTERFERENCE_USER. */
function handleEvent_InInterferenceUserState(belt, statusCode) {
  return handleEvent_InErrorState(
    belt,
    statusCode,
    SBDStates.INTERFERENCE_USER,
    ConveyorStatusCodes.BAGGAGE_INTERFERENCE_USER,
  );
}

/** Handle events when current state is BAGGAGE_RESTLESS. */
function handleEvent_InBaggageRestlessState(belt, statusCode) {
  return handleEvent_InErrorState(
    belt,
    statusCode,
    SBDStates.BAGGAGE_RESTLESS,
    ConveyorStatusCodes.BAGGAGE_RESTLESS,
  );
}

/**
 * Offer the bag back to the passenger.
 * Precondition: the bag is present at Insertion belt.
 */
function offerBag() {
  const isOfferSuccess = callDirective(SBDBelts.INSERTION, Directives.OFFER, [
    ConveyorStatusCodes.OK,
    ConveyorStatusCodes.BAGGAGE_PRESENT,
  ]);

  if (!isOfferSuccess) {
    logger.warn(
      `Error occurred in ${SBDBelts.INSERTION}.${Directives.OFFER}.`,
    );
  }

  hasTriedOfferBag = true;
}

/** Try to move bag from Verification belt to Insertion belt. */
function tryMoveBagToInsertionBelt() {
  logger.info('tryMoveBagToInsertionBelt');
  callDirective(SBDBelts.VERIFICATION, Directives.BACKWARD, [
      ConveyorStatusCodes.OK,
      ConveyorStatusCodes.BAGGAGE_PRESENT
  ]);
}

/** Transition to WAITING state. */
function transitionTo_WAITING() {
  transitionLog('To_WAITING');
  resetCurrentBag();
  errorModal.reset();
  sbdState.set(SBDStates.WAITING);

  if (get(applicationFlow) === ApplicationFlow.HYBRID) {
    setTimeout(() => {
      callDirective(
        SBDBelts.INSERTION,
        Directives.ENABLE,
        ConveyorStatusCodes.BAGGAGE_PRESENT,
      );
    }, 500)
  } else {
    callDirective(
      SBDBelts.INSERTION,
      Directives.ENABLE,
      ConveyorStatusCodes.BAGGAGE_PRESENT,
    );
  }
}

/** Transition to RECOVERY state. */
function transitionTo_RECOVERY() {
  transitionLog('To_RECOVERY');
  lastError = null;
  errorModal.reset();
  sbdState.set(SBDStates.RECOVERY);
  displayErrorModal('Recovery is in progress', conveyorRecoveryModal);
  tryResumeTransaction();
}

/** Handle events when current state is RECOVERY. */
// for Hybrid flow, for now we will allow recovery to be as it is...
// will check after testing if we need any changes to this method.
function handleEvent_InRecoveryState(belt, statusCode) {
  const currentSBDState = get(sbdState);

  if (
    currentSBDState === SBDStates.RECOVERY &&
    belt === SBDBelts.INSERTION &&
    statusCode === ConveyorStatusCodes.BAGGAGE_PRESENT
  ) {
    logger.info(
      withSBDState(
        currentSBDState,
        `Process ${statusCodeDetails(statusCode)} received from ${belt}.` +
          ` Bag has returned to ${SBDBelts.INSERTION}. Try to resume transaction.`,
      ),
    );
    transitionTo_WAITING();

    return true;
  }

  return false;
}

/** Try to resume transaction. */
// 2 - 02/09/2024 - Come here
function tryResumeTransaction() {
  const resumeTransactionHandlers = new QueryHandlers({
    [SBDBelts.INSERTION]: transitionTo_WAITING,
    [SBDBelts.VERIFICATION]: tryMoveBagToInsertionBelt, // recoveryFromVerificationBelt
    [SBDBelts.PARKING]: resumeTransactionWhenBagAtParkingBelt
  });

  tryRecoveryFlow(resumeTransactionHandlers);
}

/**
 * Try to resume transaction after it has been determined that the bag is
 * at Parking belt.
 */
export function resumeTransactionWhenBagAtParkingBelt() {
  const currentSBDState = get(sbdState);
  if (currentSBDState !== SBDStates.RECOVERY) {
    logger.info(
      withSBDState(
        currentSBDState,
        `sbdState is no longer '${SBDStates.RECOVERY}'.` +
          ' Do not continue to resume transaction.',
      ),
    );
    return;
  }

  if (get(currentBag).bagTagActivated) {
    transitionLog('To_TAG_ACTIVATED');
    errorModal.reset();
    sbdState.set(SBDStates.TAG_ACTIVATED);
    transition_TAG_ACTIVATED__INJECTED();
  } else {
    transitionLog('To_READY_TO_INJECT');
    errorModal.reset();
    sbdState.set(SBDStates.READY_TO_INJECT);

    if (get(lastBagLocation) === BagLocations.PARKING) {
      logger.debug(
        'resumeTransactionWhenBagAtParkingBelt - Proceed to tag activation.' +
          ' Start transition READY_TO_INJECT__TAG_ACTIVATED.',
      );
      transition_READY_TO_INJECT__TAG_ACTIVATED();
    } else {
      logger.debug(
        'resumeTransactionWhenBagAtParkingBelt - Proceed to tag activation.' +
          ` Set lastBagLocation to ${BagLocations.PARKING} to trigger` +
          ' lastBagLocation-subscription.',
      );
      lastBagLocation.set(BagLocations.PARKING);
    }
  }
}

/**
 * Try recovery flow with the provided handlers.
 * @param {QueryHandlers} queryHandlers - The handlers to be run.
 */
function tryRecoveryFlow(queryHandlers) {
  const currentSBDState = get(sbdState);

  // 4 - 02/09/2024 - come here
  logger.info(`tryRecoveryFlow (specific state: '${currentSBDState}').`);
  // 3 - 02/09/2024 - come here
  const isHandled = runHandlers(queryHandlers.getHandlers());
  if (isHandled) {
    return;
  }

  displayErrorModal('Can not recover from the current belt status results');
}

/** Handle BAGGAGE_INTERFERENCE_USER event. */
function handleEvent_BaggageInterferenceUser(belt, statusCode) {
  return handleEvent_ErrorCase(
    belt,
    statusCode,
    ConveyorStatusCodes.BAGGAGE_INTERFERENCE_USER,
    SBDStates.INTERFERENCE_USER,
  );
}

/** Handle BAGGAGE_RESTLESS event. */
function handleEvent_BaggageRestless(belt, statusCode) {
  return handleEvent_ErrorCase(
    belt,
    statusCode,
    ConveyorStatusCodes.BAGGAGE_RESTLESS,
    SBDStates.BAGGAGE_RESTLESS,
  );
}

/** Handle CUSS dimension-error and other similar CUSS error events. */
function handleEvent_DimensionError(belt, statusCode) {
  // We do not need to capture dimensions in HYBRID, so ignoring this error 
  if (get(applicationFlow) === ApplicationFlow.HYBRID) {
    return false; // tells that the event is not handled
  }
  
  const dimensionErrorStatusCodes = [
    ConveyorStatusCodes.BAGGAGE_OVERSIZED,
    ConveyorStatusCodes.BAGGAGE_TOO_HIGH,
    ConveyorStatusCodes.BAGGAGE_TOO_LONG,
    ConveyorStatusCodes.BAGGAGE_TOO_SHORT,
    ConveyorStatusCodes.BAGGAGE_TOO_FLAT,
  ];

  if (dimensionErrorStatusCodes.includes(statusCode)) {
    const errorDescription =
      'A bag dimension error was detected by CUSS platform' +
      ` (${statusCodeDetails(statusCode)})`;
    const flightDeckFunc = () => {
      flightdeck.cussDimensionError(
        get(currentBag),
        get(currentBagIndex),
        errorDescription,
      );
    };

    handleDimensionError(belt, statusCode, flightDeckFunc);

    return true;
  }

  return false;
}

/**
 * Handle a belt event for a specific errorCase.
 * When the event matches the errorCase, transition to the errorState.
 */
function handleEvent_ErrorCase(belt, statusCode, errorCase, errorState) {
  // we will try not to put the sbd in errorState for hybrid. so Returning straight away
  if (get(applicationFlow) === ApplicationFlow.HYBRID) {
    return false;
  }

  if (statusCode !== errorCase) {
    return false;
  }

  transitionTo_ErrorState(belt, statusCode, errorState);
  return true;
}

function transitionTo_ErrorState(belt, statusCode, errorState) {

  // we will try not to put the sbd in errorState for hybrid. so Returning straight away
  if (get(applicationFlow) === ApplicationFlow.HYBRID) {
    return;
  }

  const currentSBDState = get(sbdState);

  if (currentSBDState === errorState) {
    logger.info(
      withSBDState(
        currentSBDState,
        `Do not process ${statusCodeDetails(
          statusCode,
        )} received from ${belt}` +
          ` because currentSBDState is already '${errorState}'.`,
      ),
    );
    return;
  }

  transitionLog(`To state '${errorState}'`);
  lastError = { belt, statusCode };
  displayErrorModalForStatusCode(belt, statusCode);
  sbdState.set(errorState);
  logger.debug(
    `currentSBDState: ${get(sbdState)}. lastError: ${JSON.stringify(
      lastError,
    )}`,
  );
}

/** Reset state-related variables to initial values. */
function resetState() {
  hasTriedOfferBag = false;
  lastError = null;
  readyToInjectToTagActivatedInProgress = false;
  tagActivatedToInjectedInProgress = false;
}

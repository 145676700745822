const RECORD_IN_USE_ERROR_CODE = 17336;
const RECORD_IN_USE_ERROR_MESSAGE = 'record in use';
/**
 * Logic for ending a transaction.
 * @param {string} hasRecordInUseError - A string which tells the reason for end of transaction
 */
export default function hasRecordInUseError(errorMessages) {
  let error = errorMessages.find(e => 
    e?.code === RECORD_IN_USE_ERROR_CODE || 
    e?.message?.toLowerCase().includes(RECORD_IN_USE_ERROR_MESSAGE));

  return error ? true : false;                  
}


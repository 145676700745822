<script>
  import chevronDown from '../../svg/chevronDown.svg';

  export let label = undefined;
  export let isOpen = false;
  export let items = [];
  export let itemToString = (item) => item.name || item.id;
  export let ref = null;
  export let selectedIndex = 0;
  export let selectedItem = undefined;

  $: selectedItem = items[selectedIndex];

  /**
   * Dropdown component.
   *
   * For adding additional logic refer to
   * https://github.com/IBM/carbon-components-svelte/blob/master/src/Dropdown/Dropdown.svelte
   *
   * @example <caption>Example dropdown.</caption>
   * <Dropdown
   *   label="Select an option..."
   *   {items}
   *   itemToString={item => item.myCoolData}
   *   bind:selectedItem
   * />
   *
   * @type {String} label. - the initial text inside the dropdown button.
   * @type {Boolean} isOpen. - is the dropdown menu open.
   * @type {Array} items.
   * @type {Function} itemToString.
   * @type {String} ref.
   * @type {String} selectedIndex.
   * @type {String} selectedItem.
   */

</script>

<svelte:body
  on:click={({ target }) => {
    if (isOpen && ref && !ref.contains(target)) {
      isOpen = false;
    }
  }} />

<div class="relative text-left">
  <div
    class="relative dropdown rounded-md"
    on:click={({ target }) => {
      isOpen = ref.contains(target) ? !isOpen : false;
    }}
  >
    <button
      type="button"
      class="text-left w-full"
      id="options-menu"
      aria-haspopup="listbox"
      aria-expanded="true"
      aria-labelledby="listbox-label"
      bind:this={ref}
      on:blur={({ relatedTarget }) => {
        if (relatedTarget) {
          ref.focus();
        }
      }}
    >
      <span class="flex justify-between pr-6 truncate">
        {#if selectedItem}
          {@html itemToString(selectedItem)}
        {:else}{label}{/if}
      </span>
      <div class="absolute bottom-0 flex items-center mr-3 right-0 top-0">
        <div class="h-6 w-6">
          {@html chevronDown}
        </div>
      </div>
    </button>
  </div>

  {#if isOpen}
    <div class="absolute left-0 mt-2 w-56 rounded-md shadow-lg w-full">
      <div
        class="rounded-md bg-white shadow-xs"
        on:click={({ target }) => {
          isOpen = ref.contains(target) ? !isOpen : false;
        }}
      >
        <ul
          class="h-112 overflow-scroll"
          role="listbox"
          aria-labelledby="listbox-label"
          aria-activedescendant="listbox-item-3"
        >
          {#each items as item, i (item.id || i)}
            <li
              class="border-t border-charcoal-10 flex h-11 items-center px-4
              text-lg hover:bg-charcoal-10 focus:outline-none focus:bg-charcoal-10
              text-left truncate w-full"
              id={`listbox-item-${i}`}
              role="option"
              on:click={() => {
                selectedIndex = i;
              }}
            >
              <span class="flex justify-between truncate w-full">
                {@html itemToString(item)}
              </span>
            </li>
          {/each}
        </ul>
      </div>
    </div>
  {/if}
</div>

/** The head passenger. */
import { derived, get } from 'svelte/store';
import { locale } from 'svelte-i18n';

import { booking, userInitiationMethod } from './booking';
import { ArabicCountry, GccCountry, TransactionInitiator } from '../const';
import { isObjEmpty, safeObjectAttribute } from '../utils';
import logger from '../logger';
import { passportNumbers } from './passportNumbers';
import { updatedEmail } from './updatedEmail';

/**
 * The head passenger is the passenger to which the bags will be allocated.
 */
export const headPassenger = derived(
  [booking, userInitiationMethod, passportNumbers],
  ([$booking, $userInitiationMethod, $passportNumbers]) => {
    if ($booking.headPassengerId) {
      const passenger = booking.passengerByID($booking.headPassengerId);
      if (!isObjEmpty(passenger)) {
        return passenger;
      }
    }
    if ($userInitiationMethod === TransactionInitiator.PASSPORT) {
      return booking.getPassengerWhoInitiatedTransaction();
    }
    return ($booking.passengers || [])[0] || {};
  },
);

/**
 * Data operations relating to the head passenger.
 */
class HeadPassengerManager {
  /**
   * Return passengers sorted with the head passenger at the top.
   *
   * If the head passenger is not included in the input array,
   * then the returned array will not be sorted.
   *
   * Includes infants.
   *
   * @param {object} passengers - THe passengers object.
   * @param {object[]} Array of passenger objects to sort, defaults to
   *                   the passengers in the booking store.
   * @returns {object[]} Sorted array of Switchboard Passenger objects.
   */
  sortPassengers(passengers = null) {
    const head = get(headPassenger);
    let sorted = [];

    passengers = passengers || get(booking).passengers || [];    
    passengers = booking.filterInfants(passengers);    
    passengers.forEach((passenger) => {
      if (passenger.passengerID === head.passengerID) {
        sorted.unshift(head);
      } else {
        sorted.push(passenger);
      }

      if (booking.passengerHasInfant(passenger)) {
        sorted.push(booking.getInfantByID(passenger.infantPassengerID));
      }
    });
   
    return sorted;
  }

  sortPassengersWithoutInfants(passengers = null) {
    const head = get(headPassenger);
    let sorted = [];
    passengers = passengers || get(booking).passengers || [];    
    passengers = booking.filterInfants(passengers);    
    passengers.forEach((passenger) => {
      if (passenger.passengerID === head.passengerID) {
        sorted.unshift(head);
      } else {
        sorted.push(passenger);
      }
    });
   
    return sorted;
  }

  /**
   * Is the passenger a head passenger.
   *
   * @param {object} passenger - The passenger.
   * @returns {boolean}
   */
  passengerIsHead(passenger) {
    const head = get(headPassenger);
    return (
      head.passengerIndex === passenger.passengerIndex &&
      head.passengerType === passenger.passengerType
    );
  }

  /** Set app language based on docS. */
  setLocale() {
    const { passportIssuingCountry } = booking.parsePassengerDocString(get(headPassenger));
    if (GccCountry.includes(passportIssuingCountry) || ArabicCountry.includes(passportIssuingCountry)) {
      logger.info(`${passportIssuingCountry} detected. Setting locale to 'ar'.`);
      locale.set('ar');
    }
  }

  getEmailAddress() {
    var result = null;
    const updatedEmailAddress = get(updatedEmail);
    if (updatedEmailAddress) {
      return updatedEmailAddress;
    }
    if (safeObjectAttribute(get(booking), 'passengers')) {
      if (
        get(booking).passengers.length === 1 &&
        safeObjectAttribute(get(booking), 'passengers[0].emailAddresses') &&
        get(booking).passengers[0].emailAddresses.length > 0
      ) {
        logger.info(
          get(booking).passengers[0].emailAddresses[0],
          'is Single Passenger first email address',
        );
        result = get(booking).passengers[0].emailAddresses[0];
      } else if (get(booking).passengers.length > 1) {
        get(booking).passengers.forEach((passenger) => {
          if (
            this.passengerIsHead(passenger) &&
            safeObjectAttribute(passenger, 'emailAddresses') &&
            passenger.emailAddresses.length > 0
          ) {
            logger.info(
              passenger.emailAddresses[0],
              "is Head Passenger's email address",
            );
            result = passenger.emailAddresses[0];
          }
        });
      }
      if (
        result == null &&
        safeObjectAttribute(get(booking), 'emailAddresses') &&
        get(booking).emailAddresses.length > 0
      ) {
        logger.info(
          get(booking).emailAddresses[0],
          'is Booking level first email address',
        );
        result = get(booking).emailAddresses[0];
      }
    }
    return result;
  }
}

export const headPassengerManager = new HeadPassengerManager();

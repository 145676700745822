<script>
  /**
   * Run callbacks before and after it is mounted.
   *
   * This ensures that the entry() will run before the slotted components
   * are mounted and exit() will run.
   */
  import { onMount } from 'svelte';

  export let entry = () => {};
  export let exit = () => {};

  let mounted = false;

  onMount(() => {
    entry();
    mounted = true;
    return exit;
  });
</script>

{#if mounted}
  <slot />
{/if}

/* Internationalisation. */
import { addMessages, getLocaleFromNavigator, init } from 'svelte-i18n';

import ar from '../i18n/ar-XN.json';
import de from '../i18n/de-DE.json';
import el from '../i18n/el-GR.json';
import en from '../i18n/en-EN.json';
import es from '../i18n/es-ES.json';
import fr from '../i18n/fr-FR.json';
import hi from '../i18n/hi-IN.json';
import it from '../i18n/it-IT.json';
import ja from '../i18n/ja-JP.json';
import ko from '../i18n/ko-KR.json';
import nl from '../i18n/nl-NL.json';
import pt from '../i18n/pt-BR.json';
import ru from '../i18n/ru-RU.json';
import th from '../i18n/th-TH.json';
import tr from '../i18n/tr-TR.json';
import zhCN from '../i18n/zh-CN.json';
import zhTW from '../i18n/zh-TW.json';

export default () => {
  addMessages('ar', ar);
  addMessages('de', de);
  addMessages('el', el);
  addMessages('en', en);
  addMessages('es', es);
  addMessages('fr', fr);
  addMessages('hi', hi);
  addMessages('it', it);
  addMessages('ja', ja);
  addMessages('ko', ko);
  addMessages('nl', nl);
  addMessages('pt', pt);
  addMessages('ru', ru);
  addMessages('th', th);
  addMessages('tr', tr);
  addMessages('zhCN', zhCN);
  addMessages('zhTW', zhTW);

  init({
    fallbackLocale: 'en',
    initialLocale: getLocaleFromNavigator(),
  });
};

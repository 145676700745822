<script>
  import { createEventDispatcher } from 'svelte';

  import Button from './Button.svelte';

  export let key = null;

  const dispatch = createEventDispatcher();

  /**
   * Factory for creating handlers for key presses.
   *
   * @param {string} key - The letter or character on the key.
   * @returns {Function} - A function that will trigger an event for the key.
   */
  function makeHandler(key) {
    return () => {
      dispatch('pressed', {
        key,
      });
    };
  }
</script>

<Button class="w-1/10" on:click={makeHandler(key)}>
  {key}
</Button>

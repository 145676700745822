<script>
  import { _ } from 'svelte-i18n';
  import { get } from 'svelte/store';
  import { location, push } from 'svelte-spa-router';

  import { rightToLeft } from '../../js/stores';

  import Button from './Button.svelte';
  import Loyalty from '../../svg/loyalty.svg';

  function moreDetailsHandler() {
    push(`/trip-extras${get(location)}`);
  }
</script>

<style>
  .tripExtrasBanner {
    @apply flex justify-center px-18;
  }
</style>

<div class="tripExtrasBanner">
  <button
    class="border-3 border-gold-100 rounded-lg p-3"
    on:click={moreDetailsHandler}
  >
    <div class="flex">
      <div class={$rightToLeft ? 'pl-6 pr-2' : 'pl-2 pr-6'}>
        <div class="w-24">
          {@html Loyalty}
        </div>
      </div>

      <div class="text-gold-100 flex-1">
        <p class="-mb-2 text-2.8 {$rightToLeft ? 'text-right' : 'text-left'}">
          {$_('tripExtras.banner.heading')}
        </p>
        <p class="text-lg {$rightToLeft ? 'text-left' : 'text-right'}">
          {$_('tripExtras.banner.paragraph')}
        </p>
      </div>

      <div class="flex justify-end self-end {$rightToLeft ? 'pr-4' : 'pl-4'}">
        <Button size="small">{$_('app.moreDetails')}</Button>
      </div>
    </div>
  </button>
</div>

<script>
  export let ratio = '4:3';
  export let src = '';

  $: videoProps = {
    ...$$restProps,
    class: [`ratio ratio--${ratio}`, $$restProps.class]
      .filter(Boolean)
      .join(' '),
  };

  /**
   * Video component.
   *
   * @example <caption>Example usage.</caption>
   * <div class="w-1/2">
   *   <Video class="mb-4" ratio="16:9" src={myCoolVideoSrc} />
   * </div>
   *
   * @type {String} class.
   * @type {String} ratio,
   * @type {String} src.
   */
</script>

<div {...videoProps}>
  <video class="ratio__content" preload loop autoplay muted playsinline>
    <source src="videos/{src}.webm" type="video/webm" />
  </video>
</div>

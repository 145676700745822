<script>
  import { _ } from 'svelte-i18n';
  import { onMount } from 'svelte';
  import QRCode from 'qrcode';

  import logger from '../../../../../js/logger';
  import { plusgradeAncillaryOfferManager } from '../../../../../js/stores';

  let qrCodeDataSrc = null;

  /**
   * Display a QR code that takes the user to Etihad Pay
   * for payment of the current amount payable
   */
  function showQRCode() {
    QRCode.toString(plusgradeAncillaryOfferManager.getOfferUrl(), {
      type: 'svg',
    })
      .then((url) => {
        qrCodeDataSrc = url;
      })
      .catch((error) => {
        logger.warn(`showQRCode Error: ${error.message}`);
      });
  }

  onMount(() => {
    showQRCode();
  });
</script>

<div class="w-68">
  {@html qrCodeDataSrc}
</div>

<script>
  import { _ } from 'svelte-i18n';
  import { createEventDispatcher } from 'svelte';
  import { get } from 'svelte/store';

  import switchboardClient from '../../../js/services/switchboard';
  import {
    appReport,
    ApplicationStep
  } from '../../../js/appReport';  import {
    booking,
    headPassenger,
    headPassengerManager,
    rightToLeft,
    setErrorModal,
    updatedEmail,
    transactionId
  } from '../../../js/stores';
  import { baseStationCode } from '../../../js/stores/config'; 
  import { ErrorModals } from '../../../js/const/errorModals';
  import logger from '../../../js/logger';
  import { VoiceIntent } from '../../../js/services/voicerec/voicerec';
  import { checkEmailPattern } from '../../../js/utils';
  import flightdeck from '../../../js/services/flightdeck';

  import Button from '../Button.svelte';
  import InputField from '../../components/InputField.svelte';
  import LoadingAnimation from '../LoadingAnimation.svelte';
  import ModalBase from '../ModalBase.svelte';
  import OnScreenKeyboard from '../../components/OnScreenKeyboard/index.svelte';
  import hasRecordInUseError from '../../../js/recordInUseError';

  export let showChangeEmailAddress = null;

  const dispatch = createEventDispatcher();

  let serviceRetryCount = 0;
  let changeEmailAddress = {};
  let heading = '';
  let showSpinner = false;
  let showUpdating = false;

  $: if (showSpinner) {
    if (showUpdating) {
      heading = 'updating';
    } else {
      heading = 'waiting';
    }
  } else {
    heading = 'heading';
  }

  /**
   * Handle input values using onscreen keayboard.
   * @param {number} inputValue - The value of the field.
   */
  function handleEmail(inputValue) {
    changeEmailAddress.emailAddress = inputValue;
    dispatch('userInteracting');
  }

  /**
   * cancel the onscreen keyboard
   * no need to dispatch so that if the user try to edit again it wil not end the transaction
   */
  function cancel() {
    showChangeEmailAddress = false;
  }

  /**
   * Handle updates to the email address.
   * Trigger a call to switchboard for update email address.
   */
  function updateEmail(inputValue) {
    logger.info('Request updateEmailAddress.');

    if (!checkEmailPattern(changeEmailAddress.emailAddress)) {
      logger.warn('Invalid Email Address Format.');
      showChangeEmailAddress = false;
      showSpinner = false;
      showUpdating = false;
      setErrorModal(ErrorModals.EMAIL_FORMAT_ERROR);
      return;
    }

    const passenger = get(headPassenger);

    let passengerDetails = {
      firstName: passenger.firstName,
      customerPrimeId: passenger.passengerID,
      gender: passenger.gender,
      surname: passenger.lastName,
      type: passenger.amadeusPassengerType,
      marketingCarrier: "EY", // Always use EY for the marketing carrier.
      email: changeEmailAddress.emailAddress,
    };

    logger.info(`Attempting to update email for: ${passenger.passengerID} with ${changeEmailAddress.emailAddress}`)

    let editPassengerDetailsList = [passengerDetails];

    const editCPRQuery = {
      editPassengerDetailsList,
      onlyPerformRegulatoryChecks: false,
      isAgentOverride: false,
      onlyUpdatePassengerEmail: true,
      baseStation: baseStationCode,
      transactionId: get(transactionId)
    };

    makeEditCPRRequest(editCPRQuery)
  }

  function makeEditCPRRequest(editCPRQuery) {
    switchboardClient
      .editCPR(editCPRQuery)
      .then((response) => {
        logger.info('Edit CPR Response for email update: ', response);

        const errorMessages = response.data?.editCpr?.errorMessages;
        // if we receive EC category messages from response, we want to log the errors and exit
        if (errorMessages && errorMessages?.length > 0) {
          logger.error('Error(s) in (Edit CPR) amadeus response');
          
          // check if record in use error is received
          if (hasRecordInUseError(errorMessages)) {
            serviceRetryCount++;
            if (serviceRetryCount < 3) {
              logger.info('Record in use error received from editCPR change email. waiting three seconds to recall the service')
              setTimeout(() => {
                makeEditCPRRequest(editCPRQuery);
                return;
              }, 3000);
              return;
            } else {
              logger.info('Record in use error received from editCPR change email. Maximum retry attempts reached. Ending transaction.')
            }
          }
          showErrorAndSendFlightDeckAlert();
        }
      })
      .catch((error) => {
        logger.error(error);
        showErrorAndSendFlightDeckAlert();
      })
      .finally(() => {
        dispatch('userInteractionFinished');
        showChangeEmailAddress = false;
        updatedEmail.set(changeEmailAddress.emailAddress);
        showSpinner = false;
        showUpdating = false;
      });
  }

  function showErrorAndSendFlightDeckAlert() {
    flightdeck.genericAmadeusFailure(ApplicationStep.BAG_DROP_COMPLETED_SCREEN, "EditCPR", "Error updating passenger email in CM.");
    setErrorModal(ErrorModals.ASSISTANCE_REQUIRED);
  }

</script>

<ModalBase
  heading={$_(`changeEmailAddress.${heading}`)}
  textLine1={showSpinner ? '' : $_('changeEmailAddress.textLine1')}
  bind:showModal={showChangeEmailAddress}
>
  {#if showSpinner}
    <LoadingAnimation class="mt-32 mx-auto w-152" />
  {:else}
    <div class="mb-12 {$rightToLeft ? 'text-right' : 'text-left'}">
      <InputField
        hasAutofocus
        id="nameValidationMsg"
        valueHandler={handleEmail}
        label="changeEmailAddress.emailAddress"
      />
    </div>

    <div class="mb-12">
      <OnScreenKeyboard />
    </div>

    <div class="flex justify-between mt-auto -mx-2">
      <div class="px-2">
        <Button on:click={updateEmail} intent={VoiceIntent.SAVE}>
          {$_('app.save')}
        </Button>
      </div>

      <div class="px-2">
        <Button on:click={cancel} intent={VoiceIntent.CANCEL}>
          {$_('app.cancel')}
        </Button>
      </div>
    </div>
  {/if}
</ModalBase>

<script>
  import { _ } from 'svelte-i18n';

  export let privacyPolicyHandler = () => {};
</script>

<p class="text-0.5 text-center">
  {$_('app.privacyPolicyText')}
  <button class="font-AltisMedium" on:click={privacyPolicyHandler}>
    {$_('app.clickHere')}
  </button>
</p>

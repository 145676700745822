<script>
  import { _ } from 'svelte-i18n';
  import { onDestroy, onMount } from 'svelte';
  import { push } from 'svelte-spa-router';

  import {
    addBag,
    currentPassenger,
    passengerBagCounts,
    wizardPosition,
  } from '../../js/stores';
  import logger from '../../js/logger';
  import profiling from '../../js/profiling';
  import { printBagTag } from '../../js/cuss-components';
  import { sleep } from '../../js/utils';
  import { WizardPosition } from '../../js/const';

  import Content from '../components/Content.svelte';
  import Footer from '../components/Footer/index.svelte';
  import Header from '../components/Header/index.svelte';
  import Loading from '../components/Loading.svelte';

  wizardPosition.set(WizardPosition.SECURITY);
  let endProfilingForScreen = () => {};

  const PRINT_DELAY = 1000; // milliseconds

  onMount(() => {
    logger.info(`Printing bagTag.`);
    endProfilingForScreen = profiling.start('Embross print bag tag screen');
    addBag();
    const tagNumber = currentPassenger.nextAvailableBagTag();
    const endProfilingForPrint = profiling.start('Embross print bag tag');
    printBagTag(tagNumber);
    endProfilingForPrint();
    // Fake delay instead of binding to the CUSS Bag Tag Dispenser.
    sleep(PRINT_DELAY).then(() => {
      push('/place-passenger-bag');
    });
  });

  onDestroy(() => {
    endProfilingForScreen();
  });
</script>

<Header hasHomeButton={false} />

<Loading heading={$_('app.pleaseWait')} />

<Content>
  <div class="mt-54" slot="main">
    <div class="absolute bottom-56 left-0 right-0">

      <div class="border-t border-gold-100 mx-18">
        <p class="font-bold mb-2 mt-6 text-1.375 uppercase">
          {$currentPassenger.lastName}, {$currentPassenger.firstName}
        </p>

        <p class="text-1.375">
          {$_('printingBagTags.bagTagPrinting', {
            values: {
              currentBagCount: String(currentPassenger.currentBagCount()),
              totalBags: String(passengerBagCounts.totalBags()),
            },
          })}
        </p>
      </div>

    </div>
  </div>
</Content>

<Footer />

<script>
  import Key from './Key.svelte';

  export let keys = [];
</script>

<div class="flex flex-wrap justify-center mb-4 w-full">
  {#each keys as key}
    <Key on:pressed {key} />
  {/each}
  <slot />
</div>

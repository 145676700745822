<script>
  import { _ } from 'svelte-i18n';
  import verifiedToFly from '../../svg/verifiedToFly.svg';
  import { rightToLeft } from '../../js/stores';
</script>

<style>
  .verified-to-fly-text {
    text-align: center;
  }
</style>

<div class="bg-palmGreen h-32 p-2 rounded-full w-32">
  <div
    class="flex flex-col items-center justify-center {$rightToLeft
      ? 'pt-1'
      : 'pt-2'} text-white"
  >
    <div class="{$rightToLeft ? '' : 'mb-1'} w-14">
      {@html verifiedToFly}
    </div>

    <p class="verified-to-fly-text">{$_('app.verifiedToFly')}</p>
  </div>
</div>

/* Store the guest's choice of bags per passenger. */
/* eslint-disable import/prefer-default-export */

import { get } from 'svelte/store';
import { resettable } from './extensions/resettable';
import { receipt } from './receipt';

/**
 * An object mapping passengers to bag counts.
 *
 * The object keys are PassengerID strings.
 * The values are integers representing the bag count for that passenger.
 */
export const passengerBagCounts = {
  ...resettable({}),
  ...{
    /* Set the bag count of a single passenger. */
    setPassengerBagCount(passengerID, bagCount) {
      this.set({
        ...get(this),
        [passengerID]: bagCount,
      });
    },

    /* Total selected bags for all passengers. */
    totalBags() {
      return Object.entries(get(this)).reduce(
        (runningTotal, [_, bagCount]) => runningTotal + bagCount, // eslint-disable-line no-unused-vars, max-len
        0,
      );
    },
  },
};

/**
 * Determine whether the passenger has more bags to check.
 *
 * @returns {boolean} - Whether or not a passenger has more bags to check.
 */
export function haveRemainingBags() {
  return passengerBagCounts.totalBags() - receipt.injectedBagCount() > 0;
}

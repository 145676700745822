<script>
  import Items from './Items.svelte';

  import batteries from '../../../svg/dangerous-goods/batteries.svg';
  import compressedGases from '../../../svg/dangerous-goods/compressedGases.svg';
  import corrosives from '../../../svg/dangerous-goods/corrosives.svg';
  import eCigarettes from '../../../svg/dangerous-goods/eCigarettes.svg';
  import electroShockDevices from '../../../svg/dangerous-goods/electroShockDevices.svg';
  import explosives from '../../../svg/dangerous-goods/explosives.svg';
  import flammableLiquids from '../../../svg/dangerous-goods/flammableLiquids.svg';
  import firearms from '../../../svg/dangerous-goods/firearms.svg';
  import hoverboards from '../../../svg/dangerous-goods/hoverboards.svg';
  import lighter from '../../../svg/dangerous-goods/lighter.svg';
  import liquidOxygenDevices from '../../../svg/dangerous-goods/liquidOxygenDevices.svg';
  import magnetisedMaterial from '../../../svg/dangerous-goods/magnetisedMaterial.svg';
  import matches from '../../../svg/dangerous-goods/matches.svg';
  import oxidisers from '../../../svg/dangerous-goods/oxidisers.svg';
  import powerBanks from '../../../svg/dangerous-goods/powerBanks.svg';
  import radioactiveMaterial from '../../../svg/dangerous-goods/radioactiveMaterial.svg';
  import securityTypeCases from '../../../svg/dangerous-goods/securityTypeCases.svg';
  import toxicSubstances from '../../../svg/dangerous-goods/toxicSubstances.svg';

  let prohibited = [
    { name: 'explosives', icon: explosives },
    { name: 'compressedGases', icon: compressedGases },
    { name: 'flammableLiquids', icon: flammableLiquids },
    { name: 'matches', icon: matches },
    { name: 'lighter', icon: lighter },
    { name: 'liquidOxygenDevices', icon: liquidOxygenDevices },
    { name: 'hoverboards', icon: hoverboards },
    { name: 'electroShockDevices', icon: electroShockDevices },
    { name: 'radioactiveMaterial', icon: radioactiveMaterial },
    { name: 'corrosives', icon: corrosives },
    { name: 'oxidisers', icon: oxidisers },
    { name: 'toxicSubstances', icon: toxicSubstances },
    { name: 'securityTypeCases', icon: securityTypeCases },
    { name: 'magnetisedMaterial', icon: magnetisedMaterial },
    { name: 'firearms', icon: firearms },
  ];

  let permitted = [
    { name: 'eCigarettes', icon: eCigarettes },
    { name: 'powerBanks', icon: powerBanks },
    { name: 'batteries', icon: batteries },
  ];
</script>

<div class="itemsContainer">
  <Items items={prohibited} name="prohibited" />
  <div class="border-b border-gold-100 mb-6 mx-auto w-8/12" />
  <Items items={permitted} name="permitted" />
</div>

<script>
  import { onDestroy, onMount } from 'svelte';
  import { get } from 'svelte/store';
  import { _ } from 'svelte-i18n';
  import { push } from 'svelte-spa-router';
  import {
    bagInjected,
    booking,
    currentBag,
    currentBagIndex,
    currentPassenger,
    currentTotalWeight,
    featureFlags,
    haveRemainingBags,
    headPassenger,
    isBagsPaymentSuccessful,
    isPaymentDeclined,
    agentHasOverridden,
    isFinalBag,    
    plusgradeAncillaryOfferManager,
    setErrorModal,
    setInPaymentFlow,
    wizardPosition,
    acceptedToPay,
    excessWeightEachBagInPieceConcept
  } from '../../js/stores';
  import { collectExcessOnLastBag } from '../../js/stores/bags';
  import {
    BagWeightMax,
    BagWeightWarningPercent,
    FeatureFlags,
    WizardPosition,
  } from '../../js/const';  
  import { ErrorModals } from '../../js/const/errorModals';
  import flightdeck from '../../js/services/flightdeck';
  import logger from '../../js/logger';
  import {
    handleFailedDimensionChecks,
    handleIncorrectBagTag,
    sbdPausedPendingExcessBaggage,
  } from '../../js/stores/sbdState';
  import { ApplicationStep, appReport } from '../../js/appReport';
  import BaggageAllowanceExceeded from '../components/modal/BaggageAllowanceExceeded.svelte';
  import BaggageAllowancePercentageReached from '../components/modal/BaggageAllowancePercentageReached.svelte';
  import Content from '../components/Content.svelte';
  import Footer from '../components/Footer/index.svelte';
  import Header from '../components/Header/index.svelte';
  import TripExtrasPayment from '../components/TripExtras/modal/TripExtrasPayment.svelte';
  import VerificationInfo from '../components/VerificationInfo.svelte';

  import dimensions from '../../svg/dimensions.svg';
  import scanningBag from '../../svg/scanningBag.svg';
  import weightKg from '../../svg/weightKg.svg';
  import LoadingAnimation from '../components/LoadingAnimation.svelte';
  wizardPosition.set(WizardPosition.SECURITY);

  let hasShownBaggageAllowancePercentageReachedModal = null;
  let hasShownBaggageAllowanceExceededModal = null;
  let isPcBaggage = null;
  let isPlusgradeEligible = null;
  let showBaggageAllowanceExceededModal = null;
  let showBaggageAllowancePercentageReachedModal = null;
  let showTripExtrasPaymentModal = null;
  let isLoading = false;

  let unsubscribeBagInjected = () => {};
  let unsubscribeCurrentBag = () => {};

  /**
   * Custom function that calculates the percent of a number.
   *
   * @param Int percent The percent that you want to get.
   * @param Int|int num The number that you want to calculate the percent of.
   * @returns {number}
   */
  function calculateWarningWeight(percent) {
    return Math.round((percent / 100) * booking.totalCombinedAllowance());
  }

  /* Determine whether the full set of dimension values have been received. */
  function checkDimensions(bag) {
    return bag.length && bag.width && bag.height;
  }

  function getExcessWeightOfEachBag() {
    let allowedWeight = booking.getPieceWeights()[$currentBagIndex - 1];
    let bagWeight = get(currentBag).weight;
    logger.info(`Allowed piece weight to passenger is: ${allowedWeight}, Current bag weight is: ${bagWeight}`);

    // check if array excessWeightEachBagInPieceConcept already has info for current bag
    let isCurrentBagInArray = false;
    let excessPieceWeightArray = get(excessWeightEachBagInPieceConcept);
    if (excessPieceWeightArray && excessPieceWeightArray.length > 0) {          
      excessPieceWeightArray.forEach(i => {
        if (i.bagIndex === ($currentBagIndex - 1)) {
          isCurrentBagInArray = true;
          i.excess = bagWeight - allowedWeight
        }
      });
    } else {
      excessPieceWeightArray = [];
    }
    if (!isCurrentBagInArray) {
      excessPieceWeightArray.push({  bagIndex: $currentBagIndex - 1, excess: bagWeight - allowedWeight });
    }
    excessWeightEachBagInPieceConcept.set(excessPieceWeightArray); 
  }

  /**
   * Update sbdPausedPendingExcessBaggage if certain conditions are met.
   * @param {Object} bag - Bag svelte-store.
   * @param {boolean} hasExceededWeightMaximum - Indicates if the bag has
   * exceeded weight maximum condition.
   * @param {boolean} hasExceededWarningThreshold - Indicates if the bag
   * has exceeded weight-related warning threshold condition.
   */
  function updateSbdPausedPendingExcessBaggage(
    bag,    
    hasExceededWarningThreshold,
  ) {
    if (isPcBaggage) {
      logger.info(`In Piece Market - isPcBaggage is: ${isPcBaggage}, $isBagsPaymentSuccessful is: ${$isBagsPaymentSuccessful}, $currentBag.inPaymentFlow is: ${$currentBag.inPaymentFlow}, isFinalBag() is: ${isFinalBag()}, $currentBag.weight is: ${$currentBag.weight}, $currentBag.isAllowableExtra is: ${$currentBag.isAllowableExtra}, showBaggageAllowanceExceededModal is: ${showBaggageAllowanceExceededModal}, hasShownBaggageAllowanceExceededModal is ${hasShownBaggageAllowanceExceededModal}`);
      
      // we do not want to listen to the 
      if (showBaggageAllowanceExceededModal ||
        (!$isBagsPaymentSuccessful && $currentBag.inPaymentFlow && isFinalBag() && $currentBag.weight > 0 && !$currentBag.isAllowableExtra)) {
        sbdPausedPendingExcessBaggage.pause('Inside updateSbdPausedPendingExcessBaggage - either showBaggageAllowanceExceededModal is true or  Bag is in payment flow, it is the final bag, the payment is not made . pausing the SBD State');
        return; 
      }

      // possibly need to add is 1) $currentBag.inPaymentFlow == false AND 2) $currentBag.inPaymentFlow == true && !isFinalBag() ;
      if ((!currentBag.failsPieceWeight(booking.getPieceWeights()[$currentBagIndex - 1])) && // weight of this piece/bag < allowed weight of a Piece i.e. 23 or 32 (determined by other pieces) 
          (!$currentBag.inPaymentFlow || // bag is not in payment flow OR
          ($currentBag.inPaymentFlow && !isFinalBag() && !showBaggageAllowanceExceededModal) || // bag is in payment flow and its not the last bag and baggageAllowanceExceeded is not visible 
          ($currentBag.inPaymentFlow && isFinalBag() && ($isBagsPaymentSuccessful || $currentBag.isAllowableExtra)) // OR bag is in payment flow and it is the final bag and either payment is sucessful or agent has overridden
          ))       
      {
        sbdPausedPendingExcessBaggage.unpause('Piece-concept - This piece/bag does not exceed weight, hence unpausing sbd state');                              
        return;
      }
    } else {
      if (!bag.failsTotalWeight() && !(hasExceededWarningThreshold && !isFinalBag())) {
        sbdPausedPendingExcessBaggage.unpause(`This bag does not exceed the total weight. If it has exceeded the percentage warning threshold, it is on the final bag, so no warning required. Check values: failsTotalWeight: ${bag.failsTotalWeight()}, hasExceededWarningThreshold: ${hasExceededWarningThreshold}, isFinalBag: ${isFinalBag()}`);
      }
    }
  }

  /** Subscription to currentBag. */
  function currentBagSubscription(bag) {
    logger.info('inside current bag subscription(bag).');

    isLoading = true;

    logger.info(`currentBag.getBagWeight() is : ${currentBag.getBagWeight()}. ` +                 
                `$currentBag.inPaymentFlow is : ${$currentBag.inPaymentFlow}`);

    if (!$isBagsPaymentSuccessful && currentBag.getBagWeight() === 0 && !$currentBag.inPaymentFlow && !$currentBag.isAllowableExtra) {
      logger.info('Reset Excess Modals to false since bag weight is 0 and payment flow is false and payment is not done and agent has not overridden excess');
      hasShownBaggageAllowanceExceededModal = false;
      showBaggageAllowanceExceededModal = false;
    } 

    // if user has already accepted to pay and its not the last bag, we do not need to show excess modal
    if (get(acceptedToPay) && !isFinalBag()) {
      logger.info('inside if (get(acceptedToPay) && !isFinalBag())..');
      showBaggageAllowanceExceededModal = false;
    }

    let shouldProcessBag = false; 
    shouldProcessBag = bag && !currentBag.isReadyForInjection();

    logger.info(`currentBag.isReadyForInjection() is ${currentBag.isReadyForInjection()}`);    

    logger.info(
      `currentBag.subscribe(). bag: ${JSON.stringify(bag)};` +
        ` shouldProcessBag: ${shouldProcessBag};` +
        ` isPcBaggage: ${isPcBaggage}.`,
    );

    const hasExceededWeightMaximum = currentBag.failsWeight();

    const hasExceededWarningThreshold =
        (currentTotalWeight() + booking.totalWeightUsedInPreviousTransaction()) >=
        calculateWarningWeight(BagWeightWarningPercent);
        
    if (shouldProcessBag) {      
      bag = currentBag; // Use the store, not the store's value
      
      const shouldShowBaggageAllowancePercentageReachedModal =
        hasExceededWarningThreshold &&
        !isFinalBag() &&
        !isPcBaggage &&
        !hasShownBaggageAllowancePercentageReachedModal &&
        featureFlags.isEnabled(FeatureFlags.EXCESS_BAGGAGE);
      
      // if the shouldShowBaggageAllowancePercentageReachedModal is false, we do not need to show BaggageAllowancePercentageReachedModal anymore
      if (!shouldShowBaggageAllowancePercentageReachedModal) {
        showBaggageAllowancePercentageReachedModal = false;
      }

      logger.debug(
        `currentBagSubscription -` +
          ` hasExceededWeightMaximum: ${hasExceededWeightMaximum},` +
          ` hasExceededWarningThreshold: ${hasExceededWarningThreshold},` +
          ` shouldShowBaggageAllowancePercentageReachedModal:` +
          ` ${shouldShowBaggageAllowancePercentageReachedModal},` +
          ` hasShownBaggageAllowancePercentageReachedModal:` +
          ` ${hasShownBaggageAllowancePercentageReachedModal},` +
          ` isFinalBag: ${isFinalBag()},` +
          ` failsTotalWeight: ${bag?.failsTotalWeight()}.`,
      );

      updateSbdPausedPendingExcessBaggage(
        bag,      
        hasExceededWarningThreshold,
      );

      if (hasExceededWeightMaximum) {        
        isLoading = false;
        logger.warn('BAG_WEIGHT_ERROR');
        setErrorModal(ErrorModals.BAG_WEIGHT_ERROR);
        flightdeck.overweightSingleBag(
          $currentBag,
          $currentBagIndex,
          currentBag.amountWeightOverSingleBagMaximum(),
          BagWeightMax,
        );
      } else if (bag.failsDimensions()) {
        logger.info(`inside bag.failsDimensions()`);
        isLoading = false;
        handleFailedDimensionChecks(() =>
          flightdeck.oversizeBag($currentBag, $currentBagIndex),
        );
      } else if (bag.failsBagTagNumber()) {
        isLoading = false;
        logger.info(`inside bag.failsBagTagNumber()`);
        handleIncorrectBagTag(() =>
          flightdeck.incorrectBagTagNumber($currentBag, $currentBagIndex),
        );
      } else if (isPcBaggage) {
        logger.info(`Piece - isPcBaggage is: ${isPcBaggage}, $isBagsPaymentSuccessful is: ${$isBagsPaymentSuccessful}, $currentBag.inPaymentFlow is: ${$currentBag.inPaymentFlow}, isFinalBag() is: ${isFinalBag()}, $currentBag.weight is: ${$currentBag.weight}, $currentBag.isAllowableExtra is: ${$currentBag.isAllowableExtra}, get(collectExcessOnLastBag) is ${get(collectExcessOnLastBag)}, showBaggageAllowanceExceededModal is ${showBaggageAllowanceExceededModal}, hasShownBaggageAllowanceExceededModal is ${hasShownBaggageAllowanceExceededModal}`);

        if ($isBagsPaymentSuccessful || $currentBag.isAllowableExtra) {
          sbdPausedPendingExcessBaggage.unpause('Payment is made or Agent has overridden excess. Unpausing the sbd State');
          return;
        }

        if (currentBag.failsPieceWeight(booking.getPieceWeights()[$currentBagIndex - 1])) {
          logger.info('failsPieceWeight is true. We need to find whats the excess weight of the bag.');
          collectExcessOnLastBag.set(true); // for piece - excess collection will only be set to true in two cases 1) if selected No of bags > allowed bags or 2) any passenger bag is > allowed piece weight
          getExcessWeightOfEachBag();
        }
          
        if (!$currentBag.inPaymentFlow && get(currentBag).weight > 0 && get(collectExcessOnLastBag)) {
          logger.info(`Bag is not in payment flow. Setting it to payment flow and showing excess modal.`);
          isLoading = false;
          appReport.updateStepStart(ApplicationStep.EXCESS_BAGGAGE_INFORMATION)
          showBaggageAllowanceExceededModal = true;
          hasShownBaggageAllowanceExceededModal = true;
          setInPaymentFlow(true);
          return;
        }

        if (isFinalBag() && get(collectExcessOnLastBag)) {
          logger.info(`Piece - It is the final bag and we need to collect excess and hasShownBaggageAllowanceExceededModal is: ${hasShownBaggageAllowanceExceededModal}`);
          if (!hasShownBaggageAllowanceExceededModal) {
            showBaggageAllowanceExceededModal = true;
            hasShownBaggageAllowanceExceededModal = true;
          }
          
          return;
        }
      } else if (bag.failsTotalWeight() && !isPcBaggage) {
        logger.info(`inside bag.failsTotalWeight() && !isPcBaggage....................`);
        // check if the payment has already been made
        if ($isBagsPaymentSuccessful) {
          logger.info(`inside bag.failsTotalWeight() && !isPcBaggage && $isBagsPaymentSuccessful....................`);

          // if the payment has already been corrected, that means the Error events
          // received from the front end app were after the bag weight
          logger.info(
            `Excess bag payment has already been made. Exiting the subscription.`,
          );
          sbdPausedPendingExcessBaggage.unpause(
            'Inside Excess bag payment section. Payment has already been made. Unpausing the sbpState',
          );
        } else if ($currentBag.inPaymentFlow && isFinalBag() && $currentBag.isAllowableExtra) {
          logger.info(`inside bag.failsTotalWeight() && !isPcBaggage && $currentBag.inPaymentFlow && isFinalBag() && $currentBag.isAllowableExtra`);
          isLoading = false;
          sbdPausedPendingExcessBaggage.unpause('In Payment flow, its the final bag, and agent has overridden excess. Unpausing the sbpState');
        } else {
          logger.info(`inside bag.failsTotalWeight() && !isPcBaggage && ELSE OF $isBagsPaymentSuccessful....................`);
          logger.info(`$currentBag.inPaymentFlow is: ${$currentBag.inPaymentFlow}`);
          logger.info(`hasShownBaggageAllowanceExceededModal is: ${hasShownBaggageAllowanceExceededModal}`);
          
          if (!$currentBag.inPaymentFlow && !hasShownBaggageAllowanceExceededModal && get(currentBag).weight > 0) {            
            isLoading = false;
            logger.info(`inside bag.failsTotalWeight() && !isPcBaggage && ELSE OF $isBagsPaymentSuccessful !$currentBag.inPaymentFlow && !hasShownBaggageAllowanceExceededModal....................`);
            appReport.updateStepStart(ApplicationStep.EXCESS_BAGGAGE_INFORMATION)
            showBaggageAllowanceExceededModal = true;
            hasShownBaggageAllowanceExceededModal = true;
            logger.info(`Bag exceeds total weight allowance. Displaying baggage allowance exceeded modal.`);
            setInPaymentFlow(true);
          } else if ($currentBag.inPaymentFlow && get(acceptedToPay) && !isFinalBag()) { //  && get(currentBag).weight > 0
            sbdPausedPendingExcessBaggage.unpause('Bag is already in payment flow, passenger has accepted to pay, it is not the last bag. unpausing the SBD state');
          } else if ($currentBag.inPaymentFlow && isFinalBag() && !$currentBag.isPaymentSuccess && $currentBag.weight > 0) {
            // probably need to pause SBD state if showBaggageAllowanceExceededModal is true
            logger.info('In weight market - It is the last bag, we are in payment flow, we have the weight but payment is not successful.')
            sbdPausedPendingExcessBaggage.pause('In weight market - Bag is in payment flow, it is the final bag, the payment is not made. pausing the SBD State');
          }
        }
      } else if (shouldShowBaggageAllowancePercentageReachedModal) {
        logger.info(` .............`);
        isLoading = false;
        showBaggageAllowancePercentageReachedModal = true;
        hasShownBaggageAllowancePercentageReachedModal = true;
        logger.info(
          `Bag weight exceeds allowance warning percentage: ${BagWeightWarningPercent}%`,
        );
      }
    } else {      
      logger.info('inside else of if (shouldProcessBag)..');
      // if it is weight concept and bag is ready to inject
      if (!isPcBaggage && currentBag.isReadyForInjection()) {
        logger.info('inside else of if (shouldProcessBag).. inside !isPcBaggage && ....');
        showBaggageAllowanceExceededModal = false;        
        sbdPausedPendingExcessBaggage.unpause('Bag assessment complete. Unpause SBD state');
        return;
      }
    }
  }

  function bagInjectedSubscription(injected) {
    isLoading = true;

    logger.info('inside bagInjectedSubscription');
    if (injected) {
      logger.info('inside bagInjectedSubscription, injected is true');
      bagInjected.set(false);
      currentBag.toReceipt();

      appReport.updateStepSuccess(ApplicationStep.BAG_ASSESSMENT);
       
      haveRemainingBags()
        ? printNextBagTag()
        : goToBagDropComplete();
    }
  }

  function printNextBagTag() {
    isLoading = false;
    push('/printing-bag-tag')
  }

  function goToBagDropComplete() {
    showBaggageAllowanceExceededModal = false;        
    // from Embross/self service, the override excess service 
    // is taking time and hence boarding pass service returns error
    setTimeout(() => {
      isLoading = false;
      push('/bag-drop-completed');
    }, 2000);
  } 

  /** Handle the click of the Plusgrade advertisement. */
  function advertisementHandler() {
    showBaggageAllowanceExceededModal = false;
    showTripExtrasPaymentModal = true;
  }

  /** Handle the Continue action from FlightDeck. */
  function advertisementContinueHandler() {
    plusgradeAncillaryOfferManager.performBookingUpdate();
    push('/printing-bag-tag');
  }

  onMount(() => {
    logger.info(`On mount of DispatchingBag`);

    hasShownBaggageAllowancePercentageReachedModal = false;
    hasShownBaggageAllowanceExceededModal = false;
    isPcBaggage = booking.getBaggageUnit() === 'pc';
    showBaggageAllowanceExceededModal = false;
    showBaggageAllowancePercentageReachedModal = false;
    showTripExtrasPaymentModal = false;

    logger.info(`Entering Self Service DispatchingBag`);

    unsubscribeCurrentBag = currentBag.subscribe(currentBagSubscription);

    unsubscribeBagInjected = bagInjected.subscribe(bagInjectedSubscription);

    if (featureFlags.isEnabled(FeatureFlags.PLUSGRADE)) {
      isPlusgradeEligible =
        !booking.hasPlusgradeUpgrade(get(headPassenger)) &&
        plusgradeAncillaryOfferManager.isEligibleForCabinUpgrade();
    }
  });

  onDestroy(() => {
    unsubscribeBagInjected();
    unsubscribeCurrentBag();
  });
</script>

<style>
  :global([dir='rtl']) div :global(svg) {
    transform: scaleX(1);
  }

  #verification-icons :global(svg) {
    opacity: 0.5;
  }

  #verification-icons :global(svg .tickIcon) {
    display: none;
  }

  #verification-icons .active :global(svg) {
    opacity: 1;
  }

  #verification-icons .active :global(svg .tickIcon) {
    display: inline;
  }

  .loading-style {   
    width: 100%;
    text-align: center;
    margin-top: 100px;    
  }

  .loading-animation {
    margin-top: 50px;    
  }

</style>

{#if isPlusgradeEligible}
  <TripExtrasPayment
    handleContinue={advertisementContinueHandler}
    bind:showModal={showTripExtrasPaymentModal}
  />
{/if}

<BaggageAllowanceExceeded
  {advertisementHandler}
  {isPlusgradeEligible}
  bind:showModal={showBaggageAllowanceExceededModal}
/>

<BaggageAllowancePercentageReached
  bind:showModal={showBaggageAllowancePercentageReachedModal}
/>

<Header hasHomeButton={false} />

<Content>
  <span slot="heading">{$_('dispatchingBag.heading')}</span>
  <span slot="text">{$_('dispatchingBag.textLine1')}</span>

  <div class="mt-24" slot="main">
    <div class="h-28 w-3/5 mx-auto">
      <div id="verification-icons" class="flex justify-around">
        <div
          class="text-center px-4 py-2 m-2 self-end"
          class:active={checkDimensions($currentBag)}
        >
          {@html dimensions}
        </div>
        <div
          class="text-center px-4 py-2 m-2 self-end"
          class:active={$currentBag.weight}
        >
          {@html weightKg}
        </div>
        <div
          class="text-center px-4 py-2 m-2 self-end"
          class:active={$currentBag.bagTagID}
        >
          {@html scanningBag}
        </div>
      </div>
    </div>

    {#if isLoading}
      <div class="loading-style">
        <h1>{$_('app.pleaseWait')}</h1>
        <div class="loading-animation">
          <LoadingAnimation />
        </div>
      </div>
    {/if}

    <!-- Force redraw of VerificationInfo when currentPassenger changes -->
    {#if $currentPassenger}
      <VerificationInfo isPcBaggage={isPcBaggage} />
    {:else}
      <VerificationInfo isPcBaggage={isPcBaggage} />
    {/if}
  </div>
</Content>

<Footer />


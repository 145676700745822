<script>
  import { _ } from 'svelte-i18n';

  export let tier = '';

  $: tierColour = `tier${tier.charAt(0).toUpperCase()}${tier.slice(1)}`;
  $: tierLocalisedName = $_(`app.${tier.toLowerCase()}`);
</script>

<div class="inline-flex rounded-sm border border-{tierColour}">
  <p
    class="font-AltisMedium px-4 text-{tierColour} text-lg
    uppercase">
    {tierLocalisedName}
  </p>
</div>

<script>
  import { _ } from 'svelte-i18n';
  import { onMount } from 'svelte';

  import {
    booking,
    currentPassenger,
    featureFlags,
    flightDate,
    rightToLeft,
  } from '../../../js/stores';
  import { BoardingClosingTime, FeatureFlags } from '../../../js/const';
  import { stripLeadingZeroes } from '../../../js/utils';

  import VerifiedToFly from '../VerifiedToFly.svelte';

  import boardingPass from '../../../svg/boardingPass.svg';
  import bookWithMiles from '../../../svg/bookWithMiles.svg';
  import checkedBaggage from '../../../svg/checkedBaggage.svg';

  export let passenger = null;

  const hasPcrTest = featureFlags.isEnabled(FeatureFlags.PCR_TEST);

  let airport = null;
  let departureGate = null;
  let boardingTime = null;
  let flightNumber = null;
  let passengerName = null;

  onMount(() => {
    airport = currentPassenger.getShortCheckInDestinationCity();
    departureGate = $booking.departureGate;
    boardingTime = $booking.boardingTime;
    flightNumber = `${$booking.airlineCode} ${stripLeadingZeroes(
      booking.getDisplayedFlightNumber($booking.flightNumber)
    )}`;
    passengerName = `${passenger.firstName} ${passenger.lastName}`;
  });
</script>

<div class="mb-28 mx-6 relative">
  {#if hasPcrTest}
    <div class="absolute top-0 left-0 -mt-12 -ml-12 z-5">
      <VerifiedToFly />
    </div>
  {/if}

  <div
    class="bg-informative-100 pb-8 pt-8 px-2 rounded-t-md text-xl {hasPcrTest
      ? 'pt-24'
      : 'pt-8'}"
  >
    <div class="flex mb-12">
      <div class="ml-6 mr-2 w-1/12">
        {@html boardingPass}
      </div>

      <div class="flex-1">
        <div class="flex items-center justify-between w-full">
          <div class="px-4">
            <p class="font-bold">{$_('welcome.passengerName')}</p>
            <p>{passengerName}</p>
          </div>

          <div class="px-6">
            <p class="font-bold">{$_('welcome.flightDate')}</p>
            <p>{$flightDate}</p>
          </div>

          <div class="px-6">
            <p class="font-bold">{$_('welcome.flightNumber')}</p>
            <p>{flightNumber}</p>
          </div>
        </div>
      </div>
    </div>

    {#if airport}
      <div class="flex mb-12">
        <div class="mx-4 w-1/12">
          {@html checkedBaggage}
        </div>

        <div class="flex items-center">
          <div class="px-4">
            <p>
              {$_('bagDropCompleted.clearCustoms', {
                values: {
                  airport: booking.getTranslatedCity(airport),
                },
              })}
            </p>
          </div>
        </div>
      </div>
    {/if}

    <div class="flex mb-10">
      <div class="mx-4 w-1/12">
        {@html bookWithMiles}
      </div>

      <div class="flex items-center">
        {#if departureGate && departureGate !== 'GATE'}
          <div class={$rightToLeft ? 'pl-12 pr-4' : 'pl-4 pr-12'}>
            <p class="font-bold">{$_('app.gate')}</p>
            <p>{departureGate}</p>
          </div>
        {/if}

        <div class="px-4">
          <p class="font-bold">{$_('bagDropCompleted.boardingTime')}</p>
          <div class="flex items-end">
            <p>{boardingTime}</p>
            <p class="leading-7 {$rightToLeft ? 'mr-4' : 'ml-4'} text-sm">
              {$_('bagDropCompleted.boardingCloses', {
                values: {
                  closingTime: BoardingClosingTime,
                },
              })}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-center">
      <p>{$_('bagDropCompleted.haveANiceTrip')}</p>
    </div>
  </div>
</div>

<script>
  import { _ } from 'svelte-i18n';

  import { range } from '../../../js/utils';
  import { wizardPosition } from '../../../js/stores.js';
  import { WizardSize } from '../../../js/const';

  import ProgressBar from '../ProgressBar.svelte';
  import WizardItem from './WizardItem.svelte';
</script>

<style>
  footer {
    @apply absolute
      bottom-0
      border-gold-100
      border-t-2
      flex
      h-24
      justify-center
      w-full;
  }
</style>

<footer>
  <div class="flex flex-col pb-4 h-full justify-end w-9/12">
    <div class="relative flex justify-between px-2">
      {#each range(WizardSize) as index, i}
        <WizardItem {index} />
      {/each}

      <div class="absolute top-0 left-0 right-0 h-6 mx-12 flex items-center">
        <ProgressBar max={WizardSize - 1} value={$wizardPosition} />
      </div>
    </div>
  </div>
</footer>

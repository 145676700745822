<script>
  import { _ } from 'svelte-i18n';

  import ModalBase from '../../../ModalBase.svelte';
  import QrCode from './QrCode.svelte';

  import cabinUpgrade from '../../../../../svg/cabinUpgrade.svg';

  export let showModal = false;
</script>

<ModalBase heading={$_(`tripExtras.cabinUpgrade.heading`)} bind:showModal>
  <div class="-mt-8 px-4">
    <div class="w-24 mb-14 mx-auto">
      {@html cabinUpgrade}
    </div>

    <div class="text-center whitespace-pre-wrap">
      <p class="font-bold text-1.375">
        {$_('tripExtras.payment.paragraph')}
      </p>

      <div class="flex justify-center">
        <QrCode />
      </div>
    </div>
  </div>
</ModalBase>

<script>
  import { _ } from 'svelte-i18n';

  import { WeightUnit } from '../../../js/const';

  import hazard from '../../../svg/hazard.svg';

  export let disclaimer = null;
  export let heading = null;
  export let icon = null;
  export let paragraph = null;
  export let weight = null;
</script>

<div class="flex items-center py-6">
  <div class="w-18">
    {@html icon}
  </div>

  <div class="flex-1 px-6">
    <h3>{heading}</h3>
    <p class="mb-2 text-xl">{paragraph}</p>

    {#if disclaimer}
      <div class="flex items-center mb-2">
        <div class="text-arabianSunset w-6">
          {@html hazard}
        </div>
        <h3 class="px-2 text-xl">{disclaimer}</h3>
      </div>
    {/if}
  </div>

  <div class="bg-charcoal-100 text-white p-4">
    <p class="text-3xl whitespace-no-wrap">
      {weight}
      {$_(`app.${WeightUnit}`)}
    </p>
  </div>
</div>

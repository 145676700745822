<script>
  import { _, locale } from 'svelte-i18n';
  import { get } from 'svelte/store';
  import { onDestroy, onMount } from 'svelte';
  import { push } from 'svelte-spa-router';

  import {
    ApplicationFlow,
    WeightUnit,
    REFERENCE_OWNER,
    REFERENCE_TYPE,
    MERCHANT_CODE,
    TEMPLATE_ID,
    LANGUAGE,
    SESSION_TIMEOUT,
  } from '../../../js/const';
  
  import { currencyFormat, safeObjectAttribute } from '../../../js/utils';
  import { ErrorModals } from '../../../js/const/errorModals';
  import {
    CustomErrorModal,
    applicationFlow,
    booking,
    CabinUpgradeProducts,
    currentBag,
    currentBagIndex,
    currentTotalExcessWeight,
    currentTotalWeight,
    currentPassenger,
    checkoutFormPayload,
    ensureBagOnScale,
    errorModal,
    getCurrentTotalExcess,
    getExcessBaggageRate,
    haveRemainingBags,
    isFinalBag,    
    paidAllowance,
    passengerBagCounts,
    plusgradeAncillaryOfferManager,
    priceUnpaidItemsData,
    rightToLeft,
    setErrorModal,
    setIsAllowableExtra,
    setPaymentSuccess,
    setIsBagsPaymentSuccessful,
    setPaymentDeclined,
    setAgentHasOverridden,
    setCurrentBagWeight,
    setCurrentBagBagTagID,
    setAcceptedToPay,
    transactionId
  } from '../../../js/stores';
  import { collectExcessOnLastBag, excessPieceCurrency, excessPieceApproxCharges, getPieceConceptExcessNumberOfBags } from '../../../js/stores/bags';
  import { BaggagePayment } from '../../../js/controllers';
  import endTransaction from '../../../js/endTransaction';
  import flightdeck from '../../../js/services/flightdeck';
  import flightdeckConsts from '../../../js/services/flightdeck/const';
  import { VoiceIntent } from '../../../js/services/voicerec/voicerec';
  import logger from '../../../js/logger';
  import { sbdPausedPendingExcessBaggage, getAllProductDetails } from '../../../js/stores/sbdState';
  import {
    appReport,
    ApplicationStep,
    EventType,
    EventStatus,
    USER_ACTIONS,
    FailedReason,
    AGENT_ACTIONS,
  } from '../../../js/appReport';
  import { PieceConceptBaggagePayment } from '../../../js/controllers/pieceConceptBaggagePayment';

  import Advertisement from '../Advertisement.svelte';
  import Button from '../Button.svelte';
  import Payment from '../modal/Payment.svelte';
  import ModalBase from '../ModalBase.svelte';

  import warning from '../../../svg/warning.svg';    
  import switchboardClient from '../../../js/services/switchboard';
  import { baseStationCode } from '../../../js/stores/config';

  export let advertisementHandler = () => {};
  export let isPlusgradeEligible = false;
  export let showModal = false;

  const excessBaggageRate = getExcessBaggageRate();
  const isPorterBagDrop =
    get(applicationFlow) === ApplicationFlow.PORTER_BAG_DROP;

  let baggagePayment;
  let declinedPaymentModal;
  let extraAmount;
  // let extraWeight;
  let newWeight;
  let showExcessBaggagePaymentModal = false;
  let showPaymentSuccessModal = false;
  let currentTotalExcess = {};

  $: {
    if (showModal) {
      currentTotalExcess = getCurrentTotalExcess();
    }
  }

  /** Flightdeck message for passenger decline. */
  function handlePassengerDecline() {
    logger.info('inside handlePassengerDecline method');
    // weight market is working fine. we dont have intrusions during payment flow
    // for piece market adding this sbd pause as a fix here....
    if (booking.isPieceBaggage()) {
      sbdPausedPendingExcessBaggage.pause('Its piece market - Payment has been declined by the passenger. Pausing SBD state');
    }

    // setting the payment status
    setIsBagsPaymentSuccessful(false);
    setPaymentDeclined(true);

    showModal = false;
        
    const optedOutOfUpgradeError = new CustomErrorModal(
      ErrorModals.OPTED_OUT_OF_BAG_UPGRADE,
      {
        flightdeckHandlers: {
          [flightdeckConsts.TransactionStatuses.Cancelled]:
            handleFlightDeckCancelled,
          [flightdeckConsts.TransactionStatuses.Overridden]:
            handleFlightDeckOverride,
          [flightdeckConsts.TransactionStatuses.PaymentPending]:
            handleFlightDeckPaymentPending,
        },
      },
    );
    optedOutOfUpgradeError.setOverrideHandler(handleFlightDeckOverride);
    optedOutOfUpgradeError.setEndHandler(handleFlightDeckCancelled);

    optedOutOfUpgradeError.open();

    flightdeck.declinedWeightUpgrade(
      get(currentBag),
      get(currentBagIndex),
      passengerBagCounts.totalBags(),
      booking.isPieceBaggage() ? getPieceConceptExcessNumberOfBags() : currentTotalExcessWeight(),
      booking.isPieceBaggage() ? get(excessPieceApproxCharges) : currentBag.excessWeightCost(),
      booking.isPieceBaggage() ? get(excessPieceCurrency) : excessBaggageRate?.currency,
    );

    // User has declined excess baggage. Step has not finished as override is required to either end transaction or override.
    appReport.updateStepWithAction(
      ApplicationStep.EXCESS_BAGGAGE_INFORMATION,
      EventType.USER_ACTION,
      EventStatus.SUCCESS,
      USER_ACTIONS.EXCESS_BAGGAGE_INFORMATION_MODAL_DECLINED,
    );
    appReport.updateAncillaryAmountLastShown(
      booking.isPieceBaggage() ? get(excessPieceApproxCharges) : currentBag.excessWeightCost(),
      booking.isPieceBaggage() ? get(excessPieceCurrency) : excessBaggageRate?.currency,
    );
  }

  /**
   * Handle Flightdeck Override action.
   *
   * An Override means the bag does not need to be paid for.
   *
   * If the bag has been lifted, display error modal asking for return of bag.
   * Use ensureBagOnScale() to wait for bag return.
   * When bag is confirmed on the scale, proceed to next screen, or display
   * the payment modal if the remaining transaction weight (excluding the
   * current bag weight that has been overrided) exceeds the allowance.
   * setAllowableExtra() means the weight will not be included in the total.
   *
   * @returns {boolean} Returns false to tell GeneralErrorModal not to close.
   */
  function handleFlightDeckOverride(flightdeckAction) {
    logger.info('Payment declined by the passenger has been overriden by the flight deck agent.');
    
    setAgentHasOverridden(true);
    
    // weight market is working fine. we dont have intrusions during payment flow
    // for piece market adding this sbd pause as a fix here....
    if (booking.isPieceBaggage()) {
      sbdPausedPendingExcessBaggage.unpause('Its piece market - Payment was declined by passenger. Agent has now overridden the excess.');
    }
    
    logger.info('Received ensureBagOnScale.');
    appReport.updateStepWithAction(
      ApplicationStep.EXCESS_BAGGAGE_INFORMATION,
      EventType.AGENT_ACTION,
      EventStatus.SUCCESS,
      AGENT_ACTIONS.EXCESS_BAGGAGE_INFORMATION_OVERRIDE
    );

    if (get(currentBag).isLifted) {
      setErrorModal(ErrorModals.PUT_BAG_BACK_ON_SCALE);
    }

    setIsAllowableExtra(true);

    ensureBagOnScale()
      .then(() => {
        logger.info('Success ensureBagOnScale.');
        errorModal.reset();
        if (isFinalBag() && 
           ((!booking.isPieceBaggage() && currentBag.failsTotalWeight()) || (booking.isPieceBaggage() && get(collectExcessOnLastBag)))) {
          handlePayment();
        } else {
          // A consequence of this is that we may get multiple step successes for Excess Baggage Information, 
          // In the scenario of: excess -> decline -> override, then excess -> decline -> override again.
          appReport.updateStepSuccess(ApplicationStep.EXCESS_BAGGAGE_INFORMATION);

          isPorterBagDrop
            ? pushToNextScreen()
            : sbdPausedPendingExcessBaggage.unpause(
                'Agent override following User decline ' +
                  'of the allowence exceeded modal',
              );
        }
      })
      .catch((error) => {
        appReport.updateStepFailed(ApplicationStep.EXCESS_BAGGAGE_INFORMATION, FailedReason.APP_TIME_OUT);
        logger.warn(`Failed ensureBagOnScale: Error: ${error.message}.`);
        endTransaction();
      });

    return false;
  }

  /** End transaction due to Flightdeck cancel. */
  function handleFlightDeckCancelled(flightdeckAction) {
    appReport.updateStepWithAction(
      ApplicationStep.EXCESS_BAGGAGE_PAYMENT,
      EventType.STEP_FINISH,
      EventStatus.SUCCESS,
      AGENT_ACTIONS.PAYMENT_DECLINED_TRANSACTION_ENDED_FROM_FLIGHTDECK
    );
    endTransaction();
    return true;
  }

  /**
   * Flightdeck override of payment cancellation/failure - allows payment and EMD issuance retry
   * NOTE: A successful payment but unsuccessful EMD issuance can be overriden, allowing for a retry to call the EMD issuance endpoint
   */
  function handleFlightDeckPaymentFailureOverride(flightdeckAction) {
    // If the payment was successful, we do not go back into handlePayment to regenerate a checkout form but instead only retry EMD issuance
    if(baggagePayment && baggagePayment.isPaymentSuccessful)
    {
      logger.info(`FD override received, payment was ${baggagePayment.isPaymentSuccessful} and EMD issuance is being retried.`)
      // Agent must have allowed for an override to get into this section
      let eventDetails = { action: AGENT_ACTIONS.FLIGHTDECK_OVERRIDE_RETRY_EMD_ISSUANCE }

      appReport.updateStepWithEventDetails(
        ApplicationStep.EXCESS_BAGGAGE_PAYMENT,
        EventType.STEP_IN_PROGRESS,
        EventStatus.SUCCESS,
        eventDetails
      );

      baggagePayment.executeIssuePaymentDocument()
    }
    else
    {
      // Report a failure, with step finished
      appReport.updateStepWithAction(
        ApplicationStep.EXCESS_BAGGAGE_PAYMENT,
        EventType.STEP_START,
        EventStatus.SUCCESS,
        USER_ACTIONS.MAKING_PAYMENT
      );
      handlePayment();
    }
    return true;
  }

  /** Flightdeck end transaction after payment cancellation/failure. */
  function handleFlightDeckPaymentFailureCancelled(flightdeckAction) {
    // Report a failure, with step finished
    appReport.updateStepWithAction(
      ApplicationStep.EXCESS_BAGGAGE_PAYMENT,
      EventType.STEP_FINISH,
      EventStatus.SUCCESS,
      AGENT_ACTIONS.PAYMENT_DECLINED_TRANSACTION_ENDED_FROM_FLIGHTDECK,
    );
    endTransaction();
    return true;
  }

  /**
   * Handle Flightdeck Payment Pending action.
   *
   * Flightdeck agent has confirmed that the user will opt to pay.
   *
   * If the bag has been lifted, display error modal asking for return of bag.
   * Use ensureBagOnScale() to wait for bag return.
   * Call handlePayment() as if the passenger accepted the payment modal.
   *
   * @returns {boolean} Returns false to tell GeneralErrorModal not to close.
   */
  function handleFlightDeckPaymentPending(flightdeckAction) {
    logger.info('Request ensureBagOnScale.');

    if (get(currentBag).isLifted) {
      setErrorModal(ErrorModals.PUT_BAG_BACK_ON_SCALE);
    }

    ensureBagOnScale()
      .then(() => {
        logger.info('Received ensureBagOnScale.');
        errorModal.reset();
        handlePayment();
      })
      .catch((error) => {
        appReport.updateStepFailed(ApplicationStep.EXCESS_BAGGAGE_INFORMATION, FailedReason.APP_TIME_OUT);
        logger.warn(`Failed ensureBagOnScale: Error: ${error.message}.`);
        endTransaction();
      });

    return false;
  }

  /** Passenger opts to proceed to making a payment. */
  function handlePayment() {
    showModal = false;

    extraAmount = currentBag.excessCost();
    // extraWeight = currentTotalExcessWeight();

    // This appReport update intends to capture when the modal is shown and user presses accept
    // This function may be called in the following scenarios:
    // 0. EXCESS_BAGGAGE_INFORMATION, User Action, presses the pay button
    // 1. EXCESS_BAGGAGE_INFORMATION, Agent override, overriding the excess baggage.
    // 2. EXCESS_BAGGAGE_PAYMENT, Agent override, to allow user to initiate another payment attempt.
    // Note: due to the above, we CANNOT say for sure which event sent them here which is why the below details are null
    // Note: if a payment failures and agent overrides sending them back here, we expect see another appReport call here with the currentBag

    let currentBagInformation = get(currentBag);
    let eventDetails = {context: {bagTag: currentBagInformation.bagTagID, weightKgs: currentBagInformation.weight}}

    appReport.updateStepWithEventDetails(
      ApplicationStep.EXCESS_BAGGAGE_INFORMATION,
      EventType.STEP_IN_PROGRESS,
      EventStatus.SUCCESS,
      eventDetails
    );

    appReport.updateAncillaryAmountLastShown(
      extraAmount,
      excessBaggageRate.currency,
    );

    const tagNumber = currentPassenger.nextAvailableBagTag();
    setCurrentBagBagTagID(tagNumber);
    const bagTagObject = currentPassenger.getBagTagObject(tagNumber);

    const productsList = getAllProductDetails();
    const currentBagWeight = currentBag.getBagWeight();
    
    UpdateBagInformation(productsList, currentBagWeight, bagTagObject, 0);

    logger.info('inside BaggageAllowanceExceeded, handlePayment(), calling currentBag.activate');
  }

  function UpdateBagInformation(productsList, currentBagWeight, bagTagObject, serviceRetryCount) {
   
      const bookingDetails = get(booking);
      // This code would be called by SELF SERVICE / EMBROSS flow to update the bag tag
      currentBag.activate(currentTotalWeight(), productsList, currentBagWeight, bagTagObject, false)
      .then((isUpdateBaggageSuccess) => {
        if (isUpdateBaggageSuccess == 'retry') {
          serviceRetryCount++;
          if (serviceRetryCount < 3) {
            setTimeout(() => {
              UpdateBagInformation(productsList, currentBagWeight, bagTagObject, serviceRetryCount);
            }, 3000);
            return;
          } else {
            logger.warn(`Error in updating baggage allowance: ${JSON.stringify(error)}`);
            setErrorModal(ErrorModals.ASSISTANCE_REQUIRED);  
          }
        } else if (isUpdateBaggageSuccess == 'true') {
          if (isFinalBag()) {
            logger.info('Allowable Excess is = ', get(currentBag).isAllowableExtra);
            if (get(currentBag).isAllowableExtra === true) {
              const bookingSearchQuery = {};
              bookingSearchQuery.pnr = get(booking).bookingReference;
              switchboardClient
                .bookingSearch(bookingSearchQuery)
                .then((response) => {                  
                  switchboardClient.overrideExcessBaggage({
                    calculationId: response?.data?.searchBooking?.excessBaggageId,
                    baseStation: baseStationCode,
                    transactionId: get(transactionId)
                  }).then((overrideExcessResponse) => {
                    const errorMessages = overrideExcessResponse?.data?.overrideExcessBaggage?.errorMessages
                    if (errorMessages && errorMessages?.length) {
                      logger.error('Errors received from overrideExcessBaggage api.');
                      errorMessages.forEach((error) => {
                        logger.error(error);
                      });
                    }
                  });
                });
              
                // Successfully overrided
                appReport.updateStepSuccess(ApplicationStep.EXCESS_BAGGAGE_INFORMATION);
            } else {
              switchboardClient
                .priceUnpaidItems(bookingDetails)
                .then((response) => {
                  logger.info(`Received priceUnpaidItems response.`, JSON.stringify(response));

                  if (response.data.priceUnpaidItems.isGetSuccessful) {
                    priceUnpaidItemsData.set(response); //will be used for issuepaymentDocument
                    const unpaidItems = response.data.priceUnpaidItems.unpaidItems;

                    // Filter by service type
                    const unpaidItem = unpaidItems.filter((item) => item.serviceType == 'XII');
                    let unpaidPrice = 0;
                    let unpaidCurrency; 
                    if (unpaidItem && unpaidItem.length > 0) {
                      unpaidItem.forEach(u => {
                        unpaidPrice = unpaidPrice + Number(u.price);
                      });
                      unpaidCurrency = unpaidItem[0].currency;
                    }

                    excessPieceApproxCharges.set(unpaidPrice);
                    excessPieceCurrency.set(unpaidCurrency);

                     // If we get up to here, we are finishing EXCESS_BAGGAGE_INFORMATION step
                    // and entering EXCESS_BAGGAGE_PAYMENT step
                    appReport.updateStepSuccess(ApplicationStep.EXCESS_BAGGAGE_INFORMATION);
                    appReport.updateAncillaryAmountLastShown(unpaidPrice, unpaidCurrency);

                    generateCheckoutFormPayload(unpaidPrice, unpaidCurrency);

                    appReport.updateStepStart(ApplicationStep.EXCESS_BAGGAGE_PAYMENT);
                    showExcessBaggagePaymentModal = true;
                    addToBaggagePayment(unpaidPrice, unpaidCurrency);
                  } else {
                    logger.warn(`Amadeus priceUnpaidItems Service is failed.`);
                    setErrorModal(ErrorModals.ASSISTANCE_REQUIRED);
                  }
                })
                .catch((error) => {
                  logger.warn(`Error connecting to Amadeus priceUnpaidItems Service: ${JSON.stringify(error)}`);
                  setErrorModal(ErrorModals.ASSISTANCE_REQUIRED);
                });
              }
          } else {
            isPorterBagDrop
            ? pushToNextScreen()
            : userAcceptedToPayButItsNotLastBag() 
          }
        } else {
          logger.warn(`Error in updating baggage allowance: ${JSON.stringify(error)}`);
          setErrorModal(ErrorModals.ASSISTANCE_REQUIRED);
        }
    });

    logger.info(
      `BaggageAllowanceExceeded - Excess Baggage Details: Cost=${extraAmount}` +
      `, excessAmount=${currentTotalExcess.excess},` + 
      `, excessUnit=${currentTotalExcess.unit}.`
    );
  }

  function userAcceptedToPayButItsNotLastBag() {
    setAcceptedToPay(true);
    
    showModal = false;
    setTimeout(() => {
      logger.info('After 2 seconds, now unpausing SBD.. since user has Accepted to pay');
      showModal = false;
      sbdPausedPendingExcessBaggage.unpause(
        'User accepts to pay from Allowance Exceeded modal, ' +
          'but this is not the last bag. Continuing',
      );
    }, 2000);
  }

function addToBaggagePayment(unpaidPrice, unpaidCurrency) {
  if (booking.isPieceBaggage()) {
    baggagePayment = new PieceConceptBaggagePayment(unpaidPrice, unpaidCurrency, handleAccepted, handleDeclined);
  } else {
    baggagePayment = new BaggagePayment(unpaidPrice, unpaidCurrency, handleAccepted, handleDeclined);
  }
  baggagePayment.addItem(
    unpaidPrice,
    currentTotalExcess.excess
  );
}

/**
 * This will prepare the checkout form Payload from different sources
 * like Booking, passenger details and segments or flight details
 * @returns
 */
export function generateCheckoutFormPayload(unpaidPrice, currency){  
  const currentBooking = get(booking);

  // getting the bag group owner to find out the unpaid items
  var passenger = currentBooking.passengers.filter(p => p.passengerID === currentBooking.bagGroupOwnerPassengerID);
  
  let bagGroupOwnerPassenger = passenger && passenger.length > 0 ? passenger[0] : currentBooking.passengers[0];

  logger.info(`Generating checkout form for PNR: ${bagGroupOwnerPassenger.bookingReference}`)

  checkoutFormPayload.set(
    {
      data: {
        template: {
          merchantCode: MERCHANT_CODE,
          id: TEMPLATE_ID,
          configuration: {
            language: LANGUAGE,
            sessionTimeout: SESSION_TIMEOUT,
            labels: {
              'details/items[0]/label': 'Excess Bag',
              'details/items[0]/value': `${unpaidPrice}.00 ${currency}`
              //'details/items[1]/label': 'VAT',
              //'details/items[1]/value': '10.00 AED'
            },
            customizations: {
              logoUrl: 'https://upload.wikimedia.org/wikipedia/commons/0/0e/Etihad-airways-logo.svg'
            },
            redirectUrls: [
              {
                rel: 'failure',
                href: 'https://www.google.com/search?q=failure'
              },
              {
                rel: 'TermsAndConditions',
                href: 'https://www.google.com/search?q=terms'
              }
            ]
          }
        },
        salesSummary: {
          totalPrice: {
            value: unpaidPrice * 100 , //this is to add 2 xero
            decimalPlaces: 2,
            currency: `${currency}`
          },
          reference: bagGroupOwnerPassenger.bookingReference,
          referenceType: REFERENCE_TYPE,
          referenceOwner: REFERENCE_OWNER
        }
      }
    }
  );
}

  /** Push to next screen based on application flow. */
  function pushToNextScreen() {
    if (isPorterBagDrop) {
      push('/porter-printing-bag-tag');
    } else {
      haveRemainingBags()
        ? push('/printing-bag-tag')
        : goToBagDropComplete();
    }
  }

  function goToBagDropComplete() {
    // from Embross/self service, the override excess service 
    // is taking time and hence boarding pass service returns error
    setTimeout(() => {
      push('/bag-drop-completed');
    }, 2000);
  }

  /** Handle a successful payment. */
  function handleAccepted() {
    logger.info('inside BaggageAllowanceExceeded.svelte... handleAccepted()');
    
    paidAllowance.set(get(paidAllowance) + currentTotalExcess.excess);
    showExcessBaggagePaymentModal = false;
    showPaymentSuccessModal = true;

    // after 4 seconds of successful payment
    // hide the modal automatically and continue
    // to the next screen. Since we have hidden
    // the continue button on Payment svelte for
    // baggage payment, we call handleContinue manually.
    setTimeout(() => {
      handleContinue();
    }, 2000);
  }

  /** Handle a declined (failed) payment.  */
  function handleDeclined() {
    logger.info('inside handle Declined method');
    // weight market is working fine. we dont have intrusions during payment flow
    // for piece market adding this sbd pause as a fix here....

    if (booking.isPieceBaggage()) {
      sbdPausedPendingExcessBaggage.pause('Its piece market - Payment has been declined by the passenger. Pausing SBD state');
    }

    setPaymentDeclined(true);


    // NOTE: In progress since this can be overrided. There is no retry logic, so the transaction only ends when the agent ends it.
    appReport.updateStepWithEventDetails(
      ApplicationStep.EXCESS_BAGGAGE_PAYMENT,
      EventType.STEP_IN_PROGRESS,
      EventStatus.FAILED,
      {failedReason: FailedReason.PAYMENT_FAILED}
    );

    showExcessBaggagePaymentModal = false;

    // NOTE: the text for this says payment is declined but we can go into this flow if payment succeeds but EMD issuance fails. Agent will see this distinction in FD.
    declinedPaymentModal.open();

    // If payment was success but EMD failure, send a message to FD to specifically say so and allow end and override
    if(baggagePayment && baggagePayment.isPaymentSuccessful)
    {
      flightdeck.excessBaggagePaymentSuccessEMDIssuanceFail()
    }
    // Otherwise, let FD know payment failed
    else
    {  
      flightdeck.excessBaggagePaymentFailed(
        currentTotalExcessWeight(),
        booking.isPieceBaggage() ? get(excessPieceApproxCharges) : currentBag.excessWeightCost(),
        booking.isPieceBaggage() ? get(excessPieceCurrency) : excessBaggageRate.currency
      );
    }
  }

  /** Handle the Continue button after the completion of payment. */
  function handleContinue() {
    logger.info('inside BaggageAllowanceExceeded...handleContinue()...');
    showPaymentSuccessModal = false;

    // updating the bags payment status
    setIsBagsPaymentSuccessful(true);

    isPorterBagDrop
      ? pushToNextScreen()
      : afterPaymentSuccessfulContinue()
  }

  function afterPaymentSuccessfulContinue() {
    logger.info('inside afterPaymentSuccessfulContinue...');
    sbdPausedPendingExcessBaggage.unpause('User has completed their payment');
    logger.info('inside afterPaymentSuccessfulContinue, updating isPaymentSuccess to true...');
    setPaymentSuccess(true);
  }

  onMount(() => {
    if (booking.isPieceBaggage()) {
      sbdPausedPendingExcessBaggage.pause('OnMount of BaggageAllowanceExceeded modal. its piece market - Pausing SBD state');
    }

    declinedPaymentModal = new CustomErrorModal(
      ErrorModals.EXCESS_BAGGAGE_PAYMENT_FAILED,
    );

    declinedPaymentModal.setFlightdeckHandler(
      flightdeckConsts.TransactionStatuses.Cancelled,
      handleFlightDeckPaymentFailureCancelled,
    );

    declinedPaymentModal.setEndHandler(
      handleFlightDeckPaymentFailureCancelled,
    );

    declinedPaymentModal.setFlightdeckHandler(
      flightdeckConsts.TransactionStatuses.Overridden,
      handleFlightDeckPaymentFailureOverride,
    );

    declinedPaymentModal.setOverrideHandler(
      handleFlightDeckPaymentFailureOverride,
    );
  });

  onDestroy(() => {
    if (baggagePayment) {
      baggagePayment.cancelPolling();
    }
    
  });
</script>

<Payment
  controller={baggagePayment}
  {handleContinue}
  showContinueButton={false}
  handleDeclined={handlePassengerDecline}
  bind:showModal={showExcessBaggagePaymentModal}
  bind:showSuccess={showPaymentSuccessModal}
/>

<ModalBase
  heading={$_('baggageAllowanceExceeded.heading')}
  icon={warning}
  bind:showModal
>

  <div class="mb-10 px-6">
    <p class="text-1.375 whitespace-pre-wrap">
      {$_(`baggageAllowanceExceeded.textLine1${booking.isPieceBaggage() ? 'PieceConcept' : ''}`)}
      <span class="block mt-5">
        {$_('baggageAllowanceExceeded.textLine2')}
      </span>
    </p>
  </div>

  {#if !booking.isPieceBaggage()}
    <div class="{$rightToLeft ? 'text-right' : 'text-left'} mb-14 px-6">
      <div class="flex mt-6 border-b border-gray-500 pb-2">
        <h3 class="w-1/2 {$rightToLeft ? 'text-right' : 'text-left'}">
          {$_('baggageAllowanceExceeded.baggageAllowance')}
        </h3>
        <p class="w-1/2 {$rightToLeft ? 'text-left' : 'text-right'}">
          {`${$_(
            'app.bag',
          )} ${$currentBagIndex}\/${passengerBagCounts.totalBags()}`}
        </p>
      </div>

      <div class="flex text-xl mt-4">
        <p class="w-1/2">{$_('baggageAllowanceExceeded.bagWeight')}</p>
        <p class="w-1/2 font-AltisMedium">
          {$currentBag.weight || 0}
          {$_(`app.${WeightUnit}`)}
        </p>
      </div>
   
      <div class="flex text-xl mt-2">
        <p class="w-1/2">{$_('baggageAllowanceExceeded.excessWeight')}</p>
        <p class="w-1/2 font-AltisMedium">
          {currentTotalExcess.excess < 0 ? currentTotalExcess.excess * -1 : currentTotalExcess.excess}
          {$_(`app.${currentTotalExcess.unit}`)}
        </p>
      </div>
      
      <!-- <div class="flex text-xl mt-2">
        <p class="w-1/2">{$_('baggageAllowanceExceeded.costIncurred')}</p>
        <p
          class="w-1/2 font-AltisMedium"
          class:text-saffron={currentBag.excessCost()}
        >
          {currencyFormat(
            $locale,
            excessBaggageRate.currency,
            currentBag.excessCost(),
          )}
        </p>
      </div> -->
    
      <div class="flex text-xl font-AltisMedium mt-4">
        <p class="w-1/2">{$_('baggageAllowanceExceeded.totalBagWeight')}</p>
        <p class="w-1/2">{currentTotalWeight()} {$_(`app.${WeightUnit}`)}</p>
      </div>
    </div>
  {/if}

  <div class="flex justify-between mt-auto">
    <Button on:click={handlePassengerDecline} intent={VoiceIntent.DECLINE}>
      {$_('app.decline')}
    </Button>

    <Button
      on:click={handlePayment}
      intent={$currentBagIndex >= passengerBagCounts.totalBags()
        ? VoiceIntent.PAY
        : VoiceIntent.ACCEPT}
    >
      {$currentBagIndex >= passengerBagCounts.totalBags()
        ? $_('app.pay')
        : $_('app.accept')}
    </Button>
  </div>
</ModalBase>

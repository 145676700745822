<script>
  import { onMount, onDestroy } from 'svelte';
  import { camera } from '../../js/stores';
  import events from '../../js/events';
  import facerec from '../../js/services/facerec';
  import logger from '../../js/logger';

  let reconnectTimeout, unbindDocumentScan;

  const CORNERS = [
    { position: 'top-0 left-0', rotation: '0' },
    { position: 'top-0 right-0', rotation: '90' },
    { position: 'bottom-0 left-0', rotation: '-90' },
    { position: 'bottom-0 right-0', rotation: '180' },
  ];

  $: passportScanningFrameProps = {
    ...$$restProps,
    class: ['relative', $$restProps.class].filter(Boolean).join(' '),
  };

  onMount(() => {
    logger.info('Mounting the passport camera frame');
    unbindDocumentScan = events.onDocumentScan(facerec.reconnect);
    facerec.connect();
  });

  onDestroy(() => {
    clearTimeout(reconnectTimeout);
    unbindDocumentScan();
    facerec.disconnect();
  });

  /**
   * PassportScanningFrame component is an area for passport scanning.
   *
   * @example <caption>Example usage with camera.</caption>
   * <div class="w-1/2">
   *   <PassportScanningFrame />
   * </div>
   *
   * @type {String} camera.
   */
</script>

<div {...passportScanningFrameProps}>
  <canvas class="w-full" width="1280" height="1280" />
  {#each CORNERS as corner, i}
    <svg
      class="absolute w-1/12 {corner.position}"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      style="transform: rotate({corner.rotation}deg)"
    >
      <polyline points="18 2 2 2 2 18" />
    </svg>
  {/each}

  {#if $camera}
    <div class="absolute inset-0 overflow-hidden m-2">
      <img
        src="data:image/jpeg;base64,{$camera}"
        class="block mr-auto ml-auto h-full"
        alt="Camera."
      />
    </div>
  {/if}
</div>

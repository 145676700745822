<script>
  import bridge from '../../js/cuss-bridge';

  const MRZ_HOTKEYS = Object.freeze({
    '1': [
      'P<AREHAMRI<<FATMA<AHMAD<MOHAMMAD<SALEM<<<<<<',
      'J35N913292ARE8310279F2208017<<<<<<<<<<<<<<<2'
    ],
    '2': [
      'P<AREALKATHEERI<<MAISOON<ALI<ABDULLA<SAEED<<',
      'YZ4N894504ARE8903185F2306120<<<<<<<<<<<<<<08'
    ],
    '3': [
      'P<AUSMILN<<KATRINA<LOUISE<<<<<<<<<<<<<<<<<<<',
      'E4106802<3AUS6611167F2306120<23152961P<<<<04'
    ],
    '4': [
      'P<GBRDOXSEY<<ANDREW<<<<<<<<<<<<<<<<<<<<<<<<<',
      '5220284728GBR8612180M2407094<<<<<<<<<<<<<<02'
    ],
    '5': [
      'P<IND<<TAHIRAH<ABBAS<LOKHANDWALA<<<<<<<<<<<<',
      'L6629406<4IND9601145F2402103<<<<<<<<<<<<<<<4'
    ],
    '6': [
      'P<GBRMACLACHLAN<<NICOLA<SUE<<<<<<<<<<<<<<<<<',
      '4662887144GBR7308288F2008044<<<<<<<<<<<<<<08'
    ],
    '7': [
      'P<GBRMACLACHLAN<<JADYN<JOSH<<<<<<<<<<<<<<<<<',
      '5365025596GBR0606181M2112297<<<<<<<<<<<<<<04'
    ],
    '8': [
      'P<GBRMACLACHLAN<<CLINTON<HUGH<TREVOR<<<<<<<<',
      '5483899851GBR7506073M2708160<<<<<<<<<<<<<<04'
    ],
    '9': [
      'P<AREALBLOOKI<<MOHAMMAD<ABDULLA<ABBAS<MOHAMM',
      'LRCR333312ARE8009294M2212061<<<<<<<<<<<<<<<4'
    ],
  });


  function handleMRZKey(event) {
    const keyCharacter = String.fromCharCode(event.keyCode);

    if (keyCharacter in MRZ_HOTKEYS) {
      bridge.passportMRZ(...MRZ_HOTKEYS[keyCharacter]);
    }
  }
</script>
<svelte:window on:keydown={handleMRZKey} />

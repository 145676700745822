<script>
  import { _ } from 'svelte-i18n';
  import { onDestroy } from 'svelte';
  import { get } from 'svelte/store';
  import {
    errorModal,
    getErrorModalDescriptiveName,
    setErrorModal,
  } from '../../js/stores';
  import endTransaction from '../../js/endTransaction';
  import events from '../../js/events';
  import flightdeck from '../../js/services/flightdeck';
  import { killAllTransactionPolling } from '../../js/services/flightdeck/service';
  import { secondsToMilliseconds } from '../../js/utils';
  import logger from '../../js/logger';

  import Button from './Button.svelte';
  import ModalError from './ModalError.svelte';
  import OverrideByKeypad from './modal/OverrideByKeypad.svelte';
  import X from './Icons/X.svelte';

  import assistance from '../../svg/assistance.svg';

  let agentId = null;
  let showOverride = false;
  let showOverrideByKeypad = false;
  let stopListeningForOverride = () => {};
  let stopListeningFlightdeckAction = () => {};
  let timeout = null;

  const OVERRIDE_TOUCH_TRIGGER = 5; // 5 touches.

  $: isOverridable = $errorModal && 'override' in $errorModal;
  $: isClosable = $errorModal ? $errorModal['closable'] : false;

  $: useTimeout =
    $errorModal &&
    'timeout' in $errorModal &&
    $errorModal.timeout &&
    !isOverridable;

  $: timeoutEnds =
    $errorModal && 'timeoutEnds' in $errorModal && $errorModal.timeoutEnds;

  $: {
    if (useTimeout) {
      timeout = setTimeout(() => {
        if (timeoutEnds) {
          endTransactionHandler();
        } else {
          errorModal.reset();
        }
      }, secondsToMilliseconds($errorModal.timeout));
    }
  }

  $: {
    if (isOverridable) {
      stopListeningForOverride = events.onOverrideScan((data) => {
        const { epr } = data;
        agentId = epr;
        showOverride = true;
      });

      stopListeningFlightdeckAction = events.onFlightdeckAction(
        (flightdeckAction) => {
          if (
            $errorModal &&
            'flightdeckHandlers' in $errorModal &&
            flightdeckAction.status in $errorModal.flightdeckHandlers
          ) {
            logger.info(
              'Invoking Flightdeck handler for ' +
                `'${flightdeckAction && flightdeckAction.status}' status.`
            );
            const isErrorResolved = $errorModal.flightdeckHandlers[
              flightdeckAction.status
            ](flightdeckAction);
            if (isErrorResolved) {
              errorModal.reset();
            }
          }
        }
      );
    }
  }

  const unsubscribe = errorModal.subscribe(($errorModal) => {
    if ($errorModal === null) {
      logger.info(`GeneralErrorModal closed.`);

      killAllTransactionPolling();
      clearTimeout(timeout);
      stopListeningForOverride();
      stopListeningFlightdeckAction();
    } else {
      logger.info(
        `GeneralErrorModal opened: ${getErrorModalDescriptiveName()}.`
      );
    }
  });

  /** Generate an override button handler. */
  function makeOverrideButtonHandler(func, isAffirmative) {
    return () => {
      showOverride = false;
      const { flightdeckMessage } = $errorModal.override;
      const reason = !isAffirmative ? getErrorReason() : null;
      errorModal.reset();
      func(reason);
      logger.info(
        `Agent override actioned as '${isAffirmative ? 'override' : 'end'}' ` +
          `for scenario: '${flightdeckMessage}'.`
      );
      flightdeck.flightdeck.override(agentId, flightdeckMessage, isAffirmative);
    };
  }

  /** Handle presses on the override by keypad touch area. */
  function overrideByKeypadTouchHandler() {
    this.touches = this.touches ? this.touches + 1 : 1;
    if (this.touches === OVERRIDE_TOUCH_TRIGGER) {
      logger.info('User triggers display of Override by PIN modal.');
      showOverrideByKeypad = true;
      this.touches = 0;
    }
  }

  /**
   * This function extracts the Error Reason from errorModal
   *copying error reason by value, so that when errorModal is reset()
    we dont loose the reason. even if we reset errorModal after
    endTransaction, sometimes JS runtime has a timing issue and 
    the function is called with errorModal as resetted
   */
  function getErrorReason() {
    const errorObject = get(errorModal);    
    let reason = null;    
    if (errorObject) {      
      reason = errorObject.reason
    }
    return reason;
  }

  /** Handle end press to both end the transaction and reset the errorModal. */
  function endTransactionHandler() {
    // getting the error object from svelte store    
    const endTransactionReason = getErrorReason();
    errorModal.reset();
    endTransaction(endTransactionReason);    
  }

  /** Translate a i18n key if it is truthy. */
  function translateIfDefined(i18nKey) {
    return i18nKey ? $_(i18nKey) : '';
  }

  onDestroy(() => {
    unsubscribe();
  });

</script>

{#if showOverride && $errorModal !== null}
  <ModalError
    heading={$errorModal.override.heading}
    icon={assistance}
    iconClass="text-current"
    textLine1={$errorModal.override.endHandler
      ? $errorModal.override.endDescription
      : ''}
    textLine2={$errorModal.override.overrideHandler
      ? $errorModal.override.overrideDescription
      : ''}
  >
    <div slot="modalButtons">
      <div
        class="flex {$errorModal.override.endHandler &&
        $errorModal.override.overrideHandler
          ? 'justify-between'
          : 'justify-end'}"
      >
        {#if $errorModal.override.endHandler}
          <Button
            on:click={makeOverrideButtonHandler(
              $errorModal.override.endHandler,
              false
            )}
          >
            {$_('app.end', { locale: 'en' })}
          </Button>
        {/if}

        {#if $errorModal.override.overrideHandler}
          <Button
            on:click={makeOverrideButtonHandler(
              $errorModal.override.overrideHandler,
              true
            )}
          >
            {$_('app.override', { locale: 'en' })}
          </Button>
        {/if}
      </div>
    </div>
  </ModalError>
{:else if $errorModal !== null}
  <ModalError
    heading={$_($errorModal.heading)}
    icon={$errorModal.icon}
    iconClass={$errorModal.iconClass}
    image={$errorModal.image}
    textLine1={translateIfDefined($errorModal.textLine1)}
    textLine1Class={$errorModal.textLine1Class}
    textLine2={translateIfDefined($errorModal.textLine2)}
    textLine2Class={$errorModal.textLine2Class}
    textLine3={translateIfDefined($errorModal.textLine3)}
    textLine3Class={$errorModal.textLine3Class}
    textLine4={translateIfDefined($errorModal.textLine4)}
    textLine4Class={$errorModal.textLine4Class}
    video={$errorModal.video}
  >
    <div slot="modalButtons">
      {#if isClosable}
        <div class="flex justify-end">
          <Button
            on:click={() => {
              setErrorModal(null);
            }}
          >
            {$_('app.ok')}
          </Button>
        </div>
      {:else if !isOverridable}
        <div class="flex justify-end">
          <Button icon={X} on:click={endTransactionHandler} />
        </div>
      {:else}
        <div
          class="-mt-48 h-48 relative w-full z-10"
          on:click={overrideByKeypadTouchHandler}
        />
      {/if}
    </div>
  </ModalError>
{/if}

<OverrideByKeypad bind:showModal={showOverrideByKeypad} />

<script>
  import { _ } from 'svelte-i18n';
  import { createEventDispatcher, onMount } from 'svelte';

  import arrow from '../../../svg/arrow.svg';

  export let isOpen = null;
  export let segments = null;
  export let segment = null;

  let isPreviousButtonDisabled = true;
  let isNextButtonDisabled = false;

  const dispatch = createEventDispatcher();

  /**
   * Index of the current segment.
   *
   * @returns {number} - The segment index (0-based).
   */
  function currentSegmentIndex() {
    return segments.findIndex(
      (_segment) => segment.flightNumber === _segment.flightNumber,
    );
  }

  /**
   * Dispatch the segmentSelect event with a new segment.
   *
   * @param {number} delta - The change in the segmentIndex from current.
   */
  function segmentSelect(delta) {
    const selectedSegment = segments[currentSegmentIndex() + delta];

    dispatch('segmentSelect', {
      segment: selectedSegment,
    });

    currentSegmentIndex() + delta === 0
      ? (isPreviousButtonDisabled = true)
      : (isPreviousButtonDisabled = false);

    currentSegmentIndex() + delta === segments.length - 1
      ? (isNextButtonDisabled = true)
      : (isNextButtonDisabled = false);

    isOpen = false;
  }

  onMount(() => {
    isPreviousButtonDisabled = true;
  });
</script>

<style>
  .segmentSelector {
    @apply flex pb-3 -mx-3;
  }

  .segmentSelector button {
    @apply flex items-center;
  }

  .segmentSelector p {
    @apply font-AltisMedium text-xl;
  }

  button:disabled {
    @apply opacity-50;
  }
</style>

<div class="segmentSelector">
  <button
    class="px-1"
    disabled={isPreviousButtonDisabled}
    on:click={() => segmentSelect(-1)}
  >
    <div class="px-2 rotate-180 transform">
      <div class="w-8">
        {@html arrow}
      </div>
    </div>
    <p>{$_('seatMap.previousFlight')}</p>
  </button>

  <button
    class="px-1"
    disabled={isNextButtonDisabled}
    on:click={() => segmentSelect(1)}
  >
    <div class="px-2">
      <div class="w-8">
        {@html arrow}
      </div>
    </div>
    <p>{$_('seatMap.nextFlight')}</p>
  </button>
</div>

import { get } from 'svelte/store';
import {  payetihadUri, checkoutCurrentStatus } from '../../js/stores';
import {
  AMADEUS_CHECKOUT_ENDPOINT,
  AMADEUS_TOKEN_ENDPOINT,
  PAYMENT_STATUS_OK,
  PAYMENT_STATUS_NOT_EXIST
} from '../../js/const';
import { getAmadeusClientCredentials } from '../../js/services/config';
import logger from '../logger';

const baseUrl = get(payetihadUri);

let requestTokenBodyArray = [];

class RestfulAPIClient {
  
  async generateAmadeusToken() {
    try {
      const tokenBody = encodeTokenRequestBody();
      const oauth2 = await fetch(`${baseUrl}${AMADEUS_TOKEN_ENDPOINT}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Accept: '*/*',
          'Accept-Encoding': 'gzip, deflate, br',
          Connection: 'keep-alive',
        },
        body: tokenBody,
      });

      return await oauth2.json();
    } catch(e) {
      logger.warn('exception at: generateAmadeusToken, details: ', e)
    }
  }

  async checkoutForm(payload, access_token) {
    logger.info('inside checkoutForm. get(checkoutCurrentStatus) is: ' , get(checkoutCurrentStatus));

    if (`${get(checkoutCurrentStatus)}` !== PAYMENT_STATUS_OK 
      && `${get(checkoutCurrentStatus)}` !== PAYMENT_STATUS_NOT_EXIST) {

      logger.info('inside checkoutForm. inside if.. ');
      const checkoutForm = await fetch(`${baseUrl}${AMADEUS_CHECKOUT_ENDPOINT}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: '*/*',
          'Accept-Encoding': 'gzip, deflate',
          'Authorization':  `Bearer ${access_token}`
        },
        body: JSON.stringify(payload),
      });
      return await checkoutForm.json();
    }
  }

  async checkoutStatus(id, access_token) {
    if (`${get(checkoutCurrentStatus)}` !== PAYMENT_STATUS_OK 
      && `${get(checkoutCurrentStatus)}` !== PAYMENT_STATUS_NOT_EXIST) {

      const checkoutForm = await fetch(`${baseUrl}${AMADEUS_CHECKOUT_ENDPOINT}/${id}`, {
        method: 'GET',
        headers: {
          Accept: '*/*',
          'Accept-Encoding': 'gzip, deflate',
          'Authorization':  `Bearer ${access_token}`
        },
      });
      return await checkoutForm.json();
    }
  }
}

/**
 * This function will encode the request body of the Token Parameters
 * @returns 
 */
export function encodeTokenRequestBody () {
  const requestTokenBody = getAmadeusClientCredentials();
  
  for (var element in requestTokenBody) {
    let encodedKeyValue = encodeURIComponent(element) + "=" + encodeURIComponent(requestTokenBody[element]);
    requestTokenBodyArray.push(encodedKeyValue);
  };

  return requestTokenBodyArray.join("&");
}

export default new RestfulAPIClient();
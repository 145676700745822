import { resettable } from './extensions/resettable';

export const timeoutBlocked = resettable(false);

/**
 * Triggers the resetTimeout() function in Timeout.svelte.
 */
export function resetGlobalTimeout() {
  timeoutBlocked.set(true);
  timeoutBlocked.set(false);
}

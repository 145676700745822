import { OVERRIDE_SECRET } from './const';
import logger from './logger';

const SEPARATOR = '+';

function authenticate(secret) {
  return secret === OVERRIDE_SECRET;
}

export default class AgentAuth {
  constructor(barcode) {
    let decoded = '';
    try {
      decoded = atob(barcode);
    } catch (error) {
      logger.info('Could not base64 decode.', barcode, error);
    }
    const [epr, secret] = decoded.split(SEPARATOR);
    this.authenticated = authenticate(secret);
    this.epr = this.authenticated ? epr : null;
  }
}

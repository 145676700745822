<script>
  import { _ } from 'svelte-i18n';
  import { get } from 'svelte/store';
  import { location } from 'svelte-spa-router';
  import { onDestroy, onMount } from 'svelte';

  import { ApplicationFlow, FeatureFlags } from '../../js/const';
  import {
    appIsActive,
    applicationFlow,
    booking,
    CustomErrorModal,
    errorModal,
    featureFlags,
    headPassenger,
    timeoutBlocked,
  } from '../../js/stores';
  import { ErrorModals } from '../../js/const/errorModals';
  import events from '../../js/events';
  import flightdeck from '../../js/services/flightdeck';
  import flightdeckConsts from '../../js/services/flightdeck/const';
  import logger from '../../js/logger';
  import { routeName } from '../../js/utils';

  import ModalError from './ModalError.svelte';
  import PassportScanningFrame from './PassportScanningFrame.svelte';

  import warning from '../../svg/warning.svg';

  export let closeHandler;
  export let showModal = null;

  const hasCamera = featureFlags.isEnabled(FeatureFlags.CAMERA);
  const passenger = get(headPassenger);
  const timeoutRecoveryFailModal = new CustomErrorModal(
    ErrorModals.TIMEOUT_RECOVERY_FAIL,
  );

  let unbindDocumentScan = () => {};

  /**
   * Creates the values for the modal.timeoutRecovery.textLine1 string.
   *
   * @returns {object} - Object containing values for text string.
   */
  function buildTextValues() {
    const title = booking.getTranslatedTitle(passenger) || '';
    const firstName = passenger.firstName || '';
    const lastName = passenger.lastName || '';

    logger.info(
      `buildTextValues(): ${JSON.stringify({
        title,
        firstName,
        lastName,
      })}.`,
    );

    return {
      title,
      firstName,
      lastName,
    };
  }

  function showTimeoutRecoveryFailModal() {
    if (get(errorModal) == timeoutRecoveryFailModal) {
      logger.warn('Timeout Recovery Fail modal already open');
      return;
    }

    timeoutRecoveryFailModal.setOverrideHandler(flightdeckOverrideHandler);
    timeoutRecoveryFailModal.setFlightdeckHandler(
      flightdeckConsts.TransactionStatuses.Acknowledged,
      handleFlightDeckAcknowledged,
    );
    timeoutRecoveryFailModal.open();
    flightdeck.assistanceRequired(routeName(get(location)));
  }

  function handleFlightDeckAcknowledged(flightdeckAction) {
    return true;
  }

  /**
   * Handle a document scan event.
   *
   * Displays a 'Wait for assistance' modal if there was an error.
   * Otherwise, closes this modal to resume the application.
   *
   * @param {object} eventData - The eventData emitted with the event.
   */
  function handleDocumentScan(eventData) {
    const { isMatching } = eventData;

    if (!isMatching) {
      logger.info('Timeout recovery failed due to unmatched passport.');
      showTimeoutRecoveryFailModal();
    }

    closeHandler();
  }

  /** Handle the Flightdeck Override button. */
  function flightdeckOverrideHandler() {
    showModal = true;
  }

  onMount(() => {
    timeoutBlocked.set(true);
    unbindDocumentScan = events.onDocumentScan(handleDocumentScan);
  });

  onDestroy(() => {
    unbindDocumentScan();
    timeoutBlocked.set(false);
  });
</script>

<ModalError
  heading={$_('modal.timeoutRecovery.heading')}
  textLine1={$_('modal.timeoutRecovery.textLine1', {
    values: buildTextValues(),
  })}
  icon={warning}
>
  {#if ApplicationFlow.PORTER_BAG_DROP === get(applicationFlow)}
    {#if $appIsActive && hasCamera}
      <div class="flex justify-center mb-14 w-full">
        <div class="mx-12">
          <PassportScanningFrame />
        </div>
      </div>
    {/if}
  {/if}
</ModalError>

import { get } from 'svelte/store';

import { isObjEmpty, safeObjectAttribute } from '../utils';
import logger from '../logger';
import { resettable } from './extensions/resettable';
import switchboardClient from '../services/switchboard';

export const checkInPassengers = resettable([]);
export const checkInPassports = resettable([]);
export const checkInResponses = resettable([]);

export const didPassengersCheckInSuccessfully = resettable(false)

class CheckInPassengersManager {
  /**
   * Add a passenger to the check in list.
   *
   * Won't add passengers who are already added, or who have isCheckIn as true.
   *
   * @param {object} passenger - The Switchboard Passenger object.
   * @param {string} passportNumber - Passport number that was scanned.
   */
  addCheckInPassenger(passenger, passportNumber) {
    let added = false;
    if (isObjEmpty(passenger)) {
      logger.info(
        'Not adding an empty Passenger object to the check-in list.',
      );
    } else if (get(checkInPassports).includes(passportNumber)) {
      logger.info(
        'Not adding passenger because passport already used for ' +
          'another passenger. ' +
          `Passport number: ${passportNumber}.`,
      );
    } else if (passenger.isCheckedIn || this.isCheckInPassenger(passenger)) {
      logger.info(
        'Not adding passenger to check-in list. Passenger already added or ' +
          'already checked in. ' +
          `Passenger name: ${passenger && passenger.firstName} ${
            passenger && passenger.lastName
          }.`,
      );
    } else {
      logger.info(
        `Adding passenger ${passenger && passenger.firstName} ` +
          `${passenger && passenger.lastName} to check-in list.`,
      );
      checkInPassports.set([...get(checkInPassports), passportNumber]);
      checkInPassengers.set([...get(checkInPassengers), passenger]);
      added = true;
    }
    return added;
  }

  /** Return the list of adult passengers to be checked in.*/
  getAdults() {
    return get(checkInPassengers).filter(
      (passenger) => passenger.passengerType !== 'INFANT',
    );
  }

  /** Return the list of all passengers to be checked in including the infant.*/
  get() {
    return get(checkInPassengers);
  }

  /** Return passengers who have been checked in. */
  getCheckedInPassengers() {
    return get(checkInResponses).map(
      (checkInResponse) => checkInResponse.passenger,
    );
  }

  /** Determines if any passengers are identified as requiring to be checked in.
   *
   * @returns {boolean}
   */
  hasCheckInPassengers() {
    const checkInPassengerCount = get(checkInPassengers).length;
    return checkInPassengerCount > 0;
  }

  /** Whether or not a checkin via bag drop has failed for at least one passenger in the booking.
   *
   * @returns {boolean}
   */
  hasAnyFailure() {
    return get(checkInResponses).some(
      (response) => response.data.checkInPassenger.message,
    );
  }

  /**
   * Passengers for whom check-in failed.
   *
   * @returns {object[]}
   */
  failedPassengers() {
    return get(checkInResponses)
      .filter((response) => response.data.checkInPassenger.message)
      .map((response) => response.passenger);
  }

  /** Whether any checkins via bag drop were successful.
   *
   * @return Boolean
   */
  hasAnySuccess() {
    return get(checkInResponses).some(
      (response) => !response.data.checkInPassenger.message,
    );
  }

  /**
   * isCheckInPassenger.
   *
   * @returns {boolean}
   */
  isCheckInPassenger(passenger) {
    return get(checkInPassengers).some(
      (checkInPassenger) =>
        checkInPassenger.passengerIndex === passenger.passengerIndex,
    );
  }

  /**
   * Refresh passengers.
   *
   * @param {object} booking - The booking store.
   */
  refreshPassengers(booking) {
    checkInPassengers.set(
      get(checkInPassengers).map((passenger) =>
        booking.passengerByIndex(passenger.passengerIndex),
      ),
    );
  }

  /**
   * Reset the svelte stores.
   */
  reset() {
    checkInPassengers.reset();
    checkInPassports.reset();
    checkInResponses.reset();
  }

  /**
   * Check in all the passengers who are selected for check in.
   *
   * Returns a Promise that will resolve once all check-ins are complete.
   * The value of the resolved promise will be true to indicate that
   * ErrorModals.CHECKIN_FAILURE should be shown, false indicates complete
   * success.
   *
   * @param {object} booking - The booking store.
   * @param {object} flightdeck - The flightdeck service object.
   * @returns {promise} Promise resolving once all check-ins are complete.
   */
  performCheckIns(booking, flightdeck) {
    const passengers = this.getAdults();
    logger.info(
      `Preparing to check-in ${passengers && passengers.length} passengers.`,
    );
    const pendingCheckIns = passengers.flatMap((passenger) => {
      return switchboardClient
        .checkInPassenger(booking, passenger)
        .then((response) => {
          const switchboardMessage = safeObjectAttribute(
            response,
            'data.checkInPassenger.message',
          );
          if (switchboardMessage) {
            // if a message is present we have failed
            flightdeck.checkInFailed(passenger);
            if (passenger) {
              logger.info(
                'Passenger check-in failed: ' +
                  `firstName: ${passenger.firstName}; ` +
                  `lastName: ${passenger.lastName}; ` +
                  `passengerID: ${passenger.passengerID}; ` +
                  `switchboard message: ${switchboardMessage}.`,
              );
            }
            return false;
          } else {
            logger.info(
              `Checked in passenger: ` +
                `${passenger && passenger.firstName} ${passenger && passenger.lastName}.`,
            );

            response.passenger = passenger;

            checkInResponses.set([...get(checkInResponses), response]);
            return true;
          }
        });
    });
    return Promise.allSettled(pendingCheckIns.flat()).then(
      (promiseResults) => {
        let shouldShowErrorModal = false;

        const promisesRejected = promiseResults.some(
          (result) => result.status === 'rejected',
        );
        const switchboardReportedError = promiseResults
          .filter((result) => result.status === 'fulfilled')
          .some((result) => result.value === false);

        if (promisesRejected) {
          const promiseRejectionReasons = promiseResults
            .filter((promiseResult) => promiseResult.status === 'rejected')
            .map((promiseResult) => ({
              message: promiseResult.reason.message,
              stack: promiseResult.reason.stack,
            }));
          logger.warn(
            `Execution error checking-in ` +
              `${promiseRejectionReasons && promiseRejectionReasons.length} passengers. ` +
              `Reasons: ${JSON.stringify(promiseRejectionReasons)}.`,
          );
          flightdeck.appCheckInFailure();
          shouldShowErrorModal = true;
        } else if (switchboardReportedError) {
          shouldShowErrorModal = true;
        }

        return shouldShowErrorModal;
      },
    );
  }


  /**
   * Set a bool flag to denote any successful check-ins during a pax SBD transaction.
   */
  markPassengersCheckInSuccessfully()
  {
    didPassengersCheckInSuccessfully.set(true)
  }

  /**
   * Returns bool flag if passengers were able to successfully check in during the transaction.
   * @returns bool
   */
  getPassengersCheckInSuccessfully()
  {
    return get(didPassengersCheckInSuccessfully)
  }
}

export const checkInPassengersManager = new CheckInPassengersManager();

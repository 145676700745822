import { MILLISECONDS_PER_DAY } from '../../const';
import { dateToTimestamp } from '../../utils';

/** Encapsulte segment filtering logic. */
export class DisplayedSegmentsFilter {
  /**
   * Initialise.
   *
   * @param {object[]} segments - Array of Switchboard segments.
   * @param {string} baseStation - The airport code for the location of the kiosk.
   * @param {boolean} excludeCurrent - Should current segment be excluded.
   * @param {Boolean} shortCheckInDestiation  - Should pickup the first segment that is equivalent to the baseStation
   */
  constructor(segments, baseStation, excludeCurrent, shortCheckInDestination) {
    this.segments = segments;
    this.baseStation = baseStation;
    this.excludeCurrent = excludeCurrent;
    this.shortCheckInDestination = shortCheckInDestination;
  }

  /** Sort segments in datetime order. */
  sortByDateTime() {
    this.segments.sort(
      (a, b) =>
        dateToTimestamp(a.departureDateTime) -
        dateToTimestamp(b.departureDateTime),
    );
    return this;
  }

  /**
   * Perform a slice on the segments array.
   *
   * @param {Function} func - Used to find the index to slice by.
   * @param {boolean} fromFirst - If true, slice from the start of the array
   *                              until the index found by func. Otherwise,
   *                              slice from the index found by func until the
   *                              end of the array.
   */
  slice(func, fromFirst = true, shortCheckin = false) {
    const index = this.segments.findIndex(func);
    if (index === -1) {
      return;
    }
    let sliceArgs;
    if (shortCheckin) {
      sliceArgs = [index, index + 1];
    } else {
      sliceArgs = [index];
    }

    if (fromFirst) {
      sliceArgs.unshift(0);
    }
    this.segments = this.segments.slice(...sliceArgs);
  }

  /** Remove inward flights (flights prior to the base station). */
  removeInward() {
    this.slice((segment) => segment.departureCode === this.baseStation, false);
    return this;
  }

  /** Remove return flight (flight returning to base station). */
  removeReturn() {
    this.slice((segment) => segment.arrivalCode === this.baseStation);
    return this;
  }

  /** Remove flights after stop over (flights after 24 hour period). */
  removePostStopOver() {
    this.slice((segment, idx) => {
      const priorSegment = this.segments[idx - 1];
      return (
        priorSegment &&
        dateToTimestamp(segment.departureDateTime) -
          dateToTimestamp(priorSegment.departureDateTime) >=
          MILLISECONDS_PER_DAY
      );
    });
    return this;
  }

  /**
   * Remove the current flight (immediate flight from base station).
   *
   * If this.excludeCurrent is false, does nothing.
   */
  removeCurrent() {
    this.segments =
      this.excludeCurrent &&
      (this.segments[0] || {}).departureCode === this.baseStation
        ? this.segments.slice(1)
        : this.segments;
    return this;
  }

  /**
   * Take the current flight from shortCheckInDestionation .
   *
   * If shortCheckInDestination is null, does nothing
   * and return all the list of segments
   */
  filterShortCheckIn() {
    if (this.shortCheckInDestination) {
      this.slice(
        (segment) => segment.arrivalCode === this.shortCheckInDestination,
        false,
        true,
      );
    }
    return this;
  }
}

/* Store the bag tag numbers. */
/* eslint-disable import/prefer-default-export */
import { derived, get } from 'svelte/store';
import hasRecordInUseError from '../../js/recordInUseError';
import { ApplicationStep, appReport, FailedReason } from '../appReport';
import { booking, pnr } from './booking';
import { setErrorModal } from './errorModal';
import { ErrorModals } from '../const/errorModals';
import { firstItem } from '../utils';
import { headPassenger } from './headPassenger';
import logger from '../logger';
import { passengerBagCounts } from './passengerBagCounts';
import { receipt } from './receipt';
import { resettable } from './extensions/resettable';
import switchboardClient from '../services/switchboard';
import { uniqueBagIdentifierPriorityList } from '../../js/stores/bags';
import { baseStationCode } from '../../js/stores/config';
import { 
  isStaffForceAcceptanceEnforced
} from '../../js/stores/bags';

export const tagNumbers = resettable([]);

export const currentPassenger = {
  ...derived(headPassenger, ($headPassenger) => $headPassenger),
  ...{
    /* The IssueBagTag objects from SwitchBoard. */
    getBagTagObjects() {
      const { issueBagTags } = firstItem(get(tagNumbers).passengers || []) || {};
      return issueBagTags || [];
    },

    /* Bag tag numbers belonging to the passenger. */
    bagTags() {
      return this.getBagTagObjects().map((bagTagObject) =>
        'bagTagNumbers' in bagTagObject ? bagTagObject.bagTagNumbers : null,
      );
    },

    /* An individual IssueBagTag object selected by bagTagNumber. */
    getBagTagObject(bagTagNumber) {
      const issueBagTags = this.getBagTagObjects();
      return (
        issueBagTags.find(
          (issueBagTag) => issueBagTag.bagTagNumbers === bagTagNumber,
        ) || {}
      );
    },

    /**
     * Get and Return the sequence number of the current passenger.
     *
     * @returns {string} - passenger string number.
     */
    getSequenceNumber() {
      const passengerId = get(this).passengerID;
      const passenger = get(tagNumbers).passengers.find(
        (passengerTag) => passengerTag.passengerID === passengerId,
      );
      return passenger.sequenceNumber || 0;
    },

    /**
     * Return the short-check-in destination.
     *
     * @returns {string}
     */
    getShortCheckInDestination() {
      return get(tagNumbers).shortCheckinDestination || null;
    },

    /**
     * Return the short-check-in destination details.
     *
     * @returns {string}
     */
    getShortCheckInDestinationCity() {
      return get(tagNumbers).shortCheckinDestinationCity || null;
    },

    /* Total bag tags available for this passenger. */
    bagTagsTotal() {
      return this.bagTags().length;
    },

    /* The index of the current bag, 1-based. */
    currentBagCount() {
      return this.usedBagTags().length + 1;
    },

    /* Return Total Remaining Bags Count */
    remainingBagsCount() {
      return this.bagTagsTotal() - this.usedBagTags().length;
    },

    /* Return true if the passenger has loaded all their bags */
    isFinished() {
      return this.availableBagTags().length === 0;
    },

    /**
     * Used bag tag numbers by this passenger.
     *
     * A bag tag is considered used when it has been inserted into the receipt.
     */
    usedBagTags() {
      return this.bagTags().filter((bagTagNumber) => {
        return receipt.usedBagTagNumbers().includes(bagTagNumber);
      });
    },

    /** Bag tag number of the passenger which are not already used. */
    availableBagTags() {
      const bags = this.bagTags().filter((bagTagNumber) => {
        const usedBagTags = this.usedBagTags();

        const inList = usedBagTags.includes(bagTagNumber);
        const retval = !inList;
        return retval;
      }, this);
      return bags;
    },

    /** Return the next available bag tag number. */
    nextAvailableBagTag() {
      return firstItem(this.availableBagTags());
    },
  },
};

function getPassengerListForGenerateBagTags() {
  let passengers = [];
  let ffTierName = '';
  
  
  // const passengersWithoutInfants = headPassengerManager.sortPassengersWithoutInfants();
  // passengersWithoutInfants.forEach((passenger) => {
  get(booking).passengers.forEach((passenger) => {
    if (passenger.frequentFlyerInformation) {
      ffTierName = passenger.frequentFlyerInformation.tierName;
    }

    passengers.push({
      customerPrimeId: passenger.passengerID,
      cabin: passenger.cabin,
      tierName: ffTierName
    });
  });

  return passengers;
}

function getFlightDetailsWithNumberOfBags(numberOfBags, flightdeck) {
  const bookingDetails = get(booking);
  let finalSegment = bookingDetails.segments[bookingDetails.segments.length - 1];
  let offPoint = finalSegment?.arrivalCode;

  let productPrimeId = '';

   // this passenger will be sent to generateTagNumber service to generate tags  
   const passenger = get(currentPassenger);
  
   // if we could not get a passenger, end the transaction 
   logger.info('currentPassenger object is: ', passenger);
   logger.info('headPassenger is: ' , get(headPassenger));
  
   if (passenger && passenger.segments && passenger.segments.length > 0) {
     passenger.segments.forEach((segment) => {
       // we will pick up DID of last segment in the array.. 
       productPrimeId = segment.passengerDID;
     });
   } else {
     // if we could not get a passenger, end the transaction 
     logger.info('currentPassenger object is:', passenger);
     logger.info('headPassenger is: ' , get(headPassenger));
     
     flightdeck.issueBagTagFailed();
     setErrorModal(ErrorModals.BAG_TAG_ISSUANCE_ERROR);
     return;
   }

  logger.info('ProductPrimeID or passengerDID used for getting bag tags is: ', productPrimeId);

  return {
    numberOfBags,
    marketingCarrier: bookingDetails.airlineCode,
    flightNumber: bookingDetails.flightNumber,
    departureDate: bookingDetails.departureDate,
    boardPoint: bookingDetails.originCode,
    productPrimeId: productPrimeId,
    bookingReference: bookingDetails.bookingReference,
    offPoint
  };
}


/**
 * Retrieve inactive bag tags for the entire pool.
 *
 * The passengers will be based on the passengerBagCounts store.
 *
 * Populates the tagNumbers store.
 *
 * Displays the ASSISTANCE_REQUIRED error modal if there is a connection error.
 */
export function retrieveBagTags(flightdeck, baggageReference) {
  appReport.updateStepStart(ApplicationStep.GENERATE_BAG_TAGS);

  const passenger = get(headPassenger);

  logger.info(
    `Request issueBagTags. (passengerID: ${passenger && passenger.passengerID
    }, passengerIndex: ${passenger && passenger.passengerIndex}).`,
  );

  const currentBooking = get(booking);
  const numberOfBags = passengerBagCounts.totalBags();
  const bookingReference = currentBooking.bookingReference;
  const flightDetails = getFlightDetailsWithNumberOfBags(numberOfBags, flightdeck);
  const passengerList = getPassengerListForGenerateBagTags();
  const totalNumberOfIssuedBagTags = currentBooking.totalNumberOfIssuedBagTags;
  let serviceRetryCount = 0;
  
  if (currentBooking && currentBooking.baggageReference && currentBooking.isBaggageInfoForScannedPassenger) {
    addBaggageInformation(totalNumberOfIssuedBagTags, currentBooking.baggageReference, numberOfBags, flightDetails, bookingReference, passengerList, passenger.passengerType, passenger.lastName, passenger.passengerID, flightdeck, serviceRetryCount);
  } else {
    generateBagTags(bookingReference, flightDetails, passengerList, flightdeck, baggageReference, serviceRetryCount);
  }
}

function addBaggageInformation(totalNumberOfIssuedBagTags, baggageReference, numberOfBags, flightDetails, bookingReference, passengerList, passengerType, surname, customerPrimeId, flightdeck, serviceRetryCount) {
  
  let staffPassenger = get(booking).passengers.filter(x => x.isStaff);
  let isStaff = false;
  if (staffPassenger && staffPassenger.length > 0) {
    isStaff = true;
  }

  switchboardClient.addBaggageInformation(
    totalNumberOfIssuedBagTags,
    baggageReference,
    numberOfBags,
    flightDetails,
    bookingReference,
    passengerType,
    surname,
    customerPrimeId,
    get(booking).departureDateTime,
    false,
    baseStationCode,
    isStaff
  ).then((response) => {
    
    let addBaggageInformationResponse = response?.data?.addBaggageInformation;

    const errorMessages = addBaggageInformationResponse?.errorMessages;
    if (errorMessages && errorMessages?.length > 0) {
      logger.error('Error adding baggage information');

      // check if record in use error is received
      if (hasRecordInUseError(errorMessages)) {
        serviceRetryCount++;
        if (serviceRetryCount < 3) {
          logger.info('Record in use error received from AddBaggageInformation. waiting three seconds to recall the service')
          setTimeout(() => {
            addBaggageInformation(totalNumberOfIssuedBagTags, baggageReference, numberOfBags, flightDetails, bookingReference, passengerList, passengerType, surname, customerPrimeId, flightdeck, serviceRetryCount)
            return;
          }, 3000);
          return;
        } else {
          logger.info('Record in use error received from AddBaggageInformation. Maximum retry attempts reached. Ending transaction.')
        }
      }

      errorMessages.forEach((error) => {
        logger.error(error?.message);
      });
      flightdeck.addBaggageInformationFailed();
      setErrorModal(ErrorModals.ADD_BAGGAGE_INFORMATION_ERROR);
      return;
    }

    logger.info('staff force acceptance enforced is: ', addBaggageInformationResponse?.isStaffForceAcceptanceEnforced)
    isStaffForceAcceptanceEnforced.set(addBaggageInformationResponse?.isStaffForceAcceptanceEnforced)
        

    // Update the priority UBI list with new bags from add baggage info
    let currentUniqueBagIdentifierPriorityList = get(uniqueBagIdentifierPriorityList)
    let newUBIPriorityList = currentUniqueBagIdentifierPriorityList.concat(addBaggageInformationResponse?.uniqueBagIdentifierPriorityList)
    uniqueBagIdentifierPriorityList.set(newUBIPriorityList)
    
    // checking add bag response to see if we received a baggage reference for the board point 
    var bagRefList = addBaggageInformationResponse?.baggageReferenceList;
    var baggageReferenceFound = false;
    var newBaggageReference;
    if (bagRefList && bagRefList?.length > 0) {
      bagRefList.forEach(br => {
        if (br?.boardPoint?.toUpperCase() === baseStationCode?.toUpperCase()) {
          baggageReferenceFound = true;
          newBaggageReference = br?.baggageReference;
        }
      });
    }

    serviceRetryCount = 0;
    generateBagTags(
      bookingReference,
      flightDetails,
      passengerList,
      flightdeck,
      newBaggageReference ? newBaggageReference : baggageReference,
      serviceRetryCount);     
  })
  .catch((error) => {
    logger.error('Exception Received from AddBaggageInformation');
    flightdeck.addBaggageInformationFailed();
    setErrorModal(ErrorModals.ADD_BAGGAGE_INFORMATION_ERROR);
    return;
  });
}

function generateBagTags(bookingReference, flightDetails, passengerList, flightdeck, baggageReference, serviceRetryCount) {
  switchboardClient.generateTagNumber(flightDetails, bookingReference, passengerList, baggageReference)
    .then((response) => {
      const errorMessages = response?.data?.generateTagNumber?.errorMessages;
      if (errorMessages && errorMessages?.length > 0) {
        logger.error('Error generating bag tags');

        // check if record in use error is received
        if (hasRecordInUseError(errorMessages)) {
          serviceRetryCount++;
          if (serviceRetryCount < 3) {
            logger.info('Record in use error received from GenerateTagNumber service. waiting three seconds to recall the service')
            setTimeout(() => {
              generateBagTags(bookingReference, flightDetails, passengerList, flightdeck, baggageReference, serviceRetryCount)
              return;
            }, 3000);
            return;
          } else {
            logger.info('Record in use error received from GenerateTagNumber service. Maximum retry attempts reached. Ending transaction.')
          }
        }

        errorMessages.forEach((error) => {
          logger.error(error?.message);
        });
        flightdeck.issueBagTagFailed();
        setErrorModal(ErrorModals.BAG_TAG_ISSUANCE_ERROR);
        return;
      }

      tagNumbers.set(response.data.generateTagNumber);

      // Go through the tagNumbers and update the priority based on the ubiPriorityList
      let currentTagNumbers = get(tagNumbers)

      let ubiPriorityList = get(uniqueBagIdentifierPriorityList)

      logger.info("DEBUG: UBI Priority Tag List")
      logger.info(ubiPriorityList)

      currentTagNumbers.passengers?.forEach(pax => {
        pax?.issueBagTags?.forEach(bagTag => {
          let doesBagTagExistInPriorityList = ubiPriorityList.find(ubi => ubi === bagTag?.uniqueBagIdentifier)
          if(doesBagTagExistInPriorityList)
          {
            // NOTE: Must match the priority key in printBagTag function in cuss-components.js
            logger.info(`Priority bag tag found: number - ${bagTag?.bagTagNumbers}, UBI - ${bagTag?.uniqueBagIdentifier}`)
            bagTag.bagTypes?.push("PRIORITY")
          }
        });
      })

      logger.info(
        'The following bag tags were issued: ' +
        `${currentPassenger &&
        currentPassenger.availableBagTags &&
        typeof currentPassenger.availableBagTags === 'function' &&
        Array.isArray(currentPassenger.availableBagTags()) &&
        currentPassenger.availableBagTags().join(', ')
        }.`,
      );
    },
      (error) => {
        logger.warn('Generate Tag Number error received: ', error);
        appReport.updateStepFailed(
          ApplicationStep.GENERATE_BAG_TAGS,
          FailedReason.SWITCHBOARD_CALL_FAILED
        );
      })
    .catch((error) => {
      error = Array.isArray(error) && error.length > 0 ? error[0] : error;
      logger.warn(`Failed issueBagTags: (${error.message}).`);
      flightdeck.issueBagTagFailed();
      setErrorModal(ErrorModals.BAG_TAG_ISSUANCE_ERROR);
    });
}

<script>
  import { _ } from 'svelte-i18n';
  import { get } from 'svelte/store';
  import { onDestroy, onMount } from 'svelte';
  import { push } from 'svelte-spa-router';

  import events from '../../js/events';
  import flightdeck from '../../js/services/flightdeck';
  import logger from '../../js/logger';

  import { ApplicationStep, appReport } from '../../js/appReport';
  import {
    booking,    
    currentBag,
    currentPassenger,
    setErrorModal,
    wizardPosition,
  } from '../../js/stores.js';
  import {
    readyToReceive,
    sbdPausedPendingExcessBaggage,
  } from '../../js/stores/sbdState';
  import {
    WEIGHT_DATA_NOT_RECEIVED_TIMEOUT,
    WizardPosition,
  } from '../../js/const';
  import { ErrorModals } from '../../js/const/errorModals';
  import Button, { ButtonKinds } from '../components/Button.svelte';

  import Content from '../components/Content.svelte';
  import Footer from '../components/Footer/index.svelte';
  import Header from '../components/Header/index.svelte';
  import IconList from '../components/IconList.svelte';
  import VerificationInfo from '../components/VerificationInfo.svelte';
  import Video from '../components/Video.svelte';

  import checkCircle from '../../svg/checkCircle.svg';

  let showSelfServiceTagBagInstructions = true;
  let unsubscribeCurrentBag = () => {};
  let weightDataNotReceivedTimeoutId = null;
  let showNextButton = true;
  wizardPosition.set(WizardPosition.SECURITY);

  const placeBagItems = [
    { text: 'placeBag.iconList1' },
    { text: 'placeBag.iconList2' },
    { text: 'placeBag.iconList3' },
  ];
 
  const tagBagItems = [
    { text: 'tagBag.iconList1' },
    { text: 'tagBag.iconList2' },
    { text: 'tagBag.iconList3' },
    { text: 'tagBag.iconList4' },
  ];

  function startWeightDataNotReceivedTimeout() {
    const { weight } = get(currentBag);
    if (weight > 0) {
      logger.debug('Weight data has been received. Timeout is not required.');
      return;
    }

    logger.debug('Start weight-data-not-received timeout.');
    weightDataNotReceivedTimeoutId = setTimeout(() => {
      logger.warn(
        'No weight data has been received from scale device' +
          ` (timeout: ${WEIGHT_DATA_NOT_RECEIVED_TIMEOUT} seconds).`,
      );
      flightdeck.noWeightDataReceived();
      setErrorModal(ErrorModals.CONVEYOR_NO_WEIGHT_DATA_RECEIVED);
    }, WEIGHT_DATA_NOT_RECEIVED_TIMEOUT * 1000);
  }

  function stopWeightDataNotReceivedTimeout() {
    logger.debug('Stop weight-data-not-received timeout.');
    clearTimeout(weightDataNotReceivedTimeoutId);
  }

  const removeWeightDataReceivedHandler = events.onWeightDataReceived(
    stopWeightDataNotReceivedTimeout,
  );

  const showPlaceBagScreenTimeoutId = setTimeout(() => {
    showSelfServiceTagBagInstructions = false;
    startWeightDataNotReceivedTimeout();
  }, 26000); // 2 x 13 second video loop.

  let isPcBaggage = null;

  onMount(() => {
    appReport.updateStepStart(ApplicationStep.BAG_ASSESSMENT);

    isPcBaggage = booking.getBaggageUnit() === 'pc';
    
    sbdPausedPendingExcessBaggage.pause('Displaying the Place Passenger Bag screen');

    showNextButton = true;
       
    unsubscribeCurrentBag = currentBag.subscribe(($bag) => {
      if ($bag.settled && $bag.weight) {
        showNextButton = false;
        push('/dispatching-bag');
      }
    });
  });

  function onNextClick() {
    try {
      showNextButton = false;
      readyToReceive();
    } catch (e) {
      logger.info('Inside PlacePassengerBag onMount().. failed readyToReceive().. handle exception to continue...');
    }
  }

  
  onDestroy(() => {
    unsubscribeCurrentBag();
    removeWeightDataReceivedHandler();
    clearTimeout(showPlaceBagScreenTimeoutId);
    stopWeightDataNotReceivedTimeout();
  });
</script>

<Header />

<Content>
  <span slot="heading">
    {showSelfServiceTagBagInstructions
      ? $_('tagBag.heading')
      : $_('placeBag.heading')}
  </span>

  <div slot="main">
    <div class="mx-auto w-120">
      {#if showSelfServiceTagBagInstructions}
        <IconList class="mb-12" icon={checkCircle} items={tagBagItems} />
      {:else}
        <IconList class="mb-24" icon={checkCircle} items={placeBagItems} />
      {/if}
    </div>

    <div class="mx-56 pt-2 -mt-12">
      {#if showSelfServiceTagBagInstructions}
        <Video ratio="9:16" src="selfServiceTagBagNew" />
      {:else}
        <Video ratio="9:16" src="selfServicePlaceBag" />
      {/if}
    </div>

    {#if showNextButton}    
      <div style="width: 100%; text-align: center; margin-top: 10px">        
        <h3>{$_('placeBag.bagTaggedMoveToNextStep')}</h3>        
      </div>
      <div style="width: 100%; text-align: center; margin-top: 10px">        
        <Button
          kind={ButtonKinds.PRIMARY}
          on:click={onNextClick}
        >
          {$_('placeBag.next')}
        </Button>        
      </div>
    {/if}

    <!-- Force redraw of VerificationInfo when currentPassenger changes -->
    {#if $currentPassenger}
      <VerificationInfo isPcBaggage={isPcBaggage} />
    {:else}
      <VerificationInfo isPcBaggage={isPcBaggage} />
    {/if}
  </div>
</Content>

<Footer />

/* Class for excess-baggage-rate data */
/* eslint-disable import/prefer-default-export */
import { get } from 'svelte/store';
import logger from '../logger';
import { resettable } from './extensions/resettable';
import { booking } from './booking';
import { currentTotalWeight } from '../stores';
import { WeightUnit } from '../const';

export const integratedCatalogue = {
  ...resettable({}),
  ...{
    /**
     * Get excess baggage rate for the given excess-weight.
     *
     * @param {number} excessWeight - excess weight.
     * @returns {object} - The ancillary offer containing excess-baggage rate.
     */
    getExcessBaggageRate(excessWeight) {
      try {
        return this.getExcessBaggageRateInternal(excessWeight);
      } catch (error) {
        logger.error('Failed getting excess-baggage rate.', error);
        return {};
      }
    },

    /**
     * Get an excess baggage rate for the given excess-weight.
     * This is an internal method which should be used internally in the containing object.
     *
     * @param {number} excessWeight - excess weight.
     * @returns {object} - The ancillary offer containing excess-baggage rate.
     */
    getExcessBaggageRateInternal(excessWeight) {
      // if frequent flyer allowance exists, pick up integrated catalogue range
      // with total weights (for all the bags)
      const totalAllowanceWithoutFF = booking.totalAllowanceByTypeWithoutFF();
      const totalFFAllowance =  booking.totalFrequentFlyerAllowance();

      logger.info('Total allowance without ff is: ', totalAllowanceWithoutFF);
      logger.info('Total Frequent Flyer allowance is: ', totalFFAllowance);
      logger.info('The current total weight is: ', currentTotalWeight());
      logger.info('Total weight used in previous transactions is: ', booking.totalWeightUsedInPreviousTransaction());
      logger.info('Total allowance is ', booking.totalAllowanceByType());

      let parsedExcessWeight = 0;
      if (totalFFAllowance > 0) {
        let excessWeightForFF = (currentTotalWeight() + booking.totalWeightUsedInPreviousTransaction()) - booking.totalAllowanceByType();
        let excessWeightIncludingFF = excessWeightForFF + totalFFAllowance;
        if (excessWeightIncludingFF > 0) {
          parsedExcessWeight = excessWeightIncludingFF;
        }
        logger.info('With FF picking up Integrated Catalogue Range for total weight : ', parsedExcessWeight);
      } else {
        parsedExcessWeight = excessWeight > 0 ? excessWeight : 0;
        logger.info('Frequent Flyer allowance is 0, picking up Integrated Catalogue Range for only excess weight : ', parsedExcessWeight);
      }
       
      logger.info('picking up excess bag rate from integrated catalogue range: ', parsedExcessWeight);

      const { isGetSuccessful, ratesForWeightRangesList } = get(this);
      if (!isGetSuccessful || !Array.isArray(ratesForWeightRangesList)) {
        throw new Error(
          'Failed getting excess-baggage-rate data from switchboard.',
        );
      }

      const inputInfo =
        `parsedExcessWeight: ${parsedExcessWeight} kg` +
        `, provided excessWeight: ${excessWeight} kg`;

      let index = ratesForWeightRangesList.findIndex(r => r.maximumWeight >= parsedExcessWeight 
        && r.minimumWeight <= parsedExcessWeight);

      let matchedRates; 

      if (totalFFAllowance <= 0) {
        // since range array starts from 0th index
        index = index + 1; 
        matchedRates = ratesForWeightRangesList.filter((rateRange) => {
          const result =
            rateRange.ratePerKG &&
            rateRange.currency &&
            (rateRange.minimumWeight || rateRange.minimumWeight === 0) &&
            rateRange.maximumWeight &&
            rateRange.minimumWeight <= parsedExcessWeight &&
            parsedExcessWeight <= rateRange.maximumWeight;
          return result;
        });
      } else {
          // in frequent flyer case, pick up the first non zero rate
          ratesForWeightRangesList.forEach((rateRange) => { 
            if (!matchedRates && parseInt(rateRange.ratePerKG) > 0) {
                matchedRates = [rateRange];
            }
        });
      }

      logger.info(`The index of rate range found is ${index}`);
      logger.debug(`matchedRates: ${JSON.stringify(matchedRates)}. ${inputInfo}.`);

      if (matchedRates.length === 0) {
        throw new Error(
          `Could not find an excess-baggage rate for the given excess-weight. ${inputInfo}.`,
        );
      }

      const matchedRate = matchedRates[0];
      return {
        price: matchedRate.ratePerKG,
        currency: matchedRate.currency,
        unit: WeightUnit,
        slab: index
      };
    },
  },
};

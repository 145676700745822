<script>
  import { onMount, onDestroy } from 'svelte';

  import arrow from '../../svg/arrow.svg';

  export let containerRef;

  let containerScrollWidth;
  let containerScrollXPosition;
  let containerOffsetWidth;
  let hasHorizontalButtons = false;
  let isScrollButtonLeftShown = false;
  let isScrollButtonRightShown = false;
  let scrollAmount = 0;

  $: if (containerRef) {
    containerOffsetWidth = containerRef.offsetWidth;
    containerScrollWidth = containerRef.scrollWidth;

    if (containerScrollWidth > containerOffsetWidth) {
      hasHorizontalButtons = true;
    }

    if (hasHorizontalButtons) {
      isScrollButtonRightShown = true;
      containerRef.addEventListener('scroll', getContainerScrollXPosition);

      if (containerScrollXPosition > 0) {
        isScrollButtonLeftShown = true;
        if (
          containerScrollXPosition <
          containerScrollWidth - containerOffsetWidth
        ) {
          isScrollButtonRightShown = true;
        } else {
          isScrollButtonRightShown = false;
        }
      } else {
        isScrollButtonLeftShown = false;
      }
    }
  }

  function horizontalScrollLeftHandler() {
    containerRef.scrollLeft -= containerOffsetWidth / 2;
  }

  function horizontalScrollRightHandler() {
    containerRef.scrollLeft += containerOffsetWidth / 2;
  }

  function getContainerScrollXPosition() {
    containerScrollXPosition = containerRef.scrollLeft;
  }

  onDestroy(() => {
    if (hasHorizontalButtons) {
      containerRef.removeEventListener('scroll', getContainerScrollXPosition);
    }
  });

  /**
   * Horizontal Scroll Buttons component.
   *
   * Two buttons on each side of a container that scroll a horizontal distance on click.
   *
   * @example <caption>Example usage.</caption>
   * <div class="relative" bind:this={containerRef}>
   *   <HorizontalScrollButtons {containerRef} />
   * </div>
   *
   * @type {Object} containerRef. - the referenced container
   */
</script>

{#if isScrollButtonLeftShown}
  <button
    class="absolute bg-white bottom-0 left-0 -ml-2 top-0 z-5"
    role="button"
    on:click={horizontalScrollLeftHandler}>
    <div class="pr-4">
      <div class="rotate-180 transform w-8">
        {@html arrow}
      </div>
    </div>
  </button>
{/if}

{#if isScrollButtonRightShown}
  <button
    class="absolute bg-white bottom-0 -mr-2 right-0 top-0 z-5"
    role="button"
    on:click={horizontalScrollRightHandler}>
    <div class="pl-4">
      <div class="w-8">
        {@html arrow}
      </div>
    </div>
  </button>
{/if}

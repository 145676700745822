/**
 * Bindings to CUSS Components.
 *
 * All calls to the CUSS Applet should go via this module.
 *
 * Calls from the CUSS Applet to the UI application will use the methods
 * defined in cuss-bridge.js.
 */

import { tick } from 'svelte';
import { get } from 'svelte/store';
import { location } from 'svelte-spa-router';
import { 
  ApplicationStep, 
  appReport, 
  FailedReason, 
  EventType, 
  EventStatus } from './appReport';
import {
  appIsActive,
  appletRunning,
  applicationFlow,
  booking,
  infants,
  checkInPassengersManager,
  currentPassenger,
  featureFlags,
  headPassenger,
  ignoreMissingComponents,
  pnr,
  reprintBPManager,
  bpPassenger,
  receipt,
  setErrorModal,
  weightIsHeavy
} from './stores';
import {
  stripLeadingZeroes,
  dateTimeToDateFormatted,
  dateTimeToTime,
  getBannerMessage,
} from './utils';
import { baseStationCode, issuingDeviceCode } from './stores/config';
import { ApplicationFlow, FeatureFlags, FlightDeckMessages } from './const';
import { ErrorModals } from './const/errorModals';
import flightdeck from './services/flightdeck';
import logger from './logger';

import flushState from '../js/reset'
import { sleep } from '../js/utils';
import resetTransport from '../js/resetTransport';

const TEST_CHANGE = 'TEST_CHANGE';
const PRIORITY = 'PRIORITY';
const BOARDING_PASS = 'BOARDING_PASS';
const CUSTOM_PRINTER = 'CUSTOM';
const EPSON_PRINTER = 'EPSON';

let isHomeLocationFirstLoad = true;
let bagPieceWeight = '';

location.subscribe((newLocation) => {
  if (newLocation === '/') {
    if (isHomeLocationFirstLoad) {
      isHomeLocationFirstLoad = false;
      logger.log('Home-location first load.');
    } else if (get(appIsActive) === true) {
      logger.log(
        'Location has changed to Home. Run handleTransactionFinished.',
      );
      tick().then(() => handleTransactionFinished());
    }
  }
});

/*
 * Get the applet element from within the DOM.
 *
 * If the applet could not be loaded (eg. because of browser incompatibility),
 * then this will fail.
 */
function getApplet() {
  const applet = document.getElementsByTagName('applet').item(0);
  if (!applet) {
    throw 'Could not find applet element, is the applet loaded?';
  }
  return applet;
}

/** Get the EnvironmentLevel object from the CUSS Applet. */
export function getEnvLevel() {
  return getApplet().getEnvironmentLevel();
}

/** Get a binding to cussapplet.util.PrintBagtagExecutor. */
function getBagtagExecutor() {
  return getApplet().makeBagtagPrintExecutor();
}

/** Get a binding to cussapplet.util.PrintReceiptExecutor. */
export function getReceiptExecutor(printingType) {
  const flow = get(applicationFlow);
  const isSelfServiceFlow = flow === ApplicationFlow.SELF_SERVICE || flow === ApplicationFlow.HYBRID;
  if (isSelfServiceFlow) {
    return getApplet().makeReceiptPrintExecutor(printingType, CUSTOM_PRINTER);
  }
  return getApplet().makeReceiptPrintExecutor(printingType, EPSON_PRINTER);
}

/**
 * This will get the Bag Pieces and Weight
 *
 * @returns {string} bag piece and weight
 */
function getBagPieceWeight() {
  return (
    `${String(receipt.injectedBagCount()).padStart(2, '0')}/` +
    `${String(receipt.totalWeight()).padStart(3, '0')}`
  );
}

/** Handle transaction-finished event. */
export function handleTransactionFinished() {
  logger.info("Handle transaction finished called")
  const flow = get(applicationFlow);
  const isAppletRunning = get(appletRunning);
  const isSelfServiceFlow = flow === ApplicationFlow.SELF_SERVICE || flow === ApplicationFlow.HYBRID;

  appReport.transactionFinish();
  
  if (
    isAppletRunning &&
    (isSelfServiceFlow || featureFlags.isEnabled(FeatureFlags.FIT_TO_FLY))
  ) {
      logger.info("CUSS -> Requesting transaction finished.")
      getApplet().handleTransactionFinished();
      logger.info("Setting appIsActive to false.")

      appIsActive.set(false);

      // Resetting code here is required since now it is possible for a transaction to start and end fully on the home screen.
      const WAIT_FOR_TRANSPORT_RESET = 50; // milliseconds

      resetTransport();
      sleep(WAIT_FOR_TRANSPORT_RESET).then(() => {
        flushState();
      });
  } else {
    // TODO: When PSAM is supported on PBD’s CUSS platform, we should also run
    // CUSS-related transaction-finished logic for PBD flow.
    logger.warn(
      `CUSS-related transaction-finished logic is not run.`,
      `isAppletRunning=${isAppletRunning},`,
      `isSelfServiceFlow=${isSelfServiceFlow},`,
      `fitToFlyMode=${featureFlags.isEnabled(FeatureFlags.FIT_TO_FLY)}.`,
    );
  }
}

/** Get a binding to cussapplet.util.CussSBD. */
export function getCussSBD() {
  return getApplet().getCussSBD();
}

const ConveyorState = Object.freeze({
  READY: 0,
  INSERTION: 1,
  VERIFICATION: 2,
  PARKING: 3,
  next: (current) => {
    return (current + 1) % (ConveyorState.PARKING + 1);
  },
});

/** Advance the conveyor belt. */
export function progressSBD() {
  if (!get(appletRunning)) {
    return;
  }

  if (typeof progressSBD.state === 'undefined') {
    progressSBD.state = ConveyorState.READY;
  }

  if (typeof progressSBD.cussSBD === 'undefined') {
    progressSBD.cussSBD = getCussSBD();
  }

  try {
    switch (progressSBD.state) {
      case ConveyorState.READY:
        progressSBD.cussSBD.setBelt('InsertionBelt');
        progressSBD.cussSBD.callDirective('enable');
        break;
      case ConveyorState.INSERTION:
        progressSBD.cussSBD.setBelt('InsertionBelt');
        progressSBD.cussSBD.callDirective('disable');
        progressSBD.cussSBD.callDirective('forward');
        break;
      case ConveyorState.VERIFICATION:
        progressSBD.cussSBD.setBelt('VerificationBelt');
        progressSBD.cussSBD.callDirective('process');
        progressSBD.cussSBD.callDirective('forward');
        break;
      case ConveyorState.PARKING:
        progressSBD.cussSBD.setBelt('ParkingBelt');
        progressSBD.cussSBD.callDirective('forward');
        break;
    }
  } catch (exception) {
    logger.warn('Error communicating with CUSS conveyor', exception);
  }
  progressSBD.state = ConveyorState.next(progressSBD.state);
}

/** Print a bag tag. BTP*/
export function printBagTag(tagNumber, weight = 0) {
  appReport.updateStepStart(ApplicationStep.PRINT_BAG_TAGS);

  let eventDetails = { bagTagNumber: tagNumber }

  appReport.updateStepWithEventDetails(
    ApplicationStep.PRINT_BAG_TAGS,
    EventType.STEP_IN_PROGRESS,
    EventStatus.SUCCESS,
    eventDetails
  );

  if (!get(appletRunning)) {
    logger.warn('Aborting bag tag print because CUSS applet is not running.');
    appReport.updateStepFailed(ApplicationStep.PRINT_BAG_TAGS, FailedReason.NO_CUSS_APPLET);
    return;
  }

  logger.info('Attempting bag tag print.');
  const bagtagExecutor = getBagtagExecutor();
  const $pnr = get(pnr);
  const passenger = get(currentPassenger);
  const bagTagObject = currentPassenger.getBagTagObject(tagNumber);
  const airlineName = 'ETIHAD AIRWAYS';

  const segments = booking.getDisplayedSegments(
    false,
    null,
    bagTagObject.finalDestination || currentPassenger.getShortCheckInDestinationCity()
  );

  bagtagExecutor.flightDetails.paxName =
    get(currentPassenger).lastName + '/' + get(currentPassenger).firstName;
  bagtagExecutor.flightDetails.pnr = $pnr;
  bagtagExecutor.flightDetails.iataCarrierCodeBagNumber = bagTagObject.bagTagNumberPrefix + bagTagObject.bagTagNumbers;
  const showPriority = (bagTagObject.bagTypes || []).some(
    (type) => type === PRIORITY,
  );
  bagtagExecutor.flightDetails.showPriority = showPriority;
  bagtagExecutor.flightDetails.airlineName = airlineName;
  bagtagExecutor.flightDetails.bagWeight = weightIsHeavy(weight) ? weight : 0;
  bagtagExecutor.flightDetails.destDetailsList = [];
  bagtagExecutor.flightDetails.staffStandby = passenger.standByIndicator;
  bagtagExecutor.flightDetails.isStaffStandBy = passenger.isStaffStandBy;

  const flightDetails = segments
    .reverse()
    .map((segment) => [
      dateTimeToDateFormatted(segment.departureDateTime),
      segment.arrivalCode,
      segment.airlineCode,
      stripLeadingZeroes(
        booking.getDisplayedFlightNumber(segment.flightNumber),
      ),
      booking.getSequenceNumber(passenger, segment.flightNumber),
      segment.arrivalCity ||
      currentPassenger.getShortCheckInDestinationCity() ||
      segment.arrivalCode,
    ]);

  bagtagExecutor.destDetails = flightDetails;

  try {
    bagtagExecutor.executePrinting(get(ignoreMissingComponents));
    appReport.updateStepSuccess(ApplicationStep.PRINT_BAG_TAGS);
  } catch (error) {
    logger.warn('BTP print failure', error);
    flightdeck.bagTagPrintFailure(tagNumber);
  }
}

export function printBagTagTest(
  tagNumber = '1234567890',
  destinationCode = 'AAA',
  paxName = 'Aaaa/Bbbb',
  pnr = 'QWERTY',
  flightDate = '01-JAN-2020',
  showPriority = false,
  destDetails = ['01-JAN-2020, AAA, EY, 999'],
) {
  const bagtagExecutor = getBagtagExecutor();
  bagtagExecutor.flightDetails.finalDestCode = destinationCode;
  bagtagExecutor.flightDetails.paxName = paxName;
  bagtagExecutor.flightDetails.pnr = pnr;
  bagtagExecutor.flightDetails.flightDate = flightDate;
  bagtagExecutor.flightDetails.iataCarrierCodeBagNumber = tagNumber;
  bagtagExecutor.flightDetails.showPriority = showPriority;
  bagtagExecutor.flightDetails.destDetailsList = [];
  bagtagExecutor.destDetails = destDetails;

  try {
    bagtagExecutor.executePrinting(get(ignoreMissingComponents));
  } catch (error) {
    logger.warn('Bag Tag print error', error);
    appReport.updateStepFailed(ApplicationStep.PRINT_BAG_TAGS, FailedReason.PRINT_FAILED);
  }
}

window.printBagTagTest = printBagTagTest;

/**
 * Print a boarding pass.
 *
 * @param {Object} data - JS object containing all the data for the BP.
 */
function printBoardingPass(data) {
  appReport.updateStepStart(ApplicationStep.PRINT_BOARDING_PASS);

  logger.info(`Printing boarding pass with data: ${JSON.stringify(data)}.`);
  if (!get(appletRunning)) {
    logger.warn(
      'Aborting boarding pass print because CUSS applet is not running.',
    );
    appReport.updateStepFailed(ApplicationStep.PRINT_BOARDING_PASS, FailedReason.NO_CUSS_APPLET);
    return;
  }

  const receiptExecutor = getReceiptExecutor(BOARDING_PASS);
  receiptExecutor.boardingPass.agt = data.agt;
  receiptExecutor.boardingPass.arrivalDate = data.arrivalDate;
  receiptExecutor.boardingPass.arrivalTime = data.arrivalTime;
  receiptExecutor.boardingPass.arrivingCity = data.arrivingCity;
  receiptExecutor.boardingPass.barCode = data.barCode;
  receiptExecutor.boardingPass.boardingTime = data.boardingTime;
  receiptExecutor.boardingPass.className = data.className;
  receiptExecutor.boardingPass.departingCity = data.departingCity;
  receiptExecutor.boardingPass.departingTime = data.departingTime;
  receiptExecutor.boardingPass.departureDate = data.departureDate;
  receiptExecutor.boardingPass.ffTierName = data.ffTierName;
  receiptExecutor.boardingPass.flightNumber = data.flightNumber;
  receiptExecutor.boardingPass.gateNumber = data.gateNumber;
  receiptExecutor.boardingPass.infant = data.infant;  
  receiptExecutor.boardingPass.paxName = data.paxName;
  receiptExecutor.boardingPass.pieceWeight = data.pieceWeight;
  receiptExecutor.boardingPass.pnr = data.pnr;
  receiptExecutor.boardingPass.seatNumber = data.seatNumber;
  receiptExecutor.boardingPass.sequenceNumber = data.sequenceNumber;
  receiptExecutor.boardingPass.ticketNumber = data.ticketNumber;
  receiptExecutor.boardingPass.zoneNumber = data.zoneNumber;
  receiptExecutor.boardingPass.bannerMessage = data.bannerMessage;
  receiptExecutor.boardingPass.ssrCodeList = data.ssrCodeList;
  receiptExecutor.boardingPass.lounge = data.lounge;
  receiptExecutor.boardingPass.fareFamily = data.fareFamily;
  receiptExecutor.executeReceiptPrinting();

  const eventDetails = {context: {passengerName: data.paxName}}
  appReport.updateStepSuccessWithEventDetails(ApplicationStep.PRINT_BOARDING_PASS, eventDetails);
}

window.printBoardingPass = printBoardingPass;

/**
 *  This will prepare the bording pass data per passenger
 *
 *  @param {object} passenger - JS object containing one passenger.
 *  @param {string} baggagepieceWeight - JS object containing bag piece and weight.
 */
export function printPassengerBoardingPass(
  passenger,
  baggagepieceWeight = null,
) {
  const $pnr = get(pnr);
  const segments = booking.getDisplayedSegments(
    false,
    null,
    null,
  );
  let pieceWeight = baggagepieceWeight;
  segments.forEach((segment) => {
    const departuredate = dateTimeToDateFormatted(segment.departureDateTime);
    const departuretime = booking.getEstimatedDepartureTime(
      segment.flightNumber,
    );
    const arrivaldate = dateTimeToDateFormatted(segment.arrivalDateTime);
    const arrivaltime = dateTimeToTime(segment.arrivalDateTime);

    const pax = getBoardingPassDetails(passenger.passengerID, segment.passengerDID);

    const boardingPassValues = {      
      className:  pax?.cabinText ? pax?.cabinText : 'ECONOMY',
      ffTierName:  pax?.passengerFFTierName ? pax?.passengerFFTierName : "", 
      flightNumber: segment.airlineCode + stripLeadingZeroes(segment.flightNumber),

      departureDate: pax?.departureDate ? pax?.departureDate : departuredate,
      arrivalDate:  pax?.arrivalDate ? pax?.arrivalDate : arrivaldate,
      departingTime: pax?.departureTime ? pax?.departureTime : departuretime,
      arrivalTime: pax?.arrivalTime ? pax?.arrivalTime : arrivaltime,
      
      boardingTime: pax?.boardingTime ? pax?.boardingTime : 'TBA', 
      departingCity: pax?.originCity ? pax?.originCity : segment.departureCity,   
      arrivingCity: pax?.destinationCity ? pax?.destinationCity : segment.arrivalCity,
      gateNumber: pax?.gateNumber ? pax?.gateNumber : 'TBA',
      pnr: pax?.bookingReference ? pax?.bookingReference : $pnr,
      agt: baseStationCode + issuingDeviceCode,
      paxName:
        passenger.lastName +
        '/' +
        passenger.firstName +
        ' ' +
        passenger.title || '',
      ticketNumber: passenger.ticketNumber || '',
      pieceWeight: pieceWeight ? pieceWeight : '00/000',
      seatNumber: pax?.seatNumber ? pax?.seatNumber : 'NA',
      sequenceNumber: pax?.sequenceNumber ? pax?.sequenceNumber : 'NA',
      barCode: pax?.barcode ? pax?.barcode : 'NA',
      zoneNumber: pax?.zone ? `ZONE - ${pax?.zone}` : 'ZONE',
      infant: booking.passengerHasInfant(passenger)
        ? '*PLUS INFANT*'
        : undefined,
      bannerMessage: getBannerMessage(segment) ? getBannerMessage(segment) : '',
      ssrCodeList: pax?.ssrCodeList ? pax?.ssrCodeList : '',
      lounge: pax?.lounge,
      fareFamily: '' // pax?.fareFamily
    };
    printBoardingPass(boardingPassValues);
    pieceWeight = '';
  });
}

function getBoardingPassDetails(passengerID, passengerDID) {
  let bpArray = get(bpPassenger);
  const passenger = bpArray.filter((x) => x.passengerID === passengerID && x.passengerDID === passengerDID);
  return passenger[0];  
}

function convert12HourTo24HourFormat(time) {
  if (time.length === 7) {
    let hour = time.substring(0, 2);
    let minute = time.substring(3, 5);
    let meridiem = time.substring(5, 7);

    if (meridiem.toUpperCase() === 'PM' && hour !== '12') {
      hour = +hour + 12; //making it 24 hour
    } else if (meridiem.toUpperCase() === 'AM' && hour === '12') {
      hour = -hour + 12;
    }

    hour = hour.toString();

    if (hour.length === 1) {
      hour = '0' + hour.toString();
    }
    return hour + ':' + minute;
  } else {
    return time;
  }
}


/**
 * reprint Boarding Pass here.
 *
 * @param {Function} callback - Called after printing completes successfully.
 */
export function reprintBoardingPasses(callback) {
  logger.info('Attempting to reprint boarding passes');
  if (!booking.checkedInPassengers().length) {
    logger.info("No 'already checked in' passengers.");
    callback();
    return;
  }
  const handleFailure = createHandleFailureFunc(
    'Unexpected error reprinting Boarding Pass.'
  );

  try {
    bagPieceWeight = getBagPieceWeight();
    reprintBPManager
      .getRetrievalPromise(booking, infants)
      .then(() => {
        booking.checkedInPassengers().forEach((passenger) => {
          printPassengerBoardingPass(
            passenger,
            passenger === get(headPassenger) ? bagPieceWeight : '',
          );
        });
        callback();
      })
      .catch(handleFailure);
  } catch (error) {
    handleFailure(error);
  }
}

/** Print Boarding Pass newly checked-in passengers. */
export function printBoardingPasses() {
  logger.info(
    'Attempting to print boarding passes for newly checked-in passengers.',
  );

  try {
    bagPieceWeight = getBagPieceWeight();

    logger.info('Bag PieceWeight ', JSON.stringify(bagPieceWeight));

    const handleFailure = createHandleFailureFunc(
      'Unexpected error printing Boarding Pass for newly checked-in passengers.'
    );

    return reprintBPManager
      .getRetrievalPromise(booking, infants)
      .then(() => {
        checkInPassengersManager.get().forEach((passenger) => {
          printPassengerBoardingPass(
            passenger,
            passenger === get(headPassenger) ? bagPieceWeight : '',
          );
        });
      })
      .catch(handleFailure);
  }
  catch (error) {
    handleFailure(error);
  }
}

function createHandleFailureFunc(errorMsg) {
  return (error) => {
    logger.error(errorMsg, error);
    flightdeck.atbPrintFailure(get(booking), get(headPassenger));
    setErrorModal(ErrorModals.REQUEST_PRINT_FAILURE);
  };
}

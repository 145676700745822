import { get } from 'svelte/store';
import { appletRunning } from './stores';
import { HARDWARE_CHECK_TIME } from './const';
import flightdeck from './services/flightdeck';

// eslint-disable-next-line import/prefer-default-export
export function notifyForRunningApplet() {
  setTimeout(() => {
    const running = get(appletRunning);
    if (!running) {
      flightdeck.hardwareUnavailable();
    }
  }, HARDWARE_CHECK_TIME);
}

<script>
  import { createEventDispatcher } from 'svelte';
  import { get } from 'svelte/store';
  import { push } from 'svelte-spa-router';

  import { applicationFlow, CustomErrorModal } from '../../../js/stores';
  import { ApplicationFlow, OPERATOR_CODE } from '../../../js/const';
  import endTransaction from '../../../js/endTransaction';
  import { ErrorModals } from '../../../js/const/errorModals';
  import flightdeck from '../../../js/services/flightdeck';
  import flightdeckConsts from '../../../js/services/flightdeck/const';
  import { SeatPayment } from '../../../js/controllers';

  import CabinContainer from './CabinContainer.svelte';
  import Destination from './Destination.svelte';
  import Footer from './Footer.svelte';
  import LoadingAnimation from '../LoadingAnimation.svelte';
  import OtherAirlineMessage from './OtherAirlineMessage.svelte';
  import PassengerSelect from './PassengerSelect.svelte';
  import Payment from '../modal/Payment.svelte';
  import ScrollablePane from '../ScrollablePane.svelte';
  import ScrollablePaneHeader from './ScrollablePaneHeader.svelte';
  import SeatContent from './SeatContent.svelte';
  import SegmentSelector from './SegmentSelector.svelte';

  export let description = null;
  export let flightNumber = null;
  export let passenger = null;
  export let passengers = null;
  export let passengerSeats = null;
  export let previewSeat = null;
  export let hasError = null;
  export let isLoading = null;
  export let isOpen = null;
  export let seat = null;
  export let seatMapManager = null;
  export let segments = null;
  export let segment = null;
  export let selectedSeat = null;

  const BASE_REM = 16;
  const dispatch = createEventDispatcher();
  const SCROLLABLE_PANE_PBD_HEIGHT = 56.5; // rem
  const SCROLLABLE_PANE_SELF_SERVICE_HEIGHT = 49; // rem
  const seatSpace = 0.5; // rem
  const seatWidth = 3; // rem
  const spacerWidth = 3.25; // rem

  let subtractScrollablePaneHeaderHeights = null;
  let cabinHeight = null;
  let cabinWidth = null;
  let combinedSpacerWidth = null;
  let declinedPaymentModal;
  let hasScrollbar = false;
  let paneHeight = null;
  let scrollablePaneHeaderHeight = null;
  let selectorHeight = null;
  let seatPayment;
  let showPaymentModal = false;
  let showSuccess = false;

  $: if (showPaymentModal) {
    seatPayment = new SeatPayment(seat, handleAccepted, handleDeclined);

    seatPayment.addItem(
      Number(seat.getPrice()),
      Number(seat.getPrice()),
      seat.toString(),
      selectedSeat,
    );

    declinedPaymentModal = new CustomErrorModal(
      ErrorModals.SEAT_PAYMENT_FAILED,
    );

    declinedPaymentModal.setFlightdeckHandler(
      flightdeckConsts.TransactionStatuses.Cancelled,
      handleFlightDeckCancelled,
    );

    declinedPaymentModal.setEndHandler(handleFlightDeckCancelled);
  } else if (seatPayment) {
    seatPayment.cancelPolling();
  }

  $: airlineCode = segment ? segment.airlineCode : '';

  /** Calculate the total width of the space between seats. */
  $: combinedSpacerWidth =
    (seatMapManager ? seatMapManager.maxGroups() - 1 : null) * spacerWidth;

  /** Calculate how wide the seats inside the cabin should be. */
  $: cabinWidth = seatMapManager
    ? seatMapManager.maxSeats() * (seatWidth + seatSpace) + combinedSpacerWidth
    : null;

  /** Check for height change when components like Advertisement are active. */
  $: scrollablePaneHeaderHeight;

  /** Calculate the subtraction amount of height for ScrollablePaneHeader. */
  $: subtractScrollablePaneHeaderHeights =
    (selectorHeight + scrollablePaneHeaderHeight) / BASE_REM;

  /** Calculate the height for ScrollablePane. */
  $: paneHeight =
    get(applicationFlow) === ApplicationFlow.PORTER_BAG_DROP
      ? SCROLLABLE_PANE_PBD_HEIGHT - subtractScrollablePaneHeaderHeights
      : SCROLLABLE_PANE_SELF_SERVICE_HEIGHT -
        subtractScrollablePaneHeaderHeights; // rem

  /** Calculate if a scrollbar should be shown. */
  $: if (cabinHeight / BASE_REM > paneHeight) {
    hasScrollbar = true;
  }

  /** Handle a successful payment. */
  function handleAccepted() {
    showPaymentModal = false;
    showSuccess = true;
  }

  /** Handle a declined payment. */
  function handleDeclined() {
    flightdeck.seatChangeFailed();
    showPaymentModal = false;
    declinedPaymentModal.open();
  }

  /** Handle continuation after successful payment. */
  function handleContinue() {
    showSuccess = false;

    if (segments.length > 1 || passengers.length > 1) {
      dispatch('updateSelectedSeat', {
        passenger,
        seat: String(seat),
      });
    } else {
      advanceToNextScreen();
    }
  }

  /** Advance to the next screen as per the applicationFlow. */
  function advanceToNextScreen() {
    push('/number-of-bags');
  }

  /** Handle a cancellation action from FlightDeck. */
  function handleFlightDeckCancelled(flightdeckAction) {
    endTransaction();
    return true;
  }
</script>

<Payment
  controller={seatPayment}
  {handleContinue}
  bind:showModal={showPaymentModal}
  bind:showSuccess
/>

<div class="h-52">
  {#if isLoading}
    <LoadingAnimation class="mx-auto w-90" />
  {:else}
    <Destination {seatMapManager} {segment} />
  {/if}
</div>

<div bind:clientHeight={selectorHeight}>
  <PassengerSelect
    {flightNumber}
    {selectedSeat}
    {passengers}
    on:passengerChange
  />
  {#if segments.length > 1}
    <SegmentSelector bind:isOpen {segment} {segments} on:segmentSelect />
  {/if}
</div>

<div class="border border-current relative" dir="ltr">
  {#if isLoading}
    <div style="height: {paneHeight}rem;">
      <div class="flex h-full items-center px-18">
        <LoadingAnimation class="mb-8 w-full" />
      </div>
    </div>
  {:else if airlineCode && airlineCode !== OPERATOR_CODE}
    <OtherAirlineMessage {paneHeight} />
  {:else}
    <ScrollablePaneHeader
      bind:scrollablePaneHeaderHeight
      {cabinWidth}
      controller={seatPayment}
      {flightNumber}
      {hasScrollbar}
      {passengers}
      {seatMapManager}
      {seatSpace}
      {seatWidth}
      {seat}
      {segment}
      {spacerWidth}
    />
    <ScrollablePane
      {paneHeight}
      scrollablePaneHeaderHeight={scrollablePaneHeaderHeight / BASE_REM}
      on:scrollToSeat
    >
      <CabinContainer
        bind:cabinHeight
        bind:isOpen
        {cabinWidth}
        {passenger}
        {passengerSeats}
        {previewSeat}
        {seatMapManager}
        {seatSpace}
        {seatWidth}
        {selectedSeat}
        {spacerWidth}
        on:selectSeat
        on:scrollToSeat
      />
    </ScrollablePane>
    <SeatContent
      bind:isOpen
      bind:hasError
      {description}
      {passenger}
      {passengerSeats}
      {seat}
      on:openPaymentModal={() => {
        isOpen = false;
        showPaymentModal = true;
      }}
      on:updateSelectedSeat
    />
  {/if}
</div>

<Footer {advanceToNextScreen} bind:isOpen />

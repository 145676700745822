<script>
  import { get } from 'svelte/store';
  import { location } from 'svelte-spa-router';
  import { onDestroy, onMount } from 'svelte';

  import {
    awaitingTimeoutRecovery,
    errorModal,
    featureFlags,
    setErrorModal,
    timeoutBlocked,
  } from '../../js/stores';
  import { ErrorModals } from '../../js/const/errorModals';
  import { FeatureFlags } from '../../js/const';
  import flightdeck from '../../js/services/flightdeck';
  import { secondsToMilliseconds } from '../../js/utils';
  import {
    TimeoutWarning,
    TimeoutWarningDouble,
    TimeoutWarningExtended,
    TimeoutWarningShort,
  } from '../../js/const';

  import TimeoutModal from './TimeoutModal.svelte';
  import TimeoutRecoveryModal from './TimeoutRecoveryModal.svelte';

  let showModal = null;
  let showRecovery = null;
  let timeoutId = null;
  let timeoutWarning = null;

  const isTimeoutEnabled = featureFlags.isEnabled(FeatureFlags.GLOBAL_TIMEOUT);

  /** Routes that have no timeout. */
  const timelessRoutes = [
    '/',
    '/pnr-searching',
    '/porter-printing-bag-tag',
    '/printing-bag-tag',
    '/retrieving-bag-tags',
    '/searching',
    '/passport-verification/',
  ];

  /** Routes that use the default error modal settings from /errorModals.js. */
  const timeoutDefaultErrorModalRoutes = ['/passport-to-camera'];

  /** Routes that use double default time for timeout. */
  const timeoutDoubleTimeRoutes = ['/passport-to-camera'];

  /** Routes that use extended time for timeout. */
  const timeoutExtendedTimeRoutes = [
    '/place-passenger-bag',
    '/porter-place-passenger-bag-hybrid',
    '/porter-place-passenger-bag',
  ];

  /** Routes that use short time for timeout. */
  const timeoutShortTimeRoutes = ['/bag-drop-completed'];

  /**
   * Determine if the timeout is valid.
   *
   * Valid means that the timeout can be started or that
   * the timeout can result in an error modal.
   *
   * @returns {boolean}
   */
  function isTimeoutValid() {
    return (
      isTimeoutEnabled &&
      !get(timeoutBlocked) &&
      !onScreenWithoutTimer() &&
      !$errorModal
    );
  }

  /**
   * Handle the timeout.
   *
   * If the timeout is still valid, display the modal.
   */
  function handleTimeout() {
    if (isTimeoutValid()) {
      if (timeoutDefaultErrorModalRoutes.includes(get(location))) {
        setErrorModal(ErrorModals.TIMEOUT);
        flightdeck.passengerEndedFromTimeout();
      } else {
        showModal = true;
      }
    }
  }

  /** Reset the timer. */
  function resetTimer() {
    if (timeoutShortTimeRoutes.includes(get(location))) {
      timeoutWarning = TimeoutWarningShort;
    } else if (timeoutExtendedTimeRoutes.includes(get(location))) {
      timeoutWarning = TimeoutWarningExtended;
    } else if (timeoutDoubleTimeRoutes.includes(get(location))) {
      timeoutWarning = TimeoutWarningDouble;
    } else {
      timeoutWarning = TimeoutWarning;
    }

    clearTimeout(timeoutId);

    if (isTimeoutValid()) {
      timeoutId = setTimeout(
        handleTimeout,
        secondsToMilliseconds(timeoutWarning),
      );
    }
  }

  /** Reset the timer whenenver the user takes action. */
  function handleUserAction() {
    resetTimer();
  }

  /** Determine if on a screen without a timer. */
  function onScreenWithoutTimer() {
    return timelessRoutes.includes(get(location));
  }

  /** Reset the timer whenever the screen changes. */
  const unsubscribeLocation = location.subscribe((newLocation) => {
    resetTimer();
  });

  /** Reset the timer whenever the screen changes. */
  const unsubscribeTimeout = timeoutBlocked.subscribe(($timeoutBlocked) => {
    if (!$timeoutBlocked) {
      resetTimer();
    }
  });

  /** Handle Close button on recovery modal. */
  function handleRecoveryClose() {
    showRecovery = false;
    awaitingTimeoutRecovery.set(false);
    timeoutBlocked.set(false);
    resetTimer();
  }

  /** Handle Continue button on timeout modal. */
  function handleContinue() {
    awaitingTimeoutRecovery.set(true);
    showRecovery = true;
    showModal = false;
  }

  onMount(() => {
    showModal = false;
    showRecovery = false;
  });

  onDestroy(() => {
    handleRecoveryClose();
    unsubscribeLocation();
    unsubscribeTimeout();
  });
</script>

<svelte:window on:keydown={handleUserAction} on:mouseover={handleUserAction} />

{#if showModal}
  <TimeoutModal
    bind:showModal
    {timeoutWarning}
    on:TIMEOUT_CONTINUE={handleContinue}
  />
{/if}

{#if showRecovery}
  <TimeoutRecoveryModal bind:showModal closeHandler={handleRecoveryClose} />
{/if}

<script>
  import { _ } from 'svelte-i18n';

  import { range } from '../../../js/utils';
  import NavLink from './NavLink.svelte';

  export let currentPage;
  export let pageSize;
  export let totalItems;

  const pageCount = Math.ceil(totalItems / pageSize);
  const pageNumbers = range(pageCount, 1);
  const shouldDisplay = totalItems > pageSize;
</script>

{#if shouldDisplay}
  <div class="flex justify-end my-2 font-AltisMedium">
    <div class="my-3">{$_('app.page')}</div>
    {#each pageNumbers as pageNumber}
      <NavLink {pageNumber} {currentPage} on:setPage />
    {/each}
  </div>
{/if}

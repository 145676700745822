<script>
  import { onDestroy } from 'svelte';

  import { plusgradeAncillaryOfferManager } from '../../../../js/stores';
  import events from '../../../../js/events';
  import flightdeck from '../../../../js/services/flightdeck';
  import flightdeckConsts from '../../../../js/services/flightdeck/const';

  import CabinUpgrade from './content/CabinUpgrade.svelte';

  export let handleContinue = () => {};
  export let showModal = false;

  let stopListeningFlightdeckAction = () => {};

  $: if (showModal) {
    flightdeck.plusgradePending();
    stopListeningFlightdeckAction = events.onFlightdeckAction(
      (flightdeckAction) => {
        if (
          flightdeckAction.status ===
          flightdeckConsts.TransactionStatuses.Acknowledged
        ) {
          handleConfirmed();
        }
      }
    );
  } else {
    stopListeningFlightdeckAction();
  }

  /** Handle the Continue action from FlightDeck. */
  function handleConfirmed() {
    plusgradeAncillaryOfferManager.performBookingUpdate().then(() => {
      showModal = false;
      handleContinue();
    });
  }
</script>

<CabinUpgrade bind:showModal />

<script>
  import Message from '../../components/BagDropCompleted/Message.svelte';
  import Receipts from '../../components/BagDropCompleted/Receipts.svelte';

  export let passenger = null;
  export let showChangeEmailAddress = null;
  export let boardingPassPrintDataAvailable = null;
  export let awaitingBoardingPassResponse = null;
</script>

<Message {passenger} />
<Receipts
  {passenger}
  bind:showChangeEmailAddress
  bind:boardingPassPrintDataAvailable
  bind:awaitingBoardingPassResponse
  on:userInteractionFinished
/>

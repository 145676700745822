<script>
  import { _ } from 'svelte-i18n';
  import { get } from 'svelte/store';
  import { onDestroy, onMount } from 'svelte';

  import { ApplicationFlow, DEV_KIOSK_NAME } from '../../js/const';
  import {
    applicationFlow,
    getKioskName,
    startUpSuccessful,
  } from '../../js/stores';
  import flightdeck from '../../js/services/flightdeck';
  import logger from '../../js/logger';
  import { Resolution } from '../../js/services/config';

  import ResolutionModal from './ResolutionModal.svelte';

  const isPorterBagDrop =
    ApplicationFlow.PORTER_BAG_DROP === get(applicationFlow);

  //  for both flows 1) Self Service & 2) PBD On Embross, 
  // resolution will be the same, so keeping below condition as it is
  const device = isPorterBagDrop ? 'Porter Bag Drop' : 'Self Service';

  let unsubscribe;
  let resolution = null;
  let expectedResolution = null;
  let showModal = null;

  /** Detects resolution and displays error modal if incorrect. */
  function resolutionDetection() {
    const isDevMachine = window.location.hostname === 'localhost';
    const isDevKiosk = getKioskName() === DEV_KIOSK_NAME;

    resolution = `${window.screen.width} x ${window.screen.height}`;
    expectedResolution = `${Resolution.width} x ${Resolution.height}`;

    if (resolution === expectedResolution) {
      logger.info(`${device} Resolution: ${resolution}`);
    } else {
      logger.warn(
        `${device} Resolution incorrect. ${resolution} should be ${expectedResolution}.`,
      );

      if (!isDevMachine && !isDevKiosk) {
        flightdeck.resolutionIncorrect();
        showModal = true;
      }
    }
  }

  onMount(() => {
    showModal = false;

    unsubscribe = startUpSuccessful.subscribe((isStartUpSuccessful) => {
      if (isStartUpSuccessful) {
        resolutionDetection();
      }
    });
  });

  onDestroy(() => {
    unsubscribe();
  });
</script>

{#if showModal}
  <ResolutionModal {expectedResolution} {resolution} />
{/if}

<script>
  import { createEventDispatcher } from 'svelte';
  import { hotkey } from '../../js/actions';
  import bag from '../../svg/bag.svg';

  export let numberOfBags = '';

  const dispatch = createEventDispatcher();
</script>

<style>
  .bagsButton {
    @apply bg-charcoal-100
      font-AltisMedium
      h-29
      rounded
      mx-2
      w-48;
  }

  .bagNumber {
    @apply absolute
      font-AltisMedium
      inset-0
      mt-5
      text-1.625
      text-gold-100;
    z-index: 1;
  }
</style>

<button
  use:hotkey
  on:click={() => {
    dispatch('numberOfBagsSelection', { numberOfBags });
  }}
  class="bagsButton"
>
  <div class="relative">
    <p class="bagNumber">{numberOfBags}</p>
    <div class="mx-auto text-white w-18">
      {@html bag}
    </div>
  </div>
</button>

<script>
  import { _, locale } from 'svelte-i18n';
  import { booking } from '../../../js/stores/booking';
  import { dateFormat } from '../../../js/utils';
  import fromToIcon from '../../../svg/fromToIcon.svg';
</script>

<h3 class="border-b-2 border-gold-100 italic pb-2">
  {$_('bookingSummaryWidget.flights')}
</h3>

<div class="flex justify-between leading-1.8 uppercase mt-2">
  <div class="text-left">
    <p class="font-AltisMedium text-1.625">{booking.getDisplayedFlightCode()}</p>
    <div class="mt-2">
      {#if booking.isOnTime()}
        <div
          class="border-2 border-palmGreen leading-tight text-palmGreen
          uppercase text-center px-1"
        >
          <p>{$_('bookingSummaryWidget.onTime')}</p>
        </div>
      {:else}
        <div
          class="border-2 border-current leading-tight text-charcoal-100 uppercase
          text-center px-1"
        >
          <p>{$_('bookingSummaryWidget.delay')}</p>
        </div>
      {/if}
    </div>
  </div>

  <div class="flex text-left">
    <div class="text-right p-2">
      {@html fromToIcon}
    </div>
    <div class="font-AltisMedium text-left text-gold-100 text-xl">
      <p>
        {$booking.originCity
          ? `${booking.getTranslatedCity($booking.originCity, $locale)} (${
              $booking.originCode
            })`
          : $booking.originCode}
      </p>
      <p>
        {$booking.destinationCity
          ? `${booking.getTranslatedCity($booking.destinationCity, $locale)} (${
              $booking.destinationCode
            })`
          : $booking.destinationCode}
      </p>
    </div>
  </div>

  <div class="font-AltisMedium text-left text-xl">
    {#if $booking.departureDate}
      <p>{dateFormat($booking.departureDate)}</p>
    {/if}
    {#if $booking.arrivalDate}
      <p>{dateFormat($booking.arrivalDate)}</p>
    {/if}
  </div>

  <div class="font-AltisMedium text-left text-xl">
    <p>{$booking.departureTime ? $booking.departureTime : 'Unavailable'}</p>
    <p>{$booking.arrivalTime ? $booking.arrivalTime : 'Unavailable'}</p>
  </div>

  {#if $booking.departureGate}
    <p class="font-AltisMedium text-left text-xl">
      {`${$_('app.gate')} ${
        $booking.departureGate ? $booking.departureGate : '-'
      }`}
    </p>
  {/if}
</div>

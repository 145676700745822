/* Class for purchases data */
/* eslint-disable import/prefer-default-export */
/* eslint-disable max-classes-per-file */
import { get } from 'svelte/store';
import { resettable } from './extensions/resettable';

const purchases = resettable([]);

class Purchase {
  constructor(price, description, pnr, emdNumber, currency) {
    this.price = price;
    this.description = description;
    this.pnr = pnr;
    this.emdNumber = emdNumber;
    this.currency = currency;
  }
}

class PurchaseManager {
  havePurchases() {
    return get(purchases).length > 0;
  }

  addPurchase(price, description, pnr, emdNumber, currency) {
    purchases.set([
      ...get(purchases),
      new Purchase(price, description, pnr, emdNumber, currency),
    ]);
  }

  getPurchases() {
    return get(purchases);
  }

  reset() {
    purchases.reset();
  }
}

export const purchaseManager = new PurchaseManager();

<script>
  import { _, locale } from 'svelte-i18n';
  import { get } from 'svelte/store';

  import { currencyFormat } from '../../../js/utils';
  import { rightToLeft } from '../../../js/stores';

  import Button from '../../components/Button.svelte';

  import cabinUpgrade from '../../../svg/cabinUpgrade.svg';
  import checkCircle from '../../../svg/checkCircle.svg';
  import dutyFree from '../../../svg/dutyFree.svg';
  import excessLounge from '../../../svg/excessLounge.svg';
  import extraLegRoom from '../../../svg/extraLegRoom.svg';
  import neighbourFreeSeat from '../../../svg/neighbourFreeSeat.svg';
  import onboardWifi from '../../../svg/onboardWifi.svg';

  export let isSelected = false;
  export let product = {};

  let productIcon = null;
  let productName = null;

  if (product.isCabinUpgrade()) {
    productIcon = cabinUpgrade;
    productName = 'cabinUpgrade';
  }

  function selectHandler() {
    isSelected = !isSelected;
  }
</script>

<style>
  .tripExtrasItem {
    @apply -mx-11;
  }

  .tripExtrasItem.isSelected {
    @apply bg-charcoal-100 text-white;
  }
</style>

<button class="tripExtrasItem" class:isSelected on:click={selectHandler}>
  <div class="flex items-center {$rightToLeft ? 'pl-5 pr-10' : 'pl-10 pr-5'}">
    <div class="border-b border-gold-100 flex items-center py-4">
      <div class="w-18">
        {@html productIcon}
      </div>

      <div class="{$rightToLeft ? 'text-right' : 'text-left'} px-4 w-full">
        <h4 class="font-AltisMedium text-1.375">
          {$_(`tripExtras.${productName}.heading`)}
        </h4>
        <p class="leading-tight text-1.3">
          {$_(`tripExtras.${productName}.paragraph`)}
        </p>
      </div>
    </div>

    <div class="w-36 px-1">
      {#if product.getPrice()}
        {#if isSelected}
          <div class="bg-gold-100 mx-auto rounded-full w-10">
            {@html checkCircle}
          </div>
        {:else}
          <p class="text-center text-xl uppercase">
            {currencyFormat(
              $locale,
              product.getCurrencyCode(),
              product.getPrice(),
            )}
          </p>
          <Button isFull size="tiny">{$_('app.select')}</Button>
        {/if}
      {:else}
        <Button isFull size="tiny">{$_('app.seeOptions')}</Button>
      {/if}
    </div>
  </div>
</button>

/* Class for PaymentItem data */

// eslint-disable-next-line import/prefer-default-export
export class PaymentItem {
  constructor(paymentTransactionItems) {
    const {
      subCode,
      totalPrice,
      basePrice,
      description,
      quantity,
      serviceGroup,
      commercialName,
      eMDType,
      rficCode,
      vendor,
      equivalentPrice,
      interline,
      commission,
      assignedSeat,
      requestedSeat,
    } = paymentTransactionItems;

    this.subCode = subCode;
    this.totalPrice = totalPrice;
    this.basePrice = basePrice;
    this.description = description;
    this.quantity = quantity;
    this.serviceGroup = serviceGroup;
    this.commercialName = commercialName;
    this.eMDType = eMDType;
    this.rficCode = rficCode;
    this.vendor = vendor;
    this.equivalentPrice = equivalentPrice;
    this.interline = interline;
    this.commission = commission;
    this.assignedSeat = assignedSeat;
    this.requestedSeat = requestedSeat;
  }

  getSwitchboardObject() {
    return {
      subCode: this.subCode,
      totalPrice: this.totalPrice,
      basePrice: this.basePrice,
      description: this.description,
      quantity: this.quantity,
      serviceGroup: this.serviceGroup,
      commercialName: this.commercialName,
      eMDType: this.eMDType,
      rficCode: this.rficCode,
      vendor: this.vendor,
      equivalentPrice: this.equivalentPrice,
      interline: this.interline,
      commission: this.commission,
      assignedSeat: this.assignedSeat,
      requestedSeat: this.requestedSeat,
    };
  }
}

/** Injects context into logger. */
import { get } from 'svelte/store';
import { location } from 'svelte-spa-router';
import {
  appTransactionId,
  errorModal,
  featureFlags,
  getErrorModalDescriptiveName,
  getKioskName,
  headPassenger,
  pnr,
  transactionId,
} from '../stores';
import { isObjEmpty } from '../utils';

/**
 * Render application context for injection into logs.
 *
 * @returns {string} Application context.
 */
export default function context() {
  const passenger = get(headPassenger);

  return {
    PNR: get(pnr) || undefined,
    appTransactionId: get(appTransactionId) || undefined,
    errorModal: getErrorModalDescriptiveName() || undefined,
    featureFlags: {
      enabled: featureFlags.enabled || undefined,
      disabled: featureFlags.disabled || undefined,
    },
    headPassenger: isObjEmpty(passenger)
      ? undefined
      : {
          firstName: passenger.firstName || undefined,
          lastName: passenger.lastName || undefined,
          passengerID: passenger.ID || undefined,
        },
    kioskID: getKioskName() || undefined,
    routerLocation: get(location) || undefined,
    switchboardTransactionId: get(transactionId) || undefined,
  };
}

/**
 * Encapsulates feature flag access.
 */
import { FeatureFlags } from '../../const';
import { dedupeArray } from '../../utils';

const NEGATION_CHAR = '!';
const SEPARATOR = '.';

/**
 * Extract individual features from a '.' separated string.
 *
 * @param {string} featureString - A list of features separated by '.'.
 * @returns {string[]} The list broken into strings.
 */
function featureStringFeatures(featureString) {
  return featureString ? featureString.split(SEPARATOR) : [];
}

/**
 * Determine if a feature in the feature string is negated.
 *
 * A negated feature means it is explicitly turned off, this is indicated
 * by placing a '!' character at the front of the feature's name.
 *
 * @param {string} feature - The individual feature from a feature string.
 */
function isFeatureDisabled(feature) {
  return feature.charAt(0) === NEGATION_CHAR;
}

/**
 * Return an array of explicitly enabled features from a feature string.
 *
 * @param {string} featureString - A list of features separated by '.'
 * @returns {string[]} The array of enabled features.
 */
function featureStringFeaturesEnabled(featureString) {
  return featureStringFeatures(featureString).filter(
    (feature) => !isFeatureDisabled(feature),
  );
}

/**
 * Return an array of explicitly disabled features from a feature string.
 *
 * @param {string} featureString - A list of features separated by '.'
 * @returns {string[]} The array of enabled features.
 */
function featureStringFeaturesDisabled(featureString) {
  return featureStringFeatures(featureString)
    .filter((feature) => isFeatureDisabled(feature))
    .map((feature) => feature.replace(new RegExp(`^\\${NEGATION_CHAR}+`), ''));
}

/**
 * Check that a feature flag is valid.
 *
 * @param {string} feature - The feature name.
 * @returns {boolean} Whether or not the feature name is defined.
 */
function isValidFlag(feature) {
  return this.validFlags.includes(feature);
}

/**
 * Encapsulate feature flag access.
 */
export default class FeatureFlagsModel {
  /**
   * Set disabled/enabled values.
   *
   * Features that are negated in the feature string are prioritised over
   * enabled features.
   *
   * @param {string[]} validFlags - Flags which are considered valid.
   * @param {object} defaults - Object properties 'enabled' and 'disabled'.
   * @param {string} featureString - A '.' separated string of features.
   */
  constructor(validFlags, defaults, featureString = '') {
    this.validFlags = validFlags || [];
    defaults = defaults || {};
    const featureStringEnabled = featureStringFeaturesEnabled(featureString);
    const featureStringDisabled = featureStringFeaturesDisabled(featureString);
    this.enabled = [
      ...dedupeArray([...(defaults.enabled || []), ...featureStringEnabled]),
    ]
      .filter(isValidFlag, this)
      .filter((enabled) => !featureStringDisabled.includes(enabled))
      .sort();
    this.disabled = [
      ...dedupeArray([...(defaults.disabled || []), ...featureStringDisabled]),
    ]
      .filter(isValidFlag, this)
      .filter((disabled) => !featureStringEnabled.includes(disabled))
      .sort();
  }

  /**
   * Determine if a feature is enabled.
   *
   * @returns {boolean}
   */
  isEnabled(feature) {
    return this.enabled.includes(feature);
  }

  /**
   * Determine if a feature is disabled.
   *
   * @returns {boolean}
   */
  isDisabled(feature) {
    return this.disabled.includes(feature);
  }

  /** Feature string for querystring serialisation.
   * @returns {string}
   */
  toFeatureString() {
    return [
      ...this.enabled,
      ...this.disabled.map((feature) => `!${feature}`),
    ].join(SEPARATOR);
  }
}

<script>
  import { createEventDispatcher, onMount } from 'svelte';

  export let paneHeight = null;
  export let scrollablePaneHeaderHeight = null;

  const dispatch = createEventDispatcher();

  let scrollable = null;

  onMount(() => {
    dispatch('scrollToSeat', {
      scrollable,
    });
  });
</script>

<style>
  .scrollablePane {
    @apply h-full pr-5;
  }

  .scrollable {
    @apply overflow-x-hidden
      overflow-y-auto
      w-full;
  }

  .scrollable::-webkit-scrollbar {
    @apply w-5;
  }

  .scrollable::-webkit-scrollbar-thumb {
    @apply bg-charcoal-10
      rounded-full;
  }
</style>

<div class="scrollablePane">
  <div
    class="scrollable"
    style="max-height: {paneHeight}rem; margin-top: {scrollablePaneHeaderHeight}rem;"
    bind:this={scrollable}
  >
    <slot />
  </div>
</div>

<script>
  import { _ } from 'svelte-i18n';
  import { get } from 'svelte/store';
  import { onDestroy } from 'svelte';
  import { push } from 'svelte-spa-router';

  import Content from '../components/Content.svelte';
  import Footer from '../components/Footer/index.svelte';
  import Header from '../components/Header/index.svelte';
  import Loading from '../components/Loading.svelte';

  import { ApplicationStep, appReport } from '../../js/appReport';
  import { applicationFlow, tagNumbers, wizardPosition } from '../../js/stores';
  import { ApplicationFlow, WizardPosition } from '../../js/const';

  wizardPosition.set(WizardPosition.SECURITY);

  const unsubscribe = tagNumbers.subscribe((value) => {
    if (value && Object.values(value).length) {
      appReport.updateStepSuccess(ApplicationStep.GENERATE_BAG_TAGS);
      const flow = get(applicationFlow);
      switch (flow) {
        case ApplicationFlow.SELF_SERVICE:
          push('/printing-bag-tag');
          break;
        case ApplicationFlow.PORTER_BAG_DROP:
          push('/porter-place-passenger-bag');
          break;
        case ApplicationFlow.HYBRID:
          push('/porter-place-passenger-bag-hybrid');
          break;
      }
    }
  });

  onDestroy(() => {
    unsubscribe();
  });
</script>

<Header hasHomeButton={false} />

<Content>
  <div slot="main">
    <Loading heading={$_('app.pleaseWait')} />
  </div>
</Content>


<Footer />

<script>
  /** Porter Print Bag Tag.
   *
   * The screen first waits for the retrieval of the bag tags.
   * Once it knows it has bag tags, it will:
   * Activate a bag tag.
   * Print the activated bag tag.
   * Update internal state so that the bag tag is 'consumed' (calls toReceipt())
   * Once the bag tag is activated and printed, move to the next screen.
   */

  import { onDestroy, onMount } from 'svelte';
  import { get } from 'svelte/store';
  import { _ } from 'svelte-i18n';
  import { push } from 'svelte-spa-router';
  import { ErrorModals } from '../../js/const/errorModals';
  import {
    booking,
    currentBag,
    passengerBagCounts,
    setCurrentBagBagTagID,
    setErrorModal,
    tagNumbers,
    wizardPosition,
    currentPassenger,
    isFinalBag,
    transactionId    
  } from '../../js/stores';
  import { getAllProductDetails } from '../../js/stores/sbdState';
  import { AllowanceType, WizardPosition } from '../../js/const';
  import logger from '../../js/logger';
  import profiling from '../../js/profiling';
  import { printBagTag } from '../../js/cuss-components';
  import switchboardClient from '../../js/services/switchboard';
  import flightdeck from '../../js/services/flightdeck';

  import Content from '../components/Content.svelte';
  import Footer from '../components/Footer/index.svelte';
  import Header from '../components/Header/index.svelte';
  import Loading from '../components/Loading.svelte';

  import { baseStationCode } from '../../js/stores/config';

  let endProfilingForScreen = () => {};

  onMount(() => {
    wizardPosition.set(WizardPosition.SECURITY);

    logger.info('Entering PorterPrintingBagTag');
    endProfilingForScreen = profiling.start('PBD print bag tag screen');

    if (get(tagNumbers)) {
      const tagNumber = currentPassenger.nextAvailableBagTag();
      logger.info('Request activateBagTag.');
      setCurrentBagBagTagID(tagNumber);

      const endProfilingForRequest = profiling.start('PBD activate bag tag');

      const bagTagObject = currentPassenger.getBagTagObject(tagNumber);
      const totalWeight = getTotalWeightAllowed();
      const productsList = getAllProductDetails();
      const currentBagWeight = currentBag.getBagWeight();

      // TODO ADEEL : Amadeus, just for the last bag i am testing excess baggage.
      // so passing false to avoid activation of bag tag but want to update bag weight in DCS
      const activateBagTag = true; // isFinalBag() ? false : true;
      currentBag
        .activate(
          totalWeight,
          productsList,
          currentBagWeight,
          bagTagObject,
          activateBagTag,
        )
        .then((isTagActivated) => {
          if (isFinalBag()) {
            // if the passenger has declined payment, and the agent has overridden the excess
            // we would call CPR Identification, // get the XCI calculation id, call the Amadeus
            // overrideExcessService
            logger.info(
              'Allowable Excess is = ',
              get(currentBag).isAllowableExtra,
            );
            if (get(currentBag).isAllowableExtra === true) {
              // // we need to make a call to CPR Identification
              // // in order to get XCI (Excess calculation ID)
              // // That is a mandatory field for OverrideExcessBaggage
              const bookingSearchQuery = {};
              bookingSearchQuery.pnr = get(booking).bookingReference;
              switchboardClient
                .bookingSearch(bookingSearchQuery)
                .then((response) => {
                  switchboardClient
                    .overrideExcessBaggage({
                      calculationId:
                        response?.data?.searchBooking?.excessBaggageId,
                        baseStation: baseStationCode,
                        transactionId: get(transactionId)
                    })
                    .then((overrideExcessResponse) => {
                      const errorMessages =
                        overrideExcessResponse?.data?.overrideExcessBaggage
                          ?.errorMessages;
                      if (errorMessages && errorMessages?.length) {
                        logger.error(
                          'Errors received from overrideExcessBaggage api.',
                        );
                        errorMessages.forEach((error) => {
                          logger.error(error);
                        });
                        return;
                      } else {
                        endProfilingForRequest();
                        printBagTagAndNavigate(isTagActivated, currentBag, tagNumber);
                      }
                    });
                });
            } else {
              endProfilingForRequest();
              printBagTagAndNavigate(isTagActivated, currentBag, tagNumber);
            }
          } else {
            endProfilingForRequest();            
            printBagTagAndNavigate(isTagActivated, currentBag, tagNumber);
          }
        });
    }
  });

  function printBagTagAndNavigate(isTagActivated, currentBag, tagNumber) {    
    if (isTagActivated) {
      currentBag.toReceipt();
      const endProfilingForPrint = profiling.start('PBD print bag tag');
      printBagTag(tagNumber, currentBag.getBagWeight());
      endProfilingForPrint();

      push('/porter-checking-your-bag');
    } else {
      flightdeck.activateBagTagFailed();
      setErrorModal(ErrorModals.BAG_TAG_ACTIVATION_ERROR);
    }
  }

  onDestroy(() => {
    endProfilingForScreen();
  });

  /******************************************************************************
   * this code is a replica of the code in NumberOfBags.svelte
   * We need to put this at one place and call in here and in NumberOfBags.svelte
   * ****************************************************************************/
  function getTotalWeightAllowed() {
    let totalWeightAllowed = 0;
    if (booking.totalAllowanceByType(AllowanceType.EMD)) {
      totalWeightAllowed =
        totalWeightAllowed + booking.totalAllowanceByType(AllowanceType.EMD);
    }

    if (booking.totalAllowanceByType(AllowanceType.VCR)) {
      totalWeightAllowed =
        totalWeightAllowed + booking.totalAllowanceByType(AllowanceType.VCR);
    }

    if (booking.totalAllowanceByType(AllowanceType.PGCU)) {
      totalWeightAllowed =
        totalWeightAllowed + booking.totalAllowanceByType(AllowanceType.PGCU);
    }

    if (booking.totalAllowanceByType(AllowanceType.FF)) {
      totalWeightAllowed =
        totalWeightAllowed + booking.totalAllowanceByType(AllowanceType.FF);
    }

    return totalWeightAllowed;
  }
</script>

<Header hasHomeButton={false} />

<Loading heading={$_('app.pleaseWait')} />

<Content>
  <div class="mt-54" slot="main">
    <div class="absolute bottom-56 left-0 right-0">
      <div class="border-t border-gold-100 mx-18">
        <p class="font-bold mb-2 mt-6 text-1.375 uppercase">
          {$currentPassenger.lastName}, {$currentPassenger.firstName}
        </p>

        <p class="text-1.375">
          {$_('printingBagTags.bagTagPrinting', {
            values: {
              currentBagCount: String(currentPassenger.currentBagCount()),
              totalBags: String(passengerBagCounts.totalBags()),
            },
          })}
        </p>
      </div>
    </div>
  </div>
</Content>

<Footer />

<script>
  import { hotkey } from '../../js/actions';

  export let intent = '';
  export let kind = '';

  $: buttonProps = {
    intent,
    role: 'button',
    ...$$restProps,
    class: [
      'keypadButton',
      kind === 'wide' && 'keypadButton--wide',
      $$restProps.class,
    ]
      .filter(Boolean)
      .join(' '),
  };
</script>

<style>
  .keypadButton {
    @apply bg-white
      border
      border-current
      font-AltisMedium
      h-26
      m-2
      rounded
      text-3.5
      text-charcoal-100
      uppercase
      w-25;
  }

  .keypadButton:focus {
    @apply bg-gold-100 text-white;
    transform: scale(1.1);
  }

  .keypadButton--wide {
    @apply text-4xl w-54;
  }
</style>

<button {...buttonProps} on:click use:hotkey>
  <slot />
</button>

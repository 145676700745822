/* Profiling utility functions. */
import { v1 as uuidv1 } from 'uuid';
import logger from './logger';

export default {
  /**
   * Logs the start of a profiling interval.
   *
   * @param {string} label - A name for the profiling interval.
   * @returns {Function} A function for logging the end of the interval.
   */
  start(label) {
    const uuid = uuidv1();
    const profilingMsg = `Label: '${label}'; UUID: ${uuid}.`;
    logger.debug(`Profiling Start. ${profilingMsg}`);
    return () => {
      logger.debug(`Profiling End. ${profilingMsg}`);
    };
  },
};

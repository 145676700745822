/* eslint-disable import/prefer-default-export */
import { get, writable } from 'svelte/store';
import logger from '../../logger';
import { isFunction } from '../../utils';

const registry = [];

/**
 * A store that can be reset to its initial value.
 *
 * If initial is a function, it will be called every time
 * the store is reset.
 *
 * @param {any} initial - The initial value for the store.
 */
export function resettable(initial) {
  const getResetValue = isFunction(initial) ? initial : () => initial;
  const parent = writable(getResetValue());
  const reset = () => parent.set(getResetValue());
  registry.push(reset);
  return { ...parent, reset };
}

/**
 * Reset all the resettable stores.
 */
export function resetAllResettableStores() {
  logger.info('Resetting all resettable stores.');
  registry.forEach((reset) => reset());
}

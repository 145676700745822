<script>
  export let tabs = ['Inputs', 'Responses'];
  export let activeTab = tabs[0];
</script>

<nav class="bg-white px-8 shadow-md">
  <div class="-mb-px flex justify-start">
    <div
      class="uppercase italic text-gray-50 border-b-2 border-transparent
      tracking-wide font-bold text-xs py-3 mr-8">
      Debug Console
    </div>
    {#each tabs as tab}
      <div
        class="no-underline text-teal-dark border-b-2 border-gray-dark uppercase
        tracking-wide font-bold text-xs py-3 mr-8 cursor-pointer"
        class:border-teal-dark={activeTab === tab}
        class:border-transparent={activeTab !== tab}
        on:click={() => {
          activeTab = tab;
        }}>
        {tab}
      </div>
    {/each}
  </div>
</nav>

<script>
  import { _ } from 'svelte-i18n';
  import { get } from 'svelte/store';

  import { FARE_CODE_MAP } from '../../../js/const';
  import { booking, headPassenger, rightToLeft } from '../../../js/stores';

  import Item from './Item.svelte';

  import isometricCabinBaggage from '../../../svg/isometricCabinBaggage.svg';
  import isometricHandbag from '../../../svg/isometricHandbag.svg';

  const passenger = get(headPassenger);
  const isBusinessClass = FARE_CODE_MAP['Business'].includes(
    booking.getBookingCode(passenger)
  );
</script>

<div class="mb-10 px-2 {$rightToLeft ? 'text-right' : 'text-left'}">
  <div class="mb-10">
    <p class="mb-4 text-1.375 text-center">
      {$_('baggageAllowance.onboardBaggage')}
    </p>

    {#if isBusinessClass}
      <Item
        disclaimer={$_('baggageAllowance.cabinBaggage.disclaimer')}
        heading={$_('baggageAllowance.cabinBaggage.heading')}
        icon={isometricCabinBaggage}
        paragraph={$_('baggageAllowance.cabinBaggage.paragraph')}
        weight="12"
      />
      <Item
        heading={$_('baggageAllowance.handbag.heading')}
        icon={isometricHandbag}
        paragraph={$_('baggageAllowance.handbag.paragraph')}
        weight="5"
      />
    {:else}
      <Item
        heading={$_('baggageAllowance.cabinBaggage.heading')}
        icon={isometricCabinBaggage}
        paragraph={$_('baggageAllowance.cabinBaggage.paragraph')}
        weight="7"
      />
    {/if}

    <div class="border-b w-full" />

    {#if booking.passengerHasInfant(passenger)}
      <div class="border-b mb-8 w-full" />

      <div class="mb-4">
        <h3 class="mb-1">{$_('baggageAllowance.infants.heading')}</h3>
        <p class="text-xl">
          {$_('baggageAllowance.infants.disclaimer')}
        </p>
      </div>
    {/if}
  </div>
</div>

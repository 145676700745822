/** @file CUSS SBD constants. */

/** Status codes for CUSS components. */
export const CussStatusCodes = Object.freeze({
  EXCEPTION: -2,
  NOT_CALLED: -1,
  OK: 0,
  TIMEOUT: 1,
  CANCELLED: 3,

  BAGGAGE_FULL: 120,
  BAGGAGE_UNDETECTED: 121,
  BAGGAGE_PRESENT: 122,
  BAGGAGE_ABSENT: 123,
  BAGGAGE_OVERSIZED: 124,
  BAGGAGE_ILLICIT_WEIGHT_CHANGE: 125,
  BAGGAGE_READY_FOR_TAKE_IN: 126,
  BAGGAGE_TOO_MANY_BAGS: 127,
  BAGGAGE_DELIVER: 128,
  BAGGAGE_UNEXPECTED_BAG: 129,
  BAGGAGE_TOO_HIGH: 130,
  BAGGAGE_TOO_LONG: 131,
  BAGGAGE_TOO_FLAT: 132,
  BAGGAGE_TOO_SHORT: 133,
  BAGGAGE_PARKED: 134,
  BAGGAGE_INVALID_DATA: 135,
  BAGGAGE_TRANSPORT_FAILED: 136,
  BAGGAGE_WEIGHT_OUT_OF_RANGE: 137,
  BAGGAGE_JAMMED: 138,
  BAGGAGE_EMERGENCY_STOP: 139,
  BAGGAGE_RESTLESS: 140,
  BAGGAGE_TRANSPORT_BUSY: 144,
  BAGGAGE_MISTRACKED: 145,
  BAGGAGE_UNEXPECTED_CHANGE: 146,
  BAGGAGE_ACCEPTED: 147,
  BAGGAGE_DELIVERED: 148,
  BAGGAGE_INTERFERENCE_USER: 149,
  BAGGAGE_INTRUSION_SAFETY: 150,
  BAGGAGE_NOT_CONVEYABLE: 151,
});

export const BagLocations = Object.freeze({
  INSERTION: 'InsertionBelt',
  VERIFICATION: 'VerificationBelt',
  PARKING: 'ParkingBelt',
});

export const SBDBelts = Object.freeze({
  INSERTION: 'InsertionBelt',
  VERIFICATION: 'VerificationBelt',
  PARKING: 'ParkingBelt',
});

export const SBDStates = Object.freeze({
  BEFORE: 'Before',
  WAITING: 'Waiting',
  HAVE_WEIGHT: 'Have weight',
  HAVE_BAG_TAG: 'Have bag tag',
  HAVE_DIMENSIONS: 'Have dimensions',
  READY_TO_INJECT: 'Ready to inject',
  TAG_ACTIVATED: 'Tag activated',
  INJECTED: 'Injected',

  /** In this state, user actions are required to recover from an error. */
  USER_RECOVERABLE_ERROR: 'User recoverable error',

  INTERFERENCE_USER: 'Interference user',

  /**
   * In this state, application will try to recover from an error
   * without requiring user actions.
   */
  RECOVERY: 'Recovery',

  BAGGAGE_RESTLESS: 'Baggage restless',
});

export const CUSSAppStates = Object.freeze({
  UNAVAILABLE: 202,
  STOPPED: 204,
  DISABLED: 206,
  INITIALIZE: 207,
  AVAILABLE: 208,
  ACTIVE: 209,
});

/**
 * Return the status code name for a status code.
 *
 * @param {number} statusCode - The CUSS statusCode.
 * @returns {string} The name of the status code.
 */
export function statusCodeName(statusCode) {}

/**
 * Events to emit and receive within the application.
 *
 * This file will be broken into smaller files as it gets larger.
 */

import mitt from 'mitt';
import logger from './logger';

const CONVEYOR_EVENT = 'conveyorEvent';
const CONVEYOR_ERROR = 'conveyorError';
const DOCUMENT_SCAN = 'documentScan';
const FLIGHTDECK_ACTION = 'flightdeckAction';
const MRZ_SCAN = 'mrzScan';
const OVERRIDE_SCAN = 'overrideScan';
const VOICE_RECOGNITION = 'voiceRecognition';
const VOICE_RECOGNITION_STATUS = 'voiceRecognitionStatus';
const MULTI_BAG_TAG = 'multiBagTag';
const WEIGHT_DATA_RECEIVED = 'weightDataReceived';

const events = mitt();

const registry = [];

/**
 * Generic function to bind to an event.
 *
 * @param {string} eventName - The event name. Defined in Events in const.js.
 * @param {Function} func - The function to call when the event is received.
 * @param {boolean} persistent - Whether or not this should be persistent.
 * @returns {Function} - Call this to unbind the listener.
 */
function bind(eventName, func, persistent = false) {
  /** Actually bind to the event. */
  const bindFunc = () => {
    events.on(eventName, func);
    return () => {
      events.off(eventName, func);
    };
  };

  if (persistent) {
    if (eventName in registry) {
      throw new Error(
        'Attempted to register an event listener persistently, ' +
          'but there was already a persistent listener for this event. ' +
          `Received eventName: ${eventName}.`,
      );
    }
    registry[eventName] = bindFunc;
  }
  return bindFunc();
}

/**
 * Reregister all event listeners that were bound as persistent.
 */
function reRegisterPersistentHandlers() {
  logger.info(
    'Re-registering the following persistent event listeners: ' +
      `${registry && Object.keys(registry).join(', ')}.`,
  );
  Object.values(registry).forEach((bindFunc) => bindFunc());
}

/**
 * List of the eventNames of currently registered listeners.
 *
 * @returns {Array} - The list of eventNames.
 */
function currentListenerEventNames() {
  return [...events.all.keys()];
}

/** Unregister the all nonpersistent listeners. */
function resetEventListeners() {
  logger.info(
    'Unregistering the following event listeners: ' +
      `${
        currentListenerEventNames &&
        currentListenerEventNames() &&
        Array.isArray(currentListenerEventNames()) &&
        currentListenerEventNames().join(', ')
      }.`,
  );
  events.all.clear();
  reRegisterPersistentHandlers();
}

/**
 * Creates bind functions.
 *
 * @param {string} eventName - The eventName to bind to.
 * @returns {Function} A function that receives handlers to the event.
 */
function bindFactory(eventName) {
  return (func, persistent = false) => bind(eventName, func, persistent);
}

/**
 * Container class for Flightdeck actions received from Flightdeck.
 */
class FlightdeckAction {
  /**
   * Build the object.
   *
   * @param {object} transactionData - The flightdeck object for a transaction.
   * @param {string} status - The flightdeck status (TransactionStatuses)
   */
  constructor(transactionData, status) {
    this.transactionData = transactionData;
    this.status = status;
  }
}

export default {
  resetEventListeners,

  onConveyorEvent: bindFactory(CONVEYOR_EVENT),
  onConveyorError: bindFactory(CONVEYOR_ERROR),
  onMultiBagTag: bindFactory(MULTI_BAG_TAG),
  onWeightDataReceived: bindFactory(WEIGHT_DATA_RECEIVED),
  onDocumentScan: bindFactory(DOCUMENT_SCAN),
  onFlightdeckAction: bindFactory(FLIGHTDECK_ACTION),
  onMRZScan: bindFactory(MRZ_SCAN),
  onOverrideScan: bindFactory(OVERRIDE_SCAN),
  onVoiceRecognition: bindFactory(VOICE_RECOGNITION),
  onVoiceRecognitionStatus: bindFactory(VOICE_RECOGNITION_STATUS),

  /**
   * Emit an Override Scan event.
   *
   * @param {string} epr - The Agent's EPR (AgentID).
   */
  emitOverrideScan(epr) {
    events.emit(OVERRIDE_SCAN, { epr });
  },

  /**
   * Emit a Document scan event.
   *
   * @param {boolean} isMatching - Whether or not the scanned document matches
   *                               the document used to initiate the
   *                               transaction.
   */
  emitDocumentScan(isMatching) {
    events.emit(DOCUMENT_SCAN, { isMatching });
  },

  /**
   * Emit a MRZ scan event.
   *
   * @param {boolean} isKnown - Whether passport matches a passenger docS
   * @param {string[]} mrz - The MRZ
   * @param {documentNumber} documentNumber - The passport number.
   */
  emitMRZScan(isKnown, mrz, documentNumber) {
    events.emit(MRZ_SCAN, { isKnown, mrz, documentNumber });
  },

  /**
   * Emit a Flightdeck action event.
   *
   * @param {object} transactionData - Flightdeck object for the transaction.
   * @param {string} status - Flightdeck transaction status.
   */
  emitFlightdeckAction(transactionData, status) {
    events.emit(
      FLIGHTDECK_ACTION,
      new FlightdeckAction(transactionData, status),
    );
  },

  /**
   * Emit a Conveyor event.
   *
   * @param {string} belt - The belt.
   * @param {number} statusCode - CUSS statusCode.
   */
  emitConveyorEvent(belt, statusCode) {
    events.emit(CONVEYOR_EVENT, { belt, statusCode });
  },

  /**
   * Emit a Conveyor error event.
   *
   * @param {string} belt - The belt.
   * @param {number} statusCode - CUSS statusCode.
   */
  emitConveyorError(belt, statusCode) {
    events.emit(CONVEYOR_ERROR, { belt, statusCode });
  },

  /** Emit a multiple-bag-tag event. */
  emitMultiBagTag() {
    events.emit(MULTI_BAG_TAG);
  },

  /** Emit a weight-data-received event. */
  emitWeightDataReceived() {
    events.emit(WEIGHT_DATA_RECEIVED);
  },

  /**
   * Emit a voice recognition event.
   *
   * @param {string} eventData - User intent recognised from voice input.
   */
  emitVoiceRecognition(eventData) {
    events.emit(VOICE_RECOGNITION, eventData);
  },

  /**
   * Emit a voice recognition status update.
   *
   * @param {string} status - Listening or Processing
   */
  emitVoiceRecognitionStatus(status) {
    events.emit(VOICE_RECOGNITION_STATUS, status);
  },
};

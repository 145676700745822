<script>
  import { _ } from 'svelte-i18n';
  import { get } from 'svelte/store';
  import { location } from 'svelte-spa-router';
  import { onMount } from 'svelte';

  import { appReport, EventType, EventStatus, USER_ACTIONS } from '../../../js/appReport';
  import { errorModal, CustomErrorModal } from '../../../js/stores';
  import { ErrorModals } from '../../../js/const/errorModals';
  import flightdeck from '../../../js/services/flightdeck';
  import flightdeckConsts from '../../../js/services/flightdeck/const';
  import { routeName } from '../../../js/utils';
  import logger from '../../../js/logger';
  import {
    VoiceIntent,
    voiceRecognition,
  } from '../../../js/services/voicerec/voicerec';

  import helpCircle from '../../../svg/helpCircle.svg';
  import NavButton from './NavButton.svelte';

  let assistanceModal;

  /** Show Assistance Modal for passenger request. */
  function showAssistanceModal() {
    if (get(errorModal) == assistanceModal) {
      logger.warn('Assistance modal already open.');
      return;
    }

    flightdeck.assistanceRequired(routeName(get(location)));
    assistanceModal.open();
  }

  function handleFlightDeckAcknowledged(flightdeckAction) {
    return true;
  }

  function assistanceHandler() {
    logger.info('User pressed "Assistance" button.');
    appReport.addEventWithAction(
      EventType.USER_ACTION,
      EventStatus.SUCCESS,
      USER_ACTIONS.ASSISTANCE_BUTTON_PRESSED
    );
    showAssistanceModal();
  }

  onMount(() => {
    assistanceModal = new CustomErrorModal(
      ErrorModals.HELP_ASSISTANCE_REQUIRED
    );
    assistanceModal.setFlightdeckHandler(
      flightdeckConsts.TransactionStatuses.Acknowledged,
      handleFlightDeckAcknowledged
    );
  });
</script>

<NavButton
  icon={helpCircle}
  intent={VoiceIntent.ASSISTANCE}
  key={'P'}
  text={$_('app.help')}
  on:click={assistanceHandler}
/>

/* eslint-disable max-classes-per-file */
import { get } from 'svelte/store';
import logger from '../logger';
import { enableHotkeys } from './config';

let activeHotkeys = {};
let stash = [];
let labelTimeout = null;

const ALLOWED_CUSTOM_KEYS_REGEX = /^[A-Z0-9]$/;
const DEFAULT_KEYS = [...'ZXCVBNMASDFGHJKL'];
const SHOW_LABELS_KEYCODE = 190; // The '.' key.
const LABELS_DELAY = 3000; // milliseconds

/**
 * Encapsultes Hotkey data and methods.
 */
class Hotkey {
  /**
   * Stores the DOM node and the activeHotkeys instance.
   * @param {string} keyCharacter - The keyboard key for the hotkey.
   * @param {Node} node - The DOM node.
   * @param {object} registry - The activeHotkeys instance.
   */
  constructor(keyCharacter, node, registry) {
    this.keyCharacter = keyCharacter;
    this.node = node;
    this.registry = registry;
  }

  /**
   * Determine if this hotkey was initialised as a member of a registry.
   *
   * @param {object} registry - An instance of activeHotKeys.
   * @returns {boolean}
   */
  withinRegistry(registry) {
    return this.registry === registry;
  }

  /**
   * Perform the hotkey action, click on the DOM node.
   */
  action() {
    this.node.click();
  }

  /**
   * Create and show an on-screen label.
   *
   * If this hotkey already has a label, do nothing.
   */
  createLabel() {
    if (this.label) {
      return;
    }
    const label = document.createElement('p');
    label.setAttribute(
      'style',
      'position: absolute; ' +
        'color: orange; ' +
        'font-size: 40px; ' +
        'font-style: italic; ' +
        'padding-left: 1ex; ' +
        'padding-right: 1ex; ' +
        'background-color: rgba(0,0,0,0.5); ',
    );
    label.innerText = this.keyCharacter;
    this.node.parentNode.insertBefore(label, this.node);
    this.label = label;
  }

  /**
   * Delete a label and remove from DOM.
   *
   * If this hotkey doesn't have a label, do nothing.
   */
  clearLabel() {
    if (!this.label) {
      return;
    }

    this.node.parentNode.removeChild(this.label);
    delete this.label;
  }
}

/**
 * Manages the list of active hot keys
 */
class HotkeyManager {
  /**
   * Add a key listener using a supplied character.
   *
   * @param {object} node - The DOM node to be clicked (eg. a <button>).
   * @param {string} keyCharacter - A single character to use as a hotkey.
   *                                Must be in range A-Z or 0-9.
   * @returns {Function} Function for cancelling the hotkey.
   */
  addKey(node, keyCharacter = null) {
    if (!keyCharacter) {
      return this.addDefaultKey(node);
    }
    let clearFunc = () => {};
    try {
      if (keyCharacter.length !== 1) {
        throw new Error('Function addKey() expects a single character.');
      }
      if (!ALLOWED_CUSTOM_KEYS_REGEX.test(keyCharacter)) {
        throw new Error('Key is not supported, should be A-Z or 0-9.');
      }
      if (keyCharacter in activeHotkeys) {
        logger.debug(
          `Hotkey '${keyCharacter}' was already registered, overwriting.`,
        );
        hotkeyManager.clearKey(keyCharacter, activeHotkeys);
      }
      activeHotkeys[keyCharacter] = new Hotkey(
        keyCharacter,
        node,
        activeHotkeys,
      );
      clearFunc = ((registry) => {
        return () => {
          hotkeyManager.clearKey(keyCharacter, registry);
        };
      })(activeHotkeys);
    } catch (error) {
      logger.error(`Could not register custom key '${keyCharacter}'.`, error);
    }

    return clearFunc;
  }

  /**
   * Return the next available default key in the sequence, otherwise null.
   *
   * @returns {string} A single character string containing the next hotkey.
   */
  nextDefaultKey() {
    let nextKey = null;
    DEFAULT_KEYS.some((key) => {
      nextKey = key;
      return !(nextKey in activeHotkeys);
    });
    return nextKey;
  }

  /**
   * Set a hotkey node on the next available hotkey.
   *
   * @param {object} node - The DOM node to be clicked (eg. a <button>).
   * @returns {Function} For clearing the hotkey.
   */
  addDefaultKey(node) {
    const nextKey = this.nextDefaultKey();
    if (!nextKey) {
      logger.error('Ran out of default keys.');
      return null;
    }
    return this.addKey(node, nextKey);
  }

  /**
   * Clear a hotkey using its key character.
   *
   * @param {keyCharacter} keyCharacter - The keyboard character.
   * @param {object} registry - The registry that the key belongs to.
   */
  clearKey(keyCharacter, registry) {
    if (hotkeyManager.getHotkey(keyCharacter, registry)) {
      delete activeHotkeys[keyCharacter];
    }
  }

  /**
   * Handler to be supplied to on:keydown.
   *
   * @param {Event} event - The DOM event for the keydown.
   */
  handleKeydown(event) {
    if (!get(enableHotkeys)) {
      return;
    }
    const keyCharacter = String.fromCharCode(event.keyCode);
    if (event.keyCode === SHOW_LABELS_KEYCODE) {
      hotkeyManager.showLabels();
    } else {
      const hotkey = hotkeyManager.getHotkey(keyCharacter);
      if (hotkey) {
        hotkey.action();
      }
    }
  }

  /**
   * Temporarily display the hotkey keys next to their buttons.
   */
  showLabels() {
    clearTimeout(labelTimeout);
    labelTimeout = setTimeout(hotkeyManager.clearLabels, LABELS_DELAY);

    Object.values(activeHotkeys).forEach((hotkey) => {
      hotkey.createLabel();
    });
  }

  /**
   * Clear all hotkey labels.
   */
  clearLabels() {
    Object.values(activeHotkeys).forEach((hotkey) => {
      hotkey.clearLabel();
    });
  }

  /**
   * Get the Hotkey object for a key character.
   *
   * Ensures that the current activeHotkeys object matches the one that the
   * Hotkey was assigned to.
   *
   * @param {string} keyCharacter - A string containing the keyboard character.
   * @param {object} registry - The registry that the key belongs to.
   * @returns {Hotkey} The relevant Hotkey object, otherwise null.
   */
  getHotkey(keyCharacter, registry = null) {
    registry = registry || activeHotkeys;
    const hotkey = activeHotkeys[keyCharacter] || {};
    if ('withinRegistry' in hotkey && hotkey.withinRegistry(registry)) {
      return hotkey;
    } else {
      return null;
    }
  }

  /**
   * Clears the internal stash stack.
   */
  reset() {
    clearTimeout(labelTimeout);
    activeHotkeys = {};
    stash = [];
  }

  /**
   * Stash the hotkeys (for example, if a modal is opened).
   */
  stash() {
    hotkeyManager.clearLabels();
    stash.push(activeHotkeys);
    activeHotkeys = {};
  }

  /**
   * Pop the hotkey stash (for example, if a modal closes).
   */
  pop() {
    activeHotkeys = stash.pop() || {};
  }
}

export const hotkeyManager = new HotkeyManager();

<script>
  /** Porter Checking Your bag
   *
   * On this screen, the bag has already been weighed, the bag tag printed and
   * activated.
   * This screen waits simply waits for the bag to be lifted before proceeding
   * to the next screen.
   * This screen decides whether to accept another bag or to to proceed to the
   * end of the transaction.
   */
  import { get } from 'svelte/store';
  import { _ } from 'svelte-i18n';
  import { onDestroy, onMount } from 'svelte';
  import { push } from 'svelte-spa-router';
  import { ApplicationFlow } from '../../js/const';
  import { applicationFlow, booking, haveRemainingBags, currentBag } from '../../js/stores';
  import { ApplicationStep, appReport } from '../../js/appReport';

  import logger from '../../js/logger';

  import Footer from '../components/Footer/index.svelte';
  import Header from '../components/Header/index.svelte';
  import Loading from '../components/Loading.svelte';
  import VerificationInfo from '../components/VerificationInfo.svelte';

  logger.info('Entered PorterCheckingYourBag.');

  let isPcBaggage = null;

  onMount(() => {
    isPcBaggage = booking.getBaggageUnit() === 'pc';
  });

  const unsubscribe = currentBag.subscribe((bag) => {
    if (bag && bag.isLifted) {
      logger.info('Detected lifted bag.');
      appReport.updateStepSuccess(ApplicationStep.BAG_ASSESSMENT);

      // Bag has been lifted.
      if (haveRemainingBags()) {
        logger.info('Continuing loop');
        
        if (get(applicationFlow) === ApplicationFlow.HYBRID) {
          push('/porter-place-passenger-bag-hybrid');
        } else {
          push('/porter-place-passenger-bag');
        }
      } else {
        logger.info('End of loop.');
        push('/bag-drop-completed');
      }
    }
  });

  onDestroy(unsubscribe);

</script>

<Header hasHomeButton={false} />

<Loading heading={$_('app.pleaseWait')} />

<VerificationInfo  isPcBaggage={isPcBaggage} />

<Footer />

<script>
  import { get } from 'svelte/store';

  import { generateDummyMrz } from '../../js/utils';
  import { headPassengerManager, headPassenger } from '../../js/stores';
  import { onElementDestroy } from '../../js/actions';

  const cussBridge = window.cussBridge;
  const passengers = headPassengerManager.sortPassengers();

  let value = null;

  /** Inject a MRZ into the app via the cussBridge codepath. */
  function handleMrzInput(mrzTextareaValue) {
    if (mrzTextareaValue) {
      cussBridge.passportMRZ(...mrzTextareaValue.trim().split('\n'));
    }
  }

  /**
   * Handle click of the MRZ label.
   *
   * @returns {string} - The dummy mrz.
   */
  function generateDummyMrzHandler(passenger) {
    value = generateDummyMrz(passenger);
  }
</script>

<div class="flex items-center mt-8 w-14/15 mx-auto">
  <div class="w-3/12">
    <div class="pr-4">
      <label
        class="block text-base text-gray-500 font-bold text-right mb-1"
        for="passport"
      >
        MRZ
      </label>

      <div class="text-right">
        {#each passengers as passenger}
          <button
            class="font-bold mb-1 text-base text-gray-500 truncate w-full"
            on:click={() => {
              generateDummyMrzHandler(passenger);
            }}
          >
            {passenger.firstName}
            {passenger.lastName}
          </button>
        {/each}
      </div>
    </div>
  </div>

  <div class="w-9/12">
    <textarea
      class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 h-24 text-gray-500 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
      name="passport"
      type="text"
      use:onElementDestroy={handleMrzInput}
      bind:value
    />
  </div>
</div>

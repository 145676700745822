<script>
  //#region imports
  import { _ } from 'svelte-i18n';
  import { get } from 'svelte/store';
  import { onMount, onDestroy } from 'svelte';
  import { push } from 'svelte-spa-router';
  
  import { ApplicationStep, appReport } from '../../js/appReport';
  import {
    applicationFlow,
    anyUnmatchedInfantScans,
    booking,
    checkInPassengersManager,
    CustomErrorModal,
    featureFlags,
    headPassenger,
    headPassengerManager,
    infants,
    passengerMrzManager,
    passportNumbers,
    resetGlobalTimeout,
    setErrorModal,
    userInitiationMethod,
    wizardPosition,
  } from '../../js/stores';
  import {
    ApplicationFlow,
    FeatureFlags,
    TransactionInitiator,
    WizardPosition,
  } from '../../js/const';
  import { ErrorModals } from '../../js/const/errorModals';
  import events from '../../js/events';
  import endTransaction from '../../js/endTransaction';
  import flightdeck from '../../js/services/flightdeck';
  import flightdeckConsts from '../../js/services/flightdeck/const';
  import logger from '../../js/logger';
  import { VoiceIntent } from '../../js/services/voicerec/voicerec';
  import ChevronRight from '../components/Icons/ChevronRight.svelte';
  import Content from '../components/Content.svelte';
  import Footer from '../components/Footer/index.svelte';
  import FooterSecondary from '../components/FooterSecondary.svelte';
  import Header from '../components/Header/index.svelte';
  import PassengersList from '../components/PassengersList.svelte';
  import PassportScanningFrame from '../components/PassportScanningFrame.svelte';
  import Video from '../components/Video.svelte';

  import MRZHotkeys from '../components/MRZHotkeys.svelte';
  //#endregion imports

  wizardPosition.set(WizardPosition.PASSENGERS);

  /** The current paginated page. */
  const currentPage = 1;
  const hasCamera = featureFlags.isEnabled(FeatureFlags.CAMERA);
  const isPorterBagDrop =
    ApplicationFlow.PORTER_BAG_DROP === get(applicationFlow);
  const isHybridFlow = ApplicationFlow.HYBRID === get(applicationFlow);

  /** The amount of items to display until pagination begins. */
  const pageSize = 4;
  
  let adultOrInfantPassportScanErrorModal;
  let advancingToNextScreen = false;
  let isNextButtonActive;
  let isGuidedFlow;
  let moveToPage;
  let passengers = [];  
  let unbindMRZScan = () => {};

  /**
   * Update guided flow accordingly for the head passenger.
   *
   * If the head passenger is first on the list and they've already scanned
   * for the booking retrieval, then the user should be prompted to scan
   * the next passenger on the list.
   */
  function adjustGuidedFlowForInitialPassenger() {
    if (
      get(userInitiationMethod) === TransactionInitiator.PASSPORT &&
      getCurrentPassenger()?.passengerID === get(headPassenger)?.passengerID
    ) {
      nextPassenger();
    }
  }

  const unsubscribe = passportNumbers.subscribe((scannedPassport) => {
    isNextButtonActive = passportNumbers.anyMatchedAdults();
    
    if (passportNumbers.allPassengerPassportsScanned()) {
      logger.info('All passengers have scanned.');
      handleErrors();
    }
  });

  /**
   * Check if any passengers on the screen have scanned.
   *
   * Returns true if no passengers with docS strings have scanned,
   * or if no passengers without docS strings (passengers wishing to check in)
   * have scanned.
   *
   * @returns {boolean} Whether or not any passengers have scanned.
   */
  function noOneHasScanned() {
    return passportNumbers.noPassengersHaveScanned();
  }

  /** Check logic for any errors. */
  function handleErrors() {
    logger.info('Advancing from the passport to camera screen.');

    /** Prevent handleErrors() from being called twice. */
    if (advancingToNextScreen) {
      return;
    }
    advancingToNextScreen = true;

    if (noOneHasScanned()) {
      logger.warn(
        'Advanced through the guided flow without scanning a passenger.',
      );
      undoNextPassenger();
      setErrorModal(ErrorModals.ASSISTANCE_REQUIRED);
    } else if (anyUnmatchedInfantScans()) {
      logger.warn('Adult / infant passport scan failed.');
      undoNextPassenger();
      flightdeck.unmatchedScanForAdultInfantPair();
      adultOrInfantPassportScanErrorModal.open();
    } else {
      advanceToNextScreen();
    }
  }

  /**
   * Get unscanned passengers.
   *
   * @returns {Array} The unscanned passengers.
   */
  function getUnscannedPassengers() {
    let unscannedPassengers = [];
    passengers.forEach((passenger) => {
      if (!passportNumbers.passengerIsScanned(passenger)) {
        unscannedPassengers.push(passenger);
      }
    });

    return unscannedPassengers;
  }

  /**
   * Decrement the passenger index.
   *
   * Ensures the name shown in the guided flow prompt
   * behind the error modal, is still correct.
   */
  function undoNextPassenger() {
    currentPassengerIndex -= 1;
    advancingToNextScreen = false;
  }

  /** Advance to the next screen. */
  function advanceToNextScreen() {
    appReport.updateStepSuccess(ApplicationStep.OTHER_PASSENGERS_PASSPORT_SCAN);
    passportNumbers.cullUnscannedPassengers();
    
    if (booking.isSinglePassengerTransaction()) {
      const headPax = get(headPassenger);
      if (headPax) {
        booking.setPNRInBooking(headPax.bookingReference);
        booking.setPNR(headPax.bookingReference);
      }
    }
    
    push(
      booking.isSinglePassengerTransaction()
        ? '/welcome-single'
        : '/scan-passport',
    );
  }

  let currentPassengerIndex = 0;
  let currentPassengerValues = {};

  $: {
    currentPassengerIndex;
    passengers;
    currentPassengerValues = {
      ...((passenger) => {
        return {
          title: booking.getTranslatedTitle(passenger) || '',
          firstName: passenger.firstName || '',
          lastName: passenger.lastName || '',
        };
      })(getCurrentPassenger()), // for isGuidedFlow = true, we do not pass passport number
    };
  }

  const getCurrentPassenger = (passportNumber) => {
    if (isGuidedFlow) { // All pax not checked in
      return passengers[currentPassengerIndex] || {};
    } else {
      let currentPax = {};
      if (passportNumber) {
        let b = get(booking);      
        if (b && b.passengers) {
          b.passengers.forEach((pax) => {          
            if (pax.passportNumber === passportNumber) { 
              currentPax = pax;
            }
          });
        }      
      }
      return currentPax;
    }
  };

  /**
   * Listen for the MRZ event.
   *
   * If this is an unguided flow and the passport does not match a known
   * passenger, show the error modal.
   *
   * If this is a guided flow, and we know the currently prompted passenger's
   * passport number, and the passport scanned does not match it, show the
   * error modal.
   *
   * Otherwise, if this is a passenger who isn't checked-in, add them to the
   * checked in list.
   *
   * If the passenger's passport didn't match a known passenger, then
   * we will perform a timatic check using the prompted passenger's details.
   *
   * If this was not an out of order scan, increment the the passenger index to
   * prompt for the next passenger.
   */
  function handleMrz(mrzEvent) {
    const currentPassenger = getCurrentPassenger(mrzEvent?.documentNumber);
   
    // for a transaction where we have a mix of checked in and not checked in passengers
    // if current passenger is checked in, his passport number retrieved from amadeus 
    // is not same as the scanned passport, then we raise an error
    if (isGuidedFlow && 
        currentPassenger?.passportNumber && 
        currentPassenger?.passportNumber !== mrzEvent?.documentNumber) {
        setErrorModal(ErrorModals.PASSPORT_NOT_PROMPTED_USER);
        logger.info('Scanned passport does not match the passenger prompted on screen in the guided flow.');
        // also removing this passport from the passportNumbers array
        passportNumbers.remove(mrzEvent?.documentNumber);
        return;
    }

    const currentPassengerMatchesPassportNumber =
      booking.isPassengerDocSMatchingPassportNumber(
        currentPassenger,
        mrzEvent.documentNumber,
      );

    const outOfOrderScan =
      mrzEvent.isKnown && !currentPassengerMatchesPassportNumber;

    const passportNotInBooking = !isGuidedFlow && !mrzEvent.isKnown;
    
    const passenger = outOfOrderScan
      ? booking.passengerMatchingDocSPassportNumber(mrzEvent.documentNumber)
      : currentPassenger;

    if (passportNotInBooking) {
      setErrorModal(ErrorModals.PASSPORT_NOT_IN_BOOKING);
      logger.info('Scanned passport does not match any passengers in booking, Passports must match in a non-guided');
      return;
    } else if (
      isGuidedFlow &&
      booking.passportNumberMatchesAnyOtherPassenger(currentPassenger, mrzEvent.documentNumber)
    ) {
      setErrorModal(ErrorModals.PASSPORT_NOT_PROMPTED_USER);
      logger.info('Scanned passport does not match the passenger prompted on screen in the guided flow.');
      return;
    }

    passportNumbers.insert(mrzEvent.documentNumber);

    const addedPassengerMrz = passengerMrzManager.addPassengerMrz(
      passenger,
      mrzEvent.mrz,
    );

    checkInPassengersManager.addCheckInPassenger(
      passenger,
      mrzEvent.documentNumber,
    );

    const gender = booking.getPassengerGender(passenger);

    if (!gender) {
      setErrorModal(ErrorModals.ASSISTANCE_REQUIRED);
      logger.info(
        'Could not determine gender from passport on passport scan screen.',
      );
      return;
    }

    // updating EditCPR for one passenger for testing
    let passportExpiry = mrzEvent.mrz[1].substring(21, 27);
    // // we only get 6 digits date of expiry from MRZ, we need to append first two digits of year
    const currentDate = new Date();
    passportExpiry =
      currentDate.getFullYear().toString().substring(0, 2) + passportExpiry;
    const passportNationality = mrzEvent.mrz[1].substring(10, 13);

    if (currentPassenger) {
      // updating the Adult passport information in booking object
      get(booking).passengers.map((passenger) => {     
        if (currentPassenger.passengerID === passenger.passengerID) {   
          passenger.passportExpiry = passportExpiry;
          passenger.passportIssuingCountry = passportNationality;
          passenger.passportNumber = mrzEvent?.documentNumber;  
        }      
      });

      // updating the Adult passport information in booking object
      get(infants).map((passenger) => {     
        if (currentPassenger.passengerID === passenger.passengerID) {   
          passenger.passportExpiry = passportExpiry;
          passenger.passportIssuingCountry = passportNationality;
          passenger.passportNumber = mrzEvent?.documentNumber;  
        }      
      });
    }

    const shouldIncrement = addedPassengerMrz && !outOfOrderScan;
    if (shouldIncrement) {
      nextPassenger();
    }
  }

  function nextPassenger() {
    while (currentPassengerIndex < passengers.length) {
      currentPassengerIndex = currentPassengerIndex + 1;

      /** Automatically move to the next paginated page. */
      moveToPage(Math.ceil((currentPassengerIndex + 1) / pageSize));

      if (
        currentPassengerIndex === passengers.length ||
        !passportNumbers.passengerIsScanned(getCurrentPassenger()) 
      ) {
        break;
      }
    }
    
    if (currentPassengerIndex === passengers.length) {
      logger.info(
        'Processed all passengers in the guided flow. ' +
          `There were ${
            passengers && passengers.length
          } passengers in the list.`,
      );
      handleErrors();
    }

    resetGlobalTimeout();
  }

  /** Handle press of the 'skip' button. */
  function skip() {
    if (currentPassengerValues) {
      logger.info(
        `Skip pressed for passenger: ` +
          `${currentPassengerValues.title} ${currentPassengerValues.firstName} ${currentPassengerValues.lastName}.`,
      );
    }
    skipPassenger();
  }

  /** Skip passenger will be called, when the passenger clicks the button
   * GUEST NOT HERE, in this case we will not process this passenger as part
   * of this transaction. And any infant included with this passenger will also
   * be skipped.
   *
   * PLEASE NOTE THAT SKIP PASSENGER HAS ALOT OF COMMON CODE WITH NEXT PASSENGER
   * BUT AT THIS STAGE AT THE END OF THE PROJECT I DO NOT WANT TO MODIFY NEXT
   * PASSENGER METHOD TO MAKE A COMMON METHOD.
   */
  function skipPassenger() {
    // the currentPassengerIndex here points to the passenger at
    // which the GUEST NOT HERE button was clicked
    const passengerToSkip = passengers[currentPassengerIndex];

    while (currentPassengerIndex < passengers.length) {      
      currentPassengerIndex = currentPassengerIndex + 1;

      // check if the skipped passenger (passengerToSkip) has an infant,
      //  if yes, then skip that infant too
      if (currentPassengerIndex >= passengers.length) {
        break;
      }

      const nextPassenger = passengers[currentPassengerIndex];
      if (passengerToSkip && nextPassenger) {
        // An adult having an infant attached to them have the same passengerID
        if (
          passengerToSkip.infantPassengerID === nextPassenger?.passengerID &&
          nextPassenger.passengerType === 'INFANT'
        ) {
          //Skip this infant too and move to the next passenger
          currentPassengerIndex = currentPassengerIndex + 1;
        }
        // now check if this passenger is an infant

        // now again if after skipping the infact, if all the
        // passengers in the list are done, BREAK
        if (currentPassengerIndex >= passengers.length) {
          break;
        }

        // Update the page based on the currentPassengerIndex
        moveToPage(Math.ceil((currentPassengerIndex + 1) / pageSize));
      }

      if (
        currentPassengerIndex === passengers.length ||
        !passportNumbers.passengerIsScanned(getCurrentPassenger())
      ) {
        break;
      }
    }

    if (currentPassengerIndex === passengers.length) {
      logger.info(
        'Processed all passengers in the guided flow. ' +
          `There were ${
            passengers && passengers.length
          } passengers in the list.`,
      );
      handleErrors();
    }
    resetGlobalTimeout();
  }

  /**
   * Handle the Flightdeck action "Override" status.
   *
   * @param {string} flightdeckAction
   * @returns {boolean} See setFlightdeckHandler().
   */
  function handleFlightDeckOverride(flightdeckAction) {
    currentPassengerIndex = 0;
    passengers = getUnscannedPassengers();
    nextPassenger();
    return true;
  }

  /**
   * Handle the Flightdeck action "Cancelled" status.
   *
   * @param {string} flightdeckAction
   * @returns {boolean} See setFlightdeckHandler().
   */
  function handleFlightDeckCancelled(flightdeckAction) {
    endTransaction();
    return true;
  }

  onMount(() => {
    appReport.updateStepStart(ApplicationStep.OTHER_PASSENGERS_PASSPORT_SCAN);

    unbindMRZScan = events.onMRZScan(handleMrz);

    isGuidedFlow = booking.hasUncheckedInPassengers();
    passengers = headPassengerManager.sortPassengers();
    
    // if the transaction was started with Passport and the booking was successfuly
    // retrieved then we start the currentPassengerIndex with 1, since the Passenger who
    // scanned their passport becomes the head passenger and we link the passport mrz to them
    const headPax = get(headPassenger);
    if (headPax) {
      booking.setPNRInBooking(headPax.bookingReference);
      booking.setPNR(headPax.bookingReference);
    }

    if (headPax && headPax?.passportNumber && get(userInitiationMethod) === TransactionInitiator.PASSPORT) {  
      currentPassengerIndex = 1;
    }    

    adjustGuidedFlowForInitialPassenger();

    adultOrInfantPassportScanErrorModal = new CustomErrorModal(
      ErrorModals.ADULT_OR_INFANT_PASSPORT_SCAN_FAILED,
    );
    adultOrInfantPassportScanErrorModal.setFlightdeckHandler(
      flightdeckConsts.TransactionStatuses.Overridden,
      handleFlightDeckOverride,
    );
    adultOrInfantPassportScanErrorModal.setFlightdeckHandler(
      flightdeckConsts.TransactionStatuses.Cancelled,
      handleFlightDeckCancelled,
    );
    adultOrInfantPassportScanErrorModal.setOverrideHandler(
      handleFlightDeckOverride,
    );
  });

  onDestroy(() => {
    unbindMRZScan();
    unsubscribe();
  });
</script>

<Header />

<Content>
  <span slot="heading" class="text-2.5">
    {#if isPorterBagDrop}
      <div>
        {#if !isGuidedFlow}
          {$_(
            `passportToCamera.heading${hasCamera ? 'Camera' : ''}AllCheckedIn`,
          )}
        {:else}
          {@html $_(`passportToCamera.heading${hasCamera ? 'Camera' : ''}`, {
            values: currentPassengerValues,
          })}
        {/if}
      </div>
    {:else if isHybridFlow} 
      <div>
        {#if !isGuidedFlow}
          {$_('passportToCamera.headingSelfServiceAllCheckedIn')}
        {:else}
          {@html $_('passportToCamera.headingSelfService', {
            values: currentPassengerValues,
          })}
        {/if}
      </div>
    {:else}
      <div>
        {#if !isGuidedFlow}
          {$_('passportToCamera.headingSelfServiceAllCheckedIn')}
        {:else}
          {@html $_('passportToCamera.headingSelfService', {
            values: currentPassengerValues,
          })}
        {/if}
      </div>
    {/if}
  </span>

  <div slot="main" class="relative">
    <div class="flex justify-center w-full">
      <div class="w-7/12">
        {#if isPorterBagDrop}
          <div class="mb-8">
            {#if hasCamera}
              <PassportScanningFrame class="mx-4" />
            {:else}
              <div class="mx-14 ratio">
                <img
                  alt="Passport scan demonstration."
                  class="ratio__content"
                  src="images/porterBagDropPassportScan.webp"
                />
              </div>
            {/if}
          </div>
        {:else}
          <div class="-mt-8">
            <Video ratio="21:9" src="passportScan" />
          </div>
        {/if}
      </div>
    </div>

    <PassengersList
      {currentPage}
      {pageSize}
      bind:currentPassengerIndex
      on:skipPassenger={skip}
      bind:moveToPage
    />

    {#if !isGuidedFlow}
      <FooterSecondary
        buttonRightHandler={handleErrors}
        buttonRightIntent={VoiceIntent.NEXT}
        buttonRightText={'All Passports Scanned'}
        isButtonRightActive={isNextButtonActive}
      />
    {/if}
  </div>
</Content>

<Footer />

<MRZHotkeys />

/* Flight Deck Message definitions. */

export const FlightDeckMessages = Object.freeze({
  ADULT_INFANT_PASSPORT_SCAN_FAILED: 'Adult / infant passport scan failed.',
  ATB_PRINT_FAILURE: 'Boarding Pass Print failure.',
  BAG_WEIGHT_EXCEEDED: 'Maximum single bag weight exceeded.',
  BOOKING_NOT_FOUND: 'Booking not found.',
  BTP_PRINT_FAILURE: 'Bag Tag Print failure.',
  CALL_FOR_ASSISTANCE: 'Call for Assistance has been selected',
  DGR_NOT_ACCEPTED: 'DGR has not been accepted.',
  EXCEED_MAX_BAG_DIMENSION: 'Baggage exceeds accepted Baggage Dimension.',
  GENERAL_FAILURE: 'Unknown application failure during transaction.',
  HARDWARE_UNAVAILABLE: 'Hardware Unavailable.',
  INVALID_BAG_TAG: 'Invalid Bag Tag Number.',
  PASSENGER_END_FROM_TIMEOUT: 'Passenger ended transaction from timeout.',
  PASSENGER_TIMEOUT_MESSAGE: 'Passenger transaction timed out.',
  TIMATIC_FAIL_MESSAGE: 'Timatic response check required for one or more passengers.',
  TIMEOUT_BOARDING_PASS_MISMATCH: 'Timeout recovery failed - Boarding Pass mismatch.',
  TIMEOUT_PASSPORT_MISMATCH: 'Timeout recovery failed - passport mismatch.',
  TRANSACTION_TIMEOUT: 'Transaction timed out.',
  TRANSACTION_TIMEOUT_MESSAGE: 'Transaction timed out.',
  UNACCOMPANIED_MINOR_FOUND: 'A UMNR is attempting to retrieve booking',
});

<script>
  import { _ } from 'svelte-i18n';
  import { get } from 'svelte/store';
  import { onMount } from 'svelte';
  import { push } from 'svelte-spa-router';

  import {
    booking,
    CabinUpgradeProducts,
    featureFlags,
    headPassenger,
    plusgradeAncillaryOfferManager,
  } from '../../../js/stores';
  import { FeatureFlags } from '../../../js/const';

  import Advertisement from '../Advertisement.svelte';
  import ColumnHeadings from './ColumnHeadings.svelte';
  import Legend from './Legend.svelte';
  import SeatingSummary from './SeatingSummary.svelte';
  import TripExtrasPayment from '../TripExtras/modal/TripExtrasPayment.svelte';

  export let cabinWidth = null;
  export let controller = null;
  export let flightNumber = null;
  export let hasScrollbar = null;
  export let passengers = null;
  export let scrollablePaneHeaderHeight = null;
  export let seatMapManager = null;
  export let seatSpace = null;
  export let seatWidth = null;
  export let seat = null;
  export let segment = null;
  export let spacerWidth = null;

  let isPlusgradeEligible = false;
  let showModal = false;

  function advertisementHandler() {
    showModal = true;
  }

  function pushToNextScreen() {
    push('/checking-in-passengers');
  }

  onMount(() => {
    if (featureFlags.isEnabled(FeatureFlags.PLUSGRADE)) {
      isPlusgradeEligible =
        !booking.hasPlusgradeUpgrade(get(headPassenger)) &&
        plusgradeAncillaryOfferManager.isEligibleForCabinUpgrade();
    }
  });
</script>

<style>
  .scrollablePaneHeader {
    @apply absolute left-0 p-2 right-0 top-0;
    z-index: 1;
  }
</style>

{#if isPlusgradeEligible}
  <TripExtrasPayment handleContinue={pushToNextScreen} bind:showModal />
{/if}

<div
  class="scrollablePaneHeader bg-white"
  bind:clientHeight={scrollablePaneHeaderHeight}
>
  {#if isPlusgradeEligible}
    <div class="mx-auto w-56">
      <Advertisement
        heading={$_(`tripExtras.cabinUpgrade.advertisement.heading`, {
          values: {
            amount: plusgradeAncillaryOfferManager.getProductPrice(
              CabinUpgradeProducts
            )
          },
        })}
        image="cabinUpgrade"
        paragraph={$_(`tripExtras.cabinUpgrade.advertisement.paragraph`)}
        imageRatio="21:9"
        size="small"
        on:click={advertisementHandler}
      />
    </div>
  {/if}

  <Legend {seatMapManager} />

  {#if controller}
    <SeatingSummary
      {controller}
      {flightNumber}
      {passengers}
      {seat}
      {segment}
    />
  {/if}

  <ColumnHeadings
    {cabinWidth}
    {seatMapManager}
    {seatSpace}
    {seatWidth}
    {spacerWidth}
    {hasScrollbar}
  />
</div>

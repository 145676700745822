<script>
  import { _ } from 'svelte-i18n';
  import { createEventDispatcher, onMount } from 'svelte';

  import { wizardPosition } from '../../../js/stores.js';

  import Check from '../../../svg/check.svg';

  export let index;

  const dispatch = createEventDispatcher();

  const isFilled = index <= $wizardPosition;

  onMount(() => {
    dispatch('currentIndex', {
      index,
    });
  });
</script>

<style>
  .check {
    @apply absolute inset-0 p-1;
  }

  .wizardItem {
    @apply flex
      flex-col
      font-AltisMedium
      items-center
      justify-center
      relative
      w-24;
    z-index: 1;
  }
</style>

<div class="wizardItem">
  <div class="mb-2">
    {#if isFilled}
      <div class="flex flex-col items-center">
        <div class="relative w-6 h-6">
          <div
            class="rounded-full bg-gold-100 border border-gold-100 h-full w-full"
          />
          <div class="check text-white">
            {@html Check}
          </div>
        </div>
      </div>
    {:else}
      <div class="w-6 h-6">
        <div
          class="rounded-full bg-white border border-gold-100 h-full w-full"
        />
      </div>
    {/if}
  </div>

  <div class="text-sm">
    {#if index === 0}
      <p>{$_('wizard.passengers')}</p>
    {/if}
    {#if index === 1}
      <p>{$_('wizard.security')}</p>
    {/if}
    {#if index === 2}
      <p>{$_('wizard.bags')}</p>
    {/if}
    {#if index === 3}
      <p>{$_('wizard.finish')}</p>
    {/if}
  </div>
</div>

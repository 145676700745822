<script>
  export let max = '100';
  export let value = '0';

  $: progressBarProps = {
    max,
    value,
    ...$$restProps,
    class: [$$restProps.class].filter(Boolean).join(' '),
  };

  /**
   * ProgressBar component.
   *
   * @example <caption>Example progress bar.</caption>
   *  <ProgressBar
   *    class="mb-2"
   *    max="{maxValue}"
   *    value="{currentValue}"
   *  </ProgressBar>
   *
   * @type {String} max. - maximum value (i.e. 100/100)
   * @type {String} value. - current value (i.e. 25/100)
   */
</script>

<style>
  progress {
    @apply border border-gold-100 h-3 w-full;
  }

  progress[value]::-webkit-progress-bar {
    @apply bg-transparent;
  }

  progress[value]::-webkit-progress-value {
    @apply bg-gold-100;
  }
</style>

<progress {...progressBarProps} />

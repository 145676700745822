<script>
  import { _ } from 'svelte-i18n';
  import { get } from 'svelte/store';

  import { booking, headPassenger } from '../../../js/stores.js';
  import { printBoardingPass } from '../../../js/stores';
  import logger from '../../../js/logger';
  import {
    VoiceIntent,
    voiceRecognition,
  } from '../../../js/services/voicerec/voicerec';

  export let advanceToNextScreen = () => {};

  import Button from '../Button.svelte';
  import ModalBase from '../ModalBase.svelte';

  import boardingPass from '../../../svg/boardingPass.svg';

  export let showModal = false;

  function noHandler() {
    logger.info('User selects "No thanks" for boarding pass print.');
    advanceToNextScreen();
  }

  function yesHandler() {
    logger.info('User selects "Yes please" for boarding pass print.');
    printBoardingPass.set(true);
    advanceToNextScreen();
  }
</script>

<ModalBase
  heading={$_('printBoardingPass.heading')}
  icon={boardingPass}
  iconClass="pl-6 text-current"
  bind:showModal
>
  <div class="flex justify-between mt-auto">
    <Button handler={noHandler} intent={VoiceIntent.NO} on:click={noHandler}>
      {$_('app.noThanks')}
    </Button>

    <Button
      handler={yesHandler}
      intent={VoiceIntent.YES}
      on:click={yesHandler}
    >
      {$_('app.yesPlease')}
    </Button>
  </div>
</ModalBase>

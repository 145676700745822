<script>
  import { _ } from 'svelte-i18n';

  export let heading = null;

  /**
   * PassengerSummaryHeadings component.
   *
   * @example <caption>Example usage.</caption>
   *  <PassengerSummaryHeadings
   *    {passengers}
   *    heading="heading"
   *  />
   *
   * @type {String} heading. - the first heading in the row
   */
</script>

<style>
  .passengerSummaryHeadings {
    @apply border-b-1
      border-gold-100
      flex
      justify-between
      pb-2;
  }
</style>

<div class="passengerSummaryHeadings">
  <div class="w-2/5">
    <h3 class="italic">
      {#if heading}
        {$_(`passengerSummaryHeadings.${heading}`)}
      {:else}
        {$_('passengerSummaryHeadings.passengers')}
      {/if}
    </h3>
  </div>
</div>

/* eslint-disable operator-assignment */
/* eslint-disable prefer-const */
/* eslint-disable import/prefer-default-export */
import { _, locale } from 'svelte-i18n';
import { get } from 'svelte/store';
import logger from '../../js/logger';
import { currentTotalExcessPiece, excessWeightEachBagInPieceConcept, currentTotalExcessWeight } from '../stores';
import { currencyFormat } from '../utils';
import { PaymentController } from './payment';
import { PaymentItem } from '../paymentItem';

const translate = get(_);

/** Payment controller for piece-concept baggage payment. */
export class PieceConceptBaggagePayment extends PaymentController {
  /**
   * Gets the heading of the baggage payment.
   *
   * @returns {string}
   */
  // eslint-disable-next-line class-methods-use-this
  getPaymentItemHeading() {
    return translate('payment.purchaseAdditionalBaggage');
  }

  /**
   * Gets the purchases of the baggage payment.
   *
   * @returns {string}
   */
  getPaymentItemPurchases() {
    
    let totalExcessPieces = currentTotalExcessPiece();
    logger.info('currentTotalExcessPieces are : ', totalExcessPieces);

    let totalExcessWeight = 0;
 
    let excessPieceWeightArray = get(excessWeightEachBagInPieceConcept);
    if (excessPieceWeightArray && excessPieceWeightArray.length > 0) {
      excessPieceWeightArray.forEach(i => {
        totalExcessWeight = totalExcessWeight + i.excess;
      });
    }

    logger.info('currentTotalExcessWeight are : ', totalExcessWeight);

    if (totalExcessPieces > 0 && totalExcessWeight > 0) {
      return translate('payment.baggagePaymentPurchasesPieceConceptExtraBagAndWeight', {
        values: {
          piece: totalExcessPieces,
          weight: totalExcessWeight,
          amount: currencyFormat(
            get(locale),
            this.currencyCode,
            this.extraAmount,
          ),
        },
      });
    } else if (totalExcessPieces > 0 && totalExcessWeight <= 0) {
      return translate('payment.baggagePaymentPurchasesPieceConcept', {
        values: {
          piece: totalExcessPieces,
          amount: currencyFormat(
            get(locale),
            this.currencyCode,
            this.extraAmount,
          ),
        },
      });
    } else if (totalExcessWeight > 0 && totalExcessPieces <= 0) {
      // means even in piece concept, passenger only has excess weight on allowed bags
      return translate('payment.baggagePaymentPurchasesPieceConceptNoExtraBags', {
        values: {
          weight: totalExcessWeight,
          amount: currencyFormat(
            get(locale),
            this.currencyCode,
            this.extraAmount,
          ),
        },
      });
    } else {
      return "";
    }
  }

  /**
   * Adds a payment item for baggage.
   *
   * @param {number} extraAmount
   * @param {number} extraPiece
   */
  addItem(extraAmount, extraPiece) {
    const paymentTransactionItems = {
      totalPrice: extraAmount,
      quantity: String(extraPiece),
    };

    this.items.push(new PaymentItem(paymentTransactionItems));
  }
}

/* Svelte action for applying hotkeys. */
import { hotkeyManager } from '../stores';

/**
 * Assign a hotkey to a Svelte component.
 *
 * When the keyboard key matching the character is pressed, a click will
 * be performed on the Svelte component.
 *
 * @param {object} node - The Svelte node.
 * @param {string} keyCharacter - The keyboard character to assign.
 * @returns {object} The destroy property will be called by Svelte when the
 *                  component is unmounted.
 */
export function hotkey(node, keyCharacter) {
  const destroy = hotkeyManager.addKey(
    node,
    Number.isInteger(keyCharacter) ? String(keyCharacter) : keyCharacter,
  );
  return { destroy };
}

<script>
  import { _ } from 'svelte-i18n';

  import { appReport, EventType, EventStatus, USER_ACTIONS } from '../../../js/appReport';
  import {
    VoiceIntent,
    voiceRecognition,
  } from '../../../js/services/voicerec/voicerec';
  import { handleTransactionFinished } from '../../../js/cuss-components';

  import Cancel from '../../../svg/cancel.svg';
  import NavButton from './NavButton.svelte';
  import ReturnHome from '../modal/ReturnHome.svelte';

  let showReturnHomeModal = false;

  /** Current design transitions by subscribing to the route and when it sees the home route
   * it notifies cuss to transition to available.
   * 
   * This works for all pages, EXCEPT, the home route, if we want to go from home route to available.
   * So adding special logic here that if requested explicitly, the show return home modal does not pop up, and instead just exits immediately.
   */
  export let immediateTransactionEnd = false;

  function homeHandler() {
    appReport.addEventWithAction(
      EventType.USER_ACTION,
      EventStatus.SUCCESS,
      USER_ACTIONS.EXIT_BUTTON_PRESSED
    );

    if(immediateTransactionEnd)
    {
      let reason = "User pressed exit button on home screen with attempt to immediately end transaction."
      appReport.trackEndTransactionCall(reason);
      handleTransactionFinished()
    }
    else
    {
      showReturnHomeModal = true;
    }
  }
</script>

<ReturnHome bind:showModal={showReturnHomeModal} />

<NavButton
  icon={Cancel}
  intent={VoiceIntent.HOME}
  key={'O'}
  text={$_('app.exit')}
  on:click={homeHandler}
/>

<script>
    import { _ } from 'svelte-i18n';
    import { onMount } from 'svelte';
    import Footer from '../components/Footer/index.svelte';
    import Header from '../components/Header/index.svelte';
    import logger from '../../js/logger';
    import { push } from 'svelte-spa-router';
    
    import { handleTransactionFinished } from '../../js/cuss-components'

    onMount(() => {
      logger.info('Final screen Have a nice flight loaded. We will now wait for 8 seconds');

      // Note: Even if CUSS sends active within the 8 seconds of app being AVAILABLE, our application does set
      
      // appIsActive to true but there are no event handlers on this page and throughout the app so nothing happens
      
      handleTransactionFinished();     

      setTimeout(() => {      
        logger.info('Now 8 seconds wait complete. Going back to home screen');
        push('/');
      }, 8000);
    });
  </script>
  <style>
    .mainHeading {
      margin-top: 600px;
      text-align: center;
      font-weight: 600;
      font-size: 100px;
    }
  </style>
  <Header hasHomeButton={false}></Header>  
    
    <p class="mainHeading leading-tight text-gold-100">      
      Thank you for using self service bag drop
    </p>       
    
  <Footer></Footer>

/* Constants for the flightdeck service. */

const DEVICE_TYPE = 'BagDrop';

const TransactionStatuses = Object.freeze({
  Unknown: 'Unknown',
  Acknowledged: 'Acknowledged',
  Cancelled: 'Cancelled',
  Overridden: 'Overridden',
  Pending: 'Pending',
  VisaVerified: 'VisaVerified',
  PaymentPending: 'PaymentPending'
});

const TransactionTypes = Object.freeze({
  Alert: 'Alert',
  Actionable: 'Actionable',
  Unknown: 'Unknown',
});

const TransactionSubTypes = Object.freeze({
  AssistanceRequest: 'AssistanceRequest',
  AtbPrintError: 'AtbPrintError',
  Biometric: 'Biometric',
  BookingNotFound: 'BookingNotFound',
  BtpPrintError: 'BtpPrintError',
  CheckInFailed: 'CheckInFailed',
  CurrentBagExceedsRemainingAllowance: 'CurrentBagExceedsRemainingAllowance',
  CabinUpgradeRequested: 'CabinUpgradeRequested',
  DGR: 'DGR',
  ExcessBaggagePaymentDeclined: 'ExcessBaggagePaymentDeclined',
  FFEnrolment: 'FFEnrolment',
  InjectionError: 'InjectionError',
  InvalidBagTag: 'InvalidBagTag',
  MaxBaggageDimensionsExceeded: 'MaxBaggageDimensionsExceeded',
  MaxSingleBagWeightExceeded: 'MaxSingleBagWeightExceeded',
  OversizedBaggage: 'OversizedBaggage',
  OverweightBaggage: 'OverweightBaggage',
  PassportMismatch: 'PassportMismatch',
  Payment: 'Payment',
  PaymentError: 'PaymentError',
  PrintError: 'PrintError',
  SeatAssignment: 'SeatAssignment',
  TooManyBags: 'TooManyBags',
  Timatic: 'Timatic',
  TotalBaggageExceeded: 'TotalBaggageExceeded',
  TotalWeightPercentageExceeded: 'TotalWeightPercentageExceeded',
  TravelDocument: 'TravelDocument',
  Unknown: 'Unknown',
  UnreadableBagTag: 'UnreadableBagTag',
});

export default {
  DEVICE_TYPE,
  TransactionStatuses,
  TransactionTypes,
  TransactionSubTypes,
};

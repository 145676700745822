import { get } from 'svelte/store';
import qs from 'querystringify';
import { camera } from '../stores/camera';
import { cameraUri } from '../services/config';
import bridge from '../cuss-bridge';
import { MILLISECONDS_PER_SECOND } from '../const';
import { randomString } from '../utils';
import logger from '../logger';

const CAMERA_SWITCHOFF_DELAY = 3, // seconds
  ID_LENGTH = 10,
  ID_PARAM = 'id',
  TIMEOUT = 1 * MILLISECONDS_PER_SECOND; // millisconds
let currentId = null,
  disconnectTime,
  framesTimer = null,
  lastFrameReceivedEpoch,
  websocket;

/** Clear state of the camera client. */
function cleanup() {
  lastFrameReceivedEpoch = undefined;
  clearTimeout(framesTimer);
}

/** Update the value of currentId to a new unique value. */
function updateCurrentId() {
  currentId = randomString(ID_LENGTH);
}

/**
 * Check if a WebSocket event matches the currentId.
 *
 * @returns {boolean}
 */
function eventMatchesCurrentId(event) {
  const params = qs.parse(event.srcElement.url.split('?')[1]);
  return params[ID_PARAM] === currentId;
}

/**
 * Check if no frames have been received from the Camera over the past epoch.
 *
 * @returns {boolean}
 */
function tooLongSinceLastFrame() {
  const now = Date.now() / MILLISECONDS_PER_SECOND;
  return now - (lastFrameReceivedEpoch || now) > CAMERA_SWITCHOFF_DELAY;
}

/**
 * Updates lastFrameReceivedEpoch.
 */
function updateLastFameEpoch() {
  lastFrameReceivedEpoch = Date.now() / MILLISECONDS_PER_SECOND;
}

/** Sets up the websocket handlers. */
function initialiseWebsocketHandlers() {
  if (websocket) {
    websocket.onopen = handleOpen;
    websocket.onmessage = handleMessage;
    websocket.onclose = handleClose;
    websocket.onerror = handleError;
  }
}

/** Handle the websocket open event. */
function handleOpen(e) {
  logger.debug('Camera WebSocket Opened.');
  clearTimeout(framesTimer);
  framesTimer = setInterval(() => {
    if (tooLongSinceLastFrame()) {
      logger.info(
        `No camera frames received for more than ` +
          `${CAMERA_SWITCHOFF_DELAY} ` +
          `seconds. Disabling view of camera.`,
      );
      camera.set(null);
    }
  }, TIMEOUT);
}

/** Handle the websocket message event. */
function handleMessage(e) {
  if (!eventMatchesCurrentId(e)) {
    logger.info('Ignoring stale camera websocket data.');
    return;
  }
  const frameObj = JSON.parse(e.data);
  if (frameObj.mrz_lines) {
    logger.info(`Received MRZ from camera, ${frameObj.mrz_lines}.`);
    camera.set('');
    const lines = frameObj.mrz_lines.split('|');
    bridge.passportMRZ(lines[0], lines[1]);
  } else {
    if (!get(camera) && frameObj.image) {
      logger.info('Received first camera frame.');
    }
    camera.set(frameObj.image);
  }
  updateLastFameEpoch();
}

/** Handle the websocket close event. */
function handleClose(e) {
  logger.debug('Camera WebSocket Closed.');
  disconnectTime = Date.now();
  cleanup();
}

/** Handle the websocket error event. */
function handleError(e) {
  logger.error('Camera WebSocket Error: ', e);
}

/** Client methods for the face rec service. */
const faceRec = {
  /** Connect to the face rec service. */
  connect() {
    if (typeof WebSocket === 'undefined') {
      logger.error(
        'Camera WebSocket Error: ' +
          'Sorry, your browser does not support WebSocket.',
      );
    } else {
      if (typeof websocket !== 'undefined') {
        logger.info('Call to connect(), but websocket already connected.');
      } else {
        logger.info('Attempting to connect to the camera websocket.');
        updateCurrentId();
        const queryString = qs.stringify({ [ID_PARAM]: currentId }, true);
        websocket = new WebSocket(cameraUri.DEV + queryString);
        camera.set(null);
        updateLastFameEpoch();
        initialiseWebsocketHandlers();
      }
    }
  },

  /** Disconnect from the face rec service. */
  disconnect() {
    if (typeof websocket == 'undefined') {
      logger.info('Call to disconnect(), but websocket not connected.');
    } else {
      logger.info('Deliberately closing Camera websocket.');
      websocket.close();
      websocket = undefined;
    }
  },

  /** Reconnect to the face rec service. */
  reconnect() {
    logger.info('Reconnecting to the Camera.');
    faceRec.disconnect();
    faceRec.connect();
  },
};

export default faceRec;

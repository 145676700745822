<script>
  import { _, locale } from 'svelte-i18n';
  import { get } from 'svelte/store';
  import { onDestroy, onMount } from 'svelte';
  import { push } from 'svelte-spa-router';
  import { collectExcessOnLastBag, excessPieceCurrency, excessPieceApproxCharges, getPieceConceptExcessNumberOfBags, setAgentHasOverridden } from '../../../js/stores/bags';
  import {
    ApplicationFlow,
    WeightUnit,
    REFERENCE_OWNER,
    REFERENCE_TYPE,
    MERCHANT_CODE,
    TEMPLATE_ID,
    LANGUAGE,
    SESSION_TIMEOUT,
  } from '../../../js/const';
  
  import { PieceConceptBaggagePayment } from '../../../js/controllers/pieceConceptBaggagePayment';
  import { currencyFormat } from '../../../js/utils';
  import { ErrorModals } from '../../../js/const/errorModals';
  import {
    CustomErrorModal,
    integratedCatalogue,
    applicationFlow,
    booking,
    currentBag,
    currentBagIndex,
    currentTotalExcessWeight,
    currentTotalWeight,
    currentPassenger,
    checkoutFormPayload,
    ensureBagOnScale,
    errorModal,
    haveRemainingBags,
    isFinalBag,    
    paidAllowance,
    passengerBagCounts,
    priceUnpaidItemsData,
    rightToLeft,
    setErrorModal,
    setIsAllowableExtra,
    setIsBagsPaymentSuccessful,
    setCurrentBagWeight,
    setCurrentBagBagTagID,
    getCurrentTotalExcess,
    transactionId
  } from '../../../js/stores';
  import { BaggagePayment } from '../../../js/controllers';
  import endTransaction from '../../../js/endTransaction';
  import flightdeck from '../../../js/services/flightdeck';
  import flightdeckConsts from '../../../js/services/flightdeck/const';
  import { VoiceIntent } from '../../../js/services/voicerec/voicerec';
  import logger from '../../../js/logger';
  import { sbdPausedPendingExcessBaggage, getAllProductDetails } from '../../../js/stores/sbdState';

  import {
    appReport,
    ApplicationStep
  } from '../../../js/appReport';

  import Button from '../Button.svelte';
  import Payment from './Payment.svelte';
  import ModalBase from '../ModalBase.svelte';

  import warning from '../../../svg/warning.svg';    
  import switchboardClient from '../../../js/services/switchboard';
  
  import { baseStationCode } from '../../../js/stores/config';

  export let showModal = false;

  const excessBaggageRate = getExcessBaggageRate();
  const isPorterBagDrop = get(applicationFlow) === ApplicationFlow.PORTER_BAG_DROP;
  const isHybrid =  get(applicationFlow) === ApplicationFlow.HYBRID;

  let baggagePayment;
  let declinedPaymentModal;
  let extraAmount;
  let extraWeight;
  let newWeight;
  let showExcessBaggagePaymentModal = false;
  let showPaymentSuccessModal = false;
  let currentTotalExcess = {};

  $: {
    if (showModal) {
      currentTotalExcess = getCurrentTotalExcess();
    }
  }

  /** Get excess bagggage rate */
  function getExcessBaggageRate() {
    
    logger.info('get(excessPieceApproxCharges) is: ', get(excessPieceApproxCharges));
    logger.info('get(excessPieceCurrency) is: ', get(excessPieceCurrency));
    logger.info('booking.isPieceBaggage() is: ', booking.isPieceBaggage());
  
    return booking.isPieceBaggage()
      ? { currency: get(excessPieceCurrency) }
      : integratedCatalogue.getExcessBaggageRate(currentTotalExcessWeight());
  }


  function addToBaggagePayment(unpaidPrice, unpaidCurrency) {
    logger.info(`insde addToBaggagePayment.. unpaidPrice = ${unpaidPrice}, unpaidCurrency = ${unpaidCurrency} 
      booking.isPieceBaggage() = ${booking.isPieceBaggage()}, booking.getBaggageUnit() = ${booking.getBaggageUnit()}`);

    if (booking.isPieceBaggage()) {
      logger.info('booking.isPieceBaggage() is true');
      baggagePayment = new PieceConceptBaggagePayment(unpaidPrice, unpaidCurrency, handleAccepted, handleDeclined);
    } else {
      logger.info('booking.isPieceBaggage() is false');
      baggagePayment = new BaggagePayment(unpaidPrice, unpaidCurrency, handleAccepted, handleDeclined);
    }
    
    baggagePayment.addItem(
      unpaidPrice,
      currentTotalExcess.excess
    );
  }

  /** Flightdeck message for passenger decline. */
  function handlePassengerDecline() {
    showModal = false;
    // setting the payment status
    setIsBagsPaymentSuccessful(false);

    const optedOutOfUpgradeError = new CustomErrorModal(
      ErrorModals.OPTED_OUT_OF_BAG_UPGRADE,
      {
        flightdeckHandlers: {
          [flightdeckConsts.TransactionStatuses.Cancelled]:
            handleFlightDeckCancelled,
          [flightdeckConsts.TransactionStatuses.Overridden]:
            handleFlightDeckOverride,
          [flightdeckConsts.TransactionStatuses.PaymentPending]:
            handleFlightDeckPaymentPending,
        },
      },
    );
    optedOutOfUpgradeError.setOverrideHandler(handleFlightDeckOverride);
    optedOutOfUpgradeError.open();

    flightdeck.declinedWeightUpgrade(
      get(currentBag),
      get(currentBagIndex),
      passengerBagCounts.totalBags(),
      booking.isPieceBaggage() ? getPieceConceptExcessNumberOfBags() : currentTotalExcessWeight(),
      booking.isPieceBaggage() ? get(excessPieceApproxCharges) : currentBag.excessWeightCost(),
      booking.isPieceBaggage() ? get(excessPieceCurrency) : excessBaggageRate?.currency,
    );
  }

  /**
   * Handle Flightdeck Override action.
   *
   * An Override means the bag does not need to be paid for.
   *
   * If the bag has been lifted, display error modal asking for return of bag.
   * Use ensureBagOnScale() to wait for bag return.
   * When bag is confirmed on the scale, proceed to next screen, or display
   * the payment modal if the remaining transaction weight (excluding the
   * current bag weight that has been overrided) exceeds the allowance.
   * setAllowableExtra() means the weight will not be included in the total.
   *
   * @returns {boolean} Returns false to tell GeneralErrorModal not to close.
   */
  function handleFlightDeckOverride(flightdeckAction) {
    logger.info('Received ensureBagOnScale.');
    if (get(currentBag).isLifted) {
      setErrorModal(ErrorModals.PUT_BAG_BACK_ON_SCALE);
    }

    setAgentHasOverridden(true);
    setIsAllowableExtra(true);

    ensureBagOnScale()
      .then(() => {
        logger.info('Success ensureBagOnScale.');
        errorModal.reset();
        
        if (isFinalBag()) { // removed checking piece or weight concept and then if its excess since this code is inside excess override
          logger.info('Inside flightdeck override. its final bag now calling handle Payment ');
          handlePayment();
        } else {
          (isPorterBagDrop || isHybrid)
            ? pushToNextScreen()
            : sbdPausedPendingExcessBaggage.unpause(
                'Agent override following User decline ' +
                  'of the allowence exceeded modal',
              );
        }
      })
      .catch((error) => {
        logger.warn(`Failed ensureBagOnScale: Error: ${error.message}.`);
        endTransaction();
      });

    return false;
  }

  /** End transaction due to Flightdeck cancel. */
  function handleFlightDeckCancelled(flightdeckAction) {
    endTransaction();
    return true;
  }

  /**
   * Flightdeck override of payment cancellation/failure - allows payment retry
   */
  function handleFlightDeckPaymentFailureOverride(flightdeckAction) {
    handlePayment();
    return true;
  }

  /** Flightdeck end transaction after payment cancellation/failure. */
  function handleFlightDeckPaymentFailureCancelled(flightdeckAction) {
    endTransaction();
    return true;
  }

  /**
   * Handle Flightdeck Payment Pending action.
   *
   * Flightdeck agent has confirmed that the user will opt to pay.
   *
   * If the bag has been lifted, display error modal asking for return of bag.
   * Use ensureBagOnScale() to wait for bag return.
   * Call handlePayment() as if the passenger accepted the payment modal.
   *
   * @returns {boolean} Returns false to tell GeneralErrorModal not to close.
   */
  function handleFlightDeckPaymentPending(flightdeckAction) {
    logger.info('Request ensureBagOnScale.');

    if (get(currentBag).isLifted) {
      setErrorModal(ErrorModals.PUT_BAG_BACK_ON_SCALE);
    }

    ensureBagOnScale()
      .then(() => {
        logger.info('Received ensureBagOnScale.');
        errorModal.reset();
        handlePayment();
      })
      .catch((error) => {
        logger.warn(`Failed ensureBagOnScale: Error: ${error.message}.`);
        endTransaction();
      });

    return false;
  }

  /** Passenger opts to proceed to making a payment. */
  function handlePayment() {
    showModal = false;
    
    extraAmount =  booking.isPieceBaggage() ? get(excessPieceApproxCharges) : currentBag.excessWeightCost();
    extraWeight = currentTotalExcessWeight();

    const tagNumber = currentPassenger.nextAvailableBagTag();
    setCurrentBagBagTagID(tagNumber);
    const bagTagObject = currentPassenger.getBagTagObject(tagNumber);

    const productsList = getAllProductDetails();
    const currentBagWeight = currentBag.getBagWeight();
    const bookingDetails = get(booking);

    logger.info('inside BaggageAllowanceExceeded, handlePayment(), calling currentBag.activate(...)');
    // This code would be called by SELF SERVICE / EMBROSS flow to update the bag tag
    currentBag.activate(currentTotalWeight(), productsList, currentBagWeight, bagTagObject, false)
      .then((isTagActivated) => {
        if(isTagActivated) {
          if (isFinalBag()) {
            logger.info('Allowable Excess is = ', get(currentBag).isAllowableExtra);
            if (get(currentBag).isAllowableExtra === true) {
              const bookingSearchQuery = {};
              bookingSearchQuery.pnr = get(booking).bookingReference;
              switchboardClient
                .bookingSearch(bookingSearchQuery)
                .then((response) => {                  
                  switchboardClient.overrideExcessBaggage({
                    calculationId: response?.data?.searchBooking?.excessBaggageId,
                    baseStation: baseStationCode,
                    transactionId: get(transactionId)
                  }).then((overrideExcessResponse) => {
                    const errorMessages = overrideExcessResponse?.data?.overrideExcessBaggage?.errorMessages
                    if (errorMessages && errorMessages?.length) {
                      logger.error('Errors received from overrideExcessBaggage api.');
                      errorMessages.forEach((error) => {
                        logger.error(error);
                      });
                    }
                    
                    logger.info('Override api has been called... now going to next screen')
                    pushToNextScreen();
                  });
                });

            } else {              
              switchboardClient
                .priceUnpaidItems(bookingDetails)
                .then((response) => {
                  logger.info(`Received priceUnpaidItems response.`, JSON.stringify(response));

                  if (response?.data?.priceUnpaidItems?.isGetSuccessful) {
                    priceUnpaidItemsData.set(response); //will be used for issuepaymentDocument
                    const unpaidItems = response?.data?.priceUnpaidItems?.unpaidItems;

                    logger.info(`unpaidItems is `, unpaidItems);

                    // Filter by service type
                    const unpaidItem = unpaidItems.filter((item) => item.serviceType == 'XII');
                    
                    logger.info(`unpaidItem of type XII is `, unpaidItem);

                    let unpaidPrice = 0;
                    let unpaidCurrency; 
                    if (unpaidItem && unpaidItem.length > 0) {
                      unpaidItem.forEach(u => {
                        unpaidPrice = unpaidPrice + Number(u.price);
                      });
                      unpaidCurrency = unpaidItem[0].currency;
                    }
                    
                    logger.info(`unpaidPrice is ${unpaidPrice}, unpaidCurrency is ${unpaidCurrency}`);

                    // If we get up to here, we are finishing EXCESS_BAGGAGE_INFORMATION step
                    // and entering EXCESS_BAGGAGE_PAYMENT step
                    appReport.updateStepSuccess(ApplicationStep.EXCESS_BAGGAGE_INFORMATION);
                    appReport.updateAncillaryAmountLastShown(unpaidPrice, unpaidCurrency);
                    
                    generateCheckoutFormPayload(unpaidPrice, unpaidCurrency);

                    showExcessBaggagePaymentModal = true;
                    
                    addToBaggagePayment(unpaidPrice, unpaidCurrency);
                  }else{
                    logger.warn(`Amadeus priceUnpaidItems Service is failed.`);
                    setErrorModal(ErrorModals.ASSISTANCE_REQUIRED);
                  }
                })
                .catch((error) => {
                  logger.warn(`Error connecting to Amadeus priceUnpaidItems Service: ${JSON.stringify(error)}`);
                  setErrorModal(ErrorModals.ASSISTANCE_REQUIRED);
                });
              }
          } else {
            (isPorterBagDrop || isHybrid)
            ? pushToNextScreen()
            : sbdPausedPendingExcessBaggage.unpause(
                'User accepts to pay from Allowance Exceeded modal, ' +
                  'but this is not the last bag. Continuing',
              );
          }
        } else {
          logger.warn(`Error in updating baggage allowance: ${JSON.stringify(error)}`);
          setErrorModal(ErrorModals.ASSISTANCE_REQUIRED);
        }
    });

    logger.log(
      'Excess Baggage Details:: Cost - ' +
        extraAmount +
        ' Weight - ' +
        extraWeight,
    );
   
  }

/**
 * This will prepare the checkout form Payload from different sources
 * like Booking, passenger details and segments or flight details
 * @returns
 */
export function generateCheckoutFormPayload(unpaidPrice, currency){
  //extraAmount = currentBag.excessWeightCost();
  try {
    const currentBooking = get(booking);
    checkoutFormPayload.set(
      {
        data: {
          template: {
            merchantCode: MERCHANT_CODE,
            id: TEMPLATE_ID,
            configuration: {
              language: LANGUAGE,
              sessionTimeout: SESSION_TIMEOUT,
              labels: {
                'details/items[0]/label': 'Excess Bag',
                'details/items[0]/value': `${unpaidPrice}.00 ${currency}`
                //'details/items[1]/label': 'VAT',
                //'details/items[1]/value': '10.00 AED'
              },
              customizations: {
                logoUrl: 'https://upload.wikimedia.org/wikipedia/commons/0/0e/Etihad-airways-logo.svg'
              },
              redirectUrls: [
                {
                  rel: 'failure',
                  href: 'https://www.google.com/search?q=failure'
                },
                {
                  rel: 'TermsAndConditions',
                  href: 'https://www.google.com/search?q=terms'
                }
              ]
            }
          },
          salesSummary: {
            totalPrice: {
              value: unpaidPrice * 100 , //this is to add 2 xero
              decimalPlaces: 2,
              currency: `${currency}`
            },
            reference: currentBooking.bookingReference,
            referenceType: REFERENCE_TYPE,
            referenceOwner: REFERENCE_OWNER
          }
        }
      }
    );
  } catch(e) {
    logger.warn(`inside generateCheckoutFormPayload(). Exception occured: `, e);
  }
}

  /** Push to next screen based on application flow. */
  function pushToNextScreen() {
    if (isPorterBagDrop) {
      push('/porter-printing-bag-tag');
    } else if (isHybrid) {
      push('/porter-printing-bag-tag-hybrid');
    } else {
      haveRemainingBags()
        ? push('/printing-bag-tag')
        : goToBagDropComplete();
    }
  }

  function goToBagDropComplete() {
    // from Embross/self service, the override excess service 
    // is taking time and hence boarding pass service returns error
    setTimeout(() => {
      push('/bag-drop-completed');
    }, 2000);
  }

  /** Handle a successful payment. */
  function handleAccepted() {
    paidAllowance.set(get(paidAllowance) + extraWeight);
    showExcessBaggagePaymentModal = false;
    showPaymentSuccessModal = true;

    // after 4 seconds of successful payment
    // hide the modal automatically and continue
    // to the next screen. Since we have hidden
    // the continue button on Payment svelte for
    // baggage payment, we call handleContinue manually.
    setTimeout(() => {
      handleContinue();
    }, 4000);
  }

  /** Handle a declined (failed) payment.  */
  function handleDeclined() {
    showExcessBaggagePaymentModal = false;
    declinedPaymentModal.open();
    flightdeck.excessBaggagePaymentFailed(
      currentTotalExcessWeight(),
      booking.isPieceBaggage() ? get(excessPieceApproxCharges) : currentBag.excessWeightCost(),
      booking.isPieceBaggage() ? get(excessPieceCurrency) : excessBaggageRate.currency
    );
  }

  /** Handle the Continue button after the completion of payment. */
  function handleContinue() {
    showPaymentSuccessModal = false;

    // updating the bags payment status
    setIsBagsPaymentSuccessful(true);

    (isPorterBagDrop || isHybrid)
      ? pushToNextScreen()
      : sbdPausedPendingExcessBaggage.unpause(
          'User has completed their payment',
        );
  }

  onMount(() => {
    declinedPaymentModal = new CustomErrorModal(
      ErrorModals.EXCESS_BAGGAGE_PAYMENT_FAILED,
    );

    declinedPaymentModal.setFlightdeckHandler(
      flightdeckConsts.TransactionStatuses.Cancelled,
      handleFlightDeckPaymentFailureCancelled,
    );

    declinedPaymentModal.setEndHandler(
      handleFlightDeckPaymentFailureCancelled,
    );

    declinedPaymentModal.setFlightdeckHandler(
      flightdeckConsts.TransactionStatuses.Overridden,
      handleFlightDeckPaymentFailureOverride,
    );

    declinedPaymentModal.setOverrideHandler(
      handleFlightDeckPaymentFailureOverride,
    );
  });

  onDestroy(() => {
    if (baggagePayment) {
      baggagePayment.cancelPolling();
    }
    
  });
</script>

<Payment
  controller={baggagePayment}
  {handleContinue}
  showContinueButton={false}
  handleDeclined={handlePassengerDecline}
  bind:showModal={showExcessBaggagePaymentModal}
  bind:showSuccess={showPaymentSuccessModal}
/>

<ModalBase
  heading={$_('baggageAllowanceExceeded.heading')}
  icon={warning}
  bind:showModal
>
  <div class="mb-10 px-6">
    <p class="text-1.375 whitespace-pre-wrap">
      {$_(`baggageAllowanceExceeded.textLine1${booking.isPieceBaggage() ? 'PieceConcept' : ''}`)}
      <span class="block mt-5">
        {$_('baggageAllowanceExceeded.textLine2')}
      </span>
    </p>
  </div>

  <div class="{$rightToLeft ? 'text-right' : 'text-left'} mb-14 px-6">
    <div class="flex mt-6 border-b border-gray-500 pb-2">
      <h3 class="w-1/2 {$rightToLeft ? 'text-right' : 'text-left'}">
        {$_('baggageAllowanceExceeded.baggageAllowance')}
      </h3>
      <p class="w-1/2 {$rightToLeft ? 'text-left' : 'text-right'}">
        {`${$_(
          'app.bag',
        )} ${$currentBagIndex}\/${passengerBagCounts.totalBags()}`}
      </p>
    </div>

    <div class="flex text-xl mt-4">
      <p class="w-1/2">{$_('baggageAllowanceExceeded.bagWeight')}</p>
      <p class="w-1/2 font-AltisMedium">
        {$currentBag.weight || 0}
        {$_(`app.${WeightUnit}`)}
      </p>
    </div>

    <div class="flex text-xl mt-2">
      <p class="w-1/2">{$_('baggageAllowanceExceeded.excessWeight')}</p>
      <p class="w-1/2 font-AltisMedium">
        {currentTotalExcess.excess < 0 ? currentTotalExcess.excess * -1 : currentTotalExcess.excess}
        {$_(`app.${currentTotalExcess.unit}`)}
      </p>
    </div>

    <!-- {#if !booking.isPieceBaggage()}
      <div class="flex text-xl mt-2">
        <p class="w-1/2">{$_('baggageAllowanceExceeded.costIncurred')}</p>
        <p
          class="w-1/2 font-AltisMedium"
          class:text-saffron={currentBag.excessWeightCost()}
        >
          {currencyFormat(
            $locale,
            excessBaggageRate.currency,
            currentBag.excessWeightCost(),
          )}
        </p>
      </div>
    {/if} -->

    <div class="flex text-xl font-AltisMedium mt-4">
      <p class="w-1/2">{$_('baggageAllowanceExceeded.totalBagWeight')}</p>
      <p class="w-1/2">{currentTotalWeight()} {$_(`app.${WeightUnit}`)}</p>
    </div>
  </div>

  <div class="flex justify-between mt-auto">
    <Button on:click={handlePassengerDecline} intent={VoiceIntent.DECLINE}>
      {$_('app.decline')}
    </Button>

    <Button
      on:click={handlePayment}
      intent={$currentBagIndex >= passengerBagCounts.totalBags()
        ? VoiceIntent.PAY
        : VoiceIntent.ACCEPT}
    >
      {$currentBagIndex >= passengerBagCounts.totalBags()
        ? $_('app.pay')
        : $_('app.accept')}
    </Button>
  </div>
</ModalBase>

import logger from '../../logger';
import events from '../../events';

export const VoiceIntent = Object.freeze({
  ACCEPT: 'accept',
  ASSISTANCE: 'assistance',
  BACK: 'back',
  CONFIRM: 'confirm',
  CONTINUE: 'continue',
  DECLINE: 'decline',
  END: 'end',
  HOME: 'home',
  JOIN_ETIHAD: 'joinetihad',
  LANGUAGE: 'language',
  NEXT: 'next',
  NO: 'no',
  NUMBER_OF_BAGS: 'numberofbags',
  PAY: 'pay',
  PRINT: 'print',
  SEARCH: 'search',
  SEND_EMAIL: 'sendemail',
  SKIP: 'skip',
  TRAVEL_OPTIONS: 'traveloptions',
  YES: 'yes',
  NOT_RECOGNISED: 'not recognised',
});

export const VoiceStatus = Object.freeze({
  PROCESSING: 'Processing',
  LISTENING: 'Listening',
  NOT_RECOGNISED: 'Not Recognised',
});

const VOICE_URI = 'ws://localhost:5000/ws';
const ERR_CONNECTION_REFUSED = 1006;
const CONNECTION_RETRY = 100;

/**
 * Processes Voice Recognition data.
 * @class VoiceRecognition
 */
class VoiceRecognition {
  /**
   * Creates an instance of VoiceRecognition.
   */
  constructor() {
    // check if the WebSocket has a successful connection
    this.isConnectionEstablished = false;

    this.socket = new WebSocket(VOICE_URI);

    this.socket.onopen = (event) => {
      this.isConnectionEstablished = true;
      logger.info('[open] VoiceRecAPI connection established.');
    };

    this.socket.onmessage = (event) => {
      logger.info(`[message] Data received from server: ${event && event.data}`);

      if (!['Listening', 'Processing'].includes(event.data)) {
        const speechResp = JSON.parse(event.data);
        logger.info(`speechResp.intent:${speechResp && speechResp.intent}`);

        if (speechResp.intent === VoiceIntent.NOT_RECOGNISED) {
          // alert the user
          this.onNotificationReceived(VoiceStatus.NOT_RECOGNISED);
        } else {
          this.onIntentReceived(
            speechResp.screen,
            speechResp.intent,
            speechResp.slot,
          );
        }

        // rebuild the request and try listening again
        var request = {
          screen: speechResp.screen,
          language: speechResp.language,
          intents: speechResp.intents,
        };
        this.socket.send(JSON.stringify(request));
      } else {
        this.onNotificationReceived(event.data);
      }
    };

    this.socket.onclose = (event) => {
      if (event.code === ERR_CONNECTION_REFUSED) {
        this.isConnectionEstablished = false;
        logger.info('[onclose] VoiceRecAPI connection failed.');
      }
    };
  }

  /**
   * Send data to the server.
   * @param {object} request
   */
  sendVoiceRecRequest(request) {
    const speechReq = JSON.stringify(request);
    logger.info(`sendVoiceRecRequest: ${speechReq}.`);

    if (this.isConnectionEstablished) {
      if (this.socket.readyState !== WebSocket.OPEN) {
        setTimeout(() => this.sendVoiceRecRequest(request), CONNECTION_RETRY);
      } else {
        this.socket.send(speechReq);
      }
    }
  }

  /**
   * Emit screen, intent and slot.
   * @param {string} screen
   * @param {string} intent
   * @param {string} slot
   */
  onIntentReceived(screen, intent, slot) {
    logger.info(`onIntentReceived: ${screen}, ${intent}, ${slot}`);
    events.emitVoiceRecognition({
      screen: screen,
      intent: intent,
      slot: slot,
    });
  }

  /**
   * Emit VoiceStatus.
   * @param {string} status
   */
  onNotificationReceived(status) {
    logger.info(`onNotificationReceived: ${status}`);
    events.emitVoiceRecognitionStatus(status);
  }
}

export const voiceRecognition = new VoiceRecognition();

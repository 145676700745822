import logger from '../../logger';

/**
 * Get the switchboard passengerid field for a given flightNumber.
 *
 * @param {object} passenger
 * @param {string} flightNumber
 * @return passengerId Switchboard BaggageRoute field.
 */
export function getBaggageRoutePassengerId(passenger, flightNumber) {
  logger.info(
    `Baggage Routes ` +
      JSON.stringify(passenger && passenger.passengerBaggageRoutes),
  );
  //TODO: CHECK IF IT CAUSES ANY TROUBLE
  //BUT FOR AMADEUS, WE DO NOT NEED TO ADD ANY ZERO OR STRIP ANY ZEROS
  //const paddedFlightNumber = flightNumber.padStart(4, '0');
  const passengerId = (
    (passenger.passengerBaggageRoutes || []).find(
      (baggageRoute) => baggageRoute.flightNumber === flightNumber,
    ) || {}
  ).passengerDID;

  logger.info(`passenger id extracted from getBaggageRoutePassengerId is `, passengerId);

  return passengerId;
}

<script>
  import { _ } from 'svelte-i18n';

  import Content from '../components/Content.svelte';
  import DangerousGoods from '../components/DangerousGoods/index.svelte';
  import Footer from '../components/Footer/index.svelte';
  import Header from '../components/Header/index.svelte';

  let showDangerousGoodsHeading = true;
</script>

<Header />

<Content>
    <span slot="heading">
      {showDangerousGoodsHeading ? $_('dangerousGoods.heading') : ""}
    </span>

  <div slot="main">
    <DangerousGoods bind:showDangerousGoodsHeading={showDangerousGoodsHeading} />
  </div>
</Content>

<Footer />

/** Map of passenger IDs to MRZs. */
import mrz from 'mrz';
import { get } from 'svelte/store';

import { resettable } from './extensions/resettable';
import logger from '../logger';

const passengerMrzs = resettable({});

/**
 * Returns passenger text for log message.
 *
 * @param {object} passenger - The Passenger object from Switchboard.
 * @returns {string} text for inclusion in log messages.
 */
function passengerLogText(passenger) {
  return `Passenger 
    ${passenger && passenger.firstName} 
    ${passenger && passenger.lastName}
    with passengerIndex: 
    ${passenger && passenger.passengerIndex}`;
}

/**
 * Returns MRZ text for log messages.
 *
 * @param {string[]} mrz - The MRZ array.
 * @returns {string} text for log messages.
 */
function mrzLogText(mrz) {
  return mrz && Array.isArray(mrz) ? mrz.join(', ') : 'undefined!';
}

/**
 * Used for accessing passenger mrzs.
 */
class PassengerMrzManager {
  /**
   * Store a MRZ mapped to a passsenger.
   *
   * If the passenger already has a MRZ stored, then it will be ignored.
   *
   * @param {object} passenger - Switchboard passenger object.
   * @param {string} scannedMrz - The passenger mrz.
   * @returns {boolean} Whether or not the MRZ was added.
   */
  addPassengerMrz(passenger, scannedMrz) {
    if (passenger.passengerIndex in get(passengerMrzs)) {
      logger.info(
        `${passengerLogText(passenger)} already has an MRZ mapped. ` +
        `Not mapping MRZ: "${mrzLogText(scannedMrz)}".`,
      );
      return false;
    }
    passengerMrzs.set({
      ...get(passengerMrzs),
      [passenger.passengerIndex]: scannedMrz,
    });
    logger.debug(
      `${passengerLogText(passenger)} mapped to MRZ: "${mrzLogText(scannedMrz)}".`,
    );

    // parse the mrz and update the passenger record
    const passportDetails = mrz.parse(this.getPassengerMrz(passenger));

    // if we have any fields that were returned by DCS (Amadeus), we will not override them instead use them as it is
    if (passportDetails && passportDetails.fields) {
      passenger.dateOfBirth = passenger.dateOfBirth ? passenger.dateOfBirth : this.getFormattedDateOfBirth(passportDetails.fields.birthDate);
      passenger.passportIssuingCountry = passenger.passportIssuingCountry ? passenger.passportIssuingCountry : passportDetails.fields.nationality;
      passenger.passportNumber = passenger.passportNumber ? passenger.passportNumber : passportDetails.fields.documentNumber;
      passenger.gender = passenger.gender ? passenger.gender : passportDetails.fields.sex === 'male' ? 'M' : 'F';
      passenger.passportExpiry = passenger.passportExpiry ? passenger.passportExpiry : this.getFormattedPassportExpiry(passportDetails.fields.expirationDate);
    }

    return true;
  }

  /**
 * Passport Expirty in MRZ have year in 2 digits
 * we need a logic to append the centry to it
 * The input date format would be 851020
 * we need to return a format like 1985-10-20
 */
  getFormattedPassportExpiry(passportExpiry) {
    if (!passportExpiry || passportExpiry.length !== 6) return '';

    const expiry = '20' + passportExpiry.substring(0, 2) + '-' + passportExpiry.substring(2, 4) + '-' + passportExpiry.substring(4, 6);
    return expiry;
  }

  /**
   * Date of births in passports have year in 2 digits
   * we need a logic to append the centry to it
   * The input date format would be 851020
   * we need to return a format like 1985-10-20
   */
  getFormattedDateOfBirth(birthDate) {
    if (!birthDate || birthDate.length !== 6) return '';

    let dateOfBirth = birthDate;
    const birthYear = dateOfBirth.substring(0, 2);
    const currentYear = new Date().toISOString().substring(2, 4);

    dateOfBirth = (birthYear > currentYear ? '19' : '20') + birthYear.toString() + '-' + dateOfBirth.substring(2, 4) + '-' + dateOfBirth.substring(4, 6);
    return dateOfBirth;
  }

  /**
   * Get the passenger mrz.
   *
   * @param {object} passenger - Switchboard passenger object.
   * @returns {string}
   */
  getPassengerMrz(passenger) {
    const mrz = get(passengerMrzs)[passenger.passengerIndex];
    logger.debug(
      `${passengerLogText(passenger)} found matching MRZ: "${mrzLogText(
        mrz,
      )}".`,
    );
    return mrz;
  }

  /**
   * Extract the passport number from the passenger's MRZ.
   *
   * @param {object} passenger - The Passenger object.
   * @returns {string} The passport number.
   */
  getPassengerPassportNumber(passenger) {
    return mrz.parse(this.getPassengerMrz(passenger)).fields.documentNumber;
  }

  /**
   * Get the passenger gender, i.e. 'M', 'F', 'U'.
   *
   * @param {object} passenger - Switchboard passenger object.
   * @returns {string}
   */
  getPassengerGender(passenger) {
    const SEX_GENDER_MAP = {
      male: 'M',
      female: 'F',
    };
    let sex;

    try {
      sex = mrz.parse(this.getPassengerMrz(passenger)).fields.sex;
    } catch (error) {
      sex = null;
      logger.error(`MRZ parsing failed for sex. ${error && error.message}.`);
    }

    return SEX_GENDER_MAP[sex] || null;
  }

  /**
   * Extract the nationality from the passenger's MRZ. i.e. 'IND'
   *
   * @param {object} passenger - The Passenger object.
   * @returns {string} The nationality.
   */
  getPassengerNationality(passenger) {
    if (!passenger) {
      logger.info('In passengerMrzs, getPassengerNationality(), input passenger is null');
      return;
    }

    let nationality;
    try {
      const passengerMRZ = this.getPassengerMrz(passenger);
      if (passengerMRZ) {
        const passengerMRZParsed = mrz.parse(passengerMRZ);
        if (passengerMRZParsed) {
          const fields = passengerMRZParsed.fields;
          if (fields) {
            nationality = fields.nationality;
          } else {
            logger.info('In passengerMrzs, getPassengerNationality(). passengerMRZParsed > fields is null');
          }

        }
        else {
          logger.info('In passengerMrzs, getPassengerNationality(). passengerMRZParsed is null');
        }
      } else {
        logger.info('In passengerMrzs, getPassengerNationality(). passengerMRZ is null');
      }
    } catch (error) {
      nationality = null;
      logger.error(`MRZ parsing failed for nationality. ${error && error.message}.`);
    }

    /** German passport. */
    if (nationality === 'D') {
      nationality = 'DEU';
    }

    return nationality || null;
  }

  /** Resets the svelte store. */
  reset() {
    passengerMrzs.reset();
  }
}

export const passengerMrzManager = new PassengerMrzManager();

<script>
  import { hotkey } from '../../../js/actions/hotkey.js';

  export let key = '';
  export let icon = '';
  export let intent = '';
  export let text = '';

  $: buttonProps = {
    key,
    intent,
    role: 'button',
    ...$$restProps,
    class: ['flex flex-col items-center mx-px', $$restProps.class]
      .filter(Boolean)
      .join(' '),
  };
</script>

<button {...buttonProps} on:click use:hotkey={key}>
  <div class="mb-px w-10">
    {@html icon}
  </div>
  <p class="font-AltisMedium text-lg">{text}</p>
</button>

<script>
  import { createEventDispatcher } from 'svelte';
  import { get } from 'svelte/store';
  import { onMount } from 'svelte';
  import { push } from 'svelte-spa-router';

  import { applicationFlow, CustomErrorModal } from '../../../js/stores';
  import { ApplicationFlow, OPERATOR_CODE } from '../../../js/const';
  import endTransaction from '../../../js/endTransaction';

  import Destination from './Destination.svelte';
  import Footer from './Footer.svelte';
  import LoadingAnimation from '../LoadingAnimation.svelte';
  import OtherAirlineMessage from './OtherAirlineMessage.svelte';
  import PassengerSelect from './PassengerSelect.svelte';
  import SegmentSelector from './SegmentSelector.svelte';

  export let flightNumber = null;
  export let passengers = null;
  export let isLoading = null;
  export let isOpen = null;
  export let seatMapManager = null;
  export let segments = null;
  export let segment = null;
  export let selectedSeat = null;

  const BASE_REM = 16;
  const dispatch = createEventDispatcher();
  const SCROLLABLE_PANE_PBD_HEIGHT = 56.5; // rem
  const SCROLLABLE_PANE_SELF_SERVICE_HEIGHT = 49; // rem
  const seatSpace = 0.5; // rem
  const seatWidth = 3; // rem
  const spacerWidth = 3.25; // rem

  let subtractScrollablePaneHeaderHeights = null;
  let combinedSpacerWidth = null;
  let paneHeight = null;
  let scrollablePaneHeaderHeight = null;
  let selectorHeight = null;

  onMount(() => {

  });

  /** Calculate the total width of the space between seats. */
  $: combinedSpacerWidth =
    (seatMapManager ? seatMapManager.maxGroups() - 1 : null) * spacerWidth;

  /** Calculate how wide the seats inside the cabin should be. */
  $: cabinWidth = seatMapManager
    ? seatMapManager.maxSeats() * (seatWidth + seatSpace) + combinedSpacerWidth
    : null;

  /** Check for height change when components like Advertisement are active. */
  $: scrollablePaneHeaderHeight;

  /** Calculate the subtraction amount of height for ScrollablePaneHeader. */
  $: subtractScrollablePaneHeaderHeights =
    (selectorHeight + scrollablePaneHeaderHeight) / BASE_REM;

  /** Calculate the height for ScrollablePane. */
  $: paneHeight =
    get(applicationFlow) === ApplicationFlow.PORTER_BAG_DROP
      ? SCROLLABLE_PANE_PBD_HEIGHT - subtractScrollablePaneHeaderHeights
      : SCROLLABLE_PANE_SELF_SERVICE_HEIGHT -
        subtractScrollablePaneHeaderHeights; // rem

  /** Advance to the next screen as per the applicationFlow. */
  function advanceToNextScreen() {
    push('/number-of-bags');
  }

  /** Handle a cancellation action from FlightDeck. */
  function handleFlightDeckCancelled(flightdeckAction) {
    endTransaction();
    return true;
  }
</script>

<div class="h-52">
  {#if isLoading}
    <LoadingAnimation class="mx-auto w-90" />
  {:else}
    <Destination {seatMapManager} {segment} />
  {/if}
</div>

<div bind:clientHeight={selectorHeight}>
  <PassengerSelect
    {flightNumber}
    {selectedSeat}
    {passengers}
    on:passengerChange
  />
  {#if segments.length > 1}
    <SegmentSelector bind:isOpen {segment} {segments} on:segmentSelect />
  {/if}
</div>

<div class="border border-current relative" dir="ltr">
  {#if isLoading}
    <div style="height: {paneHeight}rem;">
      <div class="flex h-full items-center px-18">
        <LoadingAnimation class="mb-8 w-full" />
      </div>
    </div>
  {:else}
    <OtherAirlineMessage {paneHeight} />
  {/if}
</div>

<Footer {advanceToNextScreen} bind:isOpen />

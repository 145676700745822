<script>
  import { _ } from 'svelte-i18n';
  import { get } from 'svelte/store';
  import { onMount } from 'svelte';
  import { push } from 'svelte-spa-router';
  import countryCodes from 'intl-tel-input/build/js/data';
  import flightdeckConsts from '../../js/services/flightdeck/const';
  import { baseStationCode } from '../../js/stores/config';
  import {
    booking,
    headPassenger,
    infants,
    rightToLeft,
    setErrorModal,
    CustomErrorModal,
    transactionId
  } from '../../js/stores';
  import { convertIataToIso2, getKeyByValue } from '../../js/utils';
  import { ErrorModals } from '../../js/const/errorModals';
  import iso2Iso3 from '../../js/data/miscellaneous/iso2iso3.json';
  import { ApplicationStep, appReport } from '../../js/appReport';
  import logger from '../../js/logger';
  import switchboardClient from '../../js/services/switchboard';
  import flightdeck from '../../js/services/flightdeck';
  import { VoiceIntent } from '../../js/services/voicerec/voicerec';
  import { END_TRANSACTION_REASON, WizardPosition } from '../../js/const';
  import hasRecordInUseError from '../../js/recordInUseError';
  import endTransaction from '../../js/endTransaction';
  import Content from '../components/Content.svelte';
  import Dropdown from '../components/Dropdown.svelte';
  import Footer from '../components/Footer/index.svelte';
  import FooterSecondary from '../components/FooterSecondary.svelte';
  import Header from '../components/Header/index.svelte';
  import InputField from '../components/InputField.svelte';
  import Loading from '../components/Loading.svelte';
  import OnScreenKeyboard from '../components/OnScreenKeyboard/index.svelte';

  const translate = get(_);
  let serviceRetryCount = 0;
  let timaticFlightDeckAlertCount = 0;
  let timaticFailErrorModal;
  let retryAttempts = 0;
  const MAX_TIMATIC_OVERRIDES_ALLOWED = 1;

  let emergencyContact = {};
  let selectedCountry = null;
  let showSpinner = null;
  let showUpdating = null;
  let showWaiting = null;
  let uniqueCombinedCountries = [];

  /**
   * Handle updates to the name field.
   *
   * @param {string} inputValue - The value of the field.
   */
  function handleName(inputValue) {
    if (emergencyContact) {
      emergencyContact.name = inputValue;
      clearValidation('nameValidationMsg');
    }
  }

  /**
   * Handle updates to the country code field.
   *
   * @param {string} inputValue - The value of the field.
   */
  function handleCountryCode(inputValue) {
    if (emergencyContact) {
      emergencyContact.countryCode = inputValue.iso2.toUpperCase();
    }
  }

  /**
   * Handle updates to the phone field.
   *
   * @param {string} inputValue - The value of the field.
   */
  function handlePhone(inputValue) {
    if (emergencyContact) {
      emergencyContact.phone = inputValue;
      clearValidation('phoneValidationMsg');
    }
  }

  function timaticOverrideUnsuccessfulChecks() {
    if (retryAttempts < MAX_TIMATIC_OVERRIDES_ALLOWED) {
      updatePassengerDetails(true);
      retryAttempts = retryAttempts + 1;
    }
  }

  timaticFailErrorModal = new CustomErrorModal(
    booking.isSinglePassengerTransaction()
      ? ErrorModals.TIMATIC_UNSUCCESSFUL_SINGLE_PASSENGER
      : ErrorModals.TIMATIC_UNSUCCESSFUL_MULTIPLE_PASSENGERS,
  );

  timaticFailErrorModal.setOverrideHandler(timaticOverrideUnsuccessfulChecks);

  function updatePassengerDetails(isAgentOverride) {
    const editCPRInput = buildEditCPRInput(isAgentOverride);
    editCPR(editCPRInput);
  }

  function handleFlightDeckOverride(flightdeckAction) {
    timaticFlightDeckAlertCount = timaticFlightDeckAlertCount - 1;
    if (timaticFlightDeckAlertCount > 0) {
      return false;
    } else {
      timaticOverrideUnsuccessfulChecks();
      return true;
    }
  }

  function editCPR(input) {
    switchboardClient.editCPR(input).then((response) => {
      logger.info('edit cpr response :', response);

      if (response) {
        const errorMessages = response.data?.editCpr?.errorMessages;

        if (errorMessages && errorMessages?.length > 0) {
          logger.error('Error(s) in timatic checks (Edit CPR) amadeus response');

          // check if record in use error is received
          if (hasRecordInUseError(errorMessages)) {
            serviceRetryCount++;
            if (serviceRetryCount < 3) {
              logger.info('Record in use error received from editCPR emergency contacts. waiting three seconds to recall the service')
              setTimeout(() => {
                editCPR(input)
                return;
              }, 3000);
              return;
            } else {
              logger.info('Record in use error received from editCPR emergency contacts. Maximum retry attempts reached. Ending transaction.')
            }
          }

          errorMessages.forEach((error) => {
            logger.error(error?.message);
          });

          flightdeck.timaticChecksFailed();

          let eventDetails = { errorMessages: errorMessages }

          appReport.updateStepInProgress(ApplicationStep.PASSENGER_ADC_CHECKS, eventDetails);

          setErrorModal(ErrorModals.ASSISTANCE_REQUIRED);
          return; // if we receive EC category messages from response, we want to log the errors and exit
        }

        const { passengers } = get(booking);
        const timaticChecks = response.data?.editCpr?.passengerTimaticChecks;
        let timaticOverrideRequired = false;

        if (timaticChecks && timaticChecks.length > 0) {
          timaticChecks.forEach((check) => {
            if (check) {
              let currentPassenger = passengers.filter(
                (x) => x.passengerID === check.customerPrimeId,
              );

              if (
                currentPassenger === null ||
                currentPassenger?.length === 0
              ) {
                // it may be an infant
                currentPassenger = [
                  booking.getInfantByID(check.customerPrimeId),
                ];
              }

              if (!currentPassenger) {
                return;
              }

              let passengerName =
                currentPassenger[0].firstName +
                ' ' +
                currentPassenger[0].lastName;

              if (
                check.timaticInfoStatus &&
                check.timaticInfoStatus?.length > 0
              ) {
                check.timaticInfoStatus.forEach((status) => {
                  logger.info(
                    `Passenger ${passengerName} has timatic status of ${status} received.`,
                  );

                  let message = `Passenger: ${passengerName}, Nationality: ${currentPassenger[0].passportIssuingCountry}`;
                  if (
                    check.flightDeckMessage &&
                    check.flightDeckMessage.length > 0
                  ) {
                    let flightDeckMessage = check.flightDeckMessage[0];
                    try {
                      flightDeckMessage = flightDeckMessage.replace(
                        /<\/?[^>]+(>|$)/g,
                        '',
                      );
                    } catch (error) {
                      flightDeckMessage =
                        'ADC/Timatic status of CON or NOT OK TO BOARD Found';
                    }
                    message = `${message} - ${status} - ${flightDeckMessage}`;
                  } else {
                    message = `${message} - ${status} - ADC/Timatic status of CON or NOT OK TO BOARD Found`;
                  }

                  flightdeck.timaticIndividual({
                    passenger: `Passenger ${passengerName} timatic/ADC checks have ${status}.`,
                    message: message,
                  });

                  let eventDetails = { error: message }

                  appReport.updateStepInProgress(ApplicationStep.PASSENGER_ADC_CHECKS, eventDetails);

                  timaticFlightDeckAlertCount =
                    timaticFlightDeckAlertCount + 1;
                });

                timaticOverrideRequired = true;

                // after logging all messages on console, showing flight deck alert
                if (retryAttempts < MAX_TIMATIC_OVERRIDES_ALLOWED) {
                  timaticFailErrorModal.open(
                    END_TRANSACTION_REASON.TIMATIC_CHECKS_FAILED,
                  );
                } else {
                  endTransaction(END_TRANSACTION_REASON.TIMATIC_CHECKS_FAILED);
                }
              }
            }
          });
        }        
        // check if any of the passengers required a timatic agent override, 
        // if not, allow to navigate forward
        logger.info('timatic overrides required = ', timaticOverrideRequired);
        if (timaticOverrideRequired === false) {
          appReport.updateStepSuccess(ApplicationStep.PASSENGER_ADC_CHECKS);
          push('/checking-in-passengers');
        }
      } else {
        logger.error('No response received from the server. ');
        setErrorModal(ErrorModals.ASSISTANCE_REQUIRED);
      }
    }),
      (error) => {
        logger.error('Amadeus Edit CPR service returned an error. ', error);
        setErrorModal(ErrorModals.ASSISTANCE_REQUIRED);
      };
  }

  /**
   * Create the BuildEditCPRInput object for SwitchBoard.
   *
   * @param {object} editCprInput - Switchboard EditCprInput object.
   * @returns {Array} passengerDetailsList - Array of passengerDetails.
   */
  function buildEditCPRInput(isAgentOverride) {
    let editPassengerDetailsList = [];

    const { passengers } = get(booking);
    const allPassengers = (passengers || []).concat(get(infants));

    allPassengers.forEach((passenger) => {     
    // Edit cpr does not want infants
    // passengers.forEach((passenger) => {     
      passenger.segments.forEach((segment) => {
        let passengerDetails = {
          firstName: passenger.firstName,
          gender: passenger.gender,
          surname: passenger.lastName,
          type: passenger.amadeusPassengerType, // passenger.type,
          dateOfBirth: passenger.dateOfBirth,
          customerPrimeId: passenger.passengerID,
          marketingCarrier: segment.marketingAirlineCode,
          flightNumber: segment.flightNumber, //passenger.flightNumber,
          departureDate: segment.departureDateTime, //passenger.departureDate,
          boardPoint: segment.departureCode, //passenger.boardPoint,
          productPrimeId: segment.passengerDID, // passenger.productPrimeId,
          travelerDocumentId: passenger.passportNumber,
          travelerDocumentExpiryDate: passenger.passportExpiry,
          code: passenger.passportIssuingCountry,
          nationalityCode: passenger.passportIssuingCountry,
          emergencyContactName: passenger.emergencyContactName,
          emergencyContactCountryCode: passenger.emergencyContactCountryCode,
          emergencyContactPhone: passenger.emergencyContactPhone,
        };
        editPassengerDetailsList.push(passengerDetails);
      });
    });

    return {
      editPassengerDetailsList,
      isAgentOverride,
      onlyPerformRegulatoryChecks: false,
      baseStation: baseStationCode,
      transactionId: get(transactionId)
    };
  }

  /** Perform the emergency contact update via Switchboard. */
  function performEmergencyContactUpdate() {
    showSpinner = true;
    if (emergencyContact) {
      get(booking).passengers.map((passenger) => {        
        passenger.emergencyContactName = emergencyContact.name;
        passenger.emergencyContactCountryCode = emergencyContact.countryCode;
        passenger.emergencyContactPhone = emergencyContact.phone;        
      });
    }
    updatePassengerDetails(false);
  }

  /** Handle entry of emergency contact details being declined by the user. */
  function refusalHandler() {
    if (emergencyContact) {
      emergencyContact.name = null;
      emergencyContact.countryCode = null;
      emergencyContact.phone = null;
    }

    showWaiting = true;
    performEmergencyContactUpdate();
  }

  /** Handle action to add emergency contact details. */
  function addHandler() {
    handleCountryCode(selectedCountry);

    const isNameValid = validateName();
    const isPhoneValid = validatePhone();

    if (!isNameValid || !isPhoneValid) {
      return false;
    }

    showUpdating = true;
    performEmergencyContactUpdate();
  }

  /** Trigger display of validation messages. */
  function showValidation(id, text) {
    const validationMessage = document.getElementById(id);
    validationMessage.style.visibility = 'visible';
    validationMessage.innerText = text;
  }

  /** Validate the emergency contact name entered. */
  function validateName() {
    if (emergencyContact) {
      const nameTrimmed = emergencyContact.name.trim();

      if (!nameTrimmed.length) {
        showValidation(
          'nameValidationMsg',
          translate('validation.nameRequired'),
        );
        return false;
      }

      if (nameTrimmed.length > 100) {
        showValidation(
          'nameValidationMsg',
          translate('validation.maxCharacters'),
        );
        return false;
      }

      clearValidation('nameValidationMsg');
      return true;
    }
  }

  /** Validate emergency contact phone number. */
  function validatePhone() {
    if (emergencyContact) {
      const inputValue = emergencyContact.phone;

      const nonAlphaNumericChars = /[^\w_]/g;
      const alphaNumericChars = inputValue.replace(nonAlphaNumericChars, '');

      if (!alphaNumericChars.length) {
        showValidation(
          'phoneValidationMsg',
          translate('validation.phoneRequired'),
        );
        return false;
      }

      const nonNumeric = /[^0-9]/gi;
      if (alphaNumericChars.match(nonNumeric)) {
        showValidation(
          'phoneValidationMsg',
          translate('validation.numbersOnly'),
        );
        return false;
      }

      if (alphaNumericChars.length > 25) {
        showValidation(
          'phoneValidationMsg',
          translate('validation.max25Numbers'),
        );
        return false;
      }

      clearValidation('phoneValidationMsg');
      return true;
    }
  }

  /** Clear validation messages. */
  function clearValidation(id) {
    const validationMessage = document.getElementById(id);
    validationMessage.style.visibility = 'hidden';
    validationMessage.innerText = '';
  }

  /**
   * Update countries list with important countries placed at the top.
   *
   * @param {object[]} - An array of countries.
   * */
  function updateCountryCodes() {
    let combinedCountries = [];

    const baseStation = baseStationCode;
    const destination = booking.getArrivalCode();
    const nationality = booking.getPassengerNationality(get(headPassenger));

    const baseStationIso2 = (
      convertIataToIso2(baseStation) || ''
    ).toLowerCase();
    const destinationIso2 = (
      convertIataToIso2(destination) || ''
    ).toLowerCase();
    const nationalityIso2 = (
      getKeyByValue(iso2Iso3, nationality) || ''
    ).toLowerCase();

    const baseStationHighlight = countryCodes.find(
      (item) => item.iso2 === baseStationIso2,
    );
    const destinationHighlight = countryCodes.find(
      (item) => item.iso2 === destinationIso2,
    );
    const nationalityHighlight = countryCodes.find(
      (item) => item.iso2 === nationalityIso2,
    );

    combinedCountries = [
      ...[
        baseStationHighlight,
        destinationHighlight,
        nationalityHighlight,
      ].filter(Boolean),
      ...countryCodes,
    ];

    uniqueCombinedCountries = combinedCountries.filter((item, index) => {
      return combinedCountries.indexOf(item) === index;
    });
  }

  onMount(() => {
    showSpinner = false;
    showUpdating = false;
    showWaiting = false;

    timaticFailErrorModal.setFlightdeckHandler(
      flightdeckConsts.TransactionStatuses.Overridden,
      handleFlightDeckOverride,
    );

    timaticFailErrorModal.setFlightdeckHandler(
      flightdeckConsts.TransactionStatuses.VisaVerified,
      handleFlightDeckOverride,
    );

    timaticFailErrorModal.setFlightdeckHandler(
      flightdeckConsts.TransactionStatuses.Cancelled,
      handleFlightDeckCancelled,
    );

    updateCountryCodes();
  });

  function handleFlightDeckCancelled() {
    endTransaction(END_TRANSACTION_REASON.TIMATIC_CHECKS_FAILED);
    return true;
  }
</script>

<Header />

{#if showSpinner}
  {#if showUpdating}
    <Loading heading={$_('emergencyContact.updating')} />
  {:else}
    <Loading heading={$_('emergencyContact.waiting')} />
  {/if}
{:else}
  <Content>
    <span slot="heading">{$_('emergencyContact.heading')}</span>
    <span slot="text">{$_('emergencyContact.optionalInformation')}</span>

    <div class="mt-12" slot="main">
      <div class="flex flex-wrap -mx-6">
        <div class="mb-12 px-6 w-full">
          <span
            class="text-lg text-saffron {$rightToLeft
              ? 'float-left'
              : 'float-right'}"
            id="nameValidationMsg"
          />
          <InputField
            hasAutofocus
            label="emergencyContact.name"
            valueHandler={handleName}
          />
        </div>

        <div class="flex mb-12 w-full">
          <div class="px-6 w-1/2">
            <label for={$_('emergencyContact.countryCode')}>
              {$_('emergencyContact.countryCode')}
            </label>
            <Dropdown
              items={uniqueCombinedCountries}
              itemToString={(item) =>
                `<span class='truncate'>${item.name}</span> <span>${item.dialCode}</span>`}
              bind:selectedItem={selectedCountry}
            />
          </div>

          <div class="px-6 w-1/2">
            <span
              class="text-lg text-saffron {$rightToLeft
                ? 'float-left'
                : 'float-right'}"
              id="phoneValidationMsg"
            />
            <InputField
              label="emergencyContact.phone"
              valueHandler={handlePhone}
            />
          </div>
        </div>

        <div class="mb-10 pt-3 px-6 w-full">
          <OnScreenKeyboard />
        </div>

        <div class="mb-12 mx-auto text-center w-8/12">
          <p class="text-xl">{$_('emergencyContact.notOnSameFlight')}</p>
        </div>

        <FooterSecondary
          buttonLeftHandler={refusalHandler}
          buttonLeftIntent={VoiceIntent.DECLINE}
          buttonLeftText={$_('app.noThanks')}
          buttonRightHandler={addHandler}
          buttonRightIntent={VoiceIntent.ACCEPT}
          buttonRightText={$_('app.add')}
          disclaimer={$_('emergencyContact.disclaimer')}
        />
      </div>
    </div>
  </Content>
{/if}

<Footer />

<script>
  import { _ } from 'svelte-i18n';

  import slash from '../../../svg/slash.svg';

  export let items = [];
  export let name = '';
</script>

<style>
  .permitted-style {
    color: green !important;
  }
</style>

<div class="w-10/12 mx-auto">
  <div class="mb-5">
    <div
      class="{name === 'permitted'
        ? 'permitted-style'
        : 'text-saffron'} flex items-center mb-5 justify-center"
    >
      <div class="w-12">
        {@html slash}
      </div>
      <h3 class="mx-2">{$_(`dangerousGoods.${name}.heading`)}</h3>
    </div>

    <div class="flex flex-wrap justify-center -mx-4">
      {#each items as item}
        <div class="mb-3 px-2 w-1/5">
          <div class="flex flex-col items-center text-center">
            <div class="w-16 px-2 mb-2">
              {@html item.icon}
            </div>
            <p class="font-AltisMedium leading-tight px-1 text-0.625">
              {$_(`dangerousGoods.${name}.${item.name}`)}
            </p>
          </div>
        </div>
      {/each}
    </div>
  </div>
</div>

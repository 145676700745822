<script>
  import { _ } from 'svelte-i18n';

  import {
    VoiceIntent,
  } from '../../../js/services/voicerec/voicerec';
  import Button from '../Button.svelte';
  import ModalBase from '../ModalBase.svelte';

  export let showModal = false;
  export let approximateExcessCharges = 0;
  export let currency = null;
  export let excessNumberOfBags = 0;
  export let passengerAcceptHandler = () => {};
  export let passengerDeclineHandler = () => {};
 
</script>

<ModalBase
  heading={$_('pieceAllowanceExceeded.heading')}
  textLine1={$_('pieceAllowanceExceeded.textLine1')}
  textLine2={$_('pieceAllowanceExceeded.textLine2', {
    values: {
      quantity: String(excessNumberOfBags)
    }
  })}
  textLine3={$_('pieceAllowanceExceeded.textLine3', {
    values: {
      currency: String(currency),
      excessCharges: String(approximateExcessCharges)
    }
  })}
  textLine4={$_('pieceAllowanceExceeded.textLine4')}
  bind:showModal
>
<div class="flex justify-between mt-auto">
  <Button on:click={passengerDeclineHandler} intent={VoiceIntent.DECLINE}>
    {$_('app.decline')}
  </Button>

  <Button on:click={passengerAcceptHandler} intent={VoiceIntent.ACCEPT}>
    {$_('app.accept')}
  </Button>
</div>
</ModalBase>
<script>
  import { _ } from 'svelte-i18n';

  import HorizontalScrollButtons from '../HorizontalScrollButtons.svelte';
  import PassengerSelectItem from './PassengerSelectItem.svelte';

  export let flightNumber = null;
  export let passengers = null;
  export let selectedSeat = null;

  let containerRef = null;
</script>

<div class="passengerSelect">
  <div class="flex justify-between items-baseline">
    <h3 class="italic">
      {#if passengers.length > 1}
        {$_('seatMap.selectPassenger')}
      {:else}{$_('seatMap.passenger')}{/if}
    </h3>
  </div>

  <div class="relative mb-3">
    <div
      class="border-b border-t border-gold-100 overflow-x-auto scrolling-touch
      scrollbar-none mb-px"
      bind:this={containerRef}
    >
      <div class="flex">
        <ul class="flex items-center">
          {#each passengers as passenger}
            {#if passenger.passengerType !== 'INFANT'}
              <PassengerSelectItem
                {flightNumber}
                {passenger}
                {selectedSeat}
                on:passengerChange
              />
            {/if}
          {/each}
        </ul>
      </div>
    </div>

    <HorizontalScrollButtons {containerRef} />
  </div>
</div>

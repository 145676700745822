/** Expose various tools to the JavaScript console. */
import events from './events';
import bridge from './cuss-bridge';
import { getKioskName } from './stores';
import { lastBagLocation } from './stores/sbdState';
import { getCussSBD } from './cuss-components';
import { worker } from '../../mocks/browser';

export default {
  events,
  bridge,
  stores: {
    getKioskName,
    lastBagLocation,
  },
  cuss: {
    components: {
      getCussSBD,
    },
  },
  worker,
};

/** Create the timatic message sent to Flight Deck for a timatic check.*/
/* eslint-disable max-classes-per-file */
const TimaticStatuses = Object.freeze({
  OK_TO_BOARD: 'OK_TO_BOARD',
  CONDITIONAL: 'CONDITIONAL',
  NOT_OK_TO_BOARD: 'NOT_OK_TO_BOARD',
});

/** Encapsulates rendering of a text message for a Timatic check. */
export default class TimaticRenderer {
  /**
   * Instantiate the object.
   *
   * @param {object} timaticCheck - The timaticCheck response from Switchboard
   *                                enriched with the passener and infant
   *                                objects. The infant object is optional,
   *                                should only be present if this was a
   *                                timatic check of an infant/parent pair.
   */
  constructor(timaticCheck) {
    const { timaticInfo } = timaticCheck.timaticCheck;
    const { passenger, infant } = timaticCheck;

    this.timaticInfo = timaticInfo;
    this.adult = passenger;
    this.infant = infant;
    this.adultTimInfo = new TimaticInfo(timaticInfo[0]);
    this.infantTimInfo = infant ? new TimaticInfo(timaticInfo[1]) : null;
  }

  /**
   * Whether or not this was a timatic check of a parent/infant pair.
   *
   * @returns {boolean}
   */
  hasInfant() {
    return Boolean(this.infant);
  }

  /**
   * Render the heading line of the rendered text message.
   *
   * When this is a parent/infant pair, the heading will include the timatic
   * status of both parent and infant, each staus labeled with either
   * 'Parent:' or 'Infant' respectively. If this is the timatic check of a
   * single adult, the label is not prepended.
   *
   * @returns {string} The heading.
   */
  renderHeading() {
    return `Timatic Check. ${
      this.hasInfant()
        ? `Parent: ${this.adultTimInfo.getStatus()}; ` +
          `Infant: ${this.infantTimInfo.getStatus()}`
        : this.adultTimInfo.getStatus()
    }.`;
  }

  /**
   * Render the message body fragment of a particular passenger.
   *
   * If the passenger is 'ok to board', then no message will be rendered.
   *
   * @param {object} passenger - The Switchboard Passenger object.
   *                             Defaults to the adult passenger.
   * @param {object} timInfo - The Switchboard timInfo object for the
   *                           passenger. Defaults to the adult's timInfo.
   * @returns {string}
   */
  renderPassengerBody(passenger = null, timInfo = null) {
    passenger = passenger || this.adult;
    timInfo = timInfo || this.adultTimInfo;

    if (timInfo.isOkToBoard()) {
      return '';
    }
    return (
      `${passenger.firstName} ${passenger.lastName}` +
      (timInfo.isMultiSector() ? '\n' : '; ') +
      `${timInfo.render()}`
    );
  }

  /**
   * Render the message body fragment for the adult and prepend a label.
   *
   * @returns {string}
   */
  renderAdultBody() {
    const body = this.renderPassengerBody(this.adult, this.adultTimInfo);
    return prependLabel(body, '\nParent');
  }

  /**
   * Render the message body fragment for the infant and prepend a label.
   *
   * @returns {string}
   */
  renderInfantBody() {
    const body = this.renderPassengerBody(this.infant, this.infantTimInfo);
    return prependLabel(body, '\nInfant');
  }

  /**
   * Render the message body, consisting of the passenger fragments.
   *
   * @returns {string}
   */
  renderBody() {
    return this.hasInfant()
      ? this.renderAdultBody() + this.renderInfantBody()
      : `\n${this.renderPassengerBody()}`;
  }

  /**
   * Render the entire message. Includes heading and body.
   *
   * @returns {string}
   */
  render() {
    return this.renderHeading() + this.renderBody();
  }
}

/** Encapsulates rendering of the timaticInfo structure. */
class TimaticInfo {
  /**
   * Instatiate the object.
   *
   * @param {object} timaticInfo - Switchboard timaticInfo array.
   */
  constructor(timaticInfo) {
    this.timaticInfo = timaticInfo;
  }

  /**
   * Get the timaticInfo status.
   *
   * @returns {string}
   */
  getStatus() {
    return this.timaticInfo.status;
  }

  /**
   * Determine if OK_TO_BOARD is indicated for the timaticInfo.
   *
   * @return {Boolean}
   */
  isOkToBoard() {
    return this.getStatus() === TimaticStatuses.OK_TO_BOARD;
  }
  /**
   * Get the timaticCountryInfo array.
   *
   * Each Switchboard object is instantiated as a TimaticCountryInfo.
   *
   * @return {TimaticCountryInfo[]}
   */
  getTimaticCountryInfo() {
    const isMultiSector = this.isMultiSector();
    return (this.timaticInfo.timaticCountryInfo || []).map(
      (timaticCountryInfo) =>
        new TimaticCountryInfo(timaticCountryInfo, isMultiSector),
    );
  }

  /**
   * Determine whether this is a multi-sector timaticInfo.
   *
   * @return {Boolean}
   */
  isMultiSector() {
    return (this.timaticInfo.timaticCountryInfo || []).length > 1;
  }

  /**
   * Render the message fragment for the timInfo.
   *
   * @return {String}
   */
  render() {
    /**
     * Reducer function for rendering timaticCountryInfo array.
     *
     * Only renders if the timaticContryInfo is not "OK TO BOARD".
     *
     * return {String} The result of reducing the item.
     */
    function renderIfUnsuccessful(result, timaticCountryInfo) {
      if (timaticCountryInfo.isOkToBoard()) {
        return result;
      }
      return `${result}${result ? '\n' : ''}${timaticCountryInfo.render()}`;
    }
    return this.getTimaticCountryInfo().reduce(renderIfUnsuccessful, '');
  }
}

/** Encapsulates rendering of the timaticCountryInfo structure. */
class TimaticCountryInfo {
  /**
   * Instatiate the object.
   *
   * @param {Object} timaticCountryInfo - Switchboard timaticCountryInfo array.
   */
  constructor(timaticCountryInfo, isMultiSector) {
    this.timaticCountryInfo = timaticCountryInfo;
    this.isMultiSector = isMultiSector;
  }

  /**
   * Get the timatic status for the timaticCountryInfo.
   *
   * @return {String}
   */
  getStatus() {
    return this.timaticCountryInfo.status;
  }

  /**
   * Determine if OK_TO_BOARD is indicated for the timaticCountryInfo.
   *
   * @returns {boolean}
   */
  isOkToBoard() {
    return this.getStatus() === TimaticStatuses.OK_TO_BOARD;
  }

  /**
   * Get the nationality.
   *
   * @returns {string}
   */
  getDestination() {
    return this.timaticCountryInfo.countryName;
  }

  /**
   * Get the sections array.
   *
   * Each Switchboard section object is instantiated as a Section.
   *
   * @returns {Section[]}
   */
  getSections() {
    return (this.timaticCountryInfo.sections || []).map(
      (section) => new Section(section),
    );
  }

  /**
   * Render a heading for the timaticCountryInfo.
   *
   * If this is a multi-sector timatic, will print the timatic status
   * in the heading (as timatic status can vary between sectors).
   *
   * @return {String}
   */
  renderHeading() {
    const statusString = !this.isMultiSector ? '' : ` (${this.getStatus()})`;
    return `Destination: ${this.getDestination()}${statusString}`;
  }

  /**
   * Render the message fragment for the timaticCountryInfo.
   *
   * @returns {string}
   */
  render() {
    return (
      this.renderHeading() +
      this.getSections().reduce(
        (result, section) => result + section.render(),
        '',
      )
    );
  }
}

/** Encapsulates rendering of the section structure. */
class Section {
  /**
   * Instantiates the object.
   *
   * @param {object} section - The Switchborad section object.
   */
  constructor(section) {
    this.section = section;
  }

  /**
   * Get Section 'name'.
   *
   * @returns {string}
   */
  getName() {
    return this.section.name || '';
  }

  /**
   * Get the subSectionInfo array.
   *
   * Each Switchboard subSectionInfo object is instantiated as a
   * SubSectionInfo..
   *
   * @returns {SubSectionInfo[]}
   */
  getSubSectionInfos() {
    return (this.section.subSectionInfos || []).map(
      (subSectionInfo) => new SubSectionInfo(subSectionInfo),
    );
  }

  /**
   * Render the message fragment for the 'section'.
   *
   * @returns {string}
   */
  render() {
    return `\n${this.getName()}${this.renderSubSectionInfos()}`;
  }

  /**
   * Render each of the subsectionInfos and concatenate them.
   *
   * @returns {string}
   */
  renderSubSectionInfos(subsectionInfos) {
    return this.getSubSectionInfos().reduce(
      (result, subsectionInfo) => result + subsectionInfo.render(),
      '',
    );
  }
}

/** Encapsulates rendering of the subSectionInfo structure. */
class SubSectionInfo {
  constructor(subSectionInfo) {
    this.subSectionInfo = subSectionInfo;
  }

  /**
   * Render a heading for the subSectionInfo.
   *
   * Tries to make use of the 'name' and 'type' fields in the subSectionInfo.
   * If neither of these are present, returns an empty string.
   *
   * @returns {string}
   */
  renderHeading() {
    const { name, type } = this.subSectionInfo;
    return type && name ? `${type}: ${name}` : type || name || '';
  }

  /**
   * Render entire fragment for the subSectionInfo.
   *
   * @returns {string}
   */
  render() {
    const { text } = this.subSectionInfo;
    return `\n\t${this.renderHeading()}\n\t\t${text}`;
  }
}

/**
 * Prepend a label to some text.
 *
 * If text is falsy, don't prepend, return an empty string.
 *
 * @param {string} text - The text to be labeled.
 * @param {string} label - The label to  prepend to text.
 * @returns {string}
 */
function prependLabel(text, label) {
  return text ? `${label}: ${text}` : '';
}

<script>
  export let handler;
  export let active;
</script>

<style>
  .active {
    @apply text-white;
    @apply bg-gold-100;
  }

  .notActive {
    @apply text-gold-100;
    @apply bg-white;
  }
</style>

<button
  class="capitalize rounded-1 border-gold-100 border-1 px-4 py-2"
  class:active
  class:notActive={!active}
  on:click={handler}
>
  <slot />
</button>

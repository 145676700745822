<script>
  import { _ } from 'svelte-i18n';
  import { get } from 'svelte/store';
  import { onDestroy, onMount } from 'svelte';
  import { push } from 'svelte-spa-router';
  import { ApplicationStep, appReport } from '../../js/appReport';
  import {
    addBag,
    booking,
    currentBag,
    currentBagIndex,
    currentPassenger,
    currentTotalWeight,
    featureFlags,
    headPassenger,
    isFinalBag,
    plusgradeAncillaryOfferManager,
    setErrorModal,
    setInPaymentFlow,
    wizardPosition,
  } from '../../js/stores';
  import {
    BagWeightMax,
    BagWeightWarningPercent,
    FeatureFlags,
    WizardPosition,
  } from '../../js/const';
  import { ErrorModals } from '../../js/const/errorModals';
  import flightdeck from '../../js/services/flightdeck';
  import logger from '../../js/logger';

  import BaggageAllowanceExceeded from '../components/modal/BaggageAllowanceExceeded.svelte';
  import BaggageAllowancePercentageReached from '../components/modal/BaggageAllowancePercentageReached.svelte';
  import Content from '../components/Content.svelte';
  import Footer from '../components/Footer/index.svelte';
  import Header from '../components/Header/index.svelte';
  import TripExtrasPayment from '../components/TripExtras/modal/TripExtrasPayment.svelte';
  import VerificationInfo from '../components/VerificationInfo.svelte';

  let isPcBaggage = null;
  let isPlusgradeEligible = null;
  let showBaggageAllowanceExceededModal = null;
  let showBaggageAllowancePercentageReachedModal = null;
  let showTripExtrasPaymentModal = null;
  let unsubscribeCurrentBag = () => {};
  let isExcessBag = false;

  wizardPosition.set(WizardPosition.SECURITY);

  /**
   * Custom function that calculates the percent of a number.
   *
   * @param Int percent The percent that you want to get.
   * @param Int|int num The number that you want to calculate the percent of.
   * @returns {number}
   */
  function calculateWarningWeight(percent) {
    return Math.round((percent / 100) * booking.totalCombinedAllowance());
  }

  /** Subscription to currentBag. */
  function currentBagSubscription(bag) {
    const shouldProcessBag = bag && bag.settled;

    logger.info(
      `currentBag.subscribe(). bag: ${JSON.stringify(bag)}; bag.settled: ${
        bag && bag.settled
      }`,
    );

    if (shouldProcessBag) {
      bag = currentBag; // Use the store, not the store's value

      // Return to bag drop case
      if (booking.totalNumberOfActivatedBagTags() > 0) {
        isExcessBag = booking.totalCombinedAllowance() - booking.totalNumberOfActivatedBagTags() <= 0;          
      } else {
        // Non-return to bag drop case
        isExcessBag = booking.totalCombinedAllowance() - $currentBagIndex < 0;
      }

      // this will evaluate a case where it is non-excess bag
      let hasExceededWeightMaximum = isPcBaggage
        ? bag.failsPieceWeight(booking.getPieceWeights()[$currentBagIndex - 1])
        : bag.failsWeight();

      // if its piece concept and also if the current bag is excess bag then we pass maximum weight
      if (isPcBaggage && isExcessBag) {
        hasExceededWeightMaximum = bag.failsPieceWeight(BagWeightMax);
      }

      const hasExceededWarningThreshold =
        (currentTotalWeight() + booking.totalWeightUsedInPreviousTransaction()) >=
        calculateWarningWeight(BagWeightWarningPercent);

      if (hasExceededWeightMaximum) {
        logger.warn('BAG_WEIGHT_ERROR');
        setErrorModal(ErrorModals.BAG_WEIGHT_ERROR);
        flightdeck.overweightSingleBag(
          $currentBag,
          $currentBagIndex,
          currentBag.amountWeightOverSingleBagMaximum(),
          BagWeightMax,
        );
      } else if (bag.isLifted) {
        // do nothing, will be handled by override.
      } else if (bag.failsTotalWeight() && !isPcBaggage) {
        if (!$currentBag.inPaymentFlow) {
          setInPaymentFlow(true);
          appReport.updateStepStart(ApplicationStep.EXCESS_BAGGAGE_INFORMATION)
          showBaggageAllowanceExceededModal = true;
          logger.info(
            'Bag exceeds total weight allowance. ' +
              'Displaying baggage allowance exceeded modal.',
          );
        }
      } else if (isFinalBag() && bag.isNumberOfBagsInStoreOverPieceAllowance()) {
        processPieceConceptExcessBaggage();
      } else if (
        hasExceededWarningThreshold &&
        !isFinalBag() &&
        !isPcBaggage &&
        featureFlags.isEnabled(FeatureFlags.EXCESS_BAGGAGE)
      ) {
        showBaggageAllowancePercentageReachedModal = true;
        logger.info(
          'Bag weight exceeds allowance warning percentage: ' +
            `${BagWeightWarningPercent}%`,
        );
      } else {
        advanceToNextScreen();
      }
    }
  }

  function processPieceConceptExcessBaggage() {
    if (!$currentBag.inPaymentFlow) {
      setInPaymentFlow(true);
      showBaggageAllowanceExceededModal = true;
      logger.info(
        'Total piece allowance is exceeded. ' +
        'Displaying baggage allowance exceeded modal.',
      );
    }
  }

  /** Move to the next screen. */
  function advanceToNextScreen() {
    push('/porter-printing-bag-tag');
  }

  /** Handle the click of the Plusgrade advertisement. */
  function advertisementHandler() {
    showBaggageAllowanceExceededModal = false;
    showTripExtrasPaymentModal = true;
  }

  /** Handle the Continue action from FlightDeck. */
  function advertisementContinueHandler() {
    plusgradeAncillaryOfferManager.performBookingUpdate();
    advanceToNextScreen();
  }

  onMount(() => {
    isPcBaggage = booking.getBaggageUnit() === 'pc';
    showBaggageAllowanceExceededModal = false;
    showBaggageAllowancePercentageReachedModal = false;
    showTripExtrasPaymentModal = false;

    logger.info(`Entering PorterPlacePassengerBag`);

    addBag();
    appReport.updateStepStart(ApplicationStep.BAG_ASSESSMENT);

    unsubscribeCurrentBag = currentBag.subscribe(currentBagSubscription);

    if (featureFlags.isEnabled(FeatureFlags.PLUSGRADE)) {
      isPlusgradeEligible =
        !booking.hasPlusgradeUpgrade(get(headPassenger)) &&
        plusgradeAncillaryOfferManager.isEligibleForCabinUpgrade();
    }
  });

  onDestroy(() => {
    unsubscribeCurrentBag();
  });
</script>

<BaggageAllowanceExceeded
  {advertisementHandler}
  {isPlusgradeEligible}
  bind:showModal={showBaggageAllowanceExceededModal}
/>

<BaggageAllowancePercentageReached
  bind:showModal={showBaggageAllowancePercentageReachedModal}
/>

<Header hasHomeButton={false} />

<Content>
  <span slot="heading">{$_('placeBag.heading')}</span>
  <span slot="text">{$_('placeBag.textPbd')}</span>

  <div slot="main">
    <div class="mt-16 mx-auto ratio ratio--9:16 w-82">
      <img
        class="ratio__content"
        src="images/porterBagDrop.webp"
        alt="Porter Bag Drop."
      />
    </div>

    <!-- Force redraw of VerificationInfo when currentPassenger changes -->
    {#if $currentPassenger}
      <VerificationInfo isPcBaggage={isPcBaggage} />
    {:else}
      <VerificationInfo isPcBaggage={isPcBaggage} />
    {/if}
  </div>
</Content>

<Footer />

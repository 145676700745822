/* Timatic Checks */
import { derived, get } from 'svelte/store';
import { isObjEmpty, safeObjectAttribute } from '../utils';
import { resettable } from './extensions/resettable';
import switchboardClient from '../services/switchboard';
import TimaticRenderer from './helpers/timaticRenderer';
import { booking } from './booking';
import { passengerMrzManager } from './passengerMrzs';
import logger from '../logger';

const TIMATIC_SUCCESS_STATUS = 'SUCCESS';
const TIMATIC_FAILURE_STATUS = 'FAILURE';
const NOT_STARTED = -1;

const PassengerTypes = Object.freeze({
  ADULT: 'ADULT',
  INFANT: 'INFANT',
});

const timaticsPendingCount = resettable(NOT_STARTED);
const timaticEditsPendingCount = resettable(NOT_STARTED);
const timaticChecks = resettable([]);
const overriddenPassengers = resettable([]);

/**
 * Given a number of pending async calls, determines if we are still waiting.
 *
 * If the pending count matches NOT_STARTED, then we are not waiting.
 * Otherwise, if the pending count exceeds 0, then we are waiting.
 * @param {number} pending - The number of pending calls.
 * @returns {boolean} Whether or not we are waiting.
 */
function testPending(pending) {
  return pending !== NOT_STARTED && pending > 0;
}

/**
 * Increments are svelte store containing a count of pending requests.
 *
 * If the store value is NOT_STARTED, will always set the new value to 1.
 * Otherwise increment the value by 1.
 *
 * @param {object} pendingStore - A svelte store containing a pending counter.
 */
function incrementPending(pendingStore) {
  const currentValue = get(pendingStore);
  pendingStore.set(currentValue === NOT_STARTED ? 1 : currentValue + 1);
}

export const areTimaticsPending = derived(timaticsPendingCount, testPending);
export const areTimaticEditsPending = derived(
  timaticEditsPendingCount,
  testPending,
);

/**
 * Manages Timatic Check requests and data.
 */
export const timaticCheckManager = {
  /**
   * Reset the resettable timatic stores.
   */
  resetStores() {
    timaticsPendingCount.reset();
    timaticEditsPendingCount.reset();
    timaticChecks.reset();
    overriddenPassengers.reset();
  },

  /**
   * Perform a Timatic check.
   *
   * Updates the store of timatic checks, so that haveUnsuccessfulTimatics()
   * will subsequently work. Uses timaticsPendingCount as a sentinel to count
   * currently pending requests.
   *
   * @param {object} passenger - A passenger object from Switchboard.
   * @param {string[]} mrz - An MRZ as an array of strings.
   */
  performCheck(passenger, mrz) {
    const timaticLogType = {
      PENDING: 'pending',
      RECEIVED: 'received',
    };

    let infant = null;
    let infantMrz = null;
    if (booking.passengerHasInfant(passenger)) {
      infant = booking.getInfantForPassenger(passenger);
      infantMrz = passengerMrzManager.getPassengerMrz(infant);
    }

    /**
     * Factory for creating a logger for timatic requests.
     *
     * @param {string} logType - Member of timaticLogType.
     * @returns {Function} - Logger function, receives timaticCheck.
     */
    function logTimatic(logType) {
      return (timaticCheck = null) => {
        const pending =
          get(timaticsPendingCount) - (logType === timaticLogType.RECEIVED)
            ? 1
            : 0;
        const successStatus = timaticCheck
          ? `Successful: ${!isUnsuccessful(timaticCheck)}. `
          : '';
        logger.debug(
          `Timatic ${logType} for ` +
            `${booking.getPassengerLogMessage(passenger)}. ` +
            `MRZ: '${mrz && Array.isArray(mrz) && mrz.join(', ')}'. ` +
            (infant
              ? `With infant ${booking.getPassengerLogMessage(infant)}. ` +
                `Infant MRZ: '${infantMrz && Array.isArray(infantMrz) && infantMrz.join(', ')}. `
              : '') +
            `${successStatus}` +
            `Currently, ${pending} timatics are pending.`,
        );
      };
    }

    const logTimaticPending = logTimatic(timaticLogType.PENDING);
    const logTimaticReceived = logTimatic(timaticLogType.RECEIVED);

    incrementPending(timaticsPendingCount);
    logTimaticPending();
    switchboardClient
      .timaticCheck(get(booking), passenger, mrz, infantMrz)
      .then((response) => {
        const timaticCheck = response.data.timaticCheck;
        logTimaticReceived(timaticCheck);
        timaticChecks.set([
          ...get(timaticChecks),
          {
            passenger,
            infant,
            mrz,
            infantMrz,
            timaticCheck,
          },
        ]);
      })
      .catch((error) => {
        logger.warn(
          `Could not perform Timatic check. Error: ${error.message}.`,
        );
        timaticChecks.set([
          ...get(timaticChecks),
          {
            passenger,
            infant,
            mrz,
            infantMrz,
            timaticCheck: {
              status: TIMATIC_FAILURE_STATUS,
              message:
                'Switchboard request failed with error: ' +
                `${error.message}.`,
            },
          },
        ]);
      })
      .finally(() => {
        timaticsPendingCount.set(get(timaticsPendingCount) - 1);
      });
  },

  /**
   * Determine if any timatic checks were unsuccessful.
   *
   * @returns {boolean} whether or not there were any unsuccessful checks.
   */
  haveUnsuccessfulTimatics() {
    return unsuccessfulTimatics().length > 0;
  },

  /** Any timatics that have a Failure status but which can't be overridden. */
  haveUnrecoverableFailures() {
    const RecoverableStatuses = ['CONDITIONAL', 'NOT_OK_TO_BOARD'];

    return unsuccessfulTimatics().some((check) => {
      const timaticInfo = safeObjectAttribute(
        check,
        'timaticCheck.timaticInfo',
      );

      const receivedTimaticInfo = Boolean(timaticInfo);
      const noRecoverableStatuses = !(timaticInfo || []).some((info) =>
        RecoverableStatuses.includes(info.status),
      );
      const unrecoverable = !receivedTimaticInfo || noRecoverableStatuses;
      if (unrecoverable) {
        logger.debug(
          'Failed timatic check deemed unrecoverable: ' +
            `Valid response (receivedTimaticInfo): ${receivedTimaticInfo}; ` +
            `Failure and no statuses were either CONDITIONAL or ` +
            `NOT_OK_TO_BOARD (noRecoverableStatuses): ${noRecoverableStatuses}.`,
        );
      }

      return unrecoverable;
    });
  },

  /**
   * Returns the tim check error messages aggregated by switchboard.
   *
   * @returns {string[]} - Switcboard message fields.
   */
  timaticCheckErrors() {
    return unsuccessfulTimatics().map(
      (check) =>
        `${check.passenger.firstName} ${check.passenger.lastName}: ` +
        safeObjectAttribute(check, 'timaticCheck.message'),
    );
  },

  /**
   * Extract the Timatic Reasons for all unsuccessful timatic checks.
   *
   * This first extracts the reasons for each passenger, then flattens them
   * into one array.
   */
  timaticReasons() {
    return unsuccessfulTimatics()
      .map((check) => individualTimaticReasons(check))
      .flat();
  },

  /**
   * Perform Timatic Override for all passengers who have unsuccessful checks.
   *
   * @returns {Promise} - A promise of this timatic override operation.
   */
  timaticOverrideUnsuccessfulChecks() {
    const timaticOverrides = unsuccessfulTimatics().map((check) => {
      const passenger = check.passenger;
      if (
        isObjEmpty(booking.passengerByIndex(passenger.passengerIndex)) &&
        isObjEmpty(booking.infantByIndex(passenger.passengerIndex))
      ) {
        logger.warn(
          `Timatic override not attempted ` +
            `${booking.getPassengerLogMessage(passenger)}. ` +
            `No matching passenger in booking response.`,
        );

        return Promise.resolve();
      }

      incrementPending(timaticEditsPendingCount);
      logger.info(
        `Request timaticOverride. ` +
          `${booking && booking.getPassengerLogMessage(passenger)}.`,
      );
      return switchboardClient
        .timaticOverride(get(booking), passenger, check.mrz, check.infantMrz)
        .then((response) => {
          timaticEditsPendingCount.set(get(timaticEditsPendingCount) - 1);
          logger.info(
            `Recieved timaticOverride response. ` +
              `${booking && booking.getPassengerLogMessage(passenger)}` +
              `Pending overrides: ${get(timaticEditsPendingCount)}.`,
          );
          overriddenPassengers.set([
            ...get(overriddenPassengers),
            passenger.passengerIndex,
          ]);
          if (
            response.data.timaticOverride.status.toUpperCase() !==
            TIMATIC_SUCCESS_STATUS
          ) {
            throw 'Timatic Override failed on SwitchBoard.';
          }
        });
    });

    return Promise.all(timaticOverrides);
  },
};

/**
 * Determine if a Timatic check was unsuccessful.
 *
 * @param {object} timaticCheck - The timaticCheck object from SwitchBoard.
 * @returns {boolean} Whether or not the timatic check was unsuccessful.
 */
function isUnsuccessful(timaticCheck) {
  return timaticCheck.status.toUpperCase() !== TIMATIC_SUCCESS_STATUS;
}

/**
 * Return array of unsuccessful timatic checks.
 *
 * @returns {object[]} Array of timaticCheck objects.
 */
function unsuccessfulTimatics() {
  return get(timaticChecks)
    .filter((check) => isUnsuccessful(check.timaticCheck))
    .filter(
      (check) =>
        !get(overriddenPassengers).includes(check.passenger.passengerIndex),
    );
}

/**
 * Extract Timatic Reasons from an individual timatic check.
 *
 * @param {object} timaticCheck - The timaticCheck object in the SwitchBoard response.
 * @returns {object} - The timatic reasons object.
 */
function individualTimaticReasons(timaticCheck) {
  const timaticRenderer = new TimaticRenderer(timaticCheck);
  return {
    passenger: timaticCheck.passenger,
    message: timaticRenderer.render(),
  };
}

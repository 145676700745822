import { get } from 'svelte/store';

import logger from '../logger';
import { resettable } from './extensions/resettable';
import { safeObjectAttribute } from '../utils';
import switchboardClient, {
  raiseErrorOnBadResponse,
} from '../services/switchboard';

const seatChanges = resettable({});
const offloadOccurred = resettable(false);
/**
 * Used for accessing seat changes.
 */
class SeatChangesManager {
  /**
   * Set a seat change and stores it.
   *
   * @param {object} passenger - Switchboard passenger object.
   * @param {string} flightNumber - The flight number.
   * @param {string} newSeat - The new seat number.
   */
  setSeatChange(passenger, flightNumber, newSeat) {
    const oldSeatChanges = get(seatChanges);
    seatChanges.set({
      ...oldSeatChanges,
      [passenger.passengerID]: {
        ...oldSeatChanges[passenger.passengerID],
        [flightNumber]: newSeat,
      },
    });
  }
  /**
   * In case of passenger gets offloaded by seat Select Change.
   */
  offloadOccurred() {
    return get(offloadOccurred);
  }
  /**
   * Get the seat number.
   *
   * @param {object} passenger - The Switchboard passenger object.
   * @param {string} flightNumber - The flight number.
   * @returns {string} The seat number.
   */
  getSeatNumber(passenger, flightNumber) {
    return safeObjectAttribute(
      get(seatChanges),
      `${passenger.passengerID}.${flightNumber}`,
    );
  }

  /**
   * Perform a passenger seat change.
   *
   * @param {object} booking
   * @param {object} passenger
   * @param {string} flightNumber
   * @param {string} seat
   * @param {string} flightdeck
   */
  performChangeSeat(booking, passenger, flightNumber, seat, flightdeck) {
    logger.info(`Request selectChangeSeat.`);

    return switchboardClient
      .selectChangeSeat(booking, passenger, flightNumber, seat)
      .then((response) => {
        if (response && response.data) {
          logger.info(
            `Received selectChangeSeat response. ` +
              `(response: ${JSON.stringify(response.data.selectChangeSeat)}).`,
          );
        }
        logger.info(`passenger: ${JSON.stringify(passenger)}`);

        const success = raiseErrorOnBadResponse(response, false);
        if (!success) {
          if (
            safeObjectAttribute(response.data, 'selectChangeSeat.message') ===
            'PASSENGER_OFFLOADED'
          ) {
            offloadOccurred.set(true);
            return;
          } else {
            raiseErrorOnBadResponse(response);
          }
        }
        this.setSeatChange(passenger, flightNumber, String(seat));
      })
      .catch((error) => {
        logger.warn(`Failed selectChangeSeat: ${error.message}`);
        throw error;
      });
  }

  /** Resets the svelte store. */
  reset() {
    seatChanges.reset();
  }
}

export const seatChangesManager = new SeatChangesManager();

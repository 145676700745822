/* Store the state of CUSS */
import { get, writable } from 'svelte/store';
import { DEV_KIOSK_NAME } from '../const';
import { resettable } from './extensions/resettable';

export const appletRunning = writable(false);
export const startUpSuccessful = writable(false);
export const envLevel = writable();
export const appIsActive = writable(false);
export const persistentSingleApplicationMode = writable(false);
export const appTransferDataContainingPassportMRZ = resettable(false);
export const applicationMadeActiveThroughTransfer = resettable(false);

/**
 * Returns the Kiosk Name from the CUSS platform.
 *
 * This can only return the correct value if
 * startUpSuccessful has been set to true.
 */
export function getKioskName() {
  let kioskName;
  try {
    kioskName = get(envLevel).kioskID.kioskName;
  } catch {
    kioskName = DEV_KIOSK_NAME;
  }
  return kioskName;
}

<script context="module">
  /**
   * Manager for Fields. Should be used as a singleton.
   */
  class FieldManager {
    /** Initialise the array of Fields. */
    constructor() {
      this.fields = {};
    }

    /**
     * Return the currently focussed Field, if there is one.
     *
     * @returns {Field} The focussed Field, or undefined.
     */
    getFocussed() {
      return Object.values(this.fields).find((field) => field.isFocussed());
    }

    /**
     * Tells each Field that there has been an update by calling its callback.
     */
    triggerReactivity() {
      Object.values(this.fields).forEach((field) => field.callback());
    }

    /**
     * Call a method on the focussed Field, if one is currently focussed.
     *
     * Automatically triggers reactivity on each Field.
     *
     * @param {string} methodName - The name of the method.
     * @param {any[]} args - Any arguments to pass to the method.
     */
    callFocussed(methodName, ...args) {
      const focussed = this.getFocussed();
      if (focussed) {
        focussed[methodName](...args);
        this.triggerReactivity();
      }
    }

    /**
     * Add a Field.
     *
     * @param {string} label - The Field's label.
     * @param {Function} callback - Callback for when a Field needs to update.
     */
    addField(label, callback) {
      this.fields[label] = new Field(callback);
      this.triggerReactivity();
    }

    /**
     * Delete a Field.
     *
     * @param {string} label - The field label.
     */
    removeField(label) {
      delete this.fields[label];
    }

    /**
     * Get a Field.
     *
     * @param {string} label - The label of the Field to get.
     * @returns {Field} The Field.
     */
    getField(label) {
      return this.fields[label];
    }

    /**
     * Sets a Field to focussed.
     *
     * Automatically triggers reactivity.
     *
     * @param {string} label - The label of the Field to set to focussed.
     */
    setFocussed(label) {
      Object.entries(this.fields).forEach(
        ([key, field]) => (this.fields[key].focussed = label === key),
      );
      this.triggerReactivity();
    }
  }

  /**
   * Encapsulates Field behaviour.
   */
  class Field {
    /**
     * Initialise the field.
     *
     * this.focussed and this.value are copies of the values local to the
     * component (defined in the <script> block). The componet will update
     * its values when this.callback is invoked.
     *
     * @param {Function} callback - Callback to tell the componet to update.
     */
    constructor(callback) {
      this.focussed = false;
      this.callback = callback;
      this.value = '';
    }

    /**
     * Determine whether or not this Field is focussed.
     *
     * @returns {boolean} Whether or not this Field is focussed.
     */
    isFocussed() {
      return this.focussed;
    }

    /**
     * Add a character to the end of the Field value.
     *
     * eg. Used when a letter is typed on the keyboard.
     */
    addChar(char) {
      this.value += char;
    }

    /** Clear the Field value. */
    clear() {
      this.value = '';
    }

    /** Perform a backspace on the Field value. */
    backspace() {
      const valueLength = this.value.length;
      if (valueLength) {
        this.value = this.value.slice(0, valueLength - 1);
      }
    }

    /** Add a space. */
    space() {
      this.value += ' ';
    }
  }

  const fieldManager = new FieldManager();

  /** Exported function for adding a character to the focussed field input. */
  export function addChar(char) {
    return fieldManager.callFocussed('addChar', char);
  }

  /** Exported function for clearing the focussed field input. */
  export function clear() {
    return fieldManager.callFocussed('clear');
  }

  /** Exported function for backspace on the focussed field input. */
  export function backspace() {
    return fieldManager.callFocussed('backspace');
  }

  /** Exported function for space on the focussed field input. */
  export function space() {
    return fieldManager.callFocussed('space');
  }
</script>

<script>
  import { onDestroy, onMount } from 'svelte';
  import { _ } from 'svelte-i18n';

  export let hasAutofocus = false;
  export let label;
  export let valueHandler = () => {};

  let value = '';
  let focussed;

  /**
   * The Field object relating to this component.
   *
   * @returns {Field} The Field object.
   */
  function thisField() {
    return fieldManager.getField(label);
  }

  /**
   * Set variables local to this component when invoked by the FieldManager.
   */
  function handleUpdate() {
    const field = thisField();
    ({ focussed, value } = field);
    valueHandler(value);
  }

  /**
   * Handle the focus event on the input element.
   *
   * Tell the FieldManager that this component is focussed.
   */
  function handleFocus() {
    fieldManager.setFocussed(label);
  }

  /**
   * Add a Field to the FieldManager for this component.
   */
  onMount(() => {
    fieldManager.addField(label, handleUpdate);

    if (hasAutofocus) {
      handleFocus();
    }
  });

  /**
   * Remove this component's Field from the FieldManager.
   */
  onDestroy(() => {
    fieldManager.removeField(label);
  });
</script>

<label for={label}>{$_(label)}</label>
<input
  id={label}
  class={focussed ? 'bg-white' : ''}
  on:focus={handleFocus}
  type="text"
  bind:value
/>

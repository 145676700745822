import { get } from 'svelte/store';
import payloads from './payloads';
import constants from './const';
import {
  flightdeckApiKey,
  flightdeckUri,
  getKioskName,
  pnr,
  timaticCheckManager,
} from '../../stores';
import {
  objectMap,
  secondsToMilliseconds,
  timeStampLocalOffset,
  timeStampZeroOffset,
} from '../../utils';
import logger from '../../logger';
import events from '../../events';

const FlightdeckHeartbeatTimer = 110; // seconds
const API_PATH = '/api';
const REGISTER_ENDPOINT = `${API_PATH}/message/register/`;
const HEARTBEAT_ENDPOINT = `${API_PATH}/message/heartbeat/`;
const MESSAGE_ENDPOINT = `${API_PATH}/message/`;
const TRANSACTION_ENDPOINT = `${API_PATH}/message/transaction/`;
const OVERRIDE_ENDPOINT = `${API_PATH}/message/override/`;
const POLLING_INTERVAL = 250; // milliseconds
const transactionPolling = {};

const { TransactionTypes, TransactionStatuses } = constants;

/**
 * Get the Flightdeck Request header.
 *
 * Uses the API Key from the config store.
 */
function getFlightdeckHeader() {
  return {
    'Content-Type': 'application/json',
    'X-API-Key': get(flightdeckApiKey),
  };
}

/**
 * Create a flightdeck 'transaction' service call function.
 *
 * Sends the request.
 * Automatically start polling if this is an actionable alert.
 * Polling fires an event when complete.
 *
 * @param {Function} payloadFactory - Function for creating the payload.
 * @returns {Function} - Invokes transaction. Has same args as payloadFactory.
 */
function makeTransaction(payloadFunc) {
  return (...args) => {
    executeTransaction(payloadFunc(...args));
  };
}

/**
 * Creates functions for each of the flightdeck transaction payloads.
 */
function makeTransactions() {
  return objectMap(payloads, (payloadFunc) => makeTransaction(payloadFunc));
}

/**
 * Register device to Flight Deck.
 *
 * Injects the nodeIds: KioskId into the request body.
 */
export function registerKiosk() {
  const uri = get(flightdeckUri);
  if (!uri) {
    return;
  }

  const body = {
    nodeIds: [getKioskName()],
  };

  fetch(get(flightdeckUri).replace(/\/+$/, '') + REGISTER_ENDPOINT, {
    method: 'POST',
    cache: 'no-cache',
    credentials: 'omit',
    headers: getFlightdeckHeader(),
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(body),
  }).catch((error) =>
    logger.warn(
      'Error communicating with Flight Deck (registerKiosk()).',
      error,
    ),
  );
}

/**
 * Send Heartbeats of Kiosk to Flight Deck.
 *
 * Injects the kioskId into the request body.
 */
export function kioskHeartbeat() {
  const uri = get(flightdeckUri);
  if (!uri) {
    return;
  }

  fetch(get(flightdeckUri).replace(/\/+$/, '') + HEARTBEAT_ENDPOINT, {
    method: 'POST',
    cache: 'no-cache',
    credentials: 'omit',
    headers: getFlightdeckHeader(),
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: `"${getKioskName()}"`,
  }).catch((error) =>
    logger.warn(
      'Error communicating with Flight Deck. (kioskHeartbeat())',
      error,
    ),
  );
  setTimeout(kioskHeartbeat, secondsToMilliseconds(FlightdeckHeartbeatTimer));
}

function executeTransaction(body) {
  const uri = get(flightdeckUri);
  if (!uri) {
    return;
  }
  logger.info(`Flightdeck request. Message: ${body && body.message}`);
  fetch(get(flightdeckUri).replace(/\/+$/, '') + TRANSACTION_ENDPOINT, {
    method: 'POST',
    cache: 'no-cache',
    credentials: 'omit',
    headers: getFlightdeckHeader(),
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(body),
  })
    .then((response) => {
      response.json().then((data) => {
        if (data.transactionType === TransactionTypes.Actionable) {
          if (body) {
            logger.info(
              `Commencing Flightdeck polling for action with message: ` +
                `'${body.message}'; ` +
                `transaction subtypes: ${body.transactionSubTypes.toString()}; ` +
                `transaction id: ${data && data.id}; ` +
                `data: ${JSON.stringify(body.data)}.`,
            );
          }
          setTransactionPolling(data);
        }
      });
    })
    .catch((error) =>
      logger.warn(
        'Error communicating with Flight Deck. (executeTransaction())',
        error,
      ),
    );
}

/**
 * Start/Continue transaction polling for a particular transaction
 *
 * @param {object} transactionData - The Flightdeck transaction object.
 */
function setTransactionPolling(transactionData) {
  killTransactionPolling(transactionData.id);
  transactionPolling[transactionData.id] = setTimeout(
    () => executeTransactionPolling(transactionData),
    POLLING_INTERVAL,
  );
}

/**
 * Kill transaction polling for a specific transaction.
 *
 * @param {number} transactionId - The Flightdeck transaction id.
 */
function killTransactionPolling(transactionId) {
  const transactionTimeout = transactionPolling[transactionId];
  clearTimeout(transactionTimeout);
  delete transactionPolling[transactionId];
}

/**
 * Kill all pending flightdeck polling.
 */
export function killAllTransactionPolling() {
  Object.keys(transactionPolling).forEach((transactionId) => {
    logger.info(
      `Killing Flightdeck transaction polling for id: ${transactionId}.`,
    );
    killTransactionPolling(transactionId);
  });
}

/**
 * Determine if transaction polling is in progress for a transaction id.
 *
 * @param {number} - The Flightdeck transaction id.
 * @returns {boolean} - Wether or not the transaction polling is current.
 */
export function isTransactionPolling(transactionId) {
  return transactionId in transactionPolling;
}

function executeTransactionPolling(transactionData) {
  fetch(
    get(flightdeckUri).replace(/\/+$/, '') +
      TRANSACTION_ENDPOINT +
      transactionData.id +
      '/status',
    {
      method: 'GET',
      cache: 'no-cache',
      credentials: 'omit',
      headers: getFlightdeckHeader(),
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    },
  )
    .then((response) => {
      response
        .clone()
        .text()
        .then((status) => {
          switch (status) {
            case TransactionStatuses.Pending:
              if (isTransactionPolling(transactionData.id)) {
                setTransactionPolling(transactionData);
              }
              break;
            case TransactionStatuses.Acknowledged:
            case TransactionStatuses.Cancelled:
            case TransactionStatuses.Overridden:
            case TransactionStatuses.VisaVerified:
            case TransactionStatuses.Unknown:
            default:
              logger.info(
                `Received Flightdeck action resolution, ` +
                  `status: ${status}, id: ${
                    transactionData && transactionData.id
                  }.`,
              );
              events.emitFlightdeckAction(transactionData, status);
              break;
          }
        });
    })
    .catch((error) => {
      logger.warn(
        'Error polling transaction status from Flight Deck, ' +
          `id: ${transactionData && transactionData.id}.`,
        error,
      );

      if (isTransactionPolling(transactionData.id)) {
        logger.info(
          'Retrying polling of transaction status from Flight Deck, ' +
            `id: ${transactionData && transactionData.id}.`,
        );
        setTransactionPolling(transactionData);
      }
    });
}

function baseRequest(endpointFragment, body) {
  fetch(get(flightdeckUri).replace(/\/+$/, '') + endpointFragment, {
    method: 'POST',
    cache: 'no-cache',
    credentials: 'omit',
    headers: getFlightdeckHeader(),
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(body),
  }).catch((error) =>
    logger.warn(
      'Error communicating with Flight Deck. (baseRequest())',
      error,
    ),
  );
}

const flightdeck = {
  /**
   * Appends additional data items to the message string.
   *
   * @param {string} message - The Flight Deck alert message.
   * @param {object} extendedData - Additional data items.
   */
  extendedAlert(message, extendedData) {
    const extension = Object.keys(extendedData).reduce((result, key) => {
      return result + ` ${key}: ${extendedData[key]};`;
    }, '');
    return this.simpleAlert(message + extension, { errorData: extendedData });
  },

  /**
   * Post the timatic failure alert to Flight Deck.
   */
  timaticAlert() {
    const responses = timaticCheckManager
      .timaticReasons()
      .map(
        (reason) =>
          `Timatic Check ${reason.status}` +
          `\n${reason.firstName} ${reason.lastName} ` +
          `(${reason.countryName})` +
          `${reason.body}`,
      );
    return this.simpleAlert(
      'Timatic response check required for one or more passengers.',
      { timatic: { responses } },
    );
  },

  /**
   * Flight Deck override
   *
   * @param {string} agentId - The agents ID.
   * @param {string} message
   * @param {boolean} wasOverriden
   * @param {string} bookingRef - The booking reference.
   */
  override(agentId, message, wasOverriden, bookingRef = null) {
    const now = new Date();
    const messageExtension = wasOverriden
      ? ' Overridden by Agent.'
      : ' Transaction ended by Agent.';
    const payload = {
      NodeId: getKioskName(),
      AgentId: agentId,
      PNR: bookingRef ? bookingRef : get(pnr),
      Data: message + messageExtension,
      UTCTimestamp: timeStampZeroOffset(now),
      Timestamp: timeStampLocalOffset(now),
    };

    baseRequest(OVERRIDE_ENDPOINT, payload);
  },
};

/**
 * Send timatic alerts for all passengers.
 */
function timaticAll() {
  timaticCheckManager.timaticReasons().forEach(transactions.timaticIndividual);
}

const transactions = makeTransactions();

export default {
  flightdeck,
  timaticAll,
  ...transactions,
};

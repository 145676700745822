<script>
  import { Priority } from '../../js/const';
  import { rightToLeft } from '../../js/stores';

  import HotkeyContext from './HotkeyContext.svelte';
  import Video from './Video.svelte';

  export let heading = '';
  export let icon = '';
  export let iconClass = '';
  export let image = '';
  export let priority = Priority.NORMAL;
  export let textLine1 = '';
  export let textLine1Class = '';
  export let textLine2 = '';
  export let textLine2Class = '';
  export let textLine3 = '';
  export let textLine3Class = '';
  export let textLine4 = '';
  export let textLine4Class = '';
  export let video = '';

  $: modalErrorProps = {
    ...$$restProps,
    class: [`modal ${priority['z-index']}`, $$restProps.class]
      .filter(Boolean)
      .join(' '),
  };

  /**
   * ModalError component.
   *
   * Error variation of ModalBase.
   *
   * @example <caption>Example usage.</caption>
   * <ModalError
   *   class="mb-12"
   *   heading={$_('myModal.heading')}
   *   icon={myIcon}
   *   iconClass="text-palmGreen"
   *   priority={Priority.NORMAL}
   *   textLine1={$_('myModal.textLine1')}
   *   textLine1Class="font-bold"
   *   textLine2={$_('myModal.textLine2')}
   *   textLine2Class="text-arabianSunset"
   *   textLine3={$_('myModal.textLine3')}
   *   textLine3Class="checkbox"
   *   textLine4={$_('myModal.textLine4')}
   *   textLine4Class="font-bold"
   * >
   *   video="myCoolVideo"
   *   <div slot="modalButtons"></div>
   * </ModalError>
   *
   * @type {String} heading.
   * @type {String} icon.
   * @type {String} iconClass.
   * @type {String} priority. - The z-index of the modal.
   * @type {String} textLine1.
   * @type {String} textLine1Class. - Additional CSS classes.
   * @type {String} textLine2.
   * @type {String} textLine2Class.
   * @type {String} textLine3.
   * @type {String} textLine3Class.
   * @type {String} textLine4.
   * @type {String} textLine4Class.
   * @type {String} video.
   */
</script>

<style>
  .checkbox {
    @apply flex items-center mx-auto w-9/12;
  }

  .modal[dir='ltr'] .checkbox {
    @apply text-left;
  }

  .modal[dir='rtl'] .checkbox {
    @apply text-right;
  }

  .checkbox::before {
    @apply block h-7 mx-4 w-7;
    content: url("data:image/svg+xml,%3Csvg style='transform: none;' class='checkboxFilled' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23bd8b13' fill-rule='evenodd' d='M22.42 1.3A4.29 4.29 0 0019.27 0H4.45A4.29 4.29 0 001.3 1.3 4.29 4.29 0 000 4.45v14.82a4.29 4.29 0 001.3 3.15 4.29 4.29 0 003.15 1.3h14.82a4.44 4.44 0 004.45-4.45V4.45a4.29 4.29 0 00-1.3-3.15zm-2.36 7.3l-9.48 9.48a1 1 0 01-.7.3.93.93 0 01-.69-.3l-5.53-5.52a1 1 0 01-.3-.7.92.92 0 01.3-.69l1.57-1.58a.94.94 0 01.7-.29.91.91 0 01.69.29l3.24 3.27 7.22-7.21a.92.92 0 01.69-.3 1 1 0 01.7.3l1.59 1.56a1 1 0 01.3.7.93.93 0 01-.3.69z'/%3E%3C/svg%3E");
  }
</style>

<div {...modalErrorProps} dir={$rightToLeft ? 'rtl' : 'ltr'}>
  <div class="modalOverlay" />

  <div class="modalContent bg-white text-charcoal-100 {priority['z-index']}">
    <div class="mb-10 px-6">
      {#if heading}
        <h1 class="modalHeading">
          {@html heading}
        </h1>
      {/if}

      {#if icon}
        <div class="modalIcon {iconClass}">
          {@html icon}
        </div>
      {/if}

      {#if textLine1}
        <p class="modalParagraph {textLine1Class}">
          {@html textLine1}
        </p>
      {/if}

      {#if textLine2}
        <p class="modalParagraph {textLine2Class}">
          {@html textLine2}
        </p>
      {/if}

      {#if textLine3}
        <p class="modalParagraph {textLine3Class}">
          {@html textLine3}
        </p>
      {/if}

      {#if textLine4}
        <p class="modalParagraph {textLine4Class}">
          {@html textLine4}
        </p>
      {/if}

      {#if image}
        <div class="mt-10 mx-24">
          <div class="ratio ratio--9:16">
            <img alt="" class="ratio__content" src="images/{image}.webp" />
          </div>
        </div>
      {/if}

      {#if video}
        <div class="relative">
          <Video class="mt-16 mx-26" ratio="9:16" src={video} />
        </div>
      {/if}
    </div>

    <slot />

    <div class="mt-auto">
      <HotkeyContext>
        <slot name="modalButtons" />
      </HotkeyContext>
    </div>
  </div>
</div>

<script>
  import { _ } from 'svelte-i18n';

  import { appReport, EventType, EventStatus, USER_ACTIONS } from '../../../js/appReport';
  import {
    VoiceIntent,
    voiceRecognition,
  } from '../../../js/services/voicerec/voicerec';
  import endTransaction from '../../../js/endTransaction';
  import logger from '../../../js/logger';
  import Button from '../Button.svelte';
  import ModalBase from '../ModalBase.svelte';

  export let showModal = false;

  let triggerClose = () => {};

  function yesHandler() {
    logger.info('Transaction ended from Home button.');
    triggerClose();
    appReport.addEventWithAction(EventType.USER_ACTION, EventStatus.SUCCESS, USER_ACTIONS.RETURN_HOME_MODAL_YES);
    endTransaction();
  }

  function noHandler() {
    triggerClose();
  }
</script>

<ModalBase
  heading={$_('modal.returnHome.heading')}
  textLine1={$_('modal.returnHome.textLine1')}
  textLine2={$_('modal.returnHome.textLine2')}
  bind:showModal
  bind:triggerClose
>
  <div class="flex justify-between mt-auto">
    <Button
      intent={VoiceIntent.YES}
      handler={yesHandler}
      on:click={yesHandler}
    >
      {$_('app.yes')}
    </Button>

    <Button
      intent={VoiceIntent.NO}
      handler={noHandler}
      on:click={noHandler}
    >
      {$_('app.no')}
    </Button>
  </div>
</ModalBase>

<script>
  import LoadingAnimation from './LoadingAnimation.svelte';

  export let heading = null;

  $: loadingProps = {
    ...$$restProps,
    class: ['w-full mx-auto mt-76 px-13', $$restProps.class]
      .filter(Boolean)
      .join(' '),
  };

  /**
   * Loading component that contains a heading and laoding animation.
   *
   * @example <caption>Example usage.</caption>
   * <Header />
   * <Loading class="mb-4" heading={$_('component.heading')} />
   * <Footer />
   *
   * @type {String} class.
   * @type {String} heading.
   */
</script>

<div {...loadingProps}>
  {#if heading}
    <div class="mb-30 text-center">
      <h1>{heading}</h1>
    </div>
  {/if}

  <LoadingAnimation />
</div>

/**
 * Configuration for remote logging.
 *
 * This is imported separately to the main logger.js file, to avoid
 * circular imports.
 */
import log from 'loglevel';
import remote from 'loglevel-plugin-remote';
import { get } from 'svelte/store';
import { loggerUri } from '../stores/config';
import logContext from './context';

const defaults = {
  method: 'POST',
  headers: {},
  token: '',
  onUnauthorized: failedToken => { },
  timeout: 0,
  interval: 20,
  level: 'trace',
  backoff: {
    multiplier: 2,
    jitter: 0.1,
    limit: 30000,
  },
  capacity: 500,
  stacktrace: {
    levels: ['trace', 'warn', 'error', 'info'],
    depth: 3,
    excess: 0,
  },
  timestamp: () => new Date().toISOString(),
  format: (log) => {return {...remote.json(log), context: logContext()};}
};

export default function enable() {
  loggerUri.subscribe(
    ($loggerUri) => {
      try {
        remote.disable();
      } catch {
        // plugin throws exception on disable() when not yet enabled, ignore.
      }
      if ($loggerUri) {
        remote.apply(log, { ...defaults, url: $loggerUri });
      }
    }
  );
}

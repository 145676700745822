<script>
  import { _, locale } from 'svelte-i18n';

  import { rightToLeft } from '../../../js/stores';
  import {
    VoiceIntent,
    voiceRecognition,
  } from '../../../js/services/voicerec/voicerec';

  import Button from '../Button.svelte';
  import ModalBase from '../ModalBase.svelte';
  import ScrollablePane from '../ScrollablePane.svelte';
  import X from '../Icons/X.svelte';

  import privacyPolicyAr from '../../../../assets/documents/privacy-policy-ar.md';
  import privacyPolicyEn from '../../../../assets/documents/privacy-policy-en.md';
  import privacyPolicyFr from '../../../../assets/documents/privacy-policy-fr.md';

  export let showModal = null;

  let paneHeight = 52; // rem
  let triggerClose;

  function closeHandler() {
    triggerClose();
  }
</script>

<ModalBase
  heading={$_('privacyPolicy.heading')}
  bind:showModal
  bind:triggerClose
>
  <div
    class="-mr-6 mb-10 px-4 text-0.625 {$rightToLeft
      ? 'text-right'
      : 'text-left'}"
  >
    <div class="markdown">
      <ScrollablePane {paneHeight}>
        <div class="px-4">
          {#if $locale === 'ar'}
            {@html privacyPolicyAr.html}
          {:else if $locale === 'fr'}
            {@html privacyPolicyFr.html}
          {:else}
            {@html privacyPolicyEn.html}
          {/if}
        </div>
      </ScrollablePane>
    </div>
  </div>

  <div class="flex justify-end mt-auto">
    <Button on:click={closeHandler} icon={X} intent={VoiceIntent.CLOSE} />
  </div>
</ModalBase>

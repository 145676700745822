<script>
  import { _ } from 'svelte-i18n';

  import events from '../../../js/events';
  import logger from '../../../js/logger';
  import { VoiceIntent } from '../../../js/services/voicerec/voicerec';

  import Button from '../Button.svelte';
  import KeypadButton from '../KeypadButton.svelte';
  import ModalBase from '../ModalBase.svelte';

  export let showModal = false;

  const clearIndex = 10;
  const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'clear'];
  const Prompts = Object.freeze({
    ENTER_PIN: 'Enter override PIN',
    ENTER_EPR: 'Enter agent EPR',
    INPUT_ERROR: 'Invalid entry',
  });
  const PASSWORD_CHARACTER = '*';
  const PIN = '935701';
  const VALID_EPR_LENGTHS = [5, 6];

  let currentButton = null;
  let fakeInput = '';
  let inputValue = '';
  let prompt = Prompts.ENTER_PIN;
  let triggerClose;

  /**
   * Handle press of the keypad numbers.
   *
   * If we are in an error state, clear the inputs.
   * Otherwise, append the pressed digit to the input string.
   *
   * @param {number} number - The number that was pressed.
   */
  function numberHandler(number) {
    if (prompt === Prompts.INPUT_ERROR) {
      reset();
    } else {
      if (inputValue.length < 10) {
        inputValue += number;
        fakeInput += PASSWORD_CHARACTER;
      }
    }
  }

  /* Clear the input string. */
  function clearInput() {
    inputValue = '';
    fakeInput = '';
  }

  /* Reset the state of the keypad. */
  function reset() {
    prompt = Prompts.ENTER_PIN;
    clearInput();
  }

  /**
   * Handle a press of the 'OK' button.
   *
   * Attempts to validate either the PIN or the EPR (depending on the prompt).
   * If the EPR is valid, perform the override.
   * If we are in an error state, clear the inputs.
   */
  function okHandler() {
    switch (prompt) {
      case Prompts.ENTER_PIN:
        if (inputValue !== PIN) {
          logger.debug('Override by PIN: Invalid PIN entry.');
          prompt = Prompts.INPUT_ERROR;
        } else {
          logger.debug('Override by PIN: PIN input OK.');
          clearInput();
          prompt = Prompts.ENTER_EPR;
        }
        break;
      case Prompts.ENTER_EPR:
        if (!VALID_EPR_LENGTHS.includes(inputValue.length)) {
          logger.debug('Override by PIN: Invalid EPR entry.');
          prompt = Prompts.INPUT_ERROR;
        } else {
          logger.debug(`Override by PIN: EPR OK (EPR: ${inputValue}).`);
          events.emitOverrideScan(inputValue);
          reset();
          triggerClose();
        }
        break;
      case Prompts.INPUT_ERROR:
        reset();
        break;
    }
  }
</script>

<ModalBase heading="Agent override" bind:showModal bind:triggerClose>
  <div class="mb-24 mt-8 mx-auto w-88">
    <div class:text-saffron={prompt === Prompts.INPUT_ERROR}>
      <p class="mb-4 text-center text-2xl">{prompt}</p>
      <div
        class="border border-current font-AltisMedium mb-4 mx-2 h-18 px-4
        rounded text-5xl text-left"
      >
        {fakeInput ? fakeInput : ' '}
      </div>
    </div>

    <div class="flex flex-wrap">
      {#each numbers as number, i}
        <KeypadButton
          kind={i === clearIndex ? 'wide' : ''}
          intent={VoiceIntent.NUMBER_OF_BAGS}
          on:click={() => {
            currentButton = i;
            if (i === clearIndex) {
              reset();
            } else {
              numberHandler(number);
            }
          }}
        >
          {number}
        </KeypadButton>
      {/each}
    </div>
  </div>

  <div class="mx-4 text-right">
    <Button on:click={okHandler}>OK</Button>
  </div>
</ModalBase>

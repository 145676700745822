<script>
  import SeatGroup from './SeatGroup.svelte';

  export let isOpen = null;
  export let passenger = null;
  export let passengerSeats = null;
  export let previewSeat = null;
  export let row = null;
  export let seatMapManager = null;
  export let seatSpace = null;
  export let seatWidth = null;
  export let selectedSeat = null;
  export let spacerWidth = null;

  $: groups = seatMapManager ? seatMapManager.getGroupsForRow(row) : null;
  $: isFirstElement = (index) => index === 0 && groups.length > 2;
  $: isLastElement = (array, index) => array.length - 1 === index;
</script>

<style>
  .row {
    @apply flex
      items-end
      justify-center
      mb-1;
  }

  .rowNumber {
    @apply flex
      font-AltisMedium
      h-13
      items-center
      justify-center
      text-center
      text-xl;
  }
</style>

<div class="row">
  {#each groups as group, index}
    {#if isLastElement(groups, index)}
      <p class="rowNumber" style="width: {spacerWidth}rem;">{row.number}</p>
    {/if}

    <SeatGroup
      bind:isOpen
      seats={group}
      {passenger}
      {passengerSeats}
      {previewSeat}
      {seatSpace}
      {seatWidth}
      {selectedSeat}
      on:selectSeat
      on:scrollToSeat
    />

    {#if isFirstElement(index)}
      <p class="rowNumber" style="width: {spacerWidth}rem;">{row.number}</p>
    {/if}
  {/each}
</div>

<script>
  import { _ } from 'svelte-i18n';

  import Content from '../components/Content.svelte';
  import Footer from '../components/Footer/index.svelte';
  import Header from '../components/Header/index.svelte';
  import TripExtras from '../components/TripExtras/index.svelte';

  export let params = {};
</script>

<Header />

<Content>
  <span slot="heading">{$_('tripExtras.heading')}</span>

  <div slot="main">
    <div class="mx-6">
      <TripExtras returnPage={params.returnPage} />
    </div>
  </div>
</Content>

<Footer />

/* eslint-disable import/prefer-default-export */
import { _, locale } from 'svelte-i18n';
import { get } from 'svelte/store';

import { currentTotalExcessWeight } from '../stores';
import { currencyFormat } from '../utils';
import { PaymentController } from '../controllers/payment';
import { PaymentItem } from '../paymentItem';

const description = 'Extra Kilogram Charge';
const serviceGroup = 'BG';
const translate = get(_);

/** Payment controller for baggage payment. */
export class BaggagePayment extends PaymentController {
  /**
   * Returns the heading of the baggage payment.
   *
   * @returns {string}
   */
  // eslint-disable-next-line class-methods-use-this
  getPaymentItemHeading() {
    return translate('payment.purchaseAdditionalBaggage');
  }

  /**
   * Returns the purchases of the baggage payment.
   *
   * @returns {string}
   */
  getPaymentItemPurchases() {
    return translate('payment.baggagePaymentPurchases', {
      values: {
        weight: currentTotalExcessWeight(),
        amount: currencyFormat(
          get(locale),
          this.currencyCode,
          this.extraAmount,
        ),
      },
    });
  }

  /**

   * Accept multiple payment items for baggage
   * @param {Number} extraAmount 
   * @param {Number} extraWeight 
   */
  addItem(extraAmount, extraWeight) {
    const paymentTransactionItems = {
      totalPrice: extraAmount,
      quantity: String(extraWeight),
    };

    this.items.push(new PaymentItem(paymentTransactionItems));
  }
}

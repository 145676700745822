<script>
  import BrandLogo from '../../../svg/brandLogo.svg';
  import Nav from './Nav.svelte';

  export let hasHomeButton = true;
  export let immediateTransactionEnd = false;
  export let logoWidth = 'w-52';
</script>

<header class="relative flex border-b-2 border-gold-100 h-27 px-9">
  <div class="absolute bg-white top-0 text-gold-100 {logoWidth}">
    {@html BrandLogo}
  </div>

  <Nav {hasHomeButton} {immediateTransactionEnd} />
</header>

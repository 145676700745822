<script>
  import { _ } from 'svelte-i18n';
  import { get } from 'svelte/store';
  import { onDestroy, onMount } from 'svelte';  
  import { ApplicationStep, appReport, EventType, EventStatus, FailedReason } from '../../js/appReport';
  import {    
    booking,
    headPassenger,
    infants,
    reprintBPManager,
    timeoutBlocked,
    wizardPosition,
  } from '../../js/stores';
  import { GO_TO_HOME_TIMEOUT, WizardPosition } from '../../js/const';
  import logger from '../../js/logger';
  import { secondsToMilliseconds } from '../../js/utils';
  import { ScreenIdleTimeout } from '../../js/screenIdleTimeout';
  import { handleTransactionEnd } from '../../js/handleTransactionEnd';
  import BagDropCompleted from '../components/BagDropCompleted/index.svelte';
  import ChangeEmailAddress from '../components/modal/ChangeEmailAddress.svelte';
  import Content from '../components/Content.svelte';
  import Footer from '../components/Footer/index.svelte';
  import Header from '../components/Header/index.svelte';

  const passenger = get(headPassenger);

  let showChangeEmailAddress = null;
  let boardingPassPrintDataAvailable = false;
  let awaitingBoardingPassResponse = true;
  let timeout = null;
  let screenIdleTimeout = null;

  wizardPosition.set(WizardPosition.FINISH);

  /**
   * Start a seperate timeout to global timeout.
   *
   * @param {boolean} extended - Extension of time.
   * @returns {number}
   */
  function startTimeout() {
    screenIdleTimeout?.stop();
    timeout = setTimeout(() => {
      appReport.updateStepSuccess(ApplicationStep.BAG_DROP_COMPLETED_SCREEN);      
      handleTransactionEnd();      
    }, secondsToMilliseconds(GO_TO_HOME_TIMEOUT));
  }

  function stopTimeout() {
    clearTimeout(timeout);
    timeout = null;
  }

  function handleUserInteracting() {
    screenIdleTimeout?.reset();
  }

  onMount(() => {
    appReport.updateStepStart(ApplicationStep.BAG_DROP_COMPLETED_SCREEN);

    logger.info(
      'Calling reprintBPManager.performReprint() ' +
        'from BagDropCompleted.svelte::onMount().',
    );

    reprintBPManager.performReprint(
      booking,
      infants
    );

    reprintBPManager.getRetrievalPromise(booking, infants).then(() => {
      boardingPassPrintDataAvailable = true; 
      awaitingBoardingPassResponse = false;
      // Only start the timeout after the boardingPassData is available
      screenIdleTimeout = new ScreenIdleTimeout();
      screenIdleTimeout.start();
    })
    .catch((error) => {
      // On errors, start the timer anyway so it navigates back to the home screen
      screenIdleTimeout = new ScreenIdleTimeout();
      screenIdleTimeout.start();
      awaitingBoardingPassResponse = false;

      appReport.updateStep(
        ApplicationStep.BAG_DROP_COMPLETED_SCREEN,
        EventType.STEP_IN_PROGRESS,
        EventStatus.FAILED,
        FailedReason.RETRIEVE_BOARDING_PASS_DATA_FAILED
      );
    });

    showChangeEmailAddress = false;
  });

  onDestroy(() => {
    timeoutBlocked.set(false);
    stopTimeout();
    screenIdleTimeout?.stop();
  });
</script>

<ChangeEmailAddress
  bind:showChangeEmailAddress
  on:userInteractionFinished={startTimeout}
  on:userInteracting={handleUserInteracting}
/>

<Header hasHomeButton={false} />

<Content>
  <span slot="heading">{$_('bagDropCompleted.heading')}</span>

  <div slot="main" class="pt-12">
    <BagDropCompleted
      {passenger}
      bind:showChangeEmailAddress
      bind:boardingPassPrintDataAvailable
      bind:awaitingBoardingPassResponse
      on:userInteractionFinished={startTimeout}
    />
  </div>
</Content>

<Footer />

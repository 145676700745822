<script>
  import Button from '../components/Button.svelte';

  function broken() {
    deliberately_not_defined();
  }
</script>

<div class="m-24 flex justify-center">
  <Button handler={broken}>Trigger Error</Button>
</div>

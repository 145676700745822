/* Store a list of all scanned passport numbers. */
import { get } from 'svelte/store';
import { isObjEmpty } from '../utils';
import { resettable } from './extensions/resettable';
import { booking } from './booking';
import { infants } from './infants';
import { checkInPassengersManager } from './checkInPassengers';
import logger from '../logger';

export const passportNumbers = {
  ...resettable([]),
  ...{
    /**
     * Add a passport number to the list of scanned passport numbers.
     *
     * @param {string} passportNumber - The passport number.
     */
    insert(passportNumber) {
      const passportNumbers = get(this);
      if (passportNumbers && passportNumbers.length > 0 && passportNumbers.includes(passportNumber)) {
        // passport already exists
        return;
      }
      
      this.set([...get(this), passportNumber]);
    },

    remove(passportNumber) {
      let currentPassportNumbers = get(this);
      let index = currentPassportNumbers?.indexOf(passportNumber);
      if (index > -1) {
        currentPassportNumbers.splice(index, 1);
      }
      this.set(currentPassportNumbers);
    },

    /**
     * Determine if a passenger has scanned their passport.
     *
     * Matches either DocS string or passengers checking in via guided flow.
     *
     * @param {object} passenger - A passenger from Switchboard
     * @returns {boolean}
     */
    passengerIsScanned(passenger) {
      const passengerPassportInPassportNumbers = get(this).includes(booking.passengerDocSPassportNumber(passenger));
      const passengerNeedsToBeCheckedIn = checkInPassengersManager.isCheckInPassenger(passenger);

      return (
        !isObjEmpty(passenger) &&
        (passengerPassportInPassportNumbers ||
          passengerNeedsToBeCheckedIn)
      );
    },

    /**
     * Remove passengers who have not presented their passport.
     */
    cullUnscannedPassengers() {
      const currentBooking = get(booking);
      const { passengers } = currentBooking;
      const filteredPassengers = (passengers || []).filter((passenger) =>
        passportNumbers.passengerIsScanned(passenger),
      );
      booking.set({ ...currentBooking, passengers: filteredPassengers });

      // For infant section.
      const infantList = get(infants);
      const filteredInfantPassengers = (infantList || []).filter(
        (passenger) => {
          return passportNumbers.passengerIsScanned(passenger);
        },
      );
      infants.set(filteredInfantPassengers);
      logger.info(
        'Removed unscanned passengers from the transaction pool. ' +
          `Remaining adults: ${
            filteredInfantPassengers && filteredPassengers.length
          }; ` +
          `Remaining infants: ${
            filteredInfantPassengers && filteredInfantPassengers.length
          }.`,
      );
    },

    /**
     * Return true if no passengers in the booking have scanned.
     *
     * @returns {boolean} - Whether or not passengers have been scanned.
     */
    noPassengersHaveScanned() {
      const passengers = booking.getPassengersIncludingInfants();
      return !(passengers || []).some((passenger) =>
        passportNumbers.passengerIsScanned(passenger),
      );
    },

    /**
     * Determine if any adults have scanned.
     *
     * @returns {boolean} - Whether or not an adult has scanned.
     */
    anyMatchedAdults() {
      const { passengers } = get(booking);
      return (passengers || []).some((passenger) =>
        passportNumbers.passengerIsScanned(passenger),
      );
    },

    /**
     * Determine if all adults and infants have scanned.
     *
     * @returns {boolean} - Whether or not all adults and infants have scanned.
     */
    allPassengerPassportsScanned() {
      const passengers = booking.getPassengersIncludingInfants();
      return (passengers || []).every((passenger) =>
        passportNumbers.passengerIsScanned(passenger),
      );
    },
  },
};

/**
 * Check if an infant is incorrectly scanned.
 *
 * If either the infant scanned without their parent,
 * or the parent scanned without their infant, return true.
 *
 * @param {object} infant - The passenger object for the infant.
 * @returns {boolean} - The result of the check.
 */
function isUnmatchedInfantScan(infant) {
  const parent = booking.getPassengerByInfantId(infant.passengerID);  
  const infantScanned = passportNumbers.passengerIsScanned(infant);
  const parentScanned = passportNumbers.passengerIsScanned(parent);
  return (
    (infantScanned && !parentScanned) || (!infantScanned && parentScanned)
  );
}

/**
 * Check whether any infants in the booking were incorrectly scanned.
 *
 * If any infants scanned without their matching parents,
 * or any parents scanned without their matching infants, return true.
 *
 * @returns {boolean} - Whether or not an infant was incorrectly scanned.
 */
export function anyUnmatchedInfantScans() {
  return get(infants).some((infant) => isUnmatchedInfantScan(infant));
}

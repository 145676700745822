<script>
  import { _ } from 'svelte-i18n';
  import { get } from 'svelte/store';
  import { push } from 'svelte-spa-router';

  import {
    applicationFlow,
    currentTotalExcessWeight,
    getRemainingTotalWeight,
    integratedCatalogue,
  } from '../../../js/stores';
  import {
    ApplicationFlow,
    WeightUnit,
  } from '../../../js/const';
  import { sbdPausedPendingExcessBaggage } from '../../../js/stores/sbdState';
  import { VoiceIntent } from '../../../js/services/voicerec/voicerec';

  import Button from '../Button.svelte';
  import ModalBase from '../ModalBase.svelte';
  import X from '../Icons/X.svelte';

  import checkedBaggage from '../../../svg/checkedBaggage.svg';
  import warning from '../../../svg/warning.svg';

  export let showModal = false;

  
  const isPorterBagDrop = get(applicationFlow) === ApplicationFlow.PORTER_BAG_DROP;
  const isHybrid = get(applicationFlow) === ApplicationFlow.HYBRID;

  /** Handle the Accept button. */
  function closeHandler() {
    showModal = false;
    if (isPorterBagDrop) {
      pushToNextScreen();
      return;
    }
    
    // since both Hybrid and Self Service flows are on Embross devices, we need to unpause SBD State
    sbdPausedPendingExcessBaggage.unpause("User presses 'Accept' on the Baggage Allowance Percentage modal");

    // now because hybrid flow should work like PBD flow, we are navigating to the bag tag printing screen
    if (isHybrid) {    
      push('/porter-printing-bag-tag-hybrid');  
    }
  }

  /** Push to next screen based on application flow. */
  function pushToNextScreen() {
    push('/porter-printing-bag-tag');
  }
</script>

<ModalBase heading={$_('app.warning')} icon={warning} bind:showModal>
  <div class="mb-6 mx-auto w-24">
    {@html checkedBaggage}
  </div>

  <div class="mb-24">
    <h2 class="mb-2">{$_('baggageAllowancePercentageReached.textLine1')}</h2>
    <h3 class="text-5xl text-palmGreen">
      {getRemainingTotalWeight()}
      {$_(`app.${WeightUnit}`)}
    </h3>
  </div>  

  <div class="flex justify-end mt-auto">
    <Button on:click={closeHandler} icon={X} intent={VoiceIntent.CLOSE} />
  </div>
</ModalBase>

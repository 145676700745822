/** Store config settings. */
import { derived, writable } from 'svelte/store';
import qs from 'querystringify';
import {
  ApplicationFlow,
  DEFAULT_BASE_STATION,
  DEFAULT_ISSUING_DEVICE,
  DEFAULT_LOCAL_CURRENCY,
  FeatureFlags,
  QueryStringParam,
} from '../const';
import { convertIataToIso2, getKeyByValue } from '../utils';
import currencyCodes from '../data/miscellaneous/currencyCodes.json';
import environments from '../services/config';
import FeatureFlagsModel from './models/featureFlags';
import { resettable } from './extensions/resettable';

const DEFAULT_FEATURE_FLAGS = {
  enabled: [
    FeatureFlags.EMAIL,
    FeatureFlags.EXCESS_BAGGAGE,
    FeatureFlags.MULTI_SECTOR,
    FeatureFlags.SEAT_PAYMENT,
  ],
  disabled: [
    FeatureFlags.PCR_TEST,
    FeatureFlags.CAMERA,
    FeatureFlags.DARK,
    FeatureFlags.FIT_TO_FLY,
    FeatureFlags.GLOBAL_TIMEOUT,    
    FeatureFlags.PLUSGRADE,
  ],
};

/**
 * Gets the currency of the airport.
 *
 * @returns {string} The currency of the airport.
 */
function currencyForAirport() {
  const baseStation = readBaseStationFromQueryString();
  const baseStationIso2 = (convertIataToIso2(baseStation) || '').toUpperCase();
  return currencyCodes[baseStationIso2];
}

/**
 * Extract an individual query string parameter.
 *
 * @param {string} paramName - The name of the parameter.
 * @returns {string} The value of the parameter (or undefined).
 */
function queryStringParam(paramName) {
  return qs.parse(window.location.search)[paramName];
}

/**
 * Read the application flow from the query string and return it.
 *
 * If no recognised flow is available, return the default.
 */
function readFlowFromQueryString() {
  const flow = queryStringParam(QueryStringParam.FLOW);
  return flow in ApplicationFlow
    ? ApplicationFlow[flow]
    : ApplicationFlow.PORTER_BAG_DROP;
}

/**
 * Init the application's feature flags based on defaults and query string.
 */
function initFeatureFlags() {
  return new FeatureFlagsModel(
    Object.values(FeatureFlags),
    DEFAULT_FEATURE_FLAGS,
    queryStringParam(QueryStringParam.FEATURES),
  );
}

/**
 * Read the local currency from the query string.
 *
 * Defaults to the value of DEFAULT_LOCAL_CURRENCY.
 */
function readLocalCurrencyFromQueryString() {
  return (
    queryStringParam(QueryStringParam.LOCAL_CURRENCY) || DEFAULT_LOCAL_CURRENCY
  );
}

/**
 * Read the base station airport code from the query string.
 *
 * Defaults to the value of DEFAULT_BASE_STATION.
 */
function readBaseStationFromQueryString() {
  return (
    queryStringParam(QueryStringParam.BASE_STATION) || DEFAULT_BASE_STATION
  );
}

/**
 * Read the issuing device from the query string.
 *
 * Defaults to the value of DEFAULT_ISSUING_DEVICE.
 */
function readIssuingDeviceCodeFromQueryString() {
  return (
    queryStringParam(QueryStringParam.ISSUING_DEVICE) || DEFAULT_ISSUING_DEVICE
  );
}

export const switchboardUri = writable(environments.getSwitchboardUri());

export const switchboardApiKey = writable(environments.getSwitchboardApiKey());

export const flightdeckUri = writable(environments.getFlightdeckUri());

export const flightdeckApiKey = writable(environments.getFlightdeckApiKey());

export const payetihadUri = writable(environments.getPayEtihadUri());

export const amadeusBoardingPassUri = writable(environments.getAmadeusBoardingPassUri());

export const loggerUri = writable(
  window.location.hostname !== 'localhost'
    ? `${window.location.protocol}//` +
        `${window.location.hostname}${window.location.pathname}log`
    : '',
);

/** Enable session termination due to eligibility check failures. */
export const enableEligibilityCheck = writable(
  window.location.hostname === 'localhost' ? false : true,
);

/** Is this URL running the 'old' version. */
function isOldVersion() {
  const regex = /^ey\.apps\.elenium\.com$/;
  return regex.test(window.location.hostname);
}

/** Enable hotkeys. */
export const enableHotkeys = writable(true);

/**
 * Ignore missing components.
 *
 * Some component calls will check this setting and ensure execution
 * can continue even if required CUSS Components have not been bound.
 */
export const ignoreMissingComponents = writable(false);

/** The base station airport code (location of the kiosk). */
export const baseStationCode = readBaseStationFromQueryString();

/** The base station airport code (location of the kiosk). */
export const baseStationCurrency = currencyForAirport();

/** The issuing device from airport (device code of the kiosk). */
export const issuingDeviceCode = readIssuingDeviceCodeFromQueryString();

/** Application flow. */
export const applicationFlow = writable(readFlowFromQueryString());

/** Feature flags. */
export const featureFlags = initFeatureFlags();

/** will be set to use for all api calls. */
export const priceUnpaidItemsData = resettable([]);

/** Reload the browser into the changed flow. */
applicationFlow.subscribe((flowValue) => {
  const appFlowStrValue = Object.keys(ApplicationFlow).find(
    (key) => ApplicationFlow[key] === flowValue,
  );

  const queryString = qs.stringify(
    {
      [QueryStringParam.FLOW]: appFlowStrValue,
      [QueryStringParam.BASE_STATION]: baseStationCode,
      [QueryStringParam.ISSUING_DEVICE]: issuingDeviceCode,
      [QueryStringParam.FEATURES]: featureFlags.toFeatureString(),
      [QueryStringParam.LOCAL_CURRENCY]: baseStationCurrency,
    },
    true,
  );
  const location = window.location;
  if (queryString !== location.search) {
    location.href = `${location.origin}${location.pathname}${queryString}`;
  }
});

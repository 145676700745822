<script>
  import amex from '../../svg/payment-types/amex.svg';
  import applePay from '../../svg/payment-types/applePay.svg';
  import jcb from '../../svg/payment-types/jcb.svg';
  import masterCard from '../../svg/payment-types/masterCard.svg';
  import visa from '../../svg/payment-types/visa.svg';

  const paymentTypeItems = [
    { icon: visa },
    { icon: masterCard },
    { icon: jcb },
    { icon: amex },
    { icon: applePay },
  ];

  $: paymentTypeProps = {
    ...$$restProps,
    class: ['paymentType', $$restProps.class].filter(Boolean).join(' '),
  };

  /**
   * Additional payment icons can be found at the link below
   * https://github.com/aaronfagan/svg-credit-card-payment-icons
   */
</script>

<style>
  .paymentType {
    @apply flex justify-center;
  }

  .paymentType .card {
    @apply px-1 w-14;
  }
</style>

<div {...paymentTypeProps}>
  {#each paymentTypeItems as item}
    <div class="card">
      {@html item.icon}
    </div>
  {/each}
</div>

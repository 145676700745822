<script>
  $: buttonProps = {
    role: 'button',
    ...$$restProps,
    class: ['px-1', $$restProps.class].filter(Boolean).join(' '),
  };
</script>

<style>
  button:focus {
    @apply outline-none;
  }

  button {
    @apply bg-white
      border-2
      border-current
      cursor-pointer
      duration-300
      ease-in
      h-15
      leading-normal
      rounded-lg
      select-none
      text-1.7
      text-center
      text-charcoal-100
      transition-all
      w-full;
  }

  button:active {
    @apply bg-gold-100
      text-2.5
      text-white
      pt-0
      font-AltisMedium;
    transition: all 0.2s ease-out;
    transform: scaleX(1.1) scaleY(1.05);
    transform-origin: center;
  }
</style>

<div {...buttonProps}>
  <button on:click>
    <slot />
  </button>
</div>

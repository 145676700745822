/* eslint-disable jsdoc/require-param */
import { get } from 'svelte/store';
import { location } from 'svelte-spa-router';

import { appTransactionId } from './stores/app';
import { baseStationCode } from './stores/config';
import { booking } from './stores/booking';
import { bags, currentBag } from './stores/bags';
import { END_TRANSACTION_REASON } from './const';
import { getKioskName } from './stores/cuss';
import { headPassenger } from './stores/headPassenger';
import { pnr } from './stores/booking';

import logger from './logger';

import { loggerUri } from '../js/stores/config'

/**
 * Application Steps
 */
export const ApplicationStep = Object.freeze({
  BIOMETRIC_PASSPORT_SCAN: 'Biometric Passport Scan', // new step
  PASSPORT_SCAN: 'Passport Scan',
  BOOKING_RETRIEVAL: 'Booking Retrieval',
  OTHER_PASSENGERS_PASSPORT_SCAN: 'Other Passengers Passport Scan',
  FLIGHT_SUMMARY: 'Flight Summary',
  PASSENGER_ADC_CHECKS: 'Passenger ADC Checks',
  PASSENGER_ACCEPTANCE: 'Passenger Acceptance',
  GET_BAGGAGE_ALLOWANCE: 'Get Baggage Allowance',
  ACCEPT_BAGGAGE_GROUP: 'Accept Baggage Group',
  DANGEROUS_GOODS: 'Dangerous Goods',
  GENERATE_BAG_TAGS: 'Generate Bag Tags',
  BAG_ASSESSMENT: 'Bag Assessment',
  PRINT_BAG_TAGS: 'Print Bag Tags',
  EXCESS_BAGGAGE_INFORMATION: 'Excess Baggage Information',
  EXCESS_BAGGAGE_PAYMENT: 'Excess Baggage Payment',
  BAG_DROP_COMPLETED_SCREEN: 'Bag Drop Completed Screen',
  PRINT_BOARDING_PASS: 'Print Boarding Pass',
  PRINT_BAGGAGE_RECEIPT: 'Print Baggage Receipt',
  EMAIL_BOARDING_PASS: 'Email Boarding Pass',
  EXCESS_BAGGAGE_EXTRA_PIECES_PAYMENT: 'Excess Baggage Payment',
});

/**
 * Event Details: Failed reasons
 */
export const FailedReason = Object.freeze({
  NO_CUSS_APPLET: 'CUSS applet is not running',
  PAYMENT_FAILED: 'Payment was unsuccessful',
  USER_DECLINED: 'User declined',
  APP_TIME_OUT: 'Application Logic Timed Out',
  SWITCHBOARD_CALL_FAILED: 'Switchboard call failed',
  DGR_DECLINED_MODAL_CANCEL: 'Agent cancels dangerous-good-declined modal',
  PRINT_FAILED: 'Execute printing failed',
  RETRIEVE_BOARDING_PASS_DATA_FAILED: 'Retrieving boarding pass data failed',
});

/**
 * Status for the event type, with relation to the step. The event status for Success and Failed are used for terminal statuses for each step
 *
 * For example, EventType of STEP_FINISH with EventStatus of SUCCESS, means the event caused the step to finish successfully.
 * For example, EventType of AGENT_OVERRIDE with EventStatus of NO_STATUS, means the event occured but had no impact on the step.
 * For example, EventType of USER_ACTION with EventStatus of FAILED, means the users action caused the step to fail.
 */
export const EventStatus = Object.freeze({
  SUCCESS: 'Success',
  FAILED: 'Failed',
  NO_STATUS: 'No Status',
});

/**
 * EventTypes refer to various events in the system. These can be high level (STEP_FINISH) which can occur across multiple steps,
 * or granular, eg, USER_ACTION, which refers to a user completing an action such as pressing a button.
 */
export const EventType = Object.freeze({
  STEP_START: 'Step Start',
  STEP_FINISH: 'Step Finish',
  ERROR_MODAL_DISPLAYED: 'Error Modal Displayed',
  STEP_IN_PROGRESS: 'Step In Progress',
  AGENT_ACTION: 'Agent Action',
  USER_ACTION: 'User Action',
  TIME_OUT: 'Time out',
});

/**
 * Event Details: User Actions / Interactions with the ABD
 */
export const USER_ACTIONS = Object.freeze({
  EXCESS_BAGGAGE_INFORMATION_MODAL_DECLINED:
    'User Declined Excess Baggage Information Modal',
  EXCESS_BAGGAGE_INFORMATION_MODAL_ACCEPTED:
    'User Accepted Excess Baggage Information Modal',
  EXCESS_BAGGAGE_PAYMENT_MODAL_DECLINED:
    'User Declined Excess Baggage Payment Modal',
  RETURN_HOME_MODAL_YES: 'User Chose Yes On Return Home Modal',
  ASSISTANCE_BUTTON_PRESSED: 'User Pressed Assistance Button',
  EXIT_BUTTON_PRESSED: 'User Pressed Exit Button',
  NUMBER_BAGS_SELECTED: 'User Pressed Number Of Bags',
  MAKING_PAYMENT: 'Passenger is in process of making the payment',
});

/**
 * Event Details: Actions that a Agent may go through.
 */
export const AGENT_ACTIONS = Object.freeze({
  DGR_DECLINED_FLIGHTDECK_TRANSACTION_END:
    'After passenger clicked yes on dangerous goods, Agent has ended the transaction',
  EXCESS_BAGGAGE_INFORMATION_OVERRIDE: 'Agent overrides the excess baggage',
  FLIGHTDECK_OVERRIDE_RETRY_EMD_ISSUANCE:
    'Agent allows for a EMD issuance retry',
  PAYMENT_DECLINED_TRANSACTION_ENDED_FROM_FLIGHTDECK:
    'Excess baggage Payment declined by the passenger, Agent ends the transaction',
});

const ApplicationFirstStep = ApplicationStep.PASSPORT_SCAN;

class AppReport {
  constructor() {
    this.resetReportObj();
    this.lastUpdatedStep = null;
  }

  updateTransactionStart() {
    logger.debug('AppReport - updateTransactionStart');
    this.reportObj = {
      ...this.reportObj,
      transactionId: get(appTransactionId),
      deviceId: getKioskName(),
      location: baseStationCode,
      startTimestampUtc: AppReport.getUtcTimeStamp(),
      endTimestampUtc: null,
      booking: {
        pnr: null,
      },
      ancillary: {
        excessLastAmountShown: 0, // Last shown amount
        excessAmountPaid: 0, // Populated if payment is actually made
        excessCurrency: '', // ISO 4217 Currency Code
      },
    };

    logger.debug(this.reportObj);
  }

  /**
   * Updates the last shown amount. Last shown because a pax can have multiple bags and be shown the excess baggage warning multiple times.
   * @param {number} amount
   */
  updateAncillaryAmountLastShown(amount, currency) {
    try {
      this.reportObj.ancillary.excessLastAmountShown = amount;
      this.reportObj.ancillary.excessCurrency = currency;
    } catch (error) {
      logger.warn(error);
    }
  }

  /**
   * Updates the ancillary reporting object after confirmation of payment success.
   * @param {number} amount
   * @param {string} currency
   */
  updateAncillaryAmountPaid(amount, currency) {
    try {
      if (this && this.reportObj && this.reportObj.ancillary) {
        this.reportObj.ancillary.excessAmountPaid = amount;
        this.reportObj.ancillary.excessCurrency = currency;
      }
    } catch (error) {
      logger.warn(error);
    }
  }

  /**
   * Updates a step with a start success.
   * @param {ApplicationStep} step
   */
  updateStepStart(step) {
    this.updateStep(step, EventType.STEP_START, EventStatus.SUCCESS);
  }

  /**
   * Update a step with a finished success.
   * @param {ApplicationStep} step
   */
  updateStepSuccess(step) {
    this.updateStep(step, EventType.STEP_FINISH, EventStatus.SUCCESS);
  }

  /**
   * Update a step with a finished success with context
   * Event details is expected to be: {context: details}, where details will go into the context.
   */
  updateStepSuccessWithEventDetails(step, eventDetails) {
    this.updateStepInternal(
      step,
      EventType.STEP_FINISH,
      EventStatus.SUCCESS,
      eventDetails,
    );
  }

  /**
   * Update a step with a failed succes with context
   * Event details is expected to be: {context: details}, where details will go into the context.
   */
  updateStepFailWithContext(step, eventDetails) {
    this.updateStepInternal(
      step,
      EventType.STEP_FINISH,
      EventStatus.SUCCESS,
      eventDetails,
    );
  }

  updateStepFailed(step, failedReason) {
    this.updateStep(
      step,
      EventType.STEP_FINISH,
      EventStatus.FAILED,
      failedReason,
    );
  }

  updateStepInProgress(step, eventDetails) {
    this.updateStep(
      step,
      EventType.STEP_IN_PROGRESS,
      EventStatus.SUCCESS,
      eventDetails,
    );
  }

  updateStep(step, eventType, status, failedReason = null, errorModal = null) {
    try {
      this.updateStepInternal(
        step,
        eventType,
        status,
        this.getEventDetails(failedReason, errorModal),
      );
    } catch (error) {
      logger.warn(error);
    }
  }

  /**
   * Update a step with any event details
   */
  updateStepWithEventDetails(step, eventType, status, eventDetails) {
    try {
      this.updateStepInternal(step, eventType, status, eventDetails);
    } catch (error) {
      logger.warn(error);
    }
  }

  /**
   * Update a step with a event details with action
   *
   * Example: User declines excess baggage information
   *
   * appReport.updateStepWithAction(
   *     ApplicationStep.EXCESS_BAGGAGE_INFORMATION,
   *     EventType.USER_ACTION,
   *     EventStatus.NO_STATUS,
   *     USER_ACTIONS.EXCESS_BAGGAGE_INFORMATION_MODAL_DECLINED,
   *   );
   */
  updateStepWithAction(step, eventType, status, action) {
    try {
      this.updateStepInternal(step, eventType, status, { action });
    } catch (error) {
      logger.warn(error);
    }
  }

  updateStepInternal(step, eventType, status, eventDetails) {
    logger.debug(
      `AppReport - updateStep. step:${step}, eventType:${eventType}` +
        `, status:${status}, eventDetails:${JSON.stringify(eventDetails)}.`,
    );

    const { application } = this.reportObj;
    let foundStep = application.find((element) => element.step === step);

    if (foundStep === undefined) {
      application.push({
        step,
        events: [],
      });

      foundStep = application[application.length - 1];
    }

    // Assign context to a local variable and remove it from eventDetails if it exists
    let context = null;
    const eventDetailsHasContextProperty =
      eventDetails &&
      Object.prototype.hasOwnProperty.call(eventDetails, 'context');
    if (eventDetailsHasContextProperty) {
      context = eventDetails.context;
      // eslint-disable-next-line no-param-reassign
      delete eventDetails.context;
    }

    foundStep.events.push({
      timestampUtc: AppReport.getUtcTimeStamp(),
      eventType,
      status,
      eventDetails,
      routerLocation: get(location),
      context: AppReport.getEventContext(step, context),
    });

    this.lastUpdatedStep = step;

    logger.debug(this.reportObj);
  }

  // eslint-disable-next-line class-methods-use-this
  getEventDetails(failedReason, errorModal) {
    if (!failedReason && !errorModal) {
      return null;
    }

    return {
      failedReason,
      modal: {
        name: errorModal,
      },
    };
  }

  /** Add an event to the current application step. */
  addEvent(eventType, status, failedReason, errorModal) {
    try {
      this.addEventWithDetails(
        eventType,
        status,
        this.getEventDetails(failedReason, errorModal),
      );
    } catch (error) {
      logger.warn(error);
    }
  }

  addEventWithAction(eventType, status, action) {
    try {
      this.addEventWithDetails(eventType, status, { action });
    } catch (error) {
      logger.warn(error);
    }
  }

  /** Add an event to the current application step with eventDetails. */
  addEventWithDetails(eventType, status, eventDetails) {
    try {
      const currentStep = this.lastUpdatedStep
        ? this.lastUpdatedStep
        : ApplicationFirstStep;
      this.updateStepInternal(currentStep, eventType, status, eventDetails);
    } catch (error) {
      logger.warn(error);
    }
  }

  /**
   * Some events (usually ones that can be associated multiple ways) require additional context to have meaning.
   * For example, when printing boarding passes, it is good to know for which pax the boarding pass is for.
   */
  static getEventContext(step, context) {
    const requiredEventContextSteps = [
      ApplicationStep.BAG_ASSESSMENT,
      ApplicationStep.PRINT_BOARDING_PASS,
      ApplicationStep.EMAIL_BOARDING_PASS,
      ApplicationStep.EXCESS_BAGGAGE_INFORMATION,
      ApplicationStep.EXCESS_BAGGAGE_PAYMENT
    ];

    const eventContextStepComesFromStore =
      step === ApplicationStep.BAG_ASSESSMENT ||
      step === ApplicationStep.EXCESS_BAGGAGE_INFORMATION ||
      step === ApplicationStep.EXCESS_BAGGAGE_PAYMENT;

    // If we know a step shouldn't have a context, return null
    if (!requiredEventContextSteps.includes(step)) {
      return null;
    }

    // If a context was explicitly passed in, use that
    if (context) {
      return context;
    }

    // Otherwise, if the context can be retrived for a step
    if (eventContextStepComesFromStore) {
      // TODO: Currently this always returns current bag,
      // If additional contexts are required in the future for different steps this can be added later
      const storedCurrentBag = get(currentBag);
      if (!storedCurrentBag) {
        return null;
      }

      return {
        currentBag: {
          bagTag: storedCurrentBag.bagTagID,
          weightKgs: storedCurrentBag.weight,
          heightMm: AppReport.toMillimetre(storedCurrentBag.height),
          widthMm: AppReport.toMillimetre(storedCurrentBag.width),
          lengthMm: AppReport.toMillimetre(storedCurrentBag.length),
        },
      };
    }

    // Base case, there is no context.
    return null;
  }

  transactionFinish() {
    try {
      this.transactionFinishInternal();
    } catch (error) {
      logger.warn(error);
    }
  }

  transactionFinishInternal() {
    logger.debug('AppReport - transactionFinish');

    const currentBooking = get(booking);

    if (!AppReport.isNullOrEmpty(currentBooking)) {
      this.reportObj.booking = {
        pnr: get(pnr),
        flightDate: booking.getDepartureDateTime(),
        totalBaggageAllowanceKgs: booking.totalCombinedAllowance(),
        origin: currentBooking.originCode,
        destination: currentBooking.destinationCode,
        flightNumber: booking.getDisplayedFlightCode(),
        pax: this.getPassengers(),
        bag: this.getBags(),
      };
    }

    this.reportObj.endTimestampUtc = AppReport.getUtcTimeStamp();

    logger.info(this.hasApplicationStep())

    if (this.hasApplicationStep()) {
      // Directly call logging endpoint to avoid bundling of logs causing truncation issue - EAM-287
      // DC: Design decision to use XMLHttpRequest as no safe way around global abortable fetch that happens right after this call
      let logMsg = JSON.stringify({logs:[{message: { txnReportObj: this.reportObj }}]})

      this.sendReportingObjectToRemote(logMsg)
    } else {
      logger.debug(
        'AppReport - No application step in reportObj. Do not log final reportObj.',
      );
    }

    this.resetReportObj();
  }

  // Modified from: https://lofi.limo/blog/retry-xmlhttprequest-carefully
  sendReportingObjectToRemote(logMsg)
  {
    let errorCount = 0;
    var request = new XMLHttpRequest();

    request.onerror = () =>
    {
      this.openAndSend(request, logMsg, ++errorCount)
    }

    request.ontimeout = () =>
    {
      this.openAndSend(request, logMsg, ++errorCount)
    }

    this.openAndSend(request, logMsg, 0)
  }

  openAndSend(request, logMsg, errorCount)
  {
    let pause = errorCount ? Math.pow(2, errorCount) * 1000 : 0
    pause = pause < 60000 ? pause : 60000 // Set a hard cap of 1 minute

    setTimeout(function () {
      let logEndpoint = get(loggerUri)
      logger.info(`DEBUG: log endpoint is: ${logEndpoint} for direct POST logging.`)

      request.open("POST", logEndpoint, true);
      request.setRequestHeader('Content-Type', "application/json");
      request.send(logMsg);
    }, pause)
  }

  trackEndTransactionCall(endTransactionReason) {
    try {
      if (endTransactionReason) {
        let eventType = EventType.STEP_FINISH;
        let eventStatus = EventStatus.FAILED;

        const isTimeoutReason =
          endTransactionReason ===
          END_TRANSACTION_REASON.SCREEN_IDLE_TIMEOUT_OCCURRED;

        if (isTimeoutReason) {
          eventType = EventType.TIME_OUT;
          eventStatus = EventType.STEP_FINISH;
        }

        this.addEvent(eventType, eventStatus, endTransactionReason);
      }
    } catch (error) {
      logger.warn(error);
    }
  }

  hasApplicationStep() {
    return this.reportObj.application.length > 0;
  }

  static isNullOrEmpty(obj) {
    return obj && Object.keys(obj).length === 0;
  }

  // eslint-disable-next-line class-methods-use-this
  getPassengers() {
    const passengers = booking.getPassengersIncludingInfants();
    const reportPassengers = passengers.map((p) => ({
      firstName: p.firstName,
      lastName: p.lastName,
      ticketNumber: p.ticketNumber,
      bookingClass: p.bookingClass,
      isHead: p.passengerID === get(headPassenger).passengerID,
    }));

    return reportPassengers;
  }

  // eslint-disable-next-line class-methods-use-this
  getBags() {
    const storedBags = get(bags);
    const reportBags = storedBags.map((bag) => ({
      bagTag: bag.bagTagID,
      weightKgs: bag.weight,
      heightMm: AppReport.toMillimetre(bag.height),
      widthMm: AppReport.toMillimetre(bag.width),
      lengthMm: AppReport.toMillimetre(bag.length),
      bagTagActivated: bag.bagTagActivated
    }));

    return reportBags;
  }

  resetReportObj() {
    try {
      this.reportObj = { application: [] };
      this.lastUpdatedStep = null;
    } catch (error) {
      logger.warn(error);
    }
  }

  static getUtcTimeStamp() {
    return new Date().toISOString();
  }

  static toMillimetre(dimInCm) {
    return dimInCm * 10;
  }
}

export const appReport = new AppReport();

/** Service config. */

class ConnectionDetails {
  constructor(uri, apiKey = '') {
    this.uri = uri;
    this.apiKey = apiKey;
  }
}

/** Envs at Elenium. */
const Envs = Object.freeze({
  DEV: 'dev',
  TEST: 'test',
  UAT: 'uat',
  PROD: null
});

/** Apps at Elenium. */
const Apps = Object.freeze({
  SWITCHBOARD: 'api',
  FLIGHTDECK: 'flightdeck',
});

function buildUri(app, env) {
  if (app === Apps.SWITCHBOARD && env === Envs.DEV) {
    return `https://dev2.ey.${app}.elenium.com/`;
  }
  if (app === Apps.SWITCHBOARD && env === Envs.UAT) {
    return `https://uat2.ey.${app}.elenium.com/`;
  }
  if (app === Apps.SWITCHBOARD && env === Envs.PROD) {
    return `https://ey2.${app}.elenium.com/`;
  }

  // the flight deck url will be picked up from here
  const prefix = env ? `${env}.ey` : 'ey';
  return `https://${prefix}.${app}.elenium.com/`;
}

/**
 * Creates a function that can create an application Uri.
 *
 * @param {string} app - Member of Apps.
 * @returns {Function}
 */
function buildAppUriFactory(app) {
  return (env) => {
    return buildUri(app, env);
  };
}

const buildSwitchboardUri = buildAppUriFactory(Apps.SWITCHBOARD);
const buildFlightdeckUri = buildAppUriFactory(Apps.FLIGHTDECK);

/** Switchboard Uris. */
const SwitchboardUris = Object.freeze({
  LOCAL: 'http://localhost:9002/graphql/',
  TEST: buildSwitchboardUri(Envs.TEST),
  DEV: buildSwitchboardUri(Envs.DEV),
  UAT: buildSwitchboardUri(Envs.UAT),
  PROD: buildSwitchboardUri(Envs.PROD),
});

const SwitchboardApiKeys = Object.freeze({
  NOT_PROD: '69d3a4ff-efa8-49db-82ae-ef973cec7530',
  PROD: '69d3a4ff-efa8-49db-82ae-ef973cec7530',
});

const FlightdeckApiKeys = Object.freeze({
  NOT_PROD: 'ea92cf6a-ce52-4e3c-b4ba-41ee2806e9e0',
  PROD: '5d6f556d-e193-4f35-9939-1d5b5d0f4b5b',
});

export const AmadeusApiKeys = Object.freeze({
  GRAND_TYPE: 'client_credentials',
  CLIENT_ID: '1PGJIR4XAzbM8eeRfdfp7WcxllfJFm2G',
  CLIENT_SECRET: 'yvGT2TeV0xze4Ga9',
  GUEST_OFFICE_ID: 'AUHEY06EB'
});

/** Camera socket device URI. */
export const cameraUri = Object.freeze({
  DEV: 'ws://127.0.0.1:2020/',
});

/** Flightdeck Uris. */
const FlightdeckUris = Object.freeze({
  DEV: buildFlightdeckUri(Envs.DEV),
  TEST: buildFlightdeckUri(Envs.UAT),
  UAT: buildFlightdeckUri(Envs.UAT),
  PROD: buildFlightdeckUri(Envs.PROD),
  STAGEDECK: 'https://stagedeck.elenium.com/',
});

/** Resolution settings. */
export const Resolution = Object.freeze({
  height: 1920,
  width: 1080,
});

/** Mapping of frontend uri fragments to Switchboard uris. */
const SwitchboardUriMap = Object.freeze({
  'dev.ey.apps': new ConnectionDetails(
    SwitchboardUris.DEV,
    SwitchboardApiKeys.NOT_PROD,
  ),
  'dev2.ey.apps': new ConnectionDetails(
    SwitchboardUris.DEV,
    SwitchboardApiKeys.NOT_PROD,
  ),
  'test.ey.apps': new ConnectionDetails(
    SwitchboardUris.TEST,
    SwitchboardApiKeys.NOT_PROD,
  ),
  'uat.ey.apps': new ConnectionDetails(
    SwitchboardUris.UAT,
    SwitchboardApiKeys.NOT_PROD,
  ),
  'uat2.ey.apps': new ConnectionDetails(
    SwitchboardUris.UAT,
    SwitchboardApiKeys.NOT_PROD,
  ),
  'ey.apps': new ConnectionDetails(
    SwitchboardUris.PROD,
    SwitchboardApiKeys.PROD,
  ),
  'ey2.apps': new ConnectionDetails(
    SwitchboardUris.PROD,
    SwitchboardApiKeys.PROD,
  ),
  default: new ConnectionDetails(
    SwitchboardUris.DEV,
    SwitchboardApiKeys.NOT_PROD,
  ),
});

/** Mapping of frontend uri fragments to Flightdeck uris. */
const FlightdeckUriMap = Object.freeze({
  'dev.ey.apps': new ConnectionDetails(
    FlightdeckUris.DEV,
    FlightdeckApiKeys.NOT_PROD,
  ),
  'dev2.ey.apps': new ConnectionDetails(
    FlightdeckUris.DEV,
    FlightdeckApiKeys.NOT_PROD,
  ),
  'test.ey.apps': new ConnectionDetails(
    FlightdeckUris.TEST,
    FlightdeckApiKeys.NOT_PROD,
  ),
  'uat.ey.apps': new ConnectionDetails(
    FlightdeckUris.UAT,
    FlightdeckApiKeys.NOT_PROD,
  ),
  'uat2.ey.apps': new ConnectionDetails(
    FlightdeckUris.UAT,
    FlightdeckApiKeys.NOT_PROD,
  ),
  'ey.apps': new ConnectionDetails(
    FlightdeckUris.PROD,
    FlightdeckApiKeys.PROD,
  ),
  'ey2.apps': new ConnectionDetails(
    FlightdeckUris.PROD,
    FlightdeckApiKeys.PROD,
  ),
  default: new ConnectionDetails(
    FlightdeckUris.DEV,
    FlightdeckApiKeys.NOT_PROD,
  ),
});

/** Mapping of frontend uri fragments to PayEtihad urls. */
const PayEtihadUriMap = Object.freeze({
  localhost: new ConnectionDetails('https://test.etihad.airlines.api.amadeus.com/v1/'),
  'test.ey.apps': new ConnectionDetails('https://test.etihad.airlines.api.amadeus.com/v1/'),
  'dev.ey.apps': new ConnectionDetails('https://test.etihad.airlines.api.amadeus.com/v1/'),
  'dev2.ey.apps': new ConnectionDetails('https://test.etihad.airlines.api.amadeus.com/v1/'),
  'uat.ey.apps': new ConnectionDetails('https://test.etihad.airlines.api.amadeus.com/v1/'),
  'uat2.ey.apps': new ConnectionDetails('https://test.etihad.airlines.api.amadeus.com/v1/'),
  'ey2.apps': new ConnectionDetails('https://etihad.airlines.api.amadeus.com/v1/'),
  default: new ConnectionDetails('https://test.etihad.airlines.api.amadeus.com/v1/'),
});

/** Mapping of front-0e */
const AmadeusBoardingPassUriMap = Object.freeze({
  localhost: new ConnectionDetails('https://test.etihad.airlines.api.amadeus.com/'),
  'dev2.ey.apps': new ConnectionDetails('https://test.etihad.airlines.api.amadeus.com/'),
  'uat2.ey.apps': new ConnectionDetails('https://test.etihad.airlines.api.amadeus.com/'),
  'ey2.apps': new ConnectionDetails('https://etihad.airlines.api.amadeus.com/'),
  default: new ConnectionDetails('https://test.etihad.airlines.api.amadeus.com/'),
});

/**
 * Match a uri from a map object with fragment from the current hostname.
 *
 * @param {object} uriMap - Maps hostname fragments with URIs.
 * @returns {string} The URI that corresponds to the current hostname.
 */
function matchUri(uriMap) {
  const hostname = window.location.hostname;
  const fragmentRegex = /(.*)\.elenium\.com/;
  const localRegex = /(localhost*)/;
  const [, fragment] =
    hostname.match(fragmentRegex) || hostname.match(localRegex) || [];
  return fragment in uriMap ? uriMap[fragment] : uriMap['default'];
}

/**
 * Return a function that returns a service Uri for a given uri map.
 *
 * @param {object} uriMap - A mapping of uri fragments to service uris.
 * @returns {Function} A function that will return a service uri.
 */
function getUriFactory(uriMap) {
  return () => {
    return matchUri(uriMap).uri;
  };
}

/**
 * Return a function that returns a service API key for a given uri map.
 *
 * @param {object} uriMap - A mapping of uri fragments to service uris.
 * @returns {Function} A function that will return a service uri.
 */
function getApiKeyFactory(uriMap) {
  return () => {
    return matchUri(uriMap).apiKey;
  };
}

/** Determine the correct service URIs. */
export default {
  getSwitchboardUri: getUriFactory(SwitchboardUriMap),
  getSwitchboardApiKey: getApiKeyFactory(SwitchboardUriMap),
  getFlightdeckUri: getUriFactory(FlightdeckUriMap),
  getFlightdeckApiKey: getApiKeyFactory(FlightdeckUriMap),
  getPayEtihadUri: getUriFactory(PayEtihadUriMap),
  getAmadeusBoardingPassUri: getUriFactory(AmadeusBoardingPassUriMap),
};


export function getAmadeusClientCredentials() {
  const hostname = window.location.hostname;
  if (hostname.indexOf('ey2') > -1) {
    return {
      grant_type: 'client_credentials',
      client_id: 'T6LmPGfIgu9Pp8Ga4A8iLFDK0SFCby0A',      
      client_secret: 'USjlTqkjqhEuHhMG',
      guest_office_id: 'AUHEY06EB'
    };
  } else {
    return {
      grant_type: 'client_credentials',
      client_id: '1PGJIR4XAzbM8eeRfdfp7WcxllfJFm2G',
      client_secret: 'yvGT2TeV0xze4Ga9',
      guest_office_id: 'AUHEY06EB'  
    }
  }
}

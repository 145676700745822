import faker from 'faker';
import { format } from 'date-fns';
import { generateMrz } from 'mrz-generator';
import { v1 as uuidv1 } from 'uuid';

import airports from './data/miscellaneous/airports.json';
import {
  FARE_CODE_MAP,
  HindiCountries,
  MILLISECONDS_PER_SECOND,
  SECONDS_PER_MINUTE,
} from './const';
import iso2Iso3 from './data/miscellaneous/iso2iso3.json';
import logger from './logger';

const MINUTES_PER_HOUR = 60;

/**
 * Strip leading zeroes from a string.
 *
 * @param {string} str - The string.
 * @returns {string} - The string without leading zeroes.
 */
export function stripLeadingZeroes(str) {
  if (!str) {
    return str;
  }
  return str.replace(/^0+/, '');
}

/**
 * Get Currency Format.
 *
 * @param {string} locale - The locale. i.e. 'en-US'.
 * @param {string} currencyCode - The currency. i.e. 'YEN'.
 * @param {string} number - The number.
 * @returns {string} - The currency and currency code. i.e. ￥6.
 */
export function currencyFormat(locale, currencyCode, number) {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'AED', //currencyCode,
  }).format(number);
}

/**
 * Get Date Format.
 *
 * Get date Format from date input.
 *
 * @param {string} dateString - The string.
 * @returns {string} - The formatted date.
 */
export function dateFormat(dateString) {
  const formatter = new Intl.DateTimeFormat('en-US', { month: 'short' });
  const date = new Date(dateString);
  const day = date.getDate();
  const year = date.getFullYear();
  return `${day}-${formatter.format(date)}-${year}`;
}

/**
 * Get Time Format.
 *
 * Get time Format from date input.
 *
 * @param {string} dateString - The string.
 * @param {boolean} isTwelveHourClock - true or false
 * @returns {string} - The formatted time.
 */
export function timeFormat(dateString, isTwelveHourClock) {
  const formatter = new Intl.DateTimeFormat('en-US', {
    hour12: Boolean(isTwelveHourClock),
    hour: 'numeric',
    minute: 'numeric',
  });

  const dateTime = new Date(dateString);
  return `${formatter.format(dateTime)}`;
}

/**
 * Pause execution.
 *
 * @example
 * await sleep(1000); // sleep for 1 second.
 *
 * @param {number} ms - The number of milliseconds to sleep by.
 * @returns {promise}
 */
export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Check object is empty or not.
 *
 * @param {object} obj - A JavaScript Object.
 * @returns {boolean} - Whether the object is empty.
 */
export function isObjEmpty(obj) {
  return Object.entries(obj).length === 0 && obj.constructor === Object;
}

/**
 * Safely get the first item of an array.
 *
 * @param {Array} array - The array.
 * @returns {any} - The first item of the array.
 */
export function firstItem(array) {
  return array.find((item) => item !== undefined);
}

/**
 * Pad leading zero.
 *
 * If value is single digit, prepend a '0'.
 * Values of two or more digits will be returned as is.
 *
 * @param {number} value - A numerical value.
 * @returns {string} - The zero padded number.
 */
function padLeadingZero(value) {
  return String(value).padStart(2, '0');
}

/**
 * Pad leading 3 digits.
 *
 * If value is single digit, prepend a '00'.
 * Values of three or more digits will be returned as is.
 *
 * @param {number} value - A numerical value.
 * @returns {string} - The zero padded number.
 */
export function padLeading2Zero(value) {
  return String(value).padStart(3, '0');
}

/**
 * Convert minutes into a time offset
 *
 * @param {Integer} minutes - The minutes to convert.
 * @returns {string} - The offset, eg 10:00.
 */
function minutesToOffset(minutes) {
  return (
    `${Math.sign(minutes) !== -1 ? '+' : '-'}` +
    `${padLeadingZero(Math.abs(Math.floor(minutes / MINUTES_PER_HOUR)))}:` +
    `${padLeadingZero(minutes % MINUTES_PER_HOUR)}`
  );
}

/**
 * Return a ISO 8601 datetime stamp.
 *
 * Modifies JavaScript toISOString() by removing the miliseconds
 * and appending the time offset.
 *
 * @param {Date} date - The date.
 * @param {number} minutes - Offset in minutes.
 * @returns {string} - The ISO 8601 datetime stamp.
 */
function iso8601(date, minutes) {
  return date.toISOString().replace(/\.\d{3}Z$/, minutesToOffset(minutes));
}

/**
 * Return a timestamp of UTC time with +00:00.
 *
 * @param {Date} date - The date.
 * @returns {string} - The timestamp.
 */
export function timeStampZeroOffset(date) {
  return iso8601(date, 0);
}

/**
 * Return a timestamp of local time with local time offset.
 *
 * @param {Date} date - The date.
 * @returns {string} - The timestamp.
 */
export function timeStampLocalOffset(date) {
  return iso8601(
    new Date(
      date.getTime() +
        date.getTimezoneOffset() *
          SECONDS_PER_MINUTE *
          MILLISECONDS_PER_SECOND,
    ),
    date.getTimezoneOffset() * -1,
  );
}

/**
 * DateTime to Timestamp convert.
 *
 * @param {string} iso8601string - The iso8601string.
 * @returns {Date} - The timestamp.
 */
export function dateToTimestamp(iso8601string) {
  return new Date(iso8601string).getTime();
}

/**
 * Compare two arrays.
 *
 * Only works for arrays containing scalars (ie === must work).
 *
 * @param {Array} array1 - first array to compare
 * @param {Array} array2 - second array to compare
 * @returns {boolean} - Whether or not the arrays match.
 */
export function compareArrays(array1, array2) {
  return (
    array1.length === array2.length &&
    array1.every((value, index) => value === array2[index])
  );
}

/**
 * Convert seconds into milliseconds. i.e. 1 becomes 1000.
 *
 * @param {number} seconds - A number of seconds.
 * @returns {number} - Conversion to milliseconds.
 */
export function secondsToMilliseconds(seconds) {
  return seconds * MILLISECONDS_PER_SECOND;
}

/**
 * Return an array of integers counting from 0.
 *
 * @param {number} size - The number of elements in the array.
 * @param {number} start - The number to start counting from.
 * @returns {Array} - The retVal number.
 */
export function range(size, start = 0) {
  const retVal = [];
  const end = start + size;
  for (let i = start; i < end; i++) {
    retVal.push(i);
  }
  return retVal;
}

/**
 * Wrap a function so that it can only be used once.
 *
 * @example
 * function sayHello(name) {
 *   logger.info(`Hello ${name}.`};
 * }
 * const sayHelloOnce = onlyOnce(sayHello);
 * sayHelloOnce('Foo');
 * // 'Hello Foo';
 * sayHelloOnce('Bar');
 * // nothing is printed.
 *
 * @param {Function} func - A JavaScript function.
 * @returns {Function} The wrapped function.
 */
export function onlyOnce(func) {
  return (...args) => {
    if (func.alreadyRun) {
      return;
    }
    func.alreadyRun = true;
    return func(...args);
  };
}

/**
 * Display a nice name for all-caps constants.
 *
 * @param {string} name - The name.
 * @returns {string} - The name in lowercase.
 */
export function prettifyAllCaps(name) {
  return name[0] + name.slice(1).toLowerCase().replace(/_/g, ' ');
}

/**
 * Create a descriptive name for a route from routes.js
 *
 * Strips leading slash
 * Uses 'home' for the '/' route.
 * Converts first character to uppercase.
 * Replaces '-' with ' '
 *
 * @param {string} path - The path.
 * @returns {string} - The descriptive name.
 */
export function routeName(path) {
  path = path.substring(1, path.length) || 'home';
  path = path.replace(/^\w/, (c) => c.toUpperCase());
  path = path.replace(/-/g, ' ');
  path = path.replace(/\//g, '');
  return path;
}

/**
 * Check if numbers are equal with tolerance.
 *
 * @param {number} number1 - The first number.
 * @param {number} number2 - The second number.
 * @param {number} tolerance - The tolerance.
 * @returns {boolean} Whether or not the numbers are less or equal with the tolerance.
 */
export function numbersEqualWithTolerance(number1, number2, tolerance = 0.5) {
  return Math.abs(number1 - number2) <= tolerance;
}

/**
 * Performs the equivalent of Array.prototype.map() on an object.
 *
 * @param {object} obj - The object to map.
 * @param {Function} func - The function to execute on each item in the object.
 * @returns {object} The new object resulting from the map.
 */
export function objectMap(obj, func) {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value], index) => [
      key,
      func(value, key, index),
    ]),
  );
}

/**
 * Safely looks up a nested attribute from an object.
 *
 * @example
 * const myObj = {foo: {bar: {baz: 'qux'}}};
 * const qux = safeObjectAttribute(myObj, 'bar.baz'); // 'qux'
 * const garply = safeObjectAttribute(myObj, 'corge.grault'); // undefined
 *
 * @param {object} obj - A JavaScript object.
 * @param {string} locator - A dot separated list of attributes.
 * @returns {any} The located attribute.
 */
export function safeObjectAttribute(obj, locator) {
  const SEPARATOR = '.';
  const dotSeparated = locator.split(SEPARATOR);
  const segments = [];
  dotSeparated.forEach((segment) => {
    const matches = segment.match(/^(.+)\[(.+)\]$/);
    if (matches) {
      segments.push(matches[1], matches[2]);
    } else {
      segments.push(segment);
    }
  });
  return segments.reduce((result, nextLocator, index, source) => {
    if (index === source.length - 1) {
      return result[nextLocator];
    }
    return result[nextLocator] || {};
  }, obj || {});
}

/**
 * Return a random number.
 *
 * @param {number} length - The length of the number to return.
 * @returns {number}
 */
function randomNumbers(length) {
  const BASE = 10;
  const OFFSET = 2;
  return [...Array(length)]
    .map(() => Math.random().toString(BASE)[OFFSET])
    .join('');
}

/**
 * Return a string of random characters.
 *
 * @param {number} length - The length of the string to return.
 * @returns {string}
 */
export function randomString(length) {
  const BASE = 36; // Used with toString() to return a base 36 number.
  const OFFSET = 2; // Offset of result of toString() which will be "0.XXXX"
  return [...Array(length)]
    .map(() => Math.random().toString(BASE)[OFFSET])
    .join('');
}

/**
 * Timeout a promise.
 *
 * @param {number} waitTime - The number of milliseconds to wait.
 * @param {promise} promise - The promise to wait for.
 * @returns {promise} Resolved by either the timeout or the provided promise.
 */
export function timeoutPromise(waitTime, promise) {
  const timeout = new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      clearTimeout(timeoutId);
      reject(`Timed out in ${waitTime}ms.`);
    }, waitTime);
  });

  return Promise.race([promise, timeout]);
}

/**
 * Convert JS Date object into Sabre Airport time.
 *
 * @param {Date} date - JavaScript Date object.
 * @returns {string} ISO 8601 with offset truncated (in local airport time).
 */
export function dateToAirportFormat(date) {
  const AIRPORT_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";
  return format(date, AIRPORT_FORMAT);
}

/**
 * Get the date value from ISO 8601 parameter.
 *
 * @param {Date} datetime - ISO 8601 (in local airport time)
 * @returns {string} formatted date string dd-MMM-yyyy.
 */
export function dateTimeToDateFormatted(datetime) {
  return dateFormat(dateTimeToDate(datetime));
}

/**
 * Return the date portion of an ISO 8601.
 *
 * @param {string} datetime - In ISO 8601
 * @returns {string} The YYYY-MM-DD portion of the input.
 */
export function dateTimeToDate(datetime) {
  return (datetime || '').split('T')[0] || '';
}

/**
 * Get the time portion of a parameter string.
 *
 * @param {string} datetime - dateTime parameter.
 * @returns {string} The hh:mm portion of the input.
 */
export function dateTimeToTime(datetime) {
  let timeValue = datetime;
  
  if (datetime.indexOf('T') > -1) {
    timeValue = (datetime || '').split('T')[1] || '';
    timeValue = timeValue.substring(0, 5);
  } else {
    timeValue = datetime.substring(0, 5) 
  }

  return timeValue;
}



/**
 * Get the date in YYMMDD format.
 *
 * @param {string} date - date parameter. Today's date as default.
 * @returns {string} The matching object key.
 */
export function getDateYYMMDD(date = null) {
  const today = date ? date : new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0');
  const yy = String(today.getFullYear()).substring(2);

  return yy + mm + dd;
}

/**
 * The booking date is formatted as YYYY-MM-DDThh:mm:ss but 
 * amadeus API needs YYYYMMDD without '-'.
 *
 * @param {string} dateValue - dateValue parameter.
 * @returns {string} The formatted value.
 * 
 * 
 */
 export function formatDateYYYYMMDD(dateValue) {
  const dashReg = /\.*-/g;
  const zeroReg = /\.*:0{2}/g;
  return dateValue.replace(dashReg, '').replace(zeroReg, '');
}

/**
 * Return a fare class name for a fareCode
 *
 * @param {string} fareCode - A single character eg. 'J' fareCode.
 * @returns {string} The fare class name, eg 'Economy'
 */
export function mapFareCode(fareCode) {
  const [fareClass] =
    Object.entries(FARE_CODE_MAP).find(([fareClass, codes]) => {
      return codes.includes(fareCode);
    }) || [];
  return fareClass;
}

/**
 * Filters duplicate items from array.
 *
 * @param {Array} array - An array.
 * @returns {Array} The input array with duplicate items removed.
 */
export function dedupeArray(array) {
  return array.filter((item, index) => array.indexOf(item) === index);
}

/**
 * @param {string} emailAddress - The email address.
 * @returns {string} - emailAddress.
 */
export function checkEmailPattern(emailAddress) {
  const emailRegex =
    /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
  if (emailRegex.test(emailAddress)) {
    return emailAddress;
  }
  return null;
}

/**
 * Creates a Map of airport data.
 *
 * @returns {Map} the Map object.
 */
const iataToIso2Map = (() => {
  const map = airports.reduce((result, item) => {
    result.set(item.iata_code, item);
    return result;
  }, new Map());

  return () => map;
})();

/**
 * Converts an airportCode (iata) to countryCode (iso2).
 *
 * @example - 'LHR' returns 'gb'.
 * @param {string} iata - the airportCode.
 * @returns {string} the countryCode.
 */
export function convertIataToIso2(iata) {
  iata = iata.toUpperCase();
  let iso2 = null;

  if (iataToIso2Map().has(iata)) {
    iso2 = iataToIso2Map().get(iata).iso_country;
  }

  return iso2 || '';
}

/**
 * Replace the seat number in a BCBP (boarding pass barcode).
 *
 * @param {string} bcbp - The raw barcode BCBP string.
 * @param {string} seatNumber - The seatNumber (eg. 26J)
 * @returns {string} The new BCBP string.
 */
export function replaceBCBPSeatNumber(bcbp, seatNumber) {
  const SEAT_NUMBER_OFFSET = 48;
  const SEAT_NUMBER_FIELD_LENGTH = 4;

  return (
    bcbp.substring(0, SEAT_NUMBER_OFFSET) +
    seatNumber.padStart(SEAT_NUMBER_FIELD_LENGTH, '0') +
    bcbp.substring(SEAT_NUMBER_OFFSET + SEAT_NUMBER_FIELD_LENGTH)
  );
}

/**
 * Find an object key by its value.
 *
 * If multiple keys share a value, will return the first matching key.
 *
 * @example - {'GB': 'GBR'} returns 'GB'.
 * @param {object} object - A JavaScript object.
 * @param {any} value - The value to match in the object.
 * @returns {string} The matching object key.
 */
export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value) || '';
}

/**
 * Find an object value by its key.
 *
 * @example - {'GB': 'GBR'} returns 'GBR'.
 * @param {object} object - A JavaScript object.
 * @param {any} key - The key to match in the object.
 * @returns {string} The matching object key.
 */
export function getValueByKey(object, key) {
  return Object.values(object).find((value) => object[key] === value) || '';
}

/**
 * Generates a dummy MRZ.
 *
 * @param {object} passenger - The passenger.
 * @returns {string} - The dummy MRZ.
 */
export function generateDummyMrz(passenger = null) {
  if (!passenger) {
    passenger = {
      docS: null,
      firstName: faker.name.firstName(),
      gender: faker.name.gender(),
      lastName: faker.name.lastName(),
    };
  }

  const iso2 = getValueByKey(iso2Iso3, faker.address.countryCode());
  const mrzData = {
    passport: {
      mrzType: 'td3',
      type: 'p',
      issuingCountry: iso2,
      number: passenger.docS
        ? passenger.docS.split('/')[2]
        : randomNumbers(9).toString(),
      expirationDate: `11 May ${(
        new Date().getFullYear() + 1
      ).toString()} 00:00:00 GMT`,
    },
    user: {
      surname: passenger.lastName,
      givenNames: passenger.firstName,
      nationality: iso2,
      dateOfBirth: '17 Oct 1966 00:12:00 GMT',
      sex: passenger.gender === 'M' ? 'male' : 'female',
    },
  };

  try {
    return generateMrz(mrzData);
  } catch (err) {
    logger.info(`mrzData: ${JSON.stringify(mrzData)}.`, err);
  }
}

/**
 * Play an audio file.
 *
 * @param {string} nationality - The passenger nationality.
 * @param {string} screen - The screen.
 */
export function playAudio(nationality, screen) {
  let language = null;

  if (HindiCountries.includes(nationality)) {
    language = 'hi_IN';
  }

  if (language) {
    new Audio(`audio/instructions/${screen}_${language}.mp3`).play();
  }
}

/**
 * Convert a string into an array of numbers.
 *
 * The number for each string character is its utf-16 value,
 * truncated to 8 bits. This will be the ASCII code for most
 * characters.
 *
 * @param {string} sourceString - The string to convert.
 * @returns {number[]} - An array of numbers.
 */
export function stringToNumberArray(sourceString) {
  return sourceString.split('').map((c) => c.charCodeAt(0) % 256);
}

/**
 * Determine if a variable is a function.
 *
 * @param {any} functionToCheck - A variable that could be a function.
 * @returns {boolean} - Whether or not functionToCheck is a function.
 */
export function isFunction(functionToCheck) {
  return (
    functionToCheck &&
    {}.toString.call(functionToCheck) === '[object Function]'
  );
}

/**
 * Generate a UUID v1 with a user provided node.
 *
 * UUID v1 provides for six bytes to be user generated, ie the
 * "node" field. This function takes the final six characters
 * of the provided string, and uses the least significant byte
 * of each character (ie. the ASCII code) to create the node field.
 *
 * @param {string} nodeString - Text to be used to create the node field.
 * @returns {string} - The resultant UUID.
 */
export function uuidv1WithNode(nodeString) {
  const UUID_NODE_LENGTH = 6;
  const node = stringToNumberArray(nodeString.slice(UUID_NODE_LENGTH * -1));
  return uuidv1({ node });
}

/**
 * get the banner message if maketing flight is not the
 *  same as OperatingFlightNumber
 *
 *  @param {object} segment - individual segment of the flight
 *  @returns {string} message to be printed in the boarding pass
 */
export function getBannerMessage(segment) {
  let bannerMessage = '';
  if (
    segment.flightNumber !== segment.marketingFlightNumber &&
    segment.airlineCode !== segment.marketingAirlineCode
  ) {
    bannerMessage = `SOLD AS ${segment.marketingAirlineCode} ${segment.marketingFlightNumber}`;
  }
  return bannerMessage;
}

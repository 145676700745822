<script>
  /** Porter Checking Your bag
   *
   * On this screen, the bag has already been weighed, the bag tag printed and
   * activated.
   * This screen waits simply waits for the bag to be lifted before proceeding
   * to the next screen.
   * This screen decides whether to accept another bag or to to proceed to the
   * end of the transaction.
   */
  import { _ } from 'svelte-i18n';
  import { onMount, onDestroy } from 'svelte';
  import { push } from 'svelte-spa-router';
  import { haveRemainingBags, currentBag, bagInjected } from '../../js/stores';
  import logger from '../../js/logger';
  import Footer from '../components/Footer/index.svelte';
  import Header from '../components/Header/index.svelte';
  import LoadingAnimation from '../components/LoadingAnimation.svelte';
  import { ApplicationStep, appReport } from '../../js/appReport';
  let unsubscribeBagInjected = () => {};
  let unsubscribe = () => {};

  onMount(() => {
    logger.info('Inside PorterCheckingYourBagHybrid.svelte... inside onMount()');
    unsubscribeBagInjected = bagInjected.subscribe(bagInjectedSubscription); 
    unsubscribe = currentBag.subscribe(currentBagSubscription);

    if (!haveRemainingBags()) {
      push('/bag-drop-completed');
    }
  });

  function bagInjectedSubscription(injected) {
    logger.info('Inside PorterCheckingYourBagHybrid.svelte... inside bagInjectedSubscription()... injected = ', injected);
    if (injected) {
      logger.info('Inside PorterCheckingYourBagHybrid.svelte...  bagInjectedSubscription(injected) .. injected is true');
      
      appReport.updateStepSuccess(ApplicationStep.BAG_ASSESSMENT);
      
      bagInjected.set(false);
      
      if (haveRemainingBags()) {
        logger.info('Continuing loop');        
        logger.info('Inside PorterCheckingYourBagHybrid.svelte...  bagInjectedSubscription(injected) .. haveRemainingBags() is true..');        
        push('/porter-place-passenger-bag-hybrid');     
      } else {
        logger.info('Inside PorterCheckingYourBagHybrid.svelte...  bagInjectedSubscription(injected) .. haveRemainingBags() is false.. navigate to Bag-drop-completed');
        logger.info('End of loop.');
        push('/bag-drop-completed');
      }
    }
  }

  function currentBagSubscription(bag) {
    logger.info('Inside PorterCheckingYourBagHybrid.svelte...  currentBag.subscribe. bag = ', bag, ' and bag.isLifted = ', bag.isLifted);
    if (bag && bag.isLifted) {
      logger.info('Inside PorterCheckingYourBagHybrid.svelte...  currentBag.subscribe.. bag  is true && bag.isLifted is true');

      appReport.updateStepSuccess(ApplicationStep.BAG_ASSESSMENT);

      logger.info('Detected lifted bag.');
      // Bag has been lifted.
      if (haveRemainingBags()) {
        logger.info('Continuing loop');
        push('/porter-place-passenger-bag-hybrid');
      } else {
        logger.info('End of loop.');
        push('/bag-drop-completed');
      }
    }
  }

  onDestroy(() => {
    unsubscribeBagInjected();
    unsubscribe();
  });

</script>


<style>
  .processBag {    
    width: 100%;
    padding-top: 15rem;
    padding-bottom: 5rem;
    text-align: center;
  }

  .loading-style {   
    width: 100%;
    text-align: center;
    margin-top: 100px;    
  }
</style>

<Header hasHomeButton={false} />

  <div class="processBag">
    <p class="italic px-14 text-3.75 text-gold-100 text-center">
      {$_('processBag.acceptingBag')}
    </p>
  </div>

  <div class="loading-style">
    <h1>{$_('app.pleaseWait')}</h1>
    <div class="loading-animation">
      <LoadingAnimation />
    </div>
  </div>

<Footer />


/** Wrap fetch() to allow aborting of all pending requests. */

import {
  AbortController,
  abortableFetch,
} from 'abortcontroller-polyfill/dist/cjs-ponyfill';
import logger from './logger';

const originalFetch = window.fetch;
let controller;
let initialised = false;

/** Reset the AbortController. */
function resetController() {
  controller = new AbortController();
}

/** Decorate fetch() to inject our controller signal. */
function fetchDecorator(func) {
  return (resource, init) => {
    init = init || {};
    init.signal = controller.signal;
    return func(resource, init);
  };
}

/**
 * Init the transport.
 */
function initTransport() {
  resetController();
  window.fetch = fetchDecorator(abortableFetch(originalFetch).fetch);
}

/**
 * Reset the transport.
 *
 * Abort all pendings requests and prepare the abort mechanism
 * for future requests.
 */
export default function resetTransport() {
  if (initialised) {
    logger.info(
      'Aborting all fetch() requests. ' +
        'Errors will be logged for any pending requests.',
    );
    controller.abort();
  } else {
    initialised = true;
  }
  initTransport();
}

<script>
  import { _ } from 'svelte-i18n';
  import { push } from 'svelte-spa-router';

  import { booking, headPassenger, rightToLeft } from '../../../js/stores';
  import { VoiceIntent } from '../../../js/services/voicerec/voicerec';

  import FooterSecondary from '../../components/FooterSecondary.svelte';
  import Items from './Items.svelte';
  import TripExtrasPayment from './modal/TripExtrasPayment.svelte';

  export let returnPage = '';

  let isSelected = false;
  let showModal = false;

  function cancelHandler() {
    push(`/${returnPage}`);
  }

  function confirmHandler() {
    showModal = true;
  }
</script>

<TripExtrasPayment handleContinue={cancelHandler} bind:showModal />

<div class="-mx-8">
  <div class="{$rightToLeft ? 'text-right' : 'text-left'} mb-3">
    <h3
      class="font-AltisMedium text-1.375 text-center text-gold-100 whitespace-pre-wrap"
    >
      {$_('tripExtras.textLine1', {
        values: {
          title: String($headPassenger.title ? $headPassenger.title : ''),
          lastName: String($headPassenger.lastName),
          flightName: booking.getDisplayedFlightCode(),
        },
      })}
    </h3>
  </div>

  <Items bind:isSelected />

  <h3 class="font-AltisMedium text-1.375 text-center whitespace-pre-wrap">
    {$_('tripExtras.textLine2')}
  </h3>
</div>

<FooterSecondary
  buttonLeftHandler={cancelHandler}
  buttonLeftIntent={VoiceIntent.CANCEL}
  buttonLeftText={$_('app.cancel')}
  buttonRightHandler={confirmHandler}
  buttonRightIntent={VoiceIntent.CONFIRM}
  buttonRightText={$_('app.confirmPurchase')}
  isButtonRightActive={isSelected}
/>

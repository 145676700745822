/* Store app state. */
/* eslint-disable import/prefer-default-export */
import { derived } from 'svelte/store';
import { locale } from 'svelte-i18n';
import { location } from 'svelte-spa-router';
import { resettable } from './extensions/resettable';
import { pnr } from './booking';
import { getKioskName } from './cuss';
import { uuidv1WithNode } from '../utils';

const ARABIC_LOCALE = 'ar';
const rightToLeftLocales = [ARABIC_LOCALE];

/* Whether or not the selected locale is right-to-left. */
export const rightToLeft = derived(locale, ($locale) =>
  rightToLeftLocales.includes($locale),
);

/* Whether or not the Arabic locale is in effect. */
export const isArabicLocale = derived(
  locale,
  ($locale) => $locale === ARABIC_LOCALE,
);

/* Current position of the Footer Wizard. */
export const wizardPosition = resettable(0);

/* Whether or not the application currently cares about scanned barcodes. */
export const acceptingBarcodes = derived(
  [location, pnr],
  ([$location, $pnr]) => {
    return new Boolean($pnr) && $location === '/';
  },
);

/**
 * A unique transaction id for the transaction.
 *
 * Uses UUID v1 format. The 'node' component of the UUID derives from
 * the kioskId.
 */
export const appTransactionId = resettable(() => {
  return uuidv1WithNode(getKioskName());
});

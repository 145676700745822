<script>
  import { _ } from 'svelte-i18n';
  import { onDestroy, onMount } from 'svelte';
  import { push } from 'svelte-spa-router';

  import logger from '../../js/logger';
  import { passportNumbers, pnr, userInitiationMethod } from '../../js/stores';
  import { TransactionInitiator } from '../../js/const';
  import { ScreenIdleTimeout } from '../../js/screenIdleTimeout';

  import Content from '../components/Content.svelte';
  import Footer from '../components/Footer/index.svelte';
  import Header from '../components/Header/index.svelte';
  import InputField from '../components/InputField.svelte';
  import OnScreenKeyboard from '../components/OnScreenKeyboard/index.svelte';

  let screenIdleTimeout = null;

  /**
   * Handle updates to the PNR field.
   *
   * Trigger a search by PNR when a PNR is input.
   *
   * @param {number} inputValue - The value of the field.
   */
  function handlePNR(inputValue) {
    screenIdleTimeout?.reset();

    const pnrRegex = /^[A-Za-z0-9]{6}$/;

    if (pnrRegex.test(inputValue)) {
      logger.info(`PNRSearchForBooking inputValue: ${inputValue}.`);
      userInitiationMethod.set(TransactionInitiator.PNR);
      pnr.set(inputValue);
      passportNumbers.reset();
      push('/searching');
    }
  }

  onMount(() => {
    logger.info('Entered PNRSearchForBooking.');

    screenIdleTimeout = new ScreenIdleTimeout();
    screenIdleTimeout.start();
  });

  onDestroy(() => {
    screenIdleTimeout?.stop();
  })
</script>

<Header />

<Content>
  <span slot="heading">{$_('manualSearch.heading')}</span>
  <span slot="text">{$_('manualSearch.textLine1')}</span>

  <div slot="main">
    <div class="my-12">
      <InputField
        hasAutofocus
        label="manualSearch.bookingReferenceNumber"
        valueHandler={handlePNR}
      />
    </div>

    <OnScreenKeyboard  hideSpecialCharacters={true} />
  </div>
</Content>

<Footer />

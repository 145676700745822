import { END_TRANSACTION_REASON, SCREEN_IDLE_TIMEOUT } from './const';
import { secondsToMilliseconds } from './utils';

import endTransaction from './endTransaction';
import logger from './logger';

// eslint-disable-next-line import/prefer-default-export
export class ScreenIdleTimeout {
  constructor() {
    this.timeout = null;
  }

  start() {
    logger.debug(`ScreenIdleTimeout - Start. ${new Date().toISOString()}`);
    this.startInternal();
  }

  startInternal() {
    this.timeout = setTimeout(() => {
      logger.debug(`ScreenIdleTimeout - Timeout occurs.  ${new Date().toISOString()}`);
      endTransaction(END_TRANSACTION_REASON.SCREEN_IDLE_TIMEOUT_OCCURRED);
    }, secondsToMilliseconds(SCREEN_IDLE_TIMEOUT));
  }

  stop() {
    logger.debug('ScreenIdleTimeout - Stop.');
    this.stopInternal();
  }

  stopInternal() {
    clearTimeout(this.timeout);
    this.timeout = null;
  }

  reset() {
    logger.debug('ScreenIdleTimeout - Reset.');
    this.stopInternal();
    this.startInternal();
  }
}

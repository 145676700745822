import { get } from 'svelte/store';
import { location, push } from 'svelte-spa-router';
import { appReport } from './appReport';
import { checkInPassengersManager } from './stores/checkInPassengers';
import { END_TRANSACTION_REASON } from './const';
import { printBoardingPass } from './stores/user';
import { receipt } from './stores/receipt';
import { seatChangesManager } from './stores/seatChanges';
import { handleTransactionEnd } from './handleTransactionEnd';
import { booking } from './stores';

const bagDropCompleted = '/bag-drop-completed';

/**
 * Logic for ending a transaction.
 * @param {string} endTransactionReason - A string which tells the reason for end of transaction
 */
export default function endTransaction(endTransactionReason = null) {
  appReport.trackEndTransactionCall(endTransactionReason);

  /* End transaction if there is a timatic checks failure */
  if (endTransactionReason === END_TRANSACTION_REASON.TIMATIC_CHECKS_FAILED) {
    // go to home page
    handleTransactionEnd();
  } else {
    if (
      (receipt.haveBagsBeenInjected() ||
        get(printBoardingPass) ||
        checkInPassengersManager.getPassengersCheckInSuccessfully()) && // we should only check checked in passengers, if they choose YES from BP Modal
        !seatChangesManager.offloadOccurred() &&
        get(location) !== bagDropCompleted
    ) {
      push(bagDropCompleted);
    } else {
      handleTransactionEnd();
    }
  }
}


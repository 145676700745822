import logger from '../logger';
import { compareArrays } from '../utils';

/**
 * Correct certain OCR misreads in an MRZ.
 *
 * Performs coercion of various fields using coerceMRZField().
 * Expects MRZ TD3 (Passport).
 *
 * @param {string[]} mrzArray - The array.
 * @returns {string[]} - The MRZ array.
 */
// eslint-disable-next-line import/prefer-default-export
export function coerceMRZ(mrzArray) {
  const original = [...mrzArray];
  const coercionRules = [
    {
      name: 'first line',
      line: 0,
      start: 0,
      length: 42,
      toNumeric: false,
    },
    {
      name: 'document number check digit',
      line: 1,
      start: 9,
      length: 1,
      toNumeric: true,
    },
    {
      name: 'nationality',
      line: 1,
      start: 10,
      length: 3,
      toNumeric: false,
    },
    {
      name: 'date of birth',
      line: 1,
      start: 13,
      length: 6,
      toNumeric: true,
    },
    {
      name: 'date of birth check digit',
      line: 1,
      start: 19,
      length: 1,
      toNumeric: true,
    },
    {
      name: 'date of expiry',
      line: 1,
      start: 21,
      length: 6,
      toNumeric: true,
    },
    {
      name: 'date of expiry check digit',
      line: 1,
      start: 27,
      length: 1,
      toNumeric: true,
    },
    {
      name: 'optional data check digit',
      line: 1,
      start: 42,
      length: 1,
      toNumeric: true,
    },
    {
      name: 'lower line overall check digit',
      line: 1,
      start: 43,
      length: 1,
      toNumeric: true,
    },
  ];

  coercionRules.forEach((rule) => {
    mrzArray[rule.line] = coerceMRZField(
      mrzArray[rule.line],
      rule.name,
      rule.start,
      rule.length,
      rule.toNumeric,
    );
  });

  if (!compareArrays(original, mrzArray)) {
    logger.info('MRZ fields have been coerced.');
  }

  return mrzArray;
}

/**
 * Converts digits to letters and vice versa within MRZ fields.
 *
 * Supported letter/digit pairs are defined in substitutionMap.
 *
 * @param {string} line - a line in the MRZ.
 * @param {string} fieldName - name of the field for logging.
 * @param {number} start - the location of the start of the field in the line.
 * @param {number} length - the length of the field.
 * @param {boolean} toNumeric - whether to convert a digit or a letter.
 * @returns {string} the resultant MRZ line.
 */
function coerceMRZField(line, fieldName, start, length, toNumeric) {
  line = line || '';
  const substitutionMap = {
    O: '0',
    Z: '2',
    T: '7',
  };
  const field = line.substr(start, length);
  const coerced = Object.entries(substitutionMap).reduce(
    (accumulator, [letter, digit]) => {
      const find = toNumeric ? letter : digit;
      const replace = toNumeric ? digit : letter;
      return accumulator.replace(new RegExp(find, 'g'), replace);
    },
    field,
  );
  if (coerced !== field) {
    logger.info(
      `Rewrote scanned MRZ field '${fieldName}'. ` +
        `Original: '${field}'. New: '${coerced}'.`,
    );
  }

  return line.substr(0, start) + coerced + line.substr(start + length);
}
